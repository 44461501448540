import styled from "@emotion/styled";
import { Button, Stack, Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useMutation, useQueryClient } from "react-query";
import AlertPopup from "../Items/AlertPopup";
import BannerImage from "./BannerImage";

const Input = styled("input")({
  display: "none",
});

const UploadBannerImage = ({ uuid, token, type = "banner" }) => {
  const [banner, setBanner] = useState("");
  const [name, setName] = useState("");
  const queryClient = useQueryClient();

  const UploadImage = useMutation(
    (image) =>
      axios.put(`${process.env.REACT_APP_PUBLIC_URL}/images/org`, image, {
        headers: { Authorization: `Bearer ${token}` },
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: "myOrg",
        });
      },
    }
  );

  const handleImageInput = (event) => {
    const file = event.target.files[0];
    setName(file.name);

    if (file) {
      setBanner(URL.createObjectURL(file));
    }
  };

  return (
    <>
      {banner ? (
        <ImageCropper
          name={name}
          type={type}
          uuid={uuid}
          UploadImage={UploadImage}
          cancel={() => setBanner("")}
          src={banner}
        />
      ) : (
        <>
          {/* <CardMedia
            component="img"
            height="400"
            image={`${process.env.REACT_APP_IMAGE_URL}${currentImage}`}
            alt="Event banner"
          /> */}
          <BannerImage uuid={uuid} token={token} type={type} />

          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              type="file"
              onChange={handleImageInput}
            />
            <Button fullWidth variant="contained" component="span">
              Change Banner Image
            </Button>
          </label>
          <AlertPopup
            open={UploadImage.isError}
            severity="error"
            message={
              UploadImage.error?.response?.data?.message
                ? UploadImage.error?.response?.data?.message
                : "An error occurred"
            }
          />
          <AlertPopup
            open={UploadImage.isSuccess}
            severity="success"
            message="Image uploaded successfully"
          />
        </>
      )}
    </>
  );
};

export default UploadBannerImage;

const ImageCropper = ({ src, cancel, UploadImage, uuid, type, name }) => {
  const cropperRef = useRef(null);
  const [loadingUpload, setLoading] = useState(false);

  const isMountedRef = useRef(true);

  useEffect(() => {
    // Component did mount logic can go here

    // Cleanup function to set isMountedRef to false when the component unmounts
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onSave = () => {
    if (!isMountedRef.current) return; // Guard against running if component has unmounted

    setLoading(true); // Start loading
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    cropper?.getCroppedCanvas().toBlob((blob) => {
      const formData = new FormData();
      formData.append("file", blob, name);
      formData.append("link_id", uuid);
      formData.append("type", type);
      UploadImage.mutate(formData, {
        onSuccess: () => {
          if (!isMountedRef.current) return; // Check mounted status before updating state
          cancel();
        },
        onError: () => {
          if (!isMountedRef.current) return; // Check mounted status before updating state
          console.error("Upload failed");
        },
        onSettled: () => {
          if (!isMountedRef.current) return; // Always check mounted status before updating state
          setLoading(false);
        },
      });
    }, "image/jpeg");
  };

  return (
    <Stack spacing={2}>
      <Cropper
        src={src}
        style={{ height: 640, width: "100%" }}
        aspectRatio={4 / 3}
        guides={true}
        background={false}
        responsive={true}
        autoCropArea={1}
        viewMode={1}
        ref={cropperRef}
      />
      <Stack
        direction="row"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}>
        <Button onClick={cancel} variant="contained">
          Cancel
        </Button>
        <LoadingButton
          onClick={onSave}
          loading={loadingUpload}
          loadingPosition="end"
          endIcon={<SaveIcon />}
          variant="contained"
          color="secondary">
          Save
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
