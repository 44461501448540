import PetsIcon from "@mui/icons-material/Pets";
import VolunteerActivismIcon from "@mui/icons-material/VolunteerActivism";
import WaterTwoToneIcon from "@mui/icons-material/WaterTwoTone";
import SpaTwoToneIcon from "@mui/icons-material/SpaTwoTone";
import HistoryEduTwoToneIcon from "@mui/icons-material/HistoryEduTwoTone";
import BalanceTwoToneIcon from "@mui/icons-material/BalanceTwoTone";
import ElectricBoltTwoToneIcon from "@mui/icons-material/ElectricBoltTwoTone";
import PaletteTwoToneIcon from "@mui/icons-material/PaletteTwoTone";
import CloudQueueTwoToneIcon from "@mui/icons-material/CloudQueueTwoTone";
import ForestTwoToneIcon from "@mui/icons-material/ForestTwoTone";
import FamilyRestroomTwoToneIcon from "@mui/icons-material/FamilyRestroomTwoTone";
import CycloneTwoToneIcon from "@mui/icons-material/CycloneTwoTone";
import AirlineStopsTwoToneIcon from "@mui/icons-material/AirlineStopsTwoTone";
import NightShelterTwoToneIcon from "@mui/icons-material/NightShelterTwoTone";
import AllInclusiveTwoToneIcon from "@mui/icons-material/AllInclusiveTwoTone";
import WorkHistoryTwoToneIcon from "@mui/icons-material/WorkHistoryTwoTone";
import ConnectWithoutContactTwoToneIcon from "@mui/icons-material/ConnectWithoutContactTwoTone";
import SynagogueTwoToneIcon from "@mui/icons-material/SynagogueTwoTone";
import WheelchairPickupTwoToneIcon from "@mui/icons-material/WheelchairPickupTwoTone";
import ImportantDevicesTwoToneIcon from "@mui/icons-material/ImportantDevicesTwoTone";
import BedroomBabyIcon from "@mui/icons-material/BedroomBaby";
import ComputerIcon from "@mui/icons-material/Computer";
import SportsHandballIcon from "@mui/icons-material/SportsHandball";
import ElderlyIcon from "@mui/icons-material/Elderly";

export const IconList = [
  {
    id: 1,
    name: "Pets",
    Icon: PetsIcon,
  },
  {
    id: 2,
    name: "Poverty",
    Icon: VolunteerActivismIcon,
  },
  {
    id: 3,
    name: "Food",
    Icon: VolunteerActivismIcon,
  },
  {
    id: 4,
    name: "Water & Sanitation",
    Icon: WaterTwoToneIcon,
  },
  {
    id: 5,
    name: "Health",
    Icon: SpaTwoToneIcon,
  },
  {
    id: 6,
    name: "Education",
    Icon: HistoryEduTwoToneIcon,
  },
  {
    id: 7,
    name: "Equality",
    Icon: BalanceTwoToneIcon,
  },
  {
    id: 8,
    name: "Energy",
    Icon: ElectricBoltTwoToneIcon,
  },
  {
    id: 9,
    name: "Arts and Culture",
    Icon: PaletteTwoToneIcon,
  },
  {
    id: 10,
    name: "Climate",
    Icon: CloudQueueTwoToneIcon,
  },
  {
    id: 11,
    name: "Conservation",
    Icon: ForestTwoToneIcon,
  },
  {
    id: 12,
    name: "Peace & Justice",
    Icon: BalanceTwoToneIcon,
  },
  {
    id: 13,
    name: "FamilyRestroomTwoToneIcon",
    Icon: FamilyRestroomTwoToneIcon,
  },
  {
    id: 14,
    name: "Crisis",
    Icon: CycloneTwoToneIcon,
  },
  {
    id: 15,
    name: "Refugees",
    Icon: AirlineStopsTwoToneIcon,
  },
  {
    id: 16,
    name: "Homeless",
    Icon: NightShelterTwoToneIcon,
  },
  {
    id: 17,
    name: "Consumption",
    Icon: AllInclusiveTwoToneIcon,
  },
  {
    id: 18,
    name: "Jobs",
    Icon: WorkHistoryTwoToneIcon,
  },
  {
    id: 19,
    name: "Community Development",
    Icon: ConnectWithoutContactTwoToneIcon,
  },
  {
    id: 20,
    name: "Religion",
    Icon: SynagogueTwoToneIcon,
  },
  {
    id: 21,
    name: "People with Disabilities",
    Icon: WheelchairPickupTwoToneIcon,
  },
  {
    id: 22,
    name: "Computer & Technology",
    Icon: ImportantDevicesTwoToneIcon,
  },
  {
    id: 23,
    name: "Babies",
    Icon: BedroomBabyIcon,
  },
  {
    id: 24,
    name: "ComputerIcon",
    Icon: ComputerIcon,
  },
  {
    id: 25,
    name: "Sport",
    Icon: SportsHandballIcon,
  },
  {
    id: 26,
    name: "Seniors",
    Icon: ElderlyIcon,
  },
];
