import React from "react";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import DashboardIcon from "@mui/icons-material/Dashboard";
import withAuth from "../../../Hoc/withAuth";
import DrawerListItem from "../Components/DrawerListItem";
import GlobalDrawerItems from "./GlobalDrawerItems";

const StudentDrawer = ({ user }) => {
  return (
    <div>
      <DrawerListItem
        name="Dashboard"
        path="StudentDashBoard"
        Icon={DashboardIcon}
      />

      <GlobalDrawerItems />

      <DrawerListItem
        name="Bookings"
        path="bookings"
        Icon={DateRangeTwoToneIcon}
      />
      <DrawerListItem
        name="Stats"
        path={`stats/${user.uuid}`}
        Icon={QueryStatsIcon}
      />
      <DrawerListItem
        name="Associations"
        path="associations"
        Icon={PeopleAltTwoToneIcon}
      />
    </div>
  );
};

export default withAuth(StudentDrawer);
