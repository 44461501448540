export const SchoolNewsReducer = (
  state = { loading: false, success: false },
  action
) => {
  switch (action.type) {
    case "GET_SCHOOL_NEWS":
      return { ...state, loading: true };
    case "ADD_SCHOOL_NEWS":
      return { ...state, loading: true, error: null };
    case "GET_SCHOOL_NEWS_SUCCESS":
      return {
        loading: false,
        data: action.payload,
      };
    case "ADD_SCHOOL_NEWS_SUCCESS":
      return {
        loading: false,
        success: true,
        data: { ...state.data, ...action.payload },
      };
    case "GET_SCHOOL_NEWS_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "ADD_SCHOOL_NEWS_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "GET_SCHOOL_NEWS_RESET":
      return { ...state, loading: false, success: null };

    // update school resource
    case "UPDATE_SCHOOL_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        success: null,
      };
    case "UPDATE_SCHOOL_DATA_SUCCESS":
      console.log(state.data);
      return {
        ...state,
        loading: false,
        success: true,
        data: {
          ...state.data,
          results: state.data.results.map((result) => {
            return result.uuid === action.payload.uuid
              ? action.payload.uuid
              : result;
          }),
        },
      };
    case "UPDATE_SCHOOL_DATA_FAIL":
      return {
        ...state,
        error: action.payload,
        success: false,
      };

    // update school resource
    case "DELETE_SCHOOL_DATA_REQUEST":
      return {
        ...state,
        loading: true,
        success: null,
      };
    case "DELETE_SCHOOL_DATA_SUCCESS":
      // console.log(state.data)
      return {
        ...state,
        loading: false,
        success: true,
        message: action.payload.message,
      };
    case "DELETE_SCHOOL_DATA_FAIL":
      return {
        ...state,
        error: action.payload,
        success: false,
      };
    default:
      return state;
  }
};
