import React, { useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Divider, Stack } from "@mui/material";
import { addImage } from "../../Redux/actions/Common";
import { useDispatch } from "react-redux";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";

const ImageCropper = ({ src, cancel, path, type }) => {
  const dispatch = useDispatch();
  const [finalImage, setFinalImage] = useState("");
  console.log(finalImage);

  const [loadingUpload, setLoading] = useState(false);

  const cropperRef = useRef();
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const formData = new FormData();
      formData.append("file", blob, "image.jpg");
      setFinalImage(formData);
    });
  };

  const onSave = () => {
    setLoading(true); // Start loading
    const imageElement = cropperRef.current;
    const cropper = imageElement.cropper;

    cropper.getCroppedCanvas().toBlob((blob) => {
      const formData = new FormData();
      formData.append("file", blob, "image.jpg");
      // Update state or perform the upload
      dispatch(addImage({ path, image: formData, type }));

      // Resetting loading and final image after dispatch
      setLoading(false); // End loading
      setFinalImage(formData); // Optionally store the formData in state if needed elsewhere
      cancel(null); // Close the cropper or reset the state as necessary
    }, "image/jpeg"); // Specify MIME type for blob if you want to enforce a specific format
  };

  return (
    <>
      <Stack>
        <Cropper
          src={src}
          style={{ height: 480, width: "100%" }}
          // Cropper.js options
          aspectRatio={4 / 3}
          guides={true}
          background={false}
          responsive={true}
          autoCropArea={1}
          crop={onCrop}
          ref={cropperRef}
        />
        <Stack
          my={2}
          divider={<Divider orientation="vertical" flexItem />}
          justifyContent="center"
          alignItems="center"
          direction="row"
          spacing={2}>
          <Button
            onClick={() => cancel(null)}
            variant="contained"
            component="span">
            Cancel
          </Button>
          <LoadingButton
            onClick={onSave}
            endIcon={<SaveIcon />}
            loading={loadingUpload}
            color="secondary"
            loadingPosition="end"
            variant="contained">
            Save
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
};

export default ImageCropper;
