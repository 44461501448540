import React from "react";
import { AppBar, CircularProgress, IconButton, Toolbar } from "@mui/material";
import { Stack } from "@mui/system";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountCircle from "@mui/icons-material/AccountCircle";
import EventNoteTwoToneIcon from "@mui/icons-material/EventNoteTwoTone";
import PeopleAltTwoToneIcon from "@mui/icons-material/PeopleAltTwoTone";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

const BottomNav = ({ user }) => {
  const router = useHistory();

  const myOrg = useSelector((state) => state.myOrg);
  const { data, loading } = myOrg;

  return (
    <AppBar
      position="fixed"
      color="inherit"
      sx={{
        mt: 2,
        top: "auto",
        bottom: 0,
        boxShadow:
          "rgba(17, 17, 26, 0.05) 16px 4px 0px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
      }}>
      <Toolbar>
        <Stack
          sx={{ width: "100%" }}
          direction="row"
          justifyContent="space-between"
          alignItems="space-evenly">
          <IconButton
            onClick={() => {
              router.push("/orgs");
            }}
            color="inherit"
            aria-label="open drawer">
            <LocationCityIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              router.push("/events");
            }}
            color="inherit"
            aria-label="open drawer">
            <EventNoteTwoToneIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              router.push(
                user.role === "school-user"
                  ? "/schoolUserDashboard"
                  : user.role === "student"
                  ? "/StudentDashBoard"
                  : user.role === "parent"
                  ? "/parentDashboard"
                  : user.role === "org-admin"
                  ? "/OrgAdminDashboard"
                  : user.role === "school-admin"
                  ? "/SchoolAdminDashBoard"
                  : ""
              );
            }}
            size="large"
            color="inherit"
            aria-label="open drawer">
            <DashboardIcon />
          </IconButton>

          {loading ? (
            <CircularProgress />
          ) : (
            <IconButton
              color="inherit"
              onClick={() => {
                router.push(
                  user.role === "school-user"
                    ? "/schoolUserDashboard"
                    : user.role === "student"
                    ? "/associations"
                    : user.role === "parent"
                    ? "/parentAssociations"
                    : user.role === "org-admin"
                    ? "/OrgAdminDashboard"
                    : user.role === "school-admin"
                    ? `/orgs/${data?.uuid}/associations`
                    : ""
                );
              }}>
              <PeopleAltTwoToneIcon />
            </IconButton>
          )}

          <IconButton
            onClick={() => {
              router.push("/profile");
            }}
            color="inherit">
            <AccountCircle />
          </IconButton>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default BottomNav;
