const initialState = {
  relations: [],
  relation: {},
  loading: false,
  error: null,
  alias: {},
};

export const RelationsReducer = (state = initialState, action) => {
  switch (action.type) {
    // Make new Relation
    case "CREATE_RELATION":
      return { ...state, loading: true, relations: state.relations };
    case "CREATE_RELATION_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        relations: [...state.relations, action.payload],
      };
    case "CREATE_RELATION_FAIL":
      return { ...state, loading: false, error: action.payload };
    // get Relations data
    case "GET_RELATIONS_REQUEST":
      return { ...state, loading: true, success: null };
    case "GET_RELATIONS_SUCCESS":
      return { ...state, loading: false, relations: action.payload };
    case "GET_RELATIONS_FAIL":
      return { ...state, loading: false, error: action.payload };
    // Student Confirm Parent RELATION
    case "GET_CONFIRM_PARENT_REQUEST":
      return { ...state, loading: true };
    case "GET_CONFIRM_PARENT_SUCCESS":
      return {
        ...state,
        loading: false,
        confirmParent: action.payload,

        alias: { isParentConfirmed: true },
      };
    case "GET_GET_CONFIRM_PARENT_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
        alias: { isParentConfirmed: null },
      };

    // Parent Confirm Student RELATION
    case "GET_CONFIRM_STUDENT_REQUEST":
      return { ...state, loading: true };
    case "GET_CONFIRM_STUDENT_SUCCESS":
      return {
        ...state,
        loading: false,
        relations: [
          ...state.relations.map((relation) => {
            if (relation.id === action.payload.id) {
              return { ...relation, studentConfirmed: true };
            } else {
              return relation;
            }
          }),
        ],
        alias: { isStudentConfirmed: true },
      };
    case "GET_CONFIRM_STUDENT_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
        alias: { isStudentConfirmed: null },
      };
    // Parent Confirm Student For Events
    case "GET_AUTH_EVENT_REQUEST":
      return { ...state, loading: true };
    case "GET_AUTH_EVENT_SUCCESS":
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        success: true,
        relations: [
          ...state.relations.map((relation) => {
            if (relation.uuid === action.payload.uuid) {
              return { ...relation, studentAuthorized: true };
            } else {
              return relation;
            }
          }),
        ],
        alias: { isStudentAuthenticated: true },
      };
    case "GET_AUTH_EVENT_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
        alias: { isStudentAuthenticated: null },
      };

    /*
          Parent De-Authenticate Student From Joining Events
       */

    case "GET_DEAUTH_EVENT_REQUEST":
      return { ...state, loading: true };
    case "GET_DEAUTH_EVENT_SUCCESS":
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        relations: [
          ...state.relations.map((relation) => {
            if (relation.uuid === action.payload.uuid) {
              return { ...relation, studentAuthorized: false };
            } else {
              return relation;
            }
          }),
        ],
        alias: { isStudentDeAuthenticated: true },
      };
    case "GET_DEAUTH_EVENT_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
        alias: { isStudentDeAuthenticated: null },
      };

    // DELETE RELATION
    case "GET_DELETE_RELATION_REQUEST":
      return { ...state, loading: true };
    case "GET_DELETE_RELATION_SUCCESS":
      return {
        ...state,
        loading: false,
        successDelete: true,
        success: true,
      };
    case "GET_DELETE_RELATION_FAIL":
      return { ...state, loading: false, error: action.payload };

    // Org Admin Org User Reducers
    // Create Org Admin/User Relation
    case "CREATE_ORG_ADMIN_USER_RELATION_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "CREATE_ORG_ADMIN_USER__RELATION_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        relations: action.payload,
      };

    case "CREATE_ORG_ADMIN_USER__RELATION_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // GET Org Admin/User Org Relation
    case "GET_ORG_ADMIN_USER__RELATION_REQUEST":
      return {
        ...state,
        loading: true,
        success: null,
      };

    case "GET_ORG_ADMIN_USER__RELATION_SUCCESS":
      return {
        ...state,
        loading: false,
        relations: action.payload,
      };
    case "GET_ORG_ADMIN_USER__RELATION_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    // Parent Confirm Student RELATION
    case "GET_CONFIRM_ORG_USER_REQUEST":
      return { ...state, loading: true };
    case "GET_CONFIRM_ORG_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        confirmedStudent: action.payload,
        alias: { isOrgUserConfirmed: true },
      };
    case "GET_CONFIRM_ORG_USER_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
        alias: { isOrgUserConfirmed: null },
      };

    // DELETE RELATION
    case "GET_DELETE_ADMIN_ORG_RELATION_REQUEST":
      return { ...state, loading: true };
    case "GET_DELETE_ADMIN_ORG_RELATION_SUCCESS":
      return {
        ...state,
        loading: false,
        successDelete: true,
        success: true,
      };
    case "GET_DELETE_ADMIN_ORG_RELATION__FAIL":
      return { ...state, loading: false, error: action.payload };

    // Parent Confirm Student For Events
    case "GET_AUTH_ORG_USER_EVENT_REQUEST":
      return { ...state, loading: true };
    case "GET_AUTH_ORG_USER_EVENT_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        confirmedStudentEvents: action.payload,
        alias: { isOrgUserAuthenticated: true },
      };
    case "GET_AUTH_ORG_USER_EVENT_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
        alias: { isOrgUserAuthenticated: null },
      };

    /*
          Parent De-Authenticate Student From Joining Events
       */

    case "GET_DEAUTH_ORG_USER_EVENT_REQUEST":
      return { ...state, loading: true };
    case "GET_DEAUTH_ORG_USER_EVENT_SUCCESS":
      console.log(action.payload);
      return {
        ...state,
        loading: false,
        confirmedStudentEvents: action.payload,
        alias: { isOrgUserDeAuthenticated: true },
      };
    case "GET_DEAUTH_ORG_USER_EVENT_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
        alias: { isOrgUserDeAuthenticated: null },
      };

    case "GET_CONFIRM_ORG_ADMIN_REQUEST":
      return { ...state, loading: true };
    case "GET_CONFIRM_ORG_ADMIN_SUCCESS":
      return {
        ...state,
        loading: false,
        confirmParent: action.payload,

        alias: { isOrgAdminConfirmed: true },
      };
    case "GET_CONFIRM_ORG_ADMIN_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
        alias: { isOrgAdminConfirmed: null },
      };

    case "RESET_RELATION_STATE":
      return {
        ...state,
        loading: false,
        success: null,
        error: null,
        alias: {
          isStudentAuthenticated: null,
          isStudentDeAuthenticated: null,
          isOrgUserConfirmed: null,
          isOrgUserDeAuthenticated: null,
          isOrgAdminConfirmed: null,
        },
      };

    default:
      return state;
  }
};
