import { Container, Grid, TextField, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AdminGetAllUsers } from "../../Redux/actions/Admin";

import UserCard from "./components/UserCard";

const AdminUsers = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AdminGetAllUsers());
  }, [dispatch]);

  const AdminData = useSelector((state) => state.AdminData);
  const { users } = AdminData;

  const filterUsers = (users) => {
    return users.filter((user) => {
      return user.role !== "administrator";
    });
  };

  const [search, setSearch] = React.useState("");

  const filterUsersBySearch = (users) => {
    if (search) {
      return users.filter((user) => {
        return (
          user.username.includes(search.toLowerCase()) ||
          user.name.includes(search.toLowerCase()) ||
          user.email.includes(search.toLowerCase())
        );
      });
    } else {
      return users;
    }
  };

  return (
    <Container>
      <Typography sx={{ my: 4 }} variant="h4" align="center">
        Users Admin
      </Typography>

      <Grid container spacing={2} sx={{ mb: 4 }}>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <TextField
            fullWidth
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {users &&
          filterUsersBySearch(filterUsers(users)).map((user) => {
            return (
              <Grid key={user.uuid} item xl={4} lg={4} md={6} sm={12} xs={12}>
                <UserCard user={user} />
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};

export default AdminUsers;
