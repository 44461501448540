import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
import { MenuItem } from "@mui/material";
import { deleteSchoolData } from "../../Redux/actions/SchoolsActions";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DeleteSchoolDataModal({id, org_id}) {
  const [openD, setOpenD] = React.useState(false);
  const handleClickOpen = () => {
    setOpenD(true);
  };
  console.log(org_id)
  const handleClose2 = () => {
    setOpenD(false);
  };

  const dispatch = useDispatch();

  const handleDelete = (id, org_id) => {
    dispatch(deleteSchoolData(id, org_id))
    setOpenD(false)
  };

  return (
    <div>
      <MenuItem onClick={handleClickOpen}>
        Delete
      </MenuItem>
      <Dialog
        maxWidth="md"
        fullWidth
        open={openD}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="Log">
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="Log">Confirm?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>cancel</Button>
          <Button variant="contained" onClick={() => handleDelete(id, org_id)} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
