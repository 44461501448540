import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom"; // Import useNavigate

const withAuth = (Component) => {
  return (props) => {
    const userInfo = useSelector((state) => state.userInfo);
    const router = useHistory(); // Initialize the navigate function

    if (!userInfo) {
      // If userInfo does not exist, redirect to root
      // clear the token from local storage
      localStorage.removeItem("token");
      useEffect(() => {
        router.push("/");

        // eslint-disable-next-line
      }, []); // Empty dependency array to run only once on mount

      return null; // Return null or a loading indicator as needed
    } else {
      return (
        <Component token={userInfo?.token} user={userInfo.user} {...props} />
      );
    }
  };
};

export default withAuth;
