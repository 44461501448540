import axios from "axios";
import { url } from "./URL";
import PropTypes from "prop-types";

export const addImage =
  ({ path, image, type }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "REQUEST_COMMON" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(`${url}${path}`, image, config);

      if (type === "avatar") {
        dispatch({ type: "SET_AVATAR_PICTURE", payload: data.data });
      }
      if (type === "banner") {
        dispatch({ type: "SET_BANNER_PICTURE", payload: data.data });
      }
      if (type === "banner-event") {
        dispatch({ type: "SET_BANNER_PICTURE", payload: data.data });
      }
      if (type === "profile") {
        dispatch({ type: "UPDATE_AVATAR_SUCCESS", payload: data.data });
      }

      // Add Image To Profile
    } catch (error) {
      dispatch({
        type: "FAIL_COMMON",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

addImage.prototypes = {
  path: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.any,
};

export const activateUserRequest =
  ({ uuid, type }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "ACTIVATE_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      if (type === "parent") {
        const { data } = await axios.put(
          `${url}/users/confirmStudent/${uuid}`,
          "",
          config
        );
        dispatch({ type: "ACTIVATE_SUCCESS", payload: data });
      } else {
        const { data } = await axios.put(
          `${url}/users/confirmParent/${uuid}`,
          "",
          config
        );
        dispatch({ type: "ACTIVATE_SUCCESS", payload: data });
      }
    } catch (error) {
      dispatch({
        type: "ACTIVATE_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteAssRequest =
  ({ uuid }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "DELETE_ASS_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      console.log(config);

      const { data } = await axios.delete(
        `${url}/users/confirmStudent/${uuid}`,
        config
      );

      dispatch({ type: "DELETE_ASS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "DELETE_ASS_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
