import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Box,
} from "@mui/material";

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import withAuth from "../../Hoc/withAuth";
import { getSchoolsListRequest } from "../../Redux/actions/AppData";
import { UserRequestToAssociate } from "../../Redux/actions/OrgAssociationsActions";
import { useHistory } from "react-router-dom";

const SchoolUserProcess = ({ user }) => {
  const history = useHistory();

  const dispatch = useDispatch();

  const [school, setSchool] = useState("");

  useEffect(() => {
    dispatch(getSchoolsListRequest());
  }, [dispatch]);

  const mySchool = useSelector((state) => state.mySchool);
  const { schoolList } = mySchool;

  console.log(schoolList);

  const orgAssociations = useSelector((state) => state.orgAssociations);

  const { requestToAssociate } = orgAssociations;

  const handleChange = () => {
    let values = {
      org_id: school,
      userType: "teacher",
      user_id: user.uuid,
    };
    dispatch(UserRequestToAssociate(values));
  };

  return (
    <Stack my={2}>
      <Typography sx={{ my: 2 }} variant="body1" gutterBottom>
        Your school admin will approve your account and you will be able to
        manage your school.
      </Typography>
      <FormControl>
        <InputLabel id="School">School</InputLabel>
        <Select
          labelId="School"
          id="School"
          value={school}
          label="School"
          onChange={(event) => setSchool(event.target.value)}>
          <MenuItem value={" "}>Please Select</MenuItem>
          {schoolList &&
            schoolList.map((school) => {
              return (
                <MenuItem key={school.uuid} value={school.uuid}>
                  {school.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
      <Box my={2} display="flex" justifyContent="space-between">
        <Button variant="contained" onClick={() => handleChange()}>
          Save
        </Button>
        {requestToAssociate && (
          <Button
            onClick={() => {
              history.push("/schoolUserDashboard");
            }}
            variant="outlined">
            next
          </Button>
        )}
      </Box>
    </Stack>
  );
};

export default withAuth(SchoolUserProcess);
