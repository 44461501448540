export const OrgsReduer = (
  state = {
    data: {
      rows: [],
    },
  },
  action
) => {
  switch (action.type) {
    case "REQUEST_ORGS":
      return { loading: true, ...state };
    case "GET_ORGS_SUCCESS":
      function combineUniqueEvents(arr1, arr2) {
        const combinedArray = [...arr1];
        arr2.forEach((obj) => {
          if (
            !combinedArray.some(
              (item) => JSON.stringify(item) === JSON.stringify(obj)
            )
          ) {
            combinedArray.push(obj);
          }
        });
        return combinedArray;
      }

      return {
        loading: false,
        data: {
          ...state.data,
          rows: combineUniqueEvents(state.data.rows, action.payload.rows),
          count: action.payload.count,
        },
      };

    case "GET_ORGS__SEARCH_SUCCESS":
      return {
        loading: false,
        data: {
          ...state.data,
          rows: action.payload.rows,
          count: action.payload.count,
        },
      };

    case "FAIL_ORGS":
      return {
        loading: false,
        data: {
          rows: [],
        },
        error: action.payload,
      };
    case "RESET_ORGS_DATA":
      return {
        data: {
          rows: [],
        },
      };
    default:
      return state;
  }
};

export const GetOrgByIdReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "REQUEST_ORG":
      return { loading: true, ...state };
    case "GET_ORG_SUCCESS":
      return { loading: false, data: action.payload };
    case "FAIL_ORG":
      return { loading: false, data: {}, error: action.payload };
    case "RESET_ORG":
      return { data: {} };
    default:
      return state;
  }
};

const iniState = {
  data: {},
  loading: false,
  error: null,
};

export const MyOrgReduer = (state = iniState, action) => {
  switch (action.type) {
    case "REQUEST_MY_ORG":
      console.log("REQUEST_MY_ORG");
      return { ...state, loading: true };
    case "GET_MY_ORG":
      return { loading: false, data: action.payload };

    case "SET_BANNER_PICTURE":
      return {
        loading: false,
        data: { ...state.data, banner: action.payload },
      };
    case "SET_AVATAR_PICTURE":
      return {
        loading: false,
        data: { ...state.data, avatar: action.payload },
      };

    case "GET_ORG_OCCURRENCES_REQUEST":
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case "GET_ORG_OCCURRENCES_SUCCESS":
      return {
        ...state,
        loading: false,
        error: null,
        orgOccurrences: action.payload,
      };
    case "GET_ORG_OCCURRENCES_FAILURE":
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    case "FAIL_MY_ORG":
      return { loading: false, data: {}, error: action.payload };
    case "RESET_ORGS":
      return { data: {} };
    default:
      return state;
  }
};

export const EditOrgReducer = (
  state = { data: {}, loading: false, success: null, error: null },
  action
) => {
  switch (action.type) {
    case "EDIT_ORG_REQUEST":
      return { loading: true, ...state };
    case "EDIT_ORG_SUCCESS":
      return { loading: false, success: true, data: action.payload };
    case "EDIT_ORG_FAIL":
      return {
        loading: false,
        success: false,
        data: {},
        error: action.payload,
      };
    case "EDIT_ORG_RESET":
      return {
        data: {},
        loading: false,
        success: null,
        error: null,
      };
    default:
      return state;
  }
};

export const addOrgReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "ADD_ORG_REQUEST":
      return { loading: true, ...state };
    case "ADD_ORG_SUCCESS":
      return { loading: false, success: true, data: action.payload };
    case "ADD_ORG_FAIL":
      return { loading: false, data: {}, error: action.payload };
    default:
      return state;
  }
};

export const getEventsByOrgIdReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "GET_EVENTS_ORG_REQUEST":
      return { loading: true, ...state };
    case "GET_EVENTS_ORG_SUCCESS":
      return { loading: false, success: true, data: action.payload };
    case "GET_EVENTS_ORG_FAIL":
      return { loading: false, data: {}, error: action.payload };
    default:
      return state;
  }
};
