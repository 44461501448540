import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTotalStats } from "../../../Redux/actions/UserStats";
import {
  Avatar,
  Card,
  CardHeader,
  Grid,
  Container,
  Stack,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Slide,
  Button,
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
} from "@mui/material";

import BookingsStatsTable from "../../../Components/Containers/BookingsStatsTable";

import EditStudent from "./EditStudent";

import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SchoolViewStudent = ({ schoolStudent, StyledButton }) => {
  const dispatch = useDispatch();

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (open === true) {
      dispatch(getTotalStats({ studentId: schoolStudent?.User?.uuid }));
    }
  }, [dispatch, schoolStudent, open]);

  const stats = useSelector((state) => state.stats);
  const { data } = stats;

  const schoolStudents = useSelector((state) => state.schoolStudents);
  const { schoolGradesList } = schoolStudents;

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // console.log(schoolStudent);

  return (
    <>
      {StyledButton ? (
        <>
          <StyledButton student={schoolStudent} onClick={handleClickOpen} />
        </>
      ) : (
        <Button variant="outlined" onClick={handleClickOpen}>
          view student
        </Button>
      )}

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "fixed" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {schoolStudent?.User?.username}
            </Typography>
            <Button
              autoFocus
              color="secondary"
              variant="contained"
              onClick={handleClose}>
              Close
            </Button>
          </Toolbar>
        </AppBar>

        <Container sx={{ my: 10 }}>
          <Grid container>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Stack spacing={2}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        alt={schoolStudent?.name}
                        aria-label={`${schoolStudent?.User?.username} profile picture`}
                        src={`${process.env.REACT_APP_IMAGE_URL}/${schoolStudent?.User?.avatar}`}
                      />
                    }
                    title={schoolStudent?.User?.username}
                    action={
                      <Box sx={{ my: 1 }}>
                        <EditStudent
                          schoolGradesList={schoolGradesList}
                          student={schoolStudent}
                        />
                      </Box>
                    }
                  />
                </Card>

                <Grid container>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <ContentItem
                      title="User Name"
                      value={schoolStudent?.User?.username}
                    />
                    <ContentItem
                      title="First Name"
                      value={schoolStudent?.User?.name}
                    />
                    <ContentItem
                      title="Last Name"
                      value={schoolStudent?.User?.surname}
                    />
                    <ContentItem
                      title="Email"
                      value={schoolStudent?.User?.email}
                    />
                  </Grid>
                  <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                    <ContentItem
                      title="Grade"
                      value={schoolStudent?.SchoolGrade?.grade}
                    />
                    <ContentItem
                      title="Class"
                      value={schoolStudent?.SchoolClass?.name}
                    />
                  </Grid>
                </Grid>

                {data?.bookings && <BookingsStatsTable data={data?.bookings} />}
              </Stack>
            </Grid>
          </Grid>
        </Container>
      </Dialog>
    </>
  );
};

export default SchoolViewStudent;

const ContentItem = ({ title, value }) => {
  return (
    <>
      <ListItem alignItems="flex-start">
        <ListItemText
          primary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary">
                {title}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body1"
                color="text.primary">
                {value}
              </Typography>
            </>
          }
        />
      </ListItem>
    </>
  );
};
