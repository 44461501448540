export const AuthReducer = (
  state = { loading: false, profile: {}, emailSend: null },
  action
) => {
  switch (action.type) {
    case "REQUEST":
      return { ...state, loading: true, success: null };

    case "SET_PROFILE_PICTURE":
      return {
        ...state,

        profile: { ...state.profile, avatar: action.payload },
      };

    case "REGISTER_SUCCESS":
      return { ...state, loading: false, success: true, ...action.payload };
    case "LOGIN_SUCCESS":
      return { ...state, loading: false, ...action.payload };
    case "GET_ME_SUCCESS":
      return {
        ...state,
        loading: false,
        profile: action.payload.user,
        success: null,
      };
    case "UPDATE_ME_REQUEST":
      return { ...state, loading: true, success: null };
    case "UPDATE_ME_SUCCESS":
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case "UPDATE_AVATAR_SUCCESS":
      return {
        ...state,
        loading: false,
        user: { ...state.user, avatar: action.payload },
      };
    case "VERIFY_EMAIL_REQUEST_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        emailVerified: true,
        ...action.payload,
      };

    case "FAIL_EMAIL_VERIFY":
      return { ...state, loading: false, success: false, emailVerified: false };

    case "RESEND_EMAIL_SUCCESS":
      return {
        ...state,
        loading: false,
        emailSend: true,
      };
    case "RESEND_EMAIL_FAIL":
      return {
        ...state,
        loading: false,
        emailSend: false,
      };

    case "FAIL_UPDATE_ME":
      return { ...state, loading: false, error: action.payload, success: null };
    case "FAIL":
      return { loading: false, user: {}, error: action.payload, success: null };
    case "FAIL_GET_ME":
      return {
        ...state,
        loading: false,
        user: {},
        error: action.payload,
        success: null,
      };
    case "LOGOUT":
      return {
        loading: false,
        error: null,
        success: null,
      };
    case "RESET":
      return {
        ...state,
        loading: false,
        error: null,
        success: null,
      };

    default:
      return state;
  }
};

export const ActivateUserReducer = (
  state = { loading: false, success: null, error: null },
  action
) => {
  switch (action.type) {
    case "ACTIVATE_REQUEST":
      return { ...state, loading: false };
    case "ACTIVATE_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        data: action.payload,
      };
    case "ACTIVATE_FAIL":
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case "ACTIVATE_RESET":
      return { loading: false, success: null, error: null };

    default:
      return state;
  }
};

export const DeleteAssReducer = (
  state = { loading: false, success: null, error: null },
  action
) => {
  switch (action.type) {
    case "DELETE_ASS_REQUEST":
      return { ...state, loading: false };
    case "DELETE_ASS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        error: null,
        data: action.payload,
      };
    case "DELETE_ASS_FAIL":
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    case "DELETE_ASS_RESET":
      return { loading: false, success: null, error: null };

    default:
      return state;
  }
};
