import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { useQuery } from "react-query";
import { LinearProgress } from "@mui/material";

function PrivateRoute({ children, ...rest }) {
  const userInfo = useSelector((state) => state.userInfo);
  const { token } = userInfo;

  const validateToken = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/token`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming this returns some data indicating validity
    } catch (error) {
      throw new Error("Token validation failed"); // Throws an error which useQuery can catch
    }
  };

  const AuthToken = useQuery("Auth Token", validateToken, {
    enabled: !!token, // Only run if token exists
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false, // Don't retry on failure
    onError: (error) => {
      console.error(error); // Log or handle error
      window.localStorage.clear(); // Consider clearing token or handling logout
    },
  });

  // Loading state
  if (AuthToken.isLoading) {
    return <LinearProgress />;
  }

  // If token is valid (assuming isSuccess is true when token is valid)
  if (AuthToken.isSuccess) {
    return <Route {...rest}>{children}</Route>;
  }

  // Redirect to login if not loading and token is not valid
  return (
    <Redirect
      to={{
        pathname: "/login",
        state: { from: rest.location },
      }}
    />
  );
}

export default PrivateRoute;
