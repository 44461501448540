import { Alert, AlertTitle, Button, Stack, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useHistory } from "react-router-dom";

const NotificationItem = ({ userNotification, readNotification }) => {
  const history = useHistory();

  return (
    <Alert
      variant="outlined"
      severity={userNotification?.severity}
      action={
        <Stack spacing={2}>
          <Button
            onClick={() => {
              history.push(`${userNotification.url}`);
            }}
            color="inherit">
            View Details
          </Button>

          {!userNotification.read && (
            <Button
              onClick={() => readNotification.mutate(userNotification.uuid)}
              color="error"
              startIcon={<DeleteForeverIcon />}></Button>
          )}
        </Stack>
      }>
      <AlertTitle>{userNotification?.title}</AlertTitle>

      <Stack>
        <Typography variant="body2" color="text.secondary">
          {userNotification?.content}
        </Typography>
        <Typography variant="caption" color="text.secondary">
          {new Date(userNotification?.createdAt).toLocaleString()}
        </Typography>
      </Stack>
    </Alert>
  );
};

export default NotificationItem;
