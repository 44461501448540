import { createTheme } from "@mui/material";

export const themeDark = createTheme({
  typography: {
    fontFamily: "Roboto, Reenie Beanie",
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",

          elevation: 0,
          backgroundColor: "#19191C",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          elevation: 0,
          backgroundColor: "#19191C",
        },
      },
    },

    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "5px",
          boxShadow: "none",
        },
      },
      variants: [
        {
          props: { variant: "contained" },
          style: {
            "&:hover": {
              boxShadow:
                "rgba(0, 158, 253, 0.25) 0 10px 40px, rgba(42, 245, 152, 0.1) 0 10px 20px",
            },
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            "&:hover": {
              boxShadow:
                "rgba(0, 158, 253, 0.25) 0 10px 40px, rgba(42, 245, 152, 0.1) 0 10px 20px",
            },
          },
        },
      ],
    },
  },
  palette: {
    mode: "dark",
    primary: {
      light: "#83e8edfe",
      main: "#09ededfe",
      info: "#1769aa",
      contrastText: "#333",
    },
    secondary: {
      light: "#4fffd9fe",
      main: "#09D1A6",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#333",
    },
    background: {
      // paper: "#444444da",
      paper: "#19191cff",
      default: "#19191cff",
    },
  },
});

export const themeLight = createTheme({
  typography: {
    fontFamily: "Roboto, Reenie Beanie",
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #e0e0e0",
          elevation: 0,
          backgroundColor: "#ffffff",
          borderRadius: "15px",
        },
      },
    },

    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          border: "1px solid #e0e0e0",
          elevation: 0,
          backgroundColor: "#ffffff",
          borderRadius: "15px",
        },
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: {
            border: "none",
            boxShadow: "none",
          },
        },
      ],
    },
  },
  palette: {
    mode: "light",
    primary: {
      light: "#23272A",
      main: "#23272A",
      dark: "#23272A",
    },
    secondary: {
      light: "#0A92ED",
      main: "#0A92ED",
      dark: "#0A92ED",
    },
    success: {
      light: "#3aeda4",
      main: "#09E98E",
      dark: "#06a363",
    },
    error: {
      light: "#B51A0B",
      main: "#B51A0B",
      dark: "#B51A0B",
    },
    background: {
      paper: "#ffffff",
      default: "#FCFCFD",
    },
  },
});

// OLD CARD
