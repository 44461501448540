import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Logout from "@mui/icons-material/Logout";
import { logout } from "../../Redux/actions/Auth";
import { ListItemIcon, ListItem, ListItemButton } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LogOutDialog() {
  const [openD, setOpenD] = React.useState(false);

  const router = useHistory();

  const handleClickOpen = () => {
    setOpenD(true);
  };

  const handleClose2 = () => {
    setOpenD(false);
  };

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    setTimeout(() => {
      router.push("/");
    }, 500);
  };

  return (
    <div>
      <ListItem>
        <ListItemButton sx={{ mx: 0, px: 0 }} onClick={handleClickOpen}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </ListItemButton>
      </ListItem>

      <Dialog
        maxWidth="md"
        fullWidth
        open={openD}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose2}
        aria-describedby="Log">
        <DialogTitle>{"Logout"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="Log">Logout?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose2}>cancel</Button>
          <Button variant="contained" onClick={handleLogout} color="error">
            LogOut
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
