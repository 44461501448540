import {
  Alert,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import CreateParentStudentRelation from "../../../Components/Relations/CreateParentStudentRelation";
import DeleteRelation from "../../../Components/Relations/DeleteRelation";
import {
  parentAuthStudentEvents,
  parentConfirmStudentRelation,
  parentDeAuthStudentEvents,
  DeleteParentStudentRelations,
} from "../../../Redux/actions/Relations";

const ParentAssociations = () => {
  const Relations = useSelector((state) => state.Relations);
  const { relations } = Relations;

  const { user } = useSelector((state) => state.userInfo);

  let role = user.role;

  return (
    <Container>
      <CreateParentStudentRelation />
      <Grid container spacing={2}>
        {relations &&
          relations.length > 0 &&
          relations?.map((relation) => {
            return (
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                key={relation.uuid}>
                <Card>
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography variant="h5">
                        {role === "parent"
                          ? relation?.studentEmail
                          : relation?.parentEmail}
                      </Typography>
                      <ButtonsForParent relation={relation} />
                      <DeleteRelation
                        DeleteParentStudentRelations={
                          DeleteParentStudentRelations
                        }
                        id={relation?.uuid}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};

export default ParentAssociations;

export const ButtonsForParent = ({ relation }) => {
  const dispatch = useDispatch();

  const parentConfirmStudent = (id) => {
    dispatch(parentConfirmStudentRelation(id));
  };

  const parentAuthStudentEvent = (id) => {
    dispatch(parentAuthStudentEvents(id));
  };
  const parentDeAuthStudentEvent = (id) => {
    dispatch(parentDeAuthStudentEvents(id));
  };

  if (relation?.studentId) {
    return (
      <>
        <Stack spacing={2}>
          {!relation?.studentConfirmed ? (
            <>
              <Alert severity="warning">
                Student with the email address {`${relation.studentEmail}`} has
                not been confirmed. Please confirm the student before
                authenticating them.
              </Alert>

              <Button
                variant="contained"
                onClick={() => parentConfirmStudent(relation?.uuid)}>
                Confirm Student
              </Button>
            </>
          ) : (
            <>
              {relation.studentAuthorized && relation?.studentConfirmed ? (
                <>
                  <Alert severity="success">
                    Remove the ability to join events for this student?
                  </Alert>

                  <Button
                    color="warning"
                    onClick={() => parentDeAuthStudentEvent(relation?.uuid)}>
                    Remove Ability to Join Events
                  </Button>
                </>
              ) : (
                <>
                  <Alert severity="warning">
                    Allow this student to join events?
                  </Alert>
                  <Button
                    variant="contained"
                    onClick={() => parentAuthStudentEvent(relation?.uuid)}>
                    Auth Student to join Events
                  </Button>
                </>
              )}
            </>
          )}
        </Stack>
      </>
    );
  } else {
    return (
      <>
        <Alert severity="warning">
          Student needs to Authorize you as the Parent
        </Alert>
      </>
    );
  }
};
