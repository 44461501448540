import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import withAuth from "../../Hoc/withAuth";
import AdminCard from "./AdminCard";
import BusinessTwoToneIcon from "@mui/icons-material/BusinessTwoTone";
import PermContactCalendarTwoToneIcon from "@mui/icons-material/PermContactCalendarTwoTone";
import StyleIcon from "@mui/icons-material/Style";

const AdminPanel = ({ user }) => {
  if (user.role !== "administrator") {
    return <div>You are not authorized to view this page</div>;
  }

  return (
    <Container>
      <Typography sx={{ my: 4 }} variant="h5" align="center">
        Admin Panel
      </Typography>
      <Grid container spacing={2}>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <AdminCard
            title="Org Admin"
            link="/admin/orgs"
            Icon={BusinessTwoToneIcon}
            admin={user.role === "administrator"}
          />
        </Grid>

        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <AdminCard
            title="Users Admin"
            link="/admin/users"
            Icon={PermContactCalendarTwoToneIcon}
            admin={user.role === "administrator"}
          />
        </Grid>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <AdminCard
            title="Tags"
            link="/admin/AdminTags"
            Icon={StyleIcon}
            admin={user.role === "administrator"}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(AdminPanel);
