import {
  Alert,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React from "react";
import PageHeader from "../../Components/Items/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getUserBookingsRequest } from "../../Redux/actions/Bookings";
import withAuth from "../../Hoc/withAuth";
import BookingsCard from "../../Components/Containers/BookingCard";
import { getTotalStats } from "../../Redux/actions/UserStats";
import SetGoals from "./Users/SelectHourGoals";
import dayjs from "dayjs";
import EventNoteTwoToneIcon from "@mui/icons-material/EventNoteTwoTone";
import { useTheme } from "@emotion/react";
import BasicPie from "../../Components/Charts/PieChart";
import { useQuery } from "react-query";
import axios from "axios";
import StudentSetupProcess from "./Users/StudentSetupProcess";
import StudentSchoolForms from "../../Components/Forms/Student/StudentSchoolForms";
import StudentAssoc from "./Users/StudentAssoc";
import BannerImage from "../../Components/ImageUploaders/BannerImage";
import AvatarImage from "../../Components/ImageUploaders/AvatarImage";

const StudentDashBoard = ({ token }) => {
  const getMe = useQuery(
    "me",
    () => {
      return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      enabled: !!token,
      onError: () => {
        router.push("/login");
      },
    }
  );

  const user = getMe?.data?.data?.user;
  const dispatch = useDispatch();

  const stats = useSelector((state) => state.stats);
  const { data, hoursSetSuccess } = stats;

  useEffect(() => {
    dispatch(getUserBookingsRequest(user?.uuid, `?status=active`));
    dispatch(getTotalStats({ studentId: user?.uuid }));
  }, [dispatch, user, hoursSetSuccess]);

  const school = user?.SchoolStudent;

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const relations = user?.relations;

  const bookings = user?.Bookings;

  const router = useHistory();

  const isStudentAuth = () => {
    let auth = false;
    relations &&
      relations.length > 0 &&
      relations?.forEach((relation) => {
        if (relation.studentAuthorized === true) {
          auth = true;
        }
      });
    return auth;
  };

  const findEvent = (bookings) => {
    return bookings?.filter((booking) => {
      return (
        dayjs(booking.eventDate).format("MMMM Do YYYY") ===
        dayjs().format("MMMM Do YYYY")
      );
    });
  };

  let todayEvents = findEvent(bookings);

  if (user?.userData?.profileComplete === false) {
    return <StudentSetupProcess user={user} token={token} />;
  }

  return (
    <Container>
      <PageHeader title="Student DashBoard" />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {user?.role === "student" && !isStudentAuth() && (
            <React.Fragment>
              <Alert
                sx={{ mb: 2 }}
                severity="error"
                action={
                  <Button
                    size="small"
                    color="info"
                    onClick={() => router.push(`/associations`)}>
                    View Associations
                  </Button>
                }>
                You need to be authorized by a parent to join events
              </Alert>
            </React.Fragment>
          )}
        </Grid>

        {todayEvents && todayEvents.length > 0 && (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <Typography
                align="center"
                variant="h5"
                gutterBottom
                sx={{ fontWeight: "bold", mb: 2 }}>
                Today's events
              </Typography>

              <Stack sx={{ border: 1, p: 2 }} spacing={2}>
                {todayEvents.map((booking, index) => {
                  return (
                    <div key={index}>
                      <>
                        <BookingsCard
                          booking={booking}
                          uuid={booking?.uuid}
                          avatar={booking.avatar}
                          name={booking.name}
                          timeSlots={booking.timeSlots}
                          eventDate={booking.eventDate}
                          status={booking.status}
                          createdAt={booking.createdAt}
                          eventId={booking.eventId}
                          router={router}
                        />

                        {user.role === "student" &&
                          (booking.status === "active" ||
                            booking.status === "inProgress") && (
                            <>
                              <Button
                                sx={{ mt: 2 }}
                                fullWidth
                                variant={
                                  booking.status === "inProgress"
                                    ? "contained"
                                    : "outlined"
                                }
                                onClick={() =>
                                  router.push(
                                    `/EventJoinCode/${booking.uuid}/${
                                      booking.status === "inProgress"
                                        ? "end"
                                        : "start"
                                    }`
                                  )
                                }>
                                {booking.status === "inProgress"
                                  ? "end"
                                  : "start"}
                              </Button>
                            </>
                          )}
                      </>
                    </div>
                  );
                })}
              </Stack>
            </Grid>
          </>
        )}
      </Grid>

      <Grid
        container
        sx={{ display: "flex", height: "100%", mb: 3 }}
        spacing={2}>
        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
          <Stack sx={{ display: "flex" }}>
            {school ? (
              <React.Fragment>
                <Typography variant="h6" gutterBottom>
                  My School
                </Typography>
                <Typography variant="body1" color="GrayText" gutterBottom>
                  View your school.
                </Typography>

                <Card sx={!matches ? { maxWidth: 345 } : { width: "100%" }}>
                  <CardActionArea
                    onClick={() => router.push(`/mySchool/${school.Org.uuid}`)}>
                    <CardHeader
                      title={school?.Org?.name}
                      avatar={
                        <AvatarImage size="small" uuid={school?.Org?.uuid} />
                      }
                    />
                    {school.Org.uuid && (
                      <BannerImage uuid={school.Org.uuid} token={token} />
                    )}
                  </CardActionArea>
                </Card>
              </React.Fragment>
            ) : (
              <>
                <Typography variant="h6" gutterBottom>
                  My School
                </Typography>
                <Typography variant="body1" color="GrayText" gutterBottom>
                  View your school.
                </Typography>
                <Card>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Let's setup your school:
                    </Typography>
                    <Typography variant="body2" color="GrayText" gutterBottom>
                      Find your school in the list below and select it to get
                      started. By selecting your school, you will be able to
                      join events and view your stats. Your school will also be
                      able to view your stats and manage your bookings.
                    </Typography>
                    <Typography variant="caption" color="GrayText" gutterBottom>
                      If you can't find your school, please contact your school
                      administrator to add your school to the system.
                    </Typography>
                    <StudentSchoolForms token={token} />
                  </CardContent>
                </Card>
              </>
            )}
          </Stack>
        </Grid>
        <Grid item xs={12} sm={8} sx={{ display: "flex" }}>
          <Stack sx={{ width: "100%" }}>
            <Typography variant="h6" gutterBottom>
              My Bookings
            </Typography>
            <Typography variant="body1" color="GrayText" gutterBottom>
              View and manage bookings
            </Typography>
            <Card sx={{ height: "100%" }}>
              <Grid container spacing={0.5}>
                {bookings &&
                  bookings.length > 0 &&
                  bookings?.map((booking, index) => {
                    return (
                      <Grid item xs={4} key={booking?.Event?.uuid}>
                        <Card>
                          <CardActionArea
                            onClick={() =>
                              router.push(`/events/${booking?.Event.uuid}`)
                            }>
                            <BannerImage
                              height="200"
                              uuid={booking?.Event?.uuid}
                            />
                          </CardActionArea>
                        </Card>
                      </Grid>
                    );
                  })}
              </Grid>
              {bookings && bookings.length === 0 && (
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}>
                  <EventNoteTwoToneIcon
                    sx={{
                      mt: 2,
                      fontSize: 100,
                    }}
                  />
                  <Typography
                    variant="body1"
                    color="GrayText"
                    align="center"
                    gutterBottom>
                    You haven't signed up for any events yet.
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={() => router.push("/events")}>
                    View Events
                  </Button>
                </Stack>
              )}
              <Button fullWidth onClick={() => router.push("/bookings")}>
                View All Bookings
              </Button>
            </Card>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            My Associations
          </Typography>
          <Typography variant="body1" color="GrayText" gutterBottom>
            View and manage associations (parents)
          </Typography>
          <StudentAssoc />
        </Grid>

        <Grid item xs={12} sm={6} sx={{ mb: 4 }}>
          <Typography variant="h6" gutterBottom>
            My Stats
          </Typography>
          <Typography variant="body1" color="GrayText" gutterBottom>
            View your stats
          </Typography>
          {data?.stats ? (
            <Card>
              <CardContent>
                <Tooltip title="View Your Stats" placement="top">
                  <CardActionArea
                    onClick={() => router.push(`/stats/${user.uuid}`)}>
                    <BasicPie
                      goals={Number(data?.stats?.hourGoals) || 0}
                      hours={Number(data?.stats?.totalHours) || 0}
                    />

                    {/* <BarChart
                    goals={data?.stats?.hourGoals || 0}
                    hours={data?.stats?.totalHours || 0}
                  /> */}
                  </CardActionArea>
                </Tooltip>
              </CardContent>
            </Card>
          ) : (
            <SetGoals user={user} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(StudentDashBoard);
