import { Button, Chip, LinearProgress } from "@mui/material";
import { Container } from "@mui/system";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AlertPopup from "../../../Components/Items/AlertPopup";
import PageHeader from "../../../Components/Items/PageHeader";
import { getBulkAddedStudents } from "../../../Redux/actions/SchoolBulkManageStudents";

const BulkAddedStudentsList = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBulkAddedStudents(id));
  }, [id, dispatch]);

  const SchoolBulk = useSelector((state) => state.SchoolBulk);

  const { loading, error, students } = SchoolBulk;

  const RenderChip = (params) => {
    return (
      <Chip
        label={
          params.value === "Completed"
            ? "Completed"
            : params.value === "Pending"
            ? "processing"
            : "Error"
        }
        color={
          params.value === "Completed"
            ? "success"
            : params.value === "Pending"
            ? "info"
            : "error"
        }
      />
    );
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 200 },
    { field: "surname", headerName: "Surname", width: 160 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "parentEmail", headerName: "Parent Email", width: 200 },

    {
      field: "status",
      headerName: "Status",
      renderCell: RenderChip,
      width: 120,
    },

    { field: "status_reason", headerName: "Status Reason", width: 200 },
    { field: "updatedAt", headerName: "Updated At", type: "date", width: 200 },
    { field: "createdAt", headerName: "Created At", type: "date", width: 200 },
  ];

  const rows =
    students &&
    students.length > 0 &&
    students.map((item, index) => {
      return {
        id: index + 1,
        ...item,
        status: item.status,
        status_reason: item.status_reason,
        updatedAt: `${new Date(
          item.updatedAt
        ).toLocaleDateString()}  -  ${new Date(
          item.updatedAt
        ).toLocaleTimeString()}`,
        createdAt: `${new Date(
          item.createdAt
        ).toLocaleTimeString()} - ${new Date(
          item.createdAt
        ).toLocaleTimeString()}`,
      };
    });

  const handleRefresh = () => {
    dispatch(getBulkAddedStudents(id));
  };

  if (loading) {
    return (
      <Container>
        <PageHeader title="Bulk Added Students" />
        <LinearProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <PageHeader title="Bulk Added Students" />

      <AlertPopup
        open={error}
        message="Failed to get Students"
        severity="error"
      />

      <Button onClick={handleRefresh}>Refresh</Button>
      {students && students.length > 0 && (
        <div style={{ height: "80Vh", width: "100%" }}>
          <DataGridPremium columns={columns} rows={rows} />
        </div>
      )}
    </Container>
  );
};

export default BulkAddedStudentsList;
