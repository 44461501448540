import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";

const UserCard = ({ user }) => {
  const router = useHistory();

  return (
    <Card>
      <CardContent>
        <Typography>{user.firstName}</Typography>
        <Typography>{user.lastName}</Typography>
        <Typography>{user.email}</Typography>
        <Typography>{user.role}</Typography>
      </CardContent>
      <CardActions>
        <Button
          size="small"
          variant="outlined"
          onClick={() => router.push(`/admin/EditUser/${user.uuid}`)}>
          Edit
        </Button>
      </CardActions>
    </Card>
  );
};

export default UserCard;
