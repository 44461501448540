import {
  Card,
  CardHeader,
  CardMedia,
  Container,
  CardContent,
  Typography,
  MenuItem,
  Button,
  TextField,
  Chip,
  // Chip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getSingleBooking } from "../../Redux/actions/Bookings";
import { getEventById } from "../../Redux/actions/Events";

import { Field, Form, Formik } from "formik";
import { useField, useFormikContext } from "formik";
import { Stack } from "@mui/system";
import PageHeader from "../Items/PageHeader";

const EditBooking = () => {
  const { id, eventId } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleBooking({ id }));
    dispatch(getEventById({ id: eventId }));
  }, [id, eventId, dispatch]);

  const singleBooking = useSelector((state) => state.singleBooking);
  const { data, loading } = singleBooking;

  const eventById = useSelector((state) => state.eventById);
  const { data: eventData, loading: loadingEvent } = eventById;
  const OCC = eventData?.Occurrences;

  console.log("OCC", OCC);
  console.log("data", data);

  const disableOccIfBooked = (occurrence) => {
    let booked = false;
    if (data?.occurrenceId === occurrence?.uuid) {
      booked = true;
    }
    if (booked) {
      return true;
    } else {
      return false;
    }
  };

  if (loading || loadingEvent) {
    <Stack justifyContent="center">
      <Typography variant="h6">Loading...</Typography>
    </Stack>;
  }

  return (
    <Container maxWidth="md">
      <PageHeader title="Edit Booking" />

      <Card>
        <CardHeader title={eventData?.name} />
        <CardMedia
          component="img"
          height="400"
          image={`${process.env.REACT_APP_IMAGE_URL}/${eventData?.banner}`}
          alt="Paella dish"
        />

        <CardContent>
          <Formik
            initialValues={{
              occ: data?.occurrenceId || "",
              timeSlots: data?.timeSlots || [],
            }}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
              }, 400);
            }}>
            {({ isSubmitting, values }) => {
              console.log(values);
              return (
                <Form>
                  <Stack spacing={2}>
                    {OCC && (
                      <SelectField
                        options={OCC.map((o) => {
                          return {
                            key: o.uuid,
                            value: o.uuid,
                            name: o.uuid,
                            // name: moment(o.occurrence).format("DD/MM/YYYY"),
                            disabled: disableOccIfBooked(o),
                          };
                        })}
                        name="occ"
                        label="Occurrence"
                      />
                    )}

                    {values.occ &&
                      OCC?.find((o) => o.uuid === values.occ)?.timeSlots.map(
                        (t, index) => {
                          return (
                            <ClickableChip
                              key={index}
                              label={t.slot}
                              variant="outlined"
                            />
                          );
                        }
                      )}

                    {/* 
                {OCC &&
                  OCC[0]?.timeSlots?.length > 0 &&
                  OCC[0].timeSlots.map((slot) => {
                    return (
                      <Chip
                        key={slot.slot}
                        variant={
                          data?.timeSlots.includes(slot.slot)
                            ? "contained"
                            : "outlined"
                        }
                        label={slot.slot}
                      />
                    );
                  })} */}

                    <div role="group" aria-labelledby="timeSlots-group">
                      {values.timeSlots.map((t, index) => {
                        return (
                          <label key={index}>
                            <Field type="checkbox" name="timeSlots" value={t} />
                            {t.slot}
                          </label>
                        );
                      })}
                    </div>

                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}>
                      Submit
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </CardContent>
      </Card>
    </Container>
  );
};

export default EditBooking;

const SelectField = ({ name, options, ...otherProps }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: "outlined",
    fullWidth: true,
    onChange: handleChange,
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }

  return (
    <TextField {...configSelect}>
      {options.map((option) => (
        <MenuItem key={option.key} value={option.value}>
          {option.name} {option.disabled && "(Booked)"}
        </MenuItem>
      ))}
    </TextField>
  );
};

const ClickableChip = ({ label, ...otherProps }) => {
  return (
    <Chip
      label={label}
      clickable
      color="primary"
      variant="outlined"
      {...otherProps}
    />
  );
};
