import { Alert, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import withAuth from "../../Hoc/withAuth";
import { useDispatch, useSelector } from "react-redux";
import { CreateParentStudentRelations } from "../../Redux/actions/Relations";

const CreateParentStudentRelation = ({ user }) => {
  const [email, setEmail] = useState("");
  const dispatch = useDispatch();

  const Relations = useSelector((state) => state.Relations);
  const { error } = Relations;

  const handleSubmit = () => {
    let formData = {
      parentEmail:
        user.role === "parent" ? user.email.toLowerCase() : email.toLowerCase(),
      studentEmail:
        user.role === "student"
          ? user.email.toLowerCase()
          : email.toLowerCase(),
    };

    dispatch(CreateParentStudentRelations(formData));
    setEmail("");
  };

  return (
    <>
      <Grid container sx={{ mb: 5 }}>
        <Grid item xs={12}>
          <Typography gutterBottom variant="body2">
            {user.role === "parent"
              ? "Connect your account to your child's and make sure that their community service efforts are accurately reflected."
              : "Add Parent to your account"}
          </Typography>

          <TextField
            label="Email"
            margin="normal"
            fullWidth
            name="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />

          <Button onClick={handleSubmit} color="primary" variant="contained">
            Add New Relation
          </Button>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
      </Grid>
    </>
  );
};

export default withAuth(CreateParentStudentRelation);
