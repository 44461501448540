import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {
  AuthReducer,
  ActivateUserReducer,
  DeleteAssReducer,
} from "./Redux/Reducers/AuthReducer";
import {
  OrgsReduer,
  MyOrgReduer,
  GetOrgByIdReducer,
  EditOrgReducer,
  addOrgReducer,
  getEventsByOrgIdReducer,
} from "./Redux/Reducers/OrgsReducer";
import {
  eventsReducer,
  eventByIdReducer,
  addEventReducer,
  editEventReducer,
  deleteEventReducer,
  getEventAdminReducer,
} from "./Redux/Reducers/EventsReducer";

import {
  endEventReducer,
  eventOccReducer,
  joinEventReducer,
} from "./Redux/Reducers/EventOccReducer";

import { commonActionsReducer } from "./Redux/Reducers/CommonReducer";
import {
  addBookingReducer,
  getSingleBookingReducer,
  getUsersBookingsReducer,
} from "./Redux/Reducers/BookingsReducer";
import { getNotificationsReducer } from "./Redux/Reducers/NotificationsReducer";
import {
  addStudentReducer,
  AssociationsReducer,
  updateStudentReducer,
} from "./Redux/Reducers/UsersReducer";
import { SearchReducer } from "./Redux/Reducers/SearchReducer";
import { AppDataReducer, getMySchoolReducer } from "./Redux/Reducers/AppData";
import { StatsReducer } from "./Redux/Reducers/UserStatsReducers";
import { GetOrgStatsReducer } from "./Redux/Reducers/OrgStatsReducers";
import { SchoolNewsReducer } from "./Redux/Reducers/SchoolDataReducer";
import { RelationsReducer } from "./Redux/Reducers/RelationsReducer";
import { AdminReducer } from "./Redux/Reducers/AdminReducer";
import OrgAssociationReducer from "./Redux/Reducers/OrgAssociationReducer";
import SchoolStudentReducer from "./Redux/Reducers/SchoolStudentReducer";
import SchoolReportsReducer from "./Redux/Reducers/SchoolReports";
import SchoolBulkManageStudentsReducer from "./Redux/Reducers/SchoolBulkManageStudentsReducer";

const reducer = combineReducers({
  userInfo: AuthReducer,
  appData: AppDataReducer,
  mySchool: getMySchoolReducer,
  activateUser: ActivateUserReducer,
  DeleteAss: DeleteAssReducer,
  updateStudent: updateStudentReducer,
  //SCHOOLS
  schoolReport: SchoolReportsReducer,
  //ORGS
  orgs: OrgsReduer,
  orgById: GetOrgByIdReducer,
  myOrg: MyOrgReduer,
  orgAssociations: OrgAssociationReducer,
  editOrg: EditOrgReducer,
  addOrg: addOrgReducer,
  //ORG/EVENTS
  orgEvents: getEventsByOrgIdReducer,
  //EVENTS
  events: eventsReducer,
  eventById: eventByIdReducer,
  addEvent: addEventReducer,
  editEvent: editEventReducer,
  deleteEvent: deleteEventReducer,
  eventAdmin: getEventAdminReducer,
  //Event OCC
  occData: eventOccReducer,
  joinEvent: joinEventReducer,
  endEvent: endEventReducer,

  //BOOKINGS
  addBooking: addBookingReducer,
  userBookings: getUsersBookingsReducer,
  singleBooking: getSingleBookingReducer,

  //SCHOOL DATA
  SchoolBulk: SchoolBulkManageStudentsReducer,
  schoolData: SchoolNewsReducer,
  schoolStudents: SchoolStudentReducer,

  //STATS

  stats: StatsReducer,
  orgStats: GetOrgStatsReducer,
  //NOTIFICATIONS
  notifications: getNotificationsReducer,
  //COMMON
  common: commonActionsReducer,
  associations: AssociationsReducer,
  search: SearchReducer,
  AddStudent: addStudentReducer,

  Relations: RelationsReducer,

  // ADMIN PANEL
  AdminData: AdminReducer,
});

const middleware = [thunk];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : { token: null };

const initialState = { userInfo: userInfoFromStorage };

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
const store = createStore(
  reducer,
  initialState,
  composeEnhancers(applyMiddleware(...middleware))
);
export default store;
