import React, { useEffect } from "react";
import { Form, Formik } from "formik";

import {
  Button,
  Grid,
  Typography,
  Stack,
  Card,
  Divider,
  Container,
  TextField,
  Alert,
  CardContent,
} from "@mui/material";
import TextfieldWrapper from "../FormComponents/TextfieldWrapper";
import * as Yup from "yup";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import AlertPopup from "../../Items/AlertPopup";
import Slide from "@mui/material/Slide";
import WeeklyEventsForm from "./WeeklyEventsForm";
import MonthlyOcc from "./MonthlyOcc";
import TimeRangPicker from "./TimeRangPicker";
import Occurrences from "./Occurrences";
import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import locale from dayjs en-gb
import "dayjs/locale/en-gb";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import MapAndSearchComponent from "../FormComponents/MapAndSearchComponent";
import AddEventTags from "./AddEventTags";
import { useQuery } from "react-query";
import ListEventTags from "./ListEventTags";
import MySlateEditor from "../MySlateEditor";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const EditEventAndOccurrences = ({ data, token }) => {
  const queryClient = useQueryClient();

  const getTags = useQuery({
    queryKey: ["orgTags", data.uuid],
    queryFn: () =>
      axios(`${process.env.REACT_APP_PUBLIC_URL}/tags/eventTags/${data.uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
  });

  const updateEvent = useMutation(
    (event) => {
      return axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/events/${data.uuid}`,
        event,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["event", data.uuid],
        });
      },
    }
  );

  return (
    <>
      <Formik
        initialValues={{
          uuid: data?.uuid || "",
          active: data?.active || false,
          name: data?.name || "",
          description: data?.description || "",
          tags: data?.tags || [],
          occurrences: data?.Occurrences || [],
          org_id: data?.org_id || "",
          restrictions: data?.restrictions || [],
          location: data?.location || data?.Org?.location || "",
          address: data?.address || data?.Org?.address || "",
          private: data?.private || false,
          additionalInformation: data?.additionalInformation || [
            { type: "paragraph", children: [{ text: "" }] },
          ],
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
          description: Yup.string().required("Description is required"),
          occurrences: Yup.array().required("Occurrences is required"),
          org_id: Yup.string().required("Org ID is required"),
        })}
        onSubmit={(values) => {
          updateEvent.mutate(values);
        }}>
        {({ values, isSubmitting, handleSubmit, setFieldValue }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography>Name</Typography>
                  <Typography variant="caption" color="GrayText">
                    The name of the event
                  </Typography>
                  <TextfieldWrapper name="name" />
                </Grid>

                {data?.Org?.orgType === "school" && (
                  <Grid item xs={12}>
                    <Stack>
                      <Typography>Private Event</Typography>
                      <Typography variant="caption" color="GrayText">
                        Is the event private?
                      </Typography>
                      <Typography variant="caption" color="GrayText">
                        Only members of the school can book this event
                      </Typography>
                      <Alert
                        severity={values.private ? "success" : "info"}
                        action={
                          <Button
                            variant="contained"
                            color={values.private ? "warning" : "inherit"}
                            onClick={() =>
                              setFieldValue("private", !values.private)
                            }>
                            {values.private
                              ? "Make Event Public"
                              : "Make Event Private"}
                          </Button>
                        }>
                        {values.private
                          ? "Event is Private"
                          : "Event is Public"}
                      </Alert>
                    </Stack>
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Typography>Description</Typography>
                  <Typography variant="caption" color="GrayText">
                    Description of the event.
                  </Typography>
                  <TextfieldWrapper multiline name="description" />
                </Grid>

                <Grid item xs={12} md={12} lg={6}>
                  <Typography>Restrictions</Typography>
                  <Typography variant="caption" color="GrayText">
                    Restrictions on who can book this event
                  </Typography>
                  <TextfieldWrapper name="restrictions" />
                </Grid>

                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Location</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Start typing your address in the field below and select
                        the correct one from the dropdown suggestions. Our
                        Google-powered location lookup ensures an accurate and
                        seamless process, making it easier to pinpoint your
                        exact location. Ideal for ensuring precise event
                        coordination and connectivity.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <MapAndSearchComponent />
                </Grid>

                <Grid item xs={12}>
                  <Typography>Causes</Typography>
                  <Typography variant="caption" color="GrayText">
                    Select some causes that best describe your School
                  </Typography>

                  <AddEventTags
                    eventId={data.uuid}
                    OrgTags={getTags?.data?.data}
                  />
                </Grid>
                <Grid item xs={12}>
                  {getTags?.data?.data && getTags?.data?.data.length > 0 && (
                    <ListEventTags
                      eventId={data.uuid}
                      OrgTags={getTags?.data?.data}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Typography>Additional Information</Typography>
                  <Typography variant="caption" color="GrayText">
                    Additional information about the event
                  </Typography>

                  <MySlateEditor
                    value={values.additionalInformation}
                    onChange={(value) => {
                      setFieldValue("additionalInformation", value);
                    }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Stack>
                    <CreateOccurrences
                      eventId={data.uuid}
                      values={values}
                      setFieldValue={setFieldValue}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <Occurrences occurrences={values.occurrences} />
                </Grid>

                <Grid item xs={12}>
                  <Stack>
                    <Typography>Active</Typography>
                    <Stack>
                      <Typography variant="caption" color="GrayText">
                        Only active events can be booked by users on the
                        platform if you deactivate the event it will not be
                        visible to users on the platform
                      </Typography>
                    </Stack>

                    <Alert
                      severity={values.active ? "success" : "error"}
                      action={
                        <Button
                          variant="contained"
                          color={values.active ? "error" : "success"}
                          onClick={() =>
                            setFieldValue("active", !values.active)
                          }>
                          {values.active ? "Deactivate" : "Activate"}
                        </Button>
                      }>
                      {values.active
                        ? "Event is Active"
                        : "Event is Inactive this event will not be visible to users on the platform"}
                    </Alert>
                  </Stack>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    type="submit"
                    disabled={
                      isSubmitting ||
                      updateEvent.isLoading ||
                      !values?.occurrences?.length > 0
                    }>
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <AlertPopup
        open={updateEvent.isSuccess}
        message="Event Updated Successfully"
        severity="success"
      />
      <AlertPopup
        open={updateEvent.error}
        message="An Error error occurred while updating the event"
        severity="error"
      />
    </>
  );
};

export default EditEventAndOccurrences;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateOccurrences = ({ values, setFieldValue, eventId }) => {
  const [open, setOpen] = React.useState(false);

  const [occurrences, setOccurrences] = React.useState([]);

  const [slots, setSlots] = React.useState({
    startTime: dayjs(new Date(0, 0, 0, 8, 0)),
    endTime: dayjs(new Date(0, 0, 0, 17, 0)),
  });

  const [capacity, setCapacity] = React.useState(0);

  const [timeSlots, setTimeSlots] = React.useState();

  const [recurrence] = React.useState("Weekly");

  const [daysOfTheWeek, setDaysOfTheWeek] = React.useState("");

  const [date, setDate] = React.useState(dayjs());

  useEffect(() => {
    // Calculate the number of hours between startDate and endDate
    const hoursBetween = slots.endTime.diff(slots.startTime, "hour");

    // Generate the array of time slots
    let timeSlots = [];
    for (let i = 1; i <= hoursBetween; i++) {
      // Add each hour to the startDate and format it to get the slot value
      let slotTime = slots.startTime.add(i, "hour").format("HH:mm");
      timeSlots.push({ slot: slotTime });
    }

    setTimeSlots(timeSlots);
  }, [slots]);

  const HandleCapacity = (e) => {
    setCapacity(e.target.value);
    setFieldValue("capacity", e.target.value);
  };

  useEffect(() => {
    setOccurrences((prev) => {
      return prev.map((occ, index) => {
        return {
          ...occ,
          id: index,
          slots: timeSlots,
          capacity: capacity,
          daysOfTheWeek: dayjs(occ.occurrence).format("dddd"),
          eventId: eventId,
        };
      });
    });
  }, [timeSlots, capacity, daysOfTheWeek, eventId, slots]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOccurrences([]);
  };

  const handleSave = () => {
    setFieldValue("occurrences", occurrences);
    setOccurrences([]);
    handleClose();
  };

  const handleOnceOffOccurrence = (data) => {
    setDate(data);
    let occur = {
      occurrence: data,
      capacity: capacity,
      slots: timeSlots,
      daysOfTheWeek: dayjs(data).format("dddd"),
      eventId: eventId,
    };
    setOccurrences([occur]);
  };

  return (
    <React.Fragment>
      <Card
        variant="contained"
        sx={{
          backgroundColor: "secondary.main",
          color: "primary.contrastText",
          "&:hover": {
            cursor: "pointer",
            backgroundColor: "secondary.dark",
            color: "primary.contrastText",
          },
        }}>
        <CardContent onClick={handleClickOpen}>
          <Stack direction="row" alignItems="center" spacing={3}>
            <CalendarMonthIcon
              sx={{
                fontSize: "2rem",
              }}
            />

            <Typography variant="h4">Occurrences</Typography>
          </Stack>

          <Typography variant="caption">
            Add occurrences to the event to allow users to book the event at
            different times and dates of the event.
          </Typography>
        </CardContent>
      </Card>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Create Occurrences
            </Typography>
            <Button autoFocus color="inherit" onClick={handleClose}>
              close
            </Button>
          </Toolbar>
        </AppBar>

        <Container>
          <Grid container sx={{ mt: 4 }} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Create Occurrences</Typography>
            </Grid>
            {/* <Grid item xs={6} md={12} lg={12}>
              <Typography>Recurrence</Typography>
              <Typography variant="caption" color="GrayText">
                How often will the event occur?
              </Typography>
              <FormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={recurrence}
                  onChange={(e) => setRecurrence(e.target.value)}>
                  <MenuItem value="OnceOff">Once Off</MenuItem>
                  <MenuItem value="Weekly">Weekly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item xs={12}>
              <Stack>
                <Typography>Capacity</Typography>
                <Typography variant="caption" color="GrayText">
                  The maximum number of people that can book this event
                </Typography>
                <TextField
                  name="capacity"
                  type="number"
                  value={capacity}
                  onChange={HandleCapacity}
                />
              </Stack>
            </Grid>

            {recurrence === "Weekly" && (
              <Grid item xs={12}>
                <Typography>Select Day of the week</Typography>
                <Typography variant="caption" color="GrayText">
                  Which day of the week will the event occur?
                </Typography>
                <FormControl fullWidth>
                  <Select
                    id="day_of_the_week"
                    value={daysOfTheWeek}
                    onChange={(e) => setDaysOfTheWeek(e.target.value)}>
                    <MenuItem value="Monday">Monday</MenuItem>
                    <MenuItem value="Tuesday">Tuesday</MenuItem>
                    <MenuItem value="Wednesday">Wednesday</MenuItem>
                    <MenuItem value="Thursday">Thursday</MenuItem>
                    <MenuItem value="Friday">Friday</MenuItem>
                    <MenuItem value="Saturday">Saturday</MenuItem>
                    <MenuItem value="Sunday">Sunday</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            )}

            {recurrence === "OnceOff" && (
              <>
                <Grid item xs={12}>
                  <Stack>
                    <Typography>Event Date</Typography>
                    <Typography variant="caption" color="GrayText">
                      Select the date of the event
                    </Typography>

                    <LocalizationProvider
                      dateAdapter={AdapterDayjs}
                      adapterLocale="en-gb">
                      <DatePicker
                        format="LL"
                        minDate={dayjs()}
                        value={dayjs(date)}
                        onChange={handleOnceOffOccurrence}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              </>
            )}
            {recurrence === "Weekly" && daysOfTheWeek && (
              <WeeklyEventsForm
                eventId={eventId}
                timeSlots={timeSlots}
                capacity={capacity}
                daysOfTheWeek={daysOfTheWeek}
                setOccurrences={setOccurrences}
                startTime={slots.startTime}
                endTime={slots.endTime}
              />
            )}
            {recurrence === "Monthly" && (
              <MonthlyOcc setOccurrences={setOccurrences} capacity={capacity} />
            )}

            <Grid item xs={12}>
              {!values?.occurrences?.length > 0 && (
                <Typography variant="caption" color="GrayText">
                  Please select a start date and end date to generate
                  occurrences
                </Typography>
              )}
            </Grid>

            {occurrences && occurrences.length > 0 && (
              <>
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                  <Typography>Time Slots</Typography>
                  <Typography variant="caption" color="GrayText">
                    This is the time slots that will be available for the event
                    attendees.
                  </Typography>
                  <Grid item xs={12}>
                    <Stack direction="row">
                      {timeSlots?.map((slot) => (
                        <Card sx={{ m: 2, p: 2 }} key={slot.slot}>
                          <Typography>{slot.slot}</Typography>
                        </Card>
                      ))}
                    </Stack>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </Grid>

                <Grid container sx={{ mt: 1, mx: 2 }}>
                  <TimeRangPicker slots={slots} setSlots={setSlots} />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <Occurrences occurrences={occurrences} />
            </Grid>
          </Grid>
        </Container>
        <DialogActions
          sx={{
            backgroundColor: "primary.secondary",
          }}>
          <Button onClick={handleClose} variant="contained" color="warning">
            close
          </Button>
          {occurrences?.length > 0 && timeSlots?.length > 0 && (
            <Button
              fullWidth
              onClick={handleSave}
              type="submit"
              variant="contained">
              Add Occurrences to Event
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};
