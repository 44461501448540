import axios from "axios";

// import constants from reducer
import {
  ORG_ASSOCIATION_REQUEST,
  ORG_ASSOCIATION_SUCCESS,
  ORG_ASSOCIATION_FAILURE,
  ORG_ASSOCIATION_LIST_FAILURE,
  ORG_ASSOCIATION_LIST_REQUEST,
  ORG_ASSOCIATION_LIST_SUCCESS,
  ORG_ASSOCIATION_DELETE_REQUEST,
  ORG_ASSOCIATION_DELETE_SUCCESS,
  ORG_ASSOCIATION_DELETE_FAILURE,
  ORG_ASSOCIATION_CREATE_REQUEST,
  ORG_ASSOCIATION_CREATE_SUCCESS,
  ORG_ASSOCIATION_CREATE_FAILURE,
  ORG_ASSOCIATION_UPDATE_REQUEST,
  ORG_ASSOCIATION_UPDATE_SUCCESS,
  ORG_ASSOCIATION_UPDATE_FAILURE,
  ORG_REQUEST_USER_TO_ASSOCIATE,
  ORG_REQUEST_SUCCESS_USER_TO_ASSOCIATE,
  ORG_REQUEST_FAILURE_USER_TO_ASSOCIATE,
  MY_ASSOCIATIONS,
  MY_ASSOCIATIONS_SUCCESS,
  MY_ASSOCIATIONS_FAIL,
} from "../Reducers/OrgAssociationReducer";

export const getOrgAssociationsList = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORG_ASSOCIATION_LIST_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/${id}`,
      config
    );

    dispatch({ type: ORG_ASSOCIATION_LIST_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: ORG_ASSOCIATION_LIST_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSingleOrgAssociation =
  (id, user_id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORG_ASSOCIATION_REQUEST });

      const { userInfo } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/${id}/${user_id}`,
        config
      );

      dispatch({ type: ORG_ASSOCIATION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ORG_ASSOCIATION_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createOrgAssociationRequest =
  (id, values) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORG_ASSOCIATION_CREATE_REQUEST });

      const { userInfo } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/${id}`,
        values,
        config
      );

      dispatch({ type: ORG_ASSOCIATION_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ORG_ASSOCIATION_CREATE_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const updateOrgAssociation =
  (id, values) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORG_ASSOCIATION_UPDATE_REQUEST });

      const { userInfo } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/${id}`,
        values,
        config
      );

      dispatch({ type: ORG_ASSOCIATION_UPDATE_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({
        type: ORG_ASSOCIATION_UPDATE_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteOrgAssociation = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: ORG_ASSOCIATION_DELETE_REQUEST });

    const { userInfo } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/${id}`,
      config
    );

    dispatch({ type: ORG_ASSOCIATION_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ORG_ASSOCIATION_DELETE_FAILURE,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const requestUserToAssociate =
  (values) => async (dispatch, getState) => {
    try {
      dispatch({ type: ORG_REQUEST_USER_TO_ASSOCIATE });

      const { userInfo } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/request`,
        values,
        config
      );

      dispatch({ type: ORG_REQUEST_SUCCESS_USER_TO_ASSOCIATE, payload: data });
    } catch (error) {
      dispatch({
        type: ORG_REQUEST_FAILURE_USER_TO_ASSOCIATE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getMyRequests = () => async (dispatch, getState) => {
  try {
    dispatch({ type: MY_ASSOCIATIONS });

    const { userInfo } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/myRequests`,
      config
    );

    dispatch({ type: MY_ASSOCIATIONS_SUCCESS, payload: data.data });
  } catch (error) {
    dispatch({
      type: MY_ASSOCIATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const UserRequestToAssociate =
  (values) => async (dispatch, getState) => {
    try {
      dispatch({ type: MY_ASSOCIATIONS });

      const { userInfo } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/${values.org_id}`,
        {
          userType: values.userType,
          user_id: values.user_id,
        },
        config
      );

      dispatch({ type: MY_ASSOCIATIONS_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({
        type: MY_ASSOCIATIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const approveAssociation =
  ({ values }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ORG_ASSOCIATION_UPDATE_REQUEST });

      const { userInfo } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/approve`,
        values,
        config
      );

      dispatch({ type: ORG_ASSOCIATION_UPDATE_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({
        type: ORG_ASSOCIATION_UPDATE_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deActiveAssociation =
  ({ values }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: ORG_ASSOCIATION_UPDATE_REQUEST });

      const { userInfo } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/orgAssociations/deActive`,
        values,
        config
      );

      dispatch({ type: ORG_ASSOCIATION_UPDATE_SUCCESS, payload: data.data });
    } catch (error) {
      dispatch({
        type: ORG_ASSOCIATION_UPDATE_FAILURE,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
