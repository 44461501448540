import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React from "react";
import AdminPanelSettingsTwoToneIcon from "@mui/icons-material/AdminPanelSettingsTwoTone";
import { useHistory } from "react-router-dom";

const AdminDrawer = () => {
  const router = useHistory();
  return (
    <div>
      <List>
        <ListItem
          button
          onClick={(event) => {
            router.push("/admin");
          }}>
          <ListItemIcon>
            <AdminPanelSettingsTwoToneIcon />
          </ListItemIcon>
          <ListItemText primary={"Admin"} />
        </ListItem>
      </List>
    </div>
  );
};

export default AdminDrawer;
