import axios from "axios";

export const getNotification = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "REQUEST_NOTIFICATIONS" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/notifications`,
      config
    );

    dispatch({ type: "GET_NOTIFICATIONS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "FAIL_NOTIFICATIONS",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetSeenNotifications = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "RESET_SEEN_NOTIFICATION_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/notifications/seen`,
      config
    );

    dispatch({ type: "RESET_SEEN_NOTIFICATION_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "RESET_SEEN_NOTIFICATION_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const readNotification = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "READ_NOTIFICATION_REQUEST" });

    const { userInfo } = getState();
    // const { notifications } = getState()
    // console.log(notifications.userNotifications);
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `${process.env.REACT_APP_PUBLIC_URL}/notifications/${id}`,
      {},
      config
    );

    dispatch({ type: "READ_NOTIFICATION_SUCCESS", payload: data.data });

  } catch (error) {
    dispatch({
      type: "READ_NOTIFICATION_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const sendNotification =
  // receiverId, content, type, severity, title, url

  (notification) => async (dispatch, getState) => {
    try {
      dispatch({ type: "SEND_NOTIFICATION_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/notifications`,
        notification,
        config
      );

      dispatch({ type: "SEND_NOTIFICATION_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "SEND_NOTIFICATION_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const sendNotificationToAdmin =
  // receiverId, content, type, severity, title, url

  (notification) => async (dispatch, getState) => {
    try {
      dispatch({ type: "SEND_NOTIFICATION_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/notifications/admin`,
        notification,
        config
      );

      dispatch({ type: "SEND_NOTIFICATION_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "SEND_NOTIFICATION_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
