import axios from "axios";
import { url } from "./URL";

export const AdminGetAllUsers = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_ALL_USERS_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${url}/admin/users`, config);

    dispatch({ type: "GET_ALL_USERS__SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_ALL_USERS__FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AdminGetUser =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_USER_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`${url}/admin/users/${id}`, config);

      dispatch({ type: "GET_USER_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_USER_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const AdminAllOrgs = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_ALL_ORG_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${url}/admin/orgs`, config);

    dispatch({ type: "GET_ALL_ORG_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_ALL_ORG_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const AdminGetOrg =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "ADMIN_GET_ORG_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`${url}/admin/orgs/${id}`, config);

      dispatch({ type: "ADMIN_GET_ORG_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "ADMIN_GET_ORG_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const AdminApproveOrg =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${url}/admin/orgs/approve/${id}`,
        config
      );

      dispatch({ type: "APPROVE_ORG", payload: data });
    } catch (error) {
      dispatch({
        type: "ORG_APP_SUS_ERROR",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const SuspendOrg =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${url}/admin/orgs/suspend/${id}`,
        config
      );

      dispatch({ type: "SUSPEND_ORG", payload: data });
    } catch (error) {
      dispatch({
        type: "ORG_APP_SUS_ERROR",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
