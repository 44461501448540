const initialState = {
  userRequestToAssociate: [],
  orgAssociation: {},
  orgAssociationList: [],
  loading: false,
  error: null,
  success: null,
  deleteLoading: false,
  deleteSuccess: null,
  deleteError: null,
  createLoading: false,
  createSuccess: null,
  createError: null,
  updateLoading: false,
  updateSuccess: null,
  updateError: null,
  requestToAssociate: null,
  associations: [],
};

// constants
export const ORG_ASSOCIATION_REQUEST = "ORG_ASSOCIATION_REQUEST";
export const ORG_ASSOCIATION_SUCCESS = "ORG_ASSOCIATION_SUCCESS";
export const ORG_ASSOCIATION_FAILURE = "ORG_ASSOCIATION_FAILURE";

export const ORG_ASSOCIATION_LIST_FAILURE = "ORG_ASSOCIATION_LIST_FAILURE";
export const ORG_ASSOCIATION_LIST_REQUEST = "ORG_ASSOCIATION_LIST_REQUEST";
export const ORG_ASSOCIATION_LIST_SUCCESS = "ORG_ASSOCIATION_LIST_SUCCESS";

export const ORG_ASSOCIATION_DELETE_REQUEST = "ORG_ASSOCIATION_DELETE_REQUEST";
export const ORG_ASSOCIATION_DELETE_SUCCESS = "ORG_ASSOCIATION_DELETE_SUCCESS";
export const ORG_ASSOCIATION_DELETE_FAILURE = "ORG_ASSOCIATION_DELETE_FAILURE";

export const ORG_ASSOCIATION_CREATE_REQUEST = "ORG_ASSOCIATION_CREATE_REQUEST";
export const ORG_ASSOCIATION_CREATE_SUCCESS = "ORG_ASSOCIATION_CREATE_SUCCESS";
export const ORG_ASSOCIATION_CREATE_FAILURE = "ORG_ASSOCIATION_CREATE_FAILURE";

export const ORG_ASSOCIATION_UPDATE_REQUEST = "ORG_ASSOCIATION_UPDATE_REQUEST";
export const ORG_ASSOCIATION_UPDATE_SUCCESS = "ORG_ASSOCIATION_UPDATE_SUCCESS";
export const ORG_ASSOCIATION_UPDATE_FAILURE = "ORG_ASSOCIATION_UPDATE_FAILURE";

export const MY_ASSOCIATIONS = "MY_ASSOCIATIONS";
export const MY_ASSOCIATIONS_FAIL = "MY_ASSOCIATIONS_FAIL";
export const MY_ASSOCIATIONS_SUCCESS = "MY_ASSOCIATIONS_SUCCESS";

export const ORG_REQUEST_USER_TO_ASSOCIATE = "ORG_REQUEST_USER_TO_ASSOCIATE";
export const ORG_REQUEST_SUCCESS_USER_TO_ASSOCIATE =
  "ORG_REQUEST_SUCCESS_USER_TO_ASSOCIATE";
export const ORG_REQUEST_FAILURE_USER_TO_ASSOCIATE =
  "ORG_REQUEST_FAILURE_USER_TO_ASSOCIATE";

const OrgAssociationReducer = (state = initialState, action) => {
  switch (action.type) {
    case MY_ASSOCIATIONS:
      return {
        ...state,

        loading: false,
        error: null,
        success: null,
      };
    case MY_ASSOCIATIONS_SUCCESS:
      return {
        ...state,
        loading: true,
        error: null,
        associations: action.payload,
      };
    case MY_ASSOCIATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case ORG_ASSOCIATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        requestToAssociate: null,
        success: null,
      };
    case ORG_ASSOCIATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orgAssociation: action.payload,
      };
    case ORG_ASSOCIATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case ORG_ASSOCIATION_LIST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };
    case ORG_ASSOCIATION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orgAssociationList: action.payload,
      };
    case ORG_ASSOCIATION_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };
    case ORG_ASSOCIATION_DELETE_REQUEST:
      return {
        ...state,
        deleteLoading: true,
        deleteSuccess: null,
        deleteError: null,
      };
    case ORG_ASSOCIATION_DELETE_SUCCESS:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: action.payload,
        deleteError: null,
      };
    case ORG_ASSOCIATION_DELETE_FAILURE:
      return {
        ...state,
        deleteLoading: false,
        deleteSuccess: null,
        deleteError: action.payload,
      };
    case ORG_ASSOCIATION_CREATE_REQUEST:
      return {
        ...state,
        createLoading: true,
        createSuccess: null,
        createError: null,
      };
    case ORG_ASSOCIATION_CREATE_SUCCESS:
      return {
        ...state,
        createLoading: false,
        orgAssociationList: [...state.orgAssociationList, action.payload],
        createError: null,
        createSuccess: true,
      };
    case ORG_ASSOCIATION_CREATE_FAILURE:
      return {
        ...state,
        createLoading: false,
        createSuccess: null,
        createError: action.payload,
      };
    case ORG_ASSOCIATION_UPDATE_REQUEST:
      return {
        ...state,
        updateLoading: true,
        updateSuccess: null,
        updateError: null,
      };
    case ORG_ASSOCIATION_UPDATE_SUCCESS:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: action.payload,
        associations: [
          ...state.orgAssociationList.map((orgAssociation) => {
            if (orgAssociation.uuid === action.payload.uuid) {
              return action.payload;
            }
            return orgAssociation;
          }),
        ],
        updateError: null,
      };
    case ORG_ASSOCIATION_UPDATE_FAILURE:
      return {
        ...state,
        updateLoading: false,
        updateSuccess: null,
        updateError: action.payload,
      };

    case ORG_REQUEST_USER_TO_ASSOCIATE:
      return {
        ...state,
        loading: true,
        requestToAssociate: null,
        error: null,
        success: null,
      };
    case ORG_REQUEST_SUCCESS_USER_TO_ASSOCIATE:
      return {
        ...state,
        loading: false,
        error: null,
        createSuccess: true,
      };
    case ORG_REQUEST_FAILURE_USER_TO_ASSOCIATE:
      return {
        ...state,
        loading: false,
        error: action.payload,
        success: null,
      };

    default:
      return state;
  }
};

export default OrgAssociationReducer;
