import {
  Alert,
  Box,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrgAdminDashAssoc from "../../Components/Dashboard-Components/org-admin/OrgAdminDashAssoc";
import PageHeader from "../../Components/Items/PageHeader";
import { useHistory } from "react-router-dom";
import { getMyOrg } from "../../Redux/actions/Orgs";
import CreateNewOrgForm from "../../Components/Forms/Orgs/CreateNewOrgForm";
import BannerImage from "../../Components/ImageUploaders/BannerImage";
import AvatarImage from "../../Components/ImageUploaders/AvatarImage";
import withAuth from "../../Hoc/withAuth";
// import { useQuery } from "react-query";
// import axios from "axios";

const OrgAdminDashboard = ({ token }) => {
  const dispatch = useDispatch();

  // const getMe = useQuery(
  //   "me",
  //   () => {
  //     return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/auth/me`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });
  //   },
  //   {
  //     enabled: !!token,
  //     onError: () => {
  //       router.push("/login");
  //     },
  //   }
  // );

  const router = useHistory();

  useEffect(() => {
    dispatch(getMyOrg());
  }, [dispatch]);

  const myOrg = useSelector((state) => state.myOrg);

  const { data, loading, orgOccurrences } = myOrg;

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <PageHeader
        title="Organization Administrator DashBoard"
        description="Manage your organization and its events"
      />
      {data && data ? (
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            {data?.approved === false && (
              <Alert severity="warning">
                Your Organisation is not approved yet. Please Send an email to{" "}
                <a href="mailto:hello@chalkitup.co.za">hello@chalkitup.co.za</a>{" "}
                to approve your Organisation.
              </Alert>
            )}

            {data?.uuid ? (
              <Tooltip
                title="Click to view Organisation details"
                placement="right"
                arrow>
                <Card>
                  <CardActionArea onClick={() => router.push("/myOrg")}>
                    <CardHeader
                      title={data?.name}
                      avatar={
                        <AvatarImage
                          size="small"
                          uuid={data?.uuid}
                          token={token}
                        />
                      }
                    />
                    <BannerImage
                      uuid={data?.uuid}
                      token={token}
                      type="banner"
                    />
                  </CardActionArea>
                </Card>
              </Tooltip>
            ) : (
              <Card
                sx={{
                  border: "2px dashed #ccc",
                }}>
                <CardActionArea onClick={() => router.push("/myOrg")}>
                  <CardHeader title="Create A Organisation" />
                  <CardMedia
                    component="img"
                    height="350"
                    image={`${process.env.REACT_APP_IMAGE_URL}`}
                    alt={data?.name}
                  />
                </CardActionArea>
              </Card>
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack mb={2}>
              <Typography variant="h6" gutterBottom>
                Up Coming Events
              </Typography>
              <Typography variant="body1" color="GrayText" gutterBottom>
                View Up Coming Events
              </Typography>

              {orgOccurrences && orgOccurrences.length > 0 ? (
                <>
                  {orgOccurrences
                    .slice(0, 4)
                    .sort(
                      (a, b) => new Date(a.occurrence) - new Date(b.occurrence)
                    )
                    .map((occ, index) => {
                      return (
                        <Card key={index}>
                          <Tooltip title={occ?.Event?.name}>
                            <CardActionArea
                              onClick={() =>
                                router.push(`/OccurrencePage/${occ.uuid}`)
                              }>
                              <CardHeader
                                title={occ?.Event?.name}
                                subheader={occ?.Event?.description}
                              />
                              <CardMedia
                                component="img"
                                height="140"
                                image={`${process.env.REACT_APP_IMAGE_URL}/${occ?.Event?.banner}`}
                                alt={occ?.Event?.name}
                              />
                            </CardActionArea>
                          </Tooltip>
                        </Card>
                      );
                    })}
                </>
              ) : (
                <Button onClick={() => router.push(`/myEvents/${data.uuid}`)}>
                  Create Events
                </Button>
              )}
            </Stack>
            <Stack>
              <Typography variant="h6" gutterBottom>
                Ambassadors
              </Typography>
              <Typography variant="body1" color="GrayText" gutterBottom>
                View and Manage Ambassadors
              </Typography>
              {data && data && <OrgAdminDashAssoc org={data} />}
            </Stack>
          </Grid>
        </Grid>
      ) : (
        <CreateNewOrgForm orgType="charity" />
      )}
    </Container>
  );
};

export default withAuth(OrgAdminDashboard);
