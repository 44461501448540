import { ListItem, ListItemText, Typography } from "@mui/material";

const ContentItem = ({ title, value }) => {
  return (
    <>
      <ListItem sx={{ m: 0, p: 0 }} alignItems="flex-start">
        <ListItemText
          primary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.secondary">
                {title}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body1"
                color="text.primary">
                {value}
              </Typography>
            </>
          }
        />
      </ListItem>
    </>
  );
};

export default ContentItem;
