import axios from "axios";
import { url } from "./URL";

export const getOrgStatsRequest = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "REQUEST_ORG_STATS" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${url}/stats/orgStats`, config);

    dispatch({ type: "SUCCESS_ORG_STATS", payload: data });
  } catch (error) {
    dispatch({
      type: "FAIL_ORG_STATS",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
