import { Button, Card, CardContent, Typography } from "@mui/material";
import React from "react";
import DeleteRelation from "./DeleteRelation";
import { useDispatch } from "react-redux";
import {
  parentAuthStudentEvents,
  parentConfirmStudentRelation,
  parentDeAuthStudentEvents,
  studentConfirmParent,
} from "../../Redux/actions/Relations";

const RelationCard = ({
  relation,
  role,
  confirmedStudent,
  confirmedStudentEvents,
}) => {
  const dispatch = useDispatch();

  const StudentConfirmParentRelation = () => {
    dispatch(studentConfirmParent(relation?.uuid));
  };

  const parentConfirmStudent = () => {
    dispatch(parentConfirmStudentRelation(relation?.uuid));
  };

  const parentAuthStudentEvent = () => {
    dispatch(parentAuthStudentEvents(relation?.uuid));
  };
  const parentDeAuthStudentEvent = () => {
    dispatch(parentDeAuthStudentEvents(relation?.uuid));
  };

  return (
    <Card>
      <CardContent sx={{ p: 3 }}>
        <Typography variant="h6">Student : {relation?.studentEmail}</Typography>
        <Typography variant="h6"> Parent : {relation?.parentEmail}</Typography>

        {role === "parent" && (
          <>
            {!confirmedStudent && !relation?.studentConfirmed ? (
              <Button onClick={parentConfirmStudent}>Confirm Student</Button>
            ) : (
              <Button disabled>Confirmed</Button>
            )}
          </>
        )}
        {role === "parent" && (
          <>
            {!confirmedStudentEvents && !relation?.studentAuthorized ? (
              <Button onClick={parentAuthStudentEvent}>
                Auth Student to join Events
              </Button>
            ) : (
              <Button color="warning" onClick={parentDeAuthStudentEvent}>
                Remove Ability to Join Events
              </Button>
            )}
          </>
        )}

        {role === "student" && !relation?.parentConfirmed && (
          <Button onClick={StudentConfirmParentRelation}>Confirm Parent</Button>
        )}

        <DeleteRelation id={relation?.uuid} />
      </CardContent>
    </Card>
  );
};

export default RelationCard;
