import React from "react";
import heroImage from "../../../images/Untitled-2.png";
import { Box } from "@mui/system";
import { Container, Grid, Typography, Stack, Button } from "@mui/material";
import { useHistory } from "react-router-dom";
import LoginIcon from "@mui/icons-material/Login";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";

const LargePage = () => {
  const router = useHistory();
  //   const handleInstallClick = async () => {
  //     console.log("Clicked");
  //     try {
  //       const registration = await navigator.serviceWorker.getRegistration();
  //       const installEvent = new Event("beforeinstallprompt");
  //       registration?.active?.dispatchEvent(installEvent);
  //     } catch (error) {
  //       console.error("Error triggering install prompt:", error);
  //     }
  //   };

  return (
    <Box
      sx={{
        backgroundImage: `url("${heroImage}")`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "200px -100px",
        height: "80vh",
        width: "100%",
        // border: "5px solid #333",
      }}>
      <Container maxWidth="xl">
        <Grid
          container
          sx={{
            display: "flex",
            height: "70vh",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <Grid item xs={5}>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h3">
              Connect, Track, Showcase
            </Typography>

            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h3">
              Community
            </Typography>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h3"
              color="primary">
              Hours
            </Typography>
            <Typography sx={{ my: "2rem" }} variant="body1">
              Transform your community service journey with our innovative
              platform. Effortlessly find, schedule, and verify volunteer
              opportunities, track your progress, and build a digital social CV.
              Unite with charities, fulfill school requirements, and make a
              tangible difference. Start your path to social impact today!
            </Typography>
            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => {
                  router.push("/login");
                }}
                variant="contained"
                endIcon={<LoginIcon />}>
                Login
              </Button>
              <Button
                onClick={() => {
                  router.push("/register");
                }}
                variant="outlined"
                color="secondary"
                endIcon={<AppRegistrationIcon />}>
                Register
              </Button>
              {/* <Button
                onClick={handleInstallClick}
                variant="outlined"
                color="secondary"
                endIcon={<AppRegistrationIcon />}>
                Install App
              </Button> */}
            </Stack>
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LargePage;
