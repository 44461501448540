import axios from "axios";

import { url } from "./URL";

export const getSchoolsListRequest = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "REQUEST_APP_DATA" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${url}/orgs/schools`, config);

    dispatch({ type: "GET_SCHOOLS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "FAIL_APP_DATA",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSchoolGradesClassesRequest =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "REQUEST_APP_DATA" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`${url}/schools/grades/${id}`, config);

      dispatch({ type: "GET_SCHOOLS_CLASSES_GRADES_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_APP_DATA",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const AddClassGradeRequest =
  ({ org_id, grade, Class }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "ADD_CLASS_GRADES_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      console.log({ org_id, grade, class: Class });
      const { data } = await axios.post(
        `${url}/schools`,
        { org_id, grade, class: Class },
        config
      );

      dispatch({ type: "ADD_CLASS_GRADES_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "ADD_CLASS_GRADES_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const StudentAddSchoolClassGrade =
  ({ values }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "ADD_CLASS_GRADES_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(`${url}/schools`, values, config);

      dispatch({ type: "ADD_CLASS_GRADES_SUCCESS", payload: data });
      dispatch(getMySchoolRequest());
    } catch (error) {
      dispatch({
        type: "ADD_CLASS_GRADES_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getMySchoolRequest = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_MY_SCHOOL_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`${url}/schools/mySchool`, config);

    dispatch({ type: "GET_MY_SCHOOL_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_MY_SCHOOL_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
