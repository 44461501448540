import "./App.css";
import { createContext, useEffect, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { themeLight, themeDark } from "./theme";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Homepage from "./Screens/Public/Homepage";
import NavBarWDrawer from "./Components/Navigation/NavBarWDrawer";
import { useSelector } from "react-redux";
import { useLocalStorage } from "./Hooks/useStorage";
import PublicRoutes from "./Router/PublicRoutes";
import AdminRoutes from "./Router/AdminRoutes";
import OrgRoutes from "./Router/OrgRoutes";
import ParentRoutes from "./Router/ParentRoutes";
import StudentRoutes from "./Router/StudentRoutes";
import { LicenseInfo } from "@mui/x-license-pro";
import { QueryClient, QueryClientProvider } from "react-query";

export const NotificationContext = createContext(null);

function App() {
  const [themeStorage, setStorage] = useLocalStorage("ChalkItUpTheme", true);

  const [notifications, setNotifications] = useState([]);

  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY);

  const [theme, setTheme] = useState(themeStorage);

  const queryClient = new QueryClient();

  console.log(`
        _       _ _      _ _              
    ___| |_ ___| | |_   |_| |_    _ _ ___ 
   |  _|   | .'| | '_|  | |  _|  | | | . |
   |___|_|_|__,|_|_,_|  |_|_|    |___|  _|
                                     |_|          
`);

  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log("Looks like we are in development mode!");
  } else {
    console.log("Looks like we are in production mode!");
  }

  useEffect(() => {
    setStorage(theme);
  }, [theme, setStorage]);

  const userInfo = useSelector((state) => state.userInfo);

  // return routes based on user role
  const routes = () => {
    switch (userInfo?.user?.role) {
      case "administrator":
        return <AdminRoutes />;
      case "org-admin":
        return <OrgRoutes />;
      case "school-admin":
        return <OrgRoutes />;
      case "school-user":
        return <OrgRoutes />;
      case "parent":
        return <ParentRoutes />;
      case "student":
        return <StudentRoutes />;
      default:
        return <PublicRoutes />;
    }
  };

  return (
    <QueryClientProvider client={queryClient}>
      <NotificationContext.Provider
        value={{
          notifications,
          setNotifications,
        }}>
        <ThemeProvider theme={theme ? themeLight : themeDark}>
          <CssBaseline />
          <Router>
            <Switch>
              <Route path="/" exact component={Homepage} />
              <NavBarWDrawer currentTheme={theme} setTheme={setTheme}>
                {routes()}
              </NavBarWDrawer>
            </Switch>
          </Router>
        </ThemeProvider>
      </NotificationContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
