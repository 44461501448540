import axios from "axios";
import { url } from "./URL";

export const getMyRelations = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_RELATIONS_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${url}/relations/ParentStudentRelation`,
      config
    );
    dispatch({ type: "GET_RELATIONS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_RELATIONS_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const studentConfirmParent = (uuid) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_CONFIRM_PARENT_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${url}/relations/studentConfirmParent/${uuid}`,
      {},
      config
    );
    dispatch({ type: "GET_CONFIRM_PARENT_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_CONFIRM_PARENT_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const parentConfirmStudentRelation =
  (uuid) => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_CONFIRM_STUDENT_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${url}/relations/parentConfirmStudentRelation/${uuid}`,
        {},
        config
      );

      dispatch({ type: "GET_CONFIRM_STUDENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_CONFIRM_STUDENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const parentAuthStudentEvents = (uuid) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_AUTH_EVENT_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${url}/relations/ParentStudentAuthorize/${uuid}`,
      {},
      config
    );

    dispatch({ type: "GET_AUTH_EVENT_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_AUTH_EVENT_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const parentDeAuthStudentEvents =
  (uuid) => async (dispatch, getState) => {
    try {
      console.log("De Auth Reached");
      dispatch({ type: "GET_DEAUTH_EVENT_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.patch(
        `${url}/relations/ParentStudentAuthorize/${uuid}`,
        {},
        config
      );

      dispatch({ type: "GET_DEAUTH_EVENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_DEAUTH_EVENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const CreateParentStudentRelations =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: "CREATE_RELATION" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${url}/relations/ParentStudentRelation`,
        formData,
        config
      );

      dispatch({ type: "CREATE_RELATION_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "CREATE_RELATION_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const DeleteParentStudentRelations =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_DELETE_RELATION_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.delete(
        `${url}/relations/ParentStudentAuthorize/${id}`,
        config
      );

      dispatch({ type: "GET_DELETE_RELATION_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_DELETE_RELATION_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

/*

    Org Admin & Org User Actions

  */


//Get Relations
export const getOrgAdminUserMyRelations = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_ORG_ADMIN_USER__RELATION_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${url}/relations/organizationAdminUserRelation`,
      config
    );
    dispatch({ type: "GET_ORG_ADMIN_USER__RELATION_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_ORG_ADMIN_USER__RELATION_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Create Org Admin Org User Relation

export const CreateOrgAdminUserRelations =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: "CREATE_ORG_ADMIN_USER_RELATION_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${url}/relations/organizationAdminUserRelation`,
        formData,
        config
      );

      dispatch({ type: "CREATE_ORG_ADMIN_USER__RELATION_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "CREATE_ORG_ADMIN_USER__RELATION_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };


  //Org Admin Confirm Org User Relation
  
export const orgAdminConfirmOrgUserRelation =
(uuid) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_CONFIRM_ORG_USER_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(
      `${url}/relations/parentConfirmStudentRelation/${uuid}`,
      {},
      config
    );

    dispatch({ type: "GET_CONFIRM_ORG_USER_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_CONFIRM_ORG_USER_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

//Delete Org Admin Org User Relation
export const DeleteOrgAdminUserRelations =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_DELETE_ADMIN_ORG_RELATION_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.delete(
        `${url}/relations/ParentStudentAuthorize/${id}`,
        config
      );

      dispatch({ type: "GET_DELETE_ADMIN_ORG_RELATION_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_DELETE_ADMIN_ORG_RELATION__FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  //Authorize Org User For Creating Events And Joining Them Or Something
  export const orgAdminAuthOrgUsertEvents = (uuid) => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_AUTH_ORG_USER_EVENT_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${url}/relations/ParentStudentAuthorize/${uuid}`,
        {},
        config
      );
  
      dispatch({ type: "GET_AUTH_ORG_USER_EVENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_AUTH_ORG_USER_EVENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  //De Auth Org User
  export const  orgAdminDeAuthOrgUsertEvents = (uuid) => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_DEAUTH_ORG_USER_EVENT_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.patch(
        `${url}/relations/ParentStudentAuthorize/${uuid}`,
        {},
        config
      );

      dispatch({ type: "GET_DEAUTH_ORG_USER_EVENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_DEAUTH_ORG_USER_EVENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  //Org User Comfirm Org Admin
  export const orgUserConfirmOrgAdmin = (uuid) => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_CONFIRM_ORG_ADMIN_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${url}/relations/studentConfirmParent/${uuid}`,
        {},
        config
      );
      dispatch({ type: "GET_CONFIRM_ORG_ADMIN_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_CONFIRM_ORG_ADMIN_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };