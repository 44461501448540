import {
  Alert,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Container, Stack } from "@mui/system";

import React from "react";

import { useSelector } from "react-redux";
import PageHeader from "../../../Components/Items/PageHeader";
import { useEffect } from "react";
// import { getSchoolGrades } from "../../../Redux/actions/SchoolStudentsActions";
// import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSchoolGrades } from "../../../Redux/actions/SchoolStudentsActions";
import { useParams } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
  CreateCertificates,
  getCertificates,
} from "../../../Redux/actions/SchoolReports";

const SchoolReports = () => {
  //   const dispatch = useDispatch();

  //   useEffect(()=> {
  //     dispatch(getSchoolGrades());
  //   }

  //   )

  const { id } = useParams();

  const dispatch = useDispatch();

  const [grade, setGrade] = React.useState("");
  const [Class, setClass] = React.useState("");
  const [startDate, setStartDate] = React.useState();
  const [endDate, setEndDate] = React.useState();

  useEffect(() => {
    dispatch(getCertificates(id));
    dispatch(getSchoolGrades(id));
  }, [id, dispatch]);

  const schoolStudents = useSelector((state) => state.schoolStudents);

  const { schoolGradesList, loading, error } = schoolStudents;

  const schoolReport = useSelector((state) => state.schoolReport);

  const { CreatedRequest, CertificateData } = schoolReport;

  console.log(CertificateData, CreatedRequest);

  const handleSubmit = () => {
    let values = {
      class_id: Class.uuid,
      grade_id: grade.uuid,
      startDate: dayjs(startDate).format("YYYY-MM-DD"),
      endDate: dayjs(endDate).format("YYYY-MM-DD"),
      org_id: id,
    };
    dispatch(CreateCertificates(values));
  };

  if (loading) {
    return (
      <Stack justifyContent="center">
        <CircularProgress />
      </Stack>
    );
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  return (
    <Container>
      <PageHeader
        title="
        Reports
        "
        description="School Reports"
        info="School Reports"
      />

      <Stack spacing={2}>
        {schoolGradesList && (
          <FormControl>
            <InputLabel id="GRADE">Select Grade</InputLabel>
            <Select
              labelId="GRADE"
              id="grade"
              value={grade}
              label="Select Grade"
              onChange={(event) => setGrade(event.target.value)}>
              <MenuItem value={" "}>Please Select</MenuItem>
              {schoolGradesList &&
                schoolGradesList?.map((grade) => {
                  return (
                    <MenuItem key={grade?.uuid} value={grade}>
                      {grade?.grade}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}

        {grade && (
          <FormControl>
            <InputLabel id="Class">Select Class</InputLabel>
            <Select
              labelId="Class"
              id="Class"
              value={Class}
              label="Select Class"
              onChange={(event) => setClass(event.target.value)}>
              <MenuItem value={" "}>Please Select</MenuItem>
              {grade &&
                grade?.SchoolClasses?.map((C) => {
                  return (
                    <MenuItem key={C.uuid} value={C}>
                      {C.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        )}

        {Class && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
              <FormControl fullWidth>
                <DatePicker
                  format="yyyy-MM-dd"
                  variant="inline"
                  label="Start Date"
                  value={startDate}
                  onChange={(date) => setStartDate(date)}
                  inputVariant="outlined"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
              <FormControl fullWidth>
                <DatePicker
                  format="yyyy-MM-dd"
                  variant="inline"
                  label="End Date"
                  value={endDate}
                  onChange={(date) => {
                    console.log(date);
                    setEndDate(date);
                  }}
                  inputVariant="outlined"
                  fullWidth
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            </Stack>
          </LocalizationProvider>
        )}
      </Stack>

      <Stack sx={{ mt: 2 }}>
        <Button onClick={() => handleSubmit()} variant="contained">
          Submit
        </Button>
      </Stack>
    </Container>
  );
};

export default SchoolReports;
