import {
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import BookingsStatsTable from "../../../Components/Containers/BookingsStatsTable";
import PageHeader from "../../../Components/Items/PageHeader";
import { getTotalStats } from "../../../Redux/actions/UserStats";

const ParentViewStudentStats = () => {
  const dispatch = useDispatch();

  const Relations = useSelector((state) => state.Relations);
  let { relations } = Relations;

  const [selectedStudent, setSelectedStudent] = useState("");

  useEffect(() => {
    dispatch(getTotalStats({ studentId: selectedStudent?.User?.uuid }));
  }, [selectedStudent, dispatch]);

  const HandleRelationSelect = (e) => {
    setSelectedStudent(e.target.value);
  };

  const stats = useSelector((state) => state.stats);
  const { data } = stats;

  const completedBookings = data?.bookings?.filter(
    (booking) => booking?.status === "completed"
  );

  return (
    <Container>
      <PageHeader
        title="Student Stats"
        description={`Welcome to the Student Stats`}
        info="You can see your child's progress here"
      />
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Select Student</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedStudent}
          label="Select Student"
          onChange={HandleRelationSelect}>
          {relations.map((relation) => {
            return (
              <MenuItem key={relation?.uuid} value={relation}>
                {relation?.User?.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Stack mt={2} spacing={2}>
        {data && <BookingsStatsTable data={completedBookings} />}

        {/* <BookingsStatsTable data={data?.bookings} /> */}
      </Stack>
    </Container>
  );
};

export default ParentViewStudentStats;
