import axios from "axios";

export const getEvents = () => async (dispatch) => {
  try {
    dispatch({ type: "REQUEST_EVENTS" });

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/events`
    );

    dispatch({ type: "GET_EVENTS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "FAIL_EVENTS",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEventsPaginate =
  ({ rowsPerPage, page, values }) =>
  async (dispatch) => {
    dispatch({ type: "REQUEST_EVENTS" });
    try {
      let S = values ? "&search=" + values.search : "";
      let P = rowsPerPage ? rowsPerPage : 20;
      let PA = page ? page : 0;

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/events/search?pageNumber=${PA}&limit=${P}${S}`
      );
      dispatch({ type: "GET_EVENTS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_EVENTS",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const getEventsSearch =
  ({ rowsPerPage, page, values }) =>
  async (dispatch) => {
    dispatch({ type: "REQUEST_EVENTS" });
    try {
      let S = values ? "&search=" + values.search : "";
      let P = rowsPerPage ? rowsPerPage : 20;
      let PA = page ? page : 0;

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/events/search?pageNumber=${PA}&limit=${P}${S}`
      );
      dispatch({ type: "GET_EVENTS__SEARCH_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_EVENTS",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getEventById =
  ({ id }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "REQUEST_EVENT" });

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/events/${id}`
      );

      dispatch({ type: "GET_EVENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_EVENT",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addEventRequest =
  ({ id, event }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "ADD_EVENT_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/orgs/${id}/events`,
        event,
        config
      );

      dispatch({ type: "ADD_EVENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "ADD_EVENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.errors
            : error.message,
      });
    }
  };

export const editEventRequest =
  ({ id, event }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "EDIT_EVENT_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/events/${id}`,
        event,
        config
      );

      dispatch({ type: "EDIT_EVENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "EDIT_EVENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const deleteEventRequest = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "DELETE_EVENT_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.delete(
      `${process.env.REACT_APP_PUBLIC_URL}/events/${id}`,
      config
    );

    dispatch({ type: "DELETE_EVENT_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "DELETE_EVENT_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEventAdminRequest =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_ADMIN_EVENT_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/events/admin/${id}`,
        config
      );

      dispatch({ type: "GET_ADMIN_EVENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_ADMIN_EVENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const EventSearch =
  ({ searchItem }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "SEARCH_EVENT_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/events/search`,
        { searchItem },
        config
      );

      dispatch({ type: "SEARCH_EVENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "SEARCH_EVENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createSingleOccurrence = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: "CREATE_SINGLE_OCCURRENCE_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data: response } = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}/events/occurrence`,
      data,
      config
    );

    dispatch({
      type: "CREATE_SINGLE_OCCURRENCE_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "CREATE_SINGLE_OCCURRENCE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editEventOccurrence = (data) => async (dispatch, getState) => {
  try {
    dispatch({ type: "EDIT_EVENT_OCCURRENCE_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data: response } = await axios.put(
      `${process.env.REACT_APP_PUBLIC_URL}/occurrences/${data.eventId}`,
      data,
      config
    );

    dispatch({
      type: "EDIT_EVENT_OCCURRENCE_SUCCESS",
      payload: response.data,
    });
  } catch (error) {
    dispatch({
      type: "EDIT_EVENT_OCCURRENCE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
