import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";

import { useField, useFormikContext } from "formik";

const DateSelectorWrapper = ({ name, ...otherProps }) => {
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DatePicker
        name={name}
        format="LL"
        variant="inline"
        {...configTextfield}
        onChange={(date) => setFieldValue(name, date)}
        inputVariant="outlined"
        fullWidth
      />
    </LocalizationProvider>
  );
};

export default DateSelectorWrapper;
