export const addBookingReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "ADD_BOOKING_REQUEST":
      return { loading: true };
    case "ADD_BOOKING_SUCCESS":
      return { ...state, loading: false, success: true, ...action.payload };
    case "ADD_BOOKING_FAIL":
      return { ...state, loading: false, data: {}, error: action.payload };
    case "ADD_BOOKING_RESET":
      return { data: {} };
    default:
      return state;
  }
};

export const getSingleBookingReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "GET_SINGLE_BOOKING_REQUEST":
      return { loading: true };
    case "GET_SINGLE_BOOKING_SUCCESS":
      return { ...state, loading: false, success: true, data: action.payload };
    case "GET_SINGLE_BOOKING_FAIL":
      return { ...state, loading: false, data: {}, error: action.payload };

    case "GET_SINGLE_BOOKING_RESET":
      return { data: {} };

    default:
      return state;
  }
};

const BookingInitial = {
  bookings: [],
  data: {},
  loading: false,
  success: false,
  error: null,
  bookingCancel: false,
};

export const getUsersBookingsReducer = (state = BookingInitial, action) => {
  switch (action.type) {
    case "GET_ACTIVE_BOOKING_REQUEST":
      return { ...state, loading: true };
    case "GET_ACTIVE_BOOKING_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        bookings: action.payload,
      };
    case "GET_ACTIVE_BOOKING_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "GET_ACTIVE_BOOKING_RESET":
      return { bookings: [] };

    case "GET_USER_BOOKING_REQUEST":
      return { ...state, loading: true };
    case "GET_USER_BOOKING_SUCCESS":
      // Create a set of id's
      var ids = new Set(state.bookings.map((d) => (d.uuid ? d.uuid : null)));
      var merged = [
        ...state.bookings,
        // filter ids if in new array
        ...action.payload.filter((d) => !ids.has(d.uuid)),
      ];
      return {
        ...state,
        loading: false,
        success: true,
        bookings: merged,
      };
    case "GET_USER_BOOKING_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "GET_PARENT_STUDENTS_BOOKING_REQUEST":
      return { ...state, loading: true };
    case "GET_PARENT_STUDENTS_BOOKING_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        bookings: action.payload,
      };
    case "GET_PARENT_STUDENTS_BOOKING_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "CANCEL_BOOKING_REQUEST":
      return { ...state, loading: true };
    case "CANCEL_BOOKING_SUCCESS":
      console.log(action.payload);
      return {
        ...state,
        bookingCancel: true,
        loading: false,
        success: true,
        bookings: state.bookings.map((booking) =>
          booking.uuid === action.payload.uuid ? action.payload : booking
        ),
      };
    case "CANCEL_BOOKING_FAIL":
      return { ...state, loading: false, data: [], error: action.payload };

    case "GET_USER_BOOKING_RESET":
      return { bookings: [], bookingCancel: false };

    default:
      return state;
  }
};
