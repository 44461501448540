import { Grid, Typography } from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DigitalClock } from "@mui/x-date-pickers";
import dayjs from "dayjs";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const TimeRangPicker = ({ slots, setSlots }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid item xs={4}>
          <Typography>Select Start Time</Typography>
          <Typography variant="caption" color="GrayText">
            What time will the event start?
          </Typography>
          <DigitalClock
            skipDisabled
            ampm={false}
            maxTime={slots.endTime}
            minTime={dayjs(new Date(0, 0, 0, 8, 0))}
            value={slots.startTime}
            onChange={(data) => {
              setSlots({ ...slots, startTime: data });
            }}
          />
        </Grid>
        <Grid item xs={4}>
          <Typography>Select End Time</Typography>
          <Typography variant="caption" color="GrayText">
            What time will the event End?
          </Typography>

          <DigitalClock
            skipDisabled
            ampm={false}
            maxTime={dayjs(new Date(0, 0, 0, 17, 0))}
            minTime={slots.startTime || dayjs(new Date(0, 0, 0, 8, 0))}
            value={slots.endTime}
            onChange={(data) => {
              setSlots({ ...slots, endTime: data });
            }}
          />
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default TimeRangPicker;
