import * as React from "react";
import FormControl from "@mui/material/FormControl";
import { Autocomplete, TextField } from "@mui/material";
import { useFormikContext, useField } from "formik";

import { tags } from "../../FormData";

export default function MultiSelectWrapper({ name, ...otherProps }) {
  const { values, setFieldValue } = useFormikContext();
  const [mata] = useField(name);

  const fixedOptions = [];

  const configTextfield = {
    name: name,
    ...otherProps,
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <div>
      <FormControl fullWidth>
        <Autocomplete
          multiple
          value={values.tags || []}
          options={tags}
          onChange={(event, newValue) => {
            setFieldValue("tags", [
              ...fixedOptions,
              ...newValue.filter(
                (option) => fixedOptions.indexOf(option) === -1
              ),
            ]);
          }}
          getOptionLabel={(option) => option}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
          
              {...params}
            
              placeholder="Tags"
            />
          )}
        />
      </FormControl>
    </div>
  );
}
