import React from "react";
import {
  Box,
  Card,
  LinearProgress,
  Container,
  Grid,
  CardHeader,
  CardContent,
} from "@mui/material";
import { useParams } from "react-router";
import EditEventAndOccurrences from "../../../Components/Forms/Events/EditEventAndOccurrences";
import withAuth from "../../../Hoc/withAuth";
import { useQuery } from "react-query";
import axios from "axios";
import UploadBannerImage from "../../../Components/ImageUploaders/UploadBannerImage";

const EditEvent = ({ token }) => {
  let { id } = useParams();

  // axios.get(
  //   `${process.env.REACT_APP_PUBLIC_URL}/events/${id}`

  const { data, isLoading } = useQuery(["event", id], () => {
    return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/events/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  });

  // const DeleteEvent = useMutation(
  //   (event) => {
  //     return axios.delete(
  //       `${process.env.REACT_APP_PUBLIC_URL}/events/${id}`,
  //       event,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //   },
  //   {
  //     onSuccess: () => {},
  //   }
  // );

  let eventData = data?.data;

  if (isLoading) return <LinearProgress />;

  return (
    <Container disableGutters maxWidth="lg" sx={{ mt: 3 }}>
      <Card>
        <CardHeader
          title="Event"
          subheader="Manage your event here. You can edit the event details, add occurrences and delete the event."
        />
        <CardContent>
          <Grid container justifyContent="center">
            <Grid item xl={12} md={12} lg={12} sm={12} xs={12}>
              <Box mb={2}>
                {eventData?.uuid && (
                  <Card sx={{ mb: 2 }}>
                    <UploadBannerImage uuid={eventData.uuid} token={token} />
                  </Card>
                )}

                {eventData?.name && (
                  <EditEventAndOccurrences data={eventData} token={token} />
                )}
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default withAuth(EditEvent);
