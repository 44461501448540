import {
  Alert,
  CircularProgress,
  Container,
  Grid,
  // Stack,
  Typography,
  Box
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrgs } from "../../../Redux/actions/Orgs";
import OrgCard from "../../../Components/Containers/OrgCard";


// import AddCards from "../../../Components/Forms/AddCards";
// import StickyContainer from "../../../Components/Containers/StickyContainer";

const Schools = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOrgs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orgs = useSelector((state) => state.orgs);
  const { error, loading, data } = orgs;

  const filterOrgs = (orgs) => {
    return orgs.filter((org) => {
      return org.orgType === "school";
    });
  };

  let filtered = data.results && filterOrgs(data.results);

  return (
    <>
      <Box
        sx={{ height: 200 }}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Typography variant="h2" align="center">
          Schools
        </Typography>
      </Box>
      <Container maxWidth="xl" disableGutters>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              {loading && <CircularProgress />}
            </Box>

            {error && <Alert severity="error">{error}</Alert>}

            <Grid container spacing={2}>
              {filtered &&
                filtered.map((org) => {
                  return (
                    <Grid
                      key={org.uuid}
                      item
                      xl={6}
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}>
                      <OrgCard data={org} loading="lazy" />
                    </Grid>
                  );
                })}
            </Grid>
          </Grid>
          {/* <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
            <StickyContainer>
              <Typography align="center">Ad Space</Typography>
              <AddCards />
            </StickyContainer>
          </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default Schools;
