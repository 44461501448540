import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../FormComponents/TextfieldWrapper";
import * as Yup from "yup";
import { UpdateMe } from "../../../Redux/actions/Auth";
import { useDispatch, useSelector } from "react-redux";
import AlertPopup from "../../Items/AlertPopup";
import DOBPicker from "../FormComponents/DobPicker";
import dayjs from "dayjs";

const SchoolAdminProfileForm = ({ data }) => {
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);
  const { success, error } = userInfo;

  return (
    <Container>
      <Formik
        initialValues={{
          name: data.name || "",
          surname: data.surname || "",
          email: data.email || "",
          username: data.username || "",
          number: data.number || "",
          dob: dayjs(data.dob) || dayjs(),
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
          surname: Yup.string().required("Surname is required"),
          email: Yup.string()
            .email("Email is invalid")
            .required("Email is required"),
          username: Yup.string().required("Username is required"),
          number: Yup.string()
            .transform((value) => value.replace(/\D/g, "")) // Remove non-digit characters before validation
            .matches(
              /^0\d{9}$/,
              "Must be a valid South African cellphone number"
            )
            .required("Cellphone number is required"),
          dob: Yup.string()
            .required("Date of birth is required")
            .test("isValidDate", "Invalid date format", (value) =>
              dayjs(value).isValid()
            )
            .test(
              "isNotFutureDate",
              "Date of birth cannot be in the future",
              (value) => dayjs().isAfter(dayjs(value))
            ),
        })}
        enableReinitialize={true}
        onSubmit={(values) => {
          dispatch(UpdateMe(values));
        }}>
        {() => {
          return (
            <Form>
              <Grid sx={{ mt: 2 }} container spacing={2}>
                <Grid item xs={12}>
                  <TextfieldWrapper label="Username" name="username" />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper label="Name" name="name" />
                </Grid>

                <Grid item xs={6}>
                  <TextfieldWrapper label="Surname" name="surname" />
                </Grid>

                <Grid item xs={6}>
                  <TextfieldWrapper label="Email" name="email" />
                </Grid>

                <Grid item xs={6}>
                  <TextfieldWrapper label="Number" name="number" />
                </Grid>
                <Grid item xs={6}>
                  <DOBPicker name="dob" />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
      <AlertPopup
        open={success}
        message="Profile updated successfully"
        severity="success"
      />
      <AlertPopup
        open={error}
        message="Error updating profile"
        severity="error"
      />
    </Container>
  );
};

export default SchoolAdminProfileForm;
