export const ADD_BULK_STUDENTS = "ADD_BULK_STUDENTS";
export const ADD_BULK_STUDENTS_SUCCESS = "ADD_BULK_STUDENTS_SUCCESS";
export const ADD_BULK_STUDENTS_FAILURE = "ADD_BULK_STUDENTS_FAILURE";

export const GET_BULK_ADDED_STUDENTS = "GET_BULK_ADDED_STUDENTS";
export const GET_BULK_ADDED_STUDENTS_SUCCESS =
  "GET_BULK_ADDED_STUDENTS_SUCCESS";
export const GET_BULK_ADDED_STUDENTS_FAILURE =
  "GET_BULK_ADDED_STUDENTS_FAILURE";


  export const RESET_BULK_MANAGE = "RESET_BULK_MANAGE";


const initialState = {
  students: [],
  loading: false,
  success: false,
  error: null,
};

const SchoolBulkManageStudentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_BULK_STUDENTS:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case ADD_BULK_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        students: action.payload,
      };
    case ADD_BULK_STUDENTS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    case GET_BULK_ADDED_STUDENTS:
      return {
        ...state,
        loading: true,
        success: false,
      };
    case GET_BULK_ADDED_STUDENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        students: action.payload,
   
      };
    case GET_BULK_ADDED_STUDENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

      case RESET_BULK_MANAGE: 
        return {
            ...state,
            students: [],
            loading: false,
            success: false,
            error: null,
        }
        

    default:
      return state;
  }
};

export default SchoolBulkManageStudentsReducer;
