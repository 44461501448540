import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import {
  generateStartEndCodeRequest,
  getOccurrenceRequest,
} from "../../../Redux/actions/EventOcc";
// import QRCode from "qrcode.react";

import moment from "moment";
import OccBooking from "../../../Components/Containers/OccBooking";
import { useRef } from "react";
import ReactToPrint from "react-to-print";
import { ComponentToPrint } from "./ComponentToPrint";
import PrintIcon from "@mui/icons-material/Print";
import logo from "../../../images/logos/NewLogoLight.png";

const OccurrencePage = () => {
  const { id } = useParams();

  const componentRef = useRef();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOccurrenceRequest({ id }));
  }, [dispatch, id]);

  const occData = useSelector((state) => state.occData);
  const { data, loading, error, code } = occData;

  const [startBarcode, setStartBarcode] = useState(false);
  const [endBarcode, setEndBarcode] = useState(false);

  // let endTime = data?.timeSlots && data?.timeSlots.length - 1;

  const isToday =
    moment(data?.occurrence).format("MMMM Do YYYY") ===
    moment().format("MMMM Do YYYY")
      ? true
      : false;

  const handelGenerateCode = () => {
    dispatch(generateStartEndCodeRequest({ id }));
  };

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
          {error && <Alert severity="error"> {JSON.stringify(error)}</Alert>}
          {loading && <CircularProgress />}
          <Card>
            <CardHeader
              title="Event Name"
              subheader={`Start Time : ${
                data?.timeSlots && data?.timeSlots[0].slot
              }`}
            />

            <CardContent>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  <Typography align="center" variant="h6">
                    {`${moment(data?.occurrence).format("MMMM Do YYYY")}`}
                  </Typography>
                  {data?.timeSlots && (
                    <Typography align="center" variant="h6">
                      {/* From : {data?.timeSlots[0].slot} To :{" "}
                      {data?.timeSlots[endTime].slot} + 1 Hour */}
                    </Typography>
                  )}

                  {/* {data?.timeSlots &&
                    data?.timeSlots.map((slot) => {
                      return (
                        <Chip
                          variant="outlined"
                          sx={{ m: 1 }}
                          label={slot.slot}
                          key={slot.slot}
                        />
                      );
                    })} */}
                </Grid>
              </Grid>
              <Stack my={4} spacing={3}>
                {code && code?.startCode ? (
                  <>
                    <Stack spacing={2} alignItems="center">
                      <Typography align="center" variant="h5">
                        Start Code
                      </Typography>

                      <Card sx={{ border: 1, width: 200 }}>
                        <Typography align="center" variant="h5">
                          {code?.startCode}
                        </Typography>
                      </Card>
                      <Typography align="center" variant="h5">
                        End Code
                      </Typography>
                      <Card sx={{ border: 1, width: 200 }}>
                        <Typography align="center" variant="h5">
                          {code?.endCode}
                        </Typography>
                      </Card>
                    </Stack>
                  </>
                ) : (
                  <Button
                    disabled={!isToday}
                    variant="contained"
                    onClick={() => handelGenerateCode()}
                    color="primary">
                    Generate Codes
                  </Button>
                )}

                <Button
                  disabled={!isToday}
                  variant="contained"
                  onClick={() => setStartBarcode(!startBarcode)}
                  color="success">
                  Generate Start Barcode
                </Button>

                {startBarcode && (
                  <>
                    <ReactToPrint
                      trigger={() => (
                        <Button startIcon={<PrintIcon />}>
                          Print this out!
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />

                    <ComponentToPrint ref={componentRef}>
                      <Stack mt={4} justifyContent="center" alignItems="center">
                        <img src={logo} alt="logo" width="200" />
                        <Typography variant="h6">Chalk It Up</Typography>
                        <Typography variant="h3" align="center">
                          Start
                        </Typography>
                        <Box
                          mt={4}
                          disabled={!isToday}
                          display="flex"
                          justifyContent="center"
                          sx={{
                            width: "100%",
                            border: 1,
                            borderColor: "success.main",
                            py: 4,
                          }}>
                          {/* <QRCode
                            value={`${code?.startCode}/start`}
                            size={500}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                            renderAs={"svg"}
                          /> */}
                        </Box>
                        <Typography sx={{ my: 2 }} variant="h5" align="center">
                          {code?.startCode}
                        </Typography>
                        <Typography variant="h5" align="center">
                          Scan This code to Start the event
                        </Typography>
                        <Typography variant="caption" align="center">
                          You need to be logged into the app to scan this code
                        </Typography>
                        <Typography align="center" variant="body1">
                          chalkitup.co.za
                        </Typography>
                      </Stack>
                    </ComponentToPrint>
                  </>
                )}
              </Stack>
              <Stack>
                <Button
                  disabled={!isToday}
                  onClick={() => {
                    setEndBarcode(!endBarcode);
                  }}
                  variant="contained"
                  color="warning">
                  Generate End Barcode
                </Button>

                {endBarcode && (
                  <>
                    <ReactToPrint
                      trigger={() => (
                        <Button startIcon={<PrintIcon />}>
                          Print this out!
                        </Button>
                      )}
                      content={() => componentRef.current}
                    />

                    <ComponentToPrint ref={componentRef}>
                      <Stack mt={4} justifyContent="center" alignItems="center">
                        <img src={logo} alt="logo" width="200" />
                        <Typography variant="h6">Chalk It Up</Typography>
                        <Typography variant="h3" align="center">
                          End
                        </Typography>
                        <Box
                          mt={4}
                          disabled={!isToday}
                          display="flex"
                          justifyContent="center"
                          sx={{
                            width: "100%",
                            border: 1,
                            borderColor: "error.main",
                            py: 4,
                          }}>
                          {/* <QRCode
                            value={`${code?.endCode}`}
                            size={500}
                            bgColor={"#ffffff"}
                            fgColor={"#000000"}
                            level={"L"}
                            includeMargin={false}
                            renderAs={"svg"}
                          /> */}
                        </Box>
                        <Typography variant="h5" align="center">
                          Scan This code to Complete the event
                        </Typography>
                        <Typography variant="caption" align="center">
                          You need to be logged into the app to scan this code
                        </Typography>
                        <Typography align="center" variant="body1">
                          chalkitup.co.za
                        </Typography>
                      </Stack>
                    </ComponentToPrint>
                  </>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
          <Stack spacing={2}>
            {data &&
              data.Bookings.map((booking) => <OccBooking data={booking} />)}
          </Stack>
        </Grid>
      </Grid>
    </Container>
  );
};

export default OccurrencePage;
