import React, { useEffect, useState } from "react";
import {
  Typography,
  CardHeader,
  Card,
  CardActionArea,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  getMySchoolRequest,
  getSchoolsListRequest,
} from "../../../Redux/actions/AppData";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

// import SchoolSelect from "../SchoolSelect";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import BannerImage from "../../ImageUploaders/BannerImage";
import AvatarImage from "../../ImageUploaders/AvatarImage";

const ParentSchoolSelect = ({ token }) => {
  const router = useHistory();
  const dispatch = useDispatch();

  const getMySchool = useQuery({
    queryKey: "mySchool",
    queryFn: () => {
      return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/schools/mySchool`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  useEffect(() => {
    dispatch(getMySchoolRequest());
  }, [dispatch]);

  const removeSchoolAssociation = useMutation(
    ({ id }) => {
      return axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/mySchool/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        getMySchool.refetch();
      },
    }
  );

  const addParentToSchool = useMutation(
    ({ id }) => {
      return axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/parentSchool/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        getMySchool.refetch();
      },
    }
  );

  const school = getMySchool?.data?.data;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        My School
      </Typography>
      <Typography variant="body1" color="GrayText" gutterBottom>
        Select a school, grade and class.
      </Typography>

      {school && school.length > 0 ? (
        school.map((school, index) => {
          return (
            <React.Fragment key={index}>
              <Card>
                <CardHeader
                  title={school?.Org?.name}
                  avatar={
                    <AvatarImage
                      token={token}
                      uuid={school?.Org?.uuid}
                      size="small"
                    />
                  }
                  action={
                    <RemoveSchool
                      school={school?.Org}
                      removeSchoolAssociation={removeSchoolAssociation}
                    />
                  }
                />
                <CardActionArea
                  onClick={() => router.push(`/mySchool/${school.Org.uuid}`)}>
                  <BannerImage uuid={school?.Org?.uuid} type="banner" />
                </CardActionArea>
              </Card>
            </React.Fragment>
          );
        })
      ) : (
        <SchoolSelect addParentToSchool={addParentToSchool} />
      )}
    </>
  );
};

export default withAuth(ParentSchoolSelect);

function RemoveSchool({ removeSchoolAssociation, school }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    removeSchoolAssociation.mutate({ id: school.id });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Remove School Association"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this school?
          </DialogContentText>
          <Typography variant="h6" color="error">
            {school?.name}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleSave}
            autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

const SchoolSelect = ({ addParentToSchool }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSchoolsListRequest());
  }, [dispatch]);

  const [school, setSchool] = useState("");

  const mySchool = useSelector((state) => state.mySchool);

  const { schoolList } = mySchool;

  console.log(schoolList);

  return (
    <Stack spacing={2}>
      <Typography variant="h6" gutterBottom>
        Select a School
      </Typography>

      <FormControl fullWidth>
        <InputLabel id="School">Select School</InputLabel>
        <Select
          labelId="School"
          id="School"
          value={school}
          label="Select School"
          onChange={(event) => setSchool(event.target.value)}>
          {schoolList &&
            schoolList.map((school) => {
              return (
                <MenuItem key={school.uuid} value={school.uuid}>
                  {school.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>

      <Button
        onClick={() => addParentToSchool.mutate({ id: school })}
        variant="contained">
        Add School
      </Button>
    </Stack>
  );
};
