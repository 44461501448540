import { CircularProgress } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMyOrg } from "../../../Redux/actions/Orgs";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DrawerListItem from "../Components/DrawerListItem";
import GlobalDrawerItems from "./GlobalDrawerItems";
import EventNoteTwoToneIcon from "@mui/icons-material/EventNoteTwoTone";
import Divider from "@mui/material/Divider";

const OrgAdminDrawer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyOrg());
  }, [dispatch]);

  const myOrg = useSelector((state) => state.myOrg);

  const { data, loading } = myOrg;

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      <DrawerListItem
        name="Dashboard"
        path="OrgAdminDashboard"
        Icon={DashboardIcon}
      />
      <GlobalDrawerItems />
      <Divider />
      <DrawerListItem
        name="My Events"
        path={`myEvents/${data?.uuid}`}
        Icon={EventNoteTwoToneIcon}
      />
    </>
  );
};

export default OrgAdminDrawer;
