import { Form, Formik } from "formik";
import React from "react";

import { Button, Grid, TextField, Card } from "@mui/material";
// import { LocalizationProvider } from "@mui/x-date-pickers-pro";
// import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
// import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import TagRow from "../../../Components/Containers/TagRow";

const EventsSearchComp = ({ setValues, values }) => {
  // const [isSearching, setIsSearching] = React.useState(false);

  return (
    <div>
      <Formik
        initialValues={{
          field: "",
          tags: [],
          // dateRange: [null, null]
        }}
        onSubmit={(values, { resetForm }) => {
          // setIsSearching(true);
          values.field = values.field.toLowerCase();

          setValues(values);
          resetForm();
        }}>
        {({ values, setFieldValue }) => {
          return (
            <Form>
              <Card sx={{ p: 1, mb: 2 }}>
                <Grid container spacing={0.5}>
                  <Grid item xs={12}>
                    <TagRow
                      setSelected={(tags) => setFieldValue("tags", tags)}
                      selected={values.tags}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="Search By Name"
                      name="field"
                      value={values.field}
                      onChange={(e) => setFieldValue("field", e.target.value)}
                      size="small"
                      fullWidth
                      variant="outlined"
                    />
                  </Grid>
                  {/* <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateRangePicker
                        slotProps={{
                          textField: {
                            size: "small",
                          },
                        }}
                        startText="Start Date"
                        endText="End Date"
                        value={values.dateRange}
                        onChange={(newValue) => {
                          setFieldValue("dateRange", newValue);
                        }}
                        localeText={{ start: "Start Date", end: "End Date" }}
                      />
                    </LocalizationProvider>
                  </Grid> */}

                  <Grid item xs={4}>
                    <Button
                      sx={{ height: "100%" }}
                      type="submit"
                      variant="contained"
                      fullWidth>
                      Search
                    </Button>
                  </Grid>
                </Grid>
              </Card>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default EventsSearchComp;
