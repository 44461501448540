export const AppDataReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "REQUEST_APP_DATA":
      return { ...state, loading: true };

    case "FAIL_APP_DATA":
      return { ...state, loading: false, data: {}, error: action.payload };
    case "RESET_APP_DATA":
      return { data: {} };
    default:
      return state;
  }
};

const MySchoolReducerState = {
  loading: false,
  error: null,
  success: false,
  school: null,
  newAddedSchool: [],
  schoolList: [],
};

export const getMySchoolReducer = (state = MySchoolReducerState, action) => {
  switch (action.type) {
    case "GET_MY_SCHOOL_REQUEST":
      return {
        ...state,
        loading: true,
        success: false,
      };

    case "GET_SCHOOLS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        schoolList: action.payload,
      };

    case "GET_SCHOOLS_CLASSES_GRADES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        schoolGradesClasses: action.payload,
      };

    case "GET_MY_SCHOOL_SUCCESS":
      return {
        ...state,
        loading: false,
        school: action.payload,
      };

    case "GET_MY_SCHOOL_FAIL":
      return {
        ...state,
        loading: false,
        success: false,
        school: [],
        error: action.payload,
      };

    case "REQUEST_ASS_SCHOOL":
      return { ...state, loading: true };
    case "SUCCESS_ASS_SCHOOL":
      return {
        ...state,
        loading: false,
        success: true,
        school: action.payload,
        schoolSaved: true,
      };
    case "FAIL_ASS_SCHOOL":
      return { ...state, loading: false, error: action.payload };

    case "ADD_CLASS_GRADES_REQUEST":
      return {
        ...state,
        loading: true,
      };

    case "ADD_CLASS_GRADES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,

        gradeClass: action.payload,
      };
    case "ADD_CLASS_GRADES_FAIL":
      return { ...state, loading: false, data: {}, error: action.payload };
    case "ADD_CLASS_GRADES_RESET":
      return { loading: {} };

    case "GET_MY_SCHOOL_RESET":
      return MySchoolReducerState;
    default:
      return state;
  }
};
