import React from "react";
import PageHeader from "../../../Components/Items/PageHeader";
import { Stack, Button } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import AddStudent from "./AddStudent";
import { useQuery } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";

const SchoolStudentCreation = ({ token }) => {
  const router = useHistory();
  const { id } = useParams();

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data } = useQuery({
    queryKey: ["grades", id],
    queryFn: () =>
      axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/grades/${id}`,
        config
      ),
  });

  return (
    <div>
      <PageHeader title="Add Students to School" />
      <Stack direction="row" justifyContent="space-between" sx={{ my: 2 }}>
        <Button
          variant="outlined"
          onClick={() => {
            router.push(`/SchoolAdmin/students/${id}/addBulkStudents`);
          }}>
          Add Bulk students
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={() => {
            router.push(`/SchoolAdmin/students/${id}/BulkAddedStudentsList`);
          }}>
          view Students List
        </Button>

        <AddStudent org_id={id} schoolGradesList={data} />
      </Stack>
    </div>
  );
};

export default withAuth(SchoolStudentCreation);
