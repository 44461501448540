const initialState = {
  loading: false,
  isSuccess: false,
  isError: false,
  orgAction: false,
  users: [],
  orgs: [],
  error: null,
};

export const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ALL_USERS_REQUEST":
      return { ...state, loading: true };
    case "GET_ALL_USERS__SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        users: action.payload,
      };

    case "GET_ALL_USERS_FAIL":
      return { ...state, loading: false, users: [], error: action.payload };
    case "RESET_ADMIN_DATA":
      return { state: initialState };

    case "GET_USER_REQUEST":
      return { ...state, loading: true };
    case "GET_USER_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        user: action.payload,
      };
    case "GET_USER_FAIL":
      return { ...state, loading: false, user: [], error: action.payload };

    case "GET_ALL_ORG_REQUEST":
      return { ...state, loading: true };
    case "GET_ALL_ORG_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        orgs: action.payload,
      };

    case "ADMIN_GET_ORG_REQUEST":
      return { ...state, loading: true };

    case "ADMIN_GET_ORG_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        org: action.payload,
      };

    case "ADMIN_GET_ORG_FAIL":
      return { ...state, loading: false, org: [], error: action.payload };

    case "GET_ALL_ORG_FAIL":
      return { ...state, loading: false, orgs: [], error: action.payload };

    case "APPROVE_ORG":
      return {
        ...state,
        loading: false,
        success: true,
        org: action.payload,
        orgAction: true,
      };
    case "SUSPEND_ORG":
      return {
        ...state,
        loading: false,
        success: true,
        org: action.payload,
        orgAction: true,
      };

    case "RESET_ORG_DATA":
      return { state: initialState };

    case "ORG_APP_SUS_ERROR":
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
