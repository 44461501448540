import styled from "@emotion/styled";
import { Avatar, Button, Stack, Tooltip } from "@mui/material";

import React, { useState } from "react";
import ImageCropper from "../Items/ImageCropper";

const AvatarImageUploader = ({ image, url, size, type }) => {
  const [avatar, setAvatar] = useState();

  const Input = styled("input")({
    display: "none",
  });

  const handleImageInput = (event) => {
    setAvatar({
      file: URL.createObjectURL(event.target.files[0]),
    });
  };

  return (
    <>
      {image && (
        <>
          {avatar ? (
            <>
              <ImageCropper
                type={type}
                path={url}
                cancel={setAvatar}
                src={avatar.file}
              />
            </>
          ) : (
            <>
              <label htmlFor="contained-button-file">
                <Input
                  accept="image/*"
                  id="contained-button-file"
                  type="file"
                  onChange={handleImageInput}
                />
                <Stack>
                  <Tooltip title="Change Image">
                    <Button component="span">
                      <Avatar
                        alt="user avatar"
                        src={`${process.env.REACT_APP_IMAGE_URL}/${image}`}
                        sx={
                          size === "small"
                            ? { width: 75, height: 75 }
                            : { width: 200, height: 200 }
                        }
                      />
                    </Button>
                  </Tooltip>
                </Stack>
              </label>
            </>
          )}
        </>
      )}
    </>
  );
};

export default AvatarImageUploader;
