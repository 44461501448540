import {
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { GetSchoolNews } from "../../../Redux/actions/SchoolsActions";
import withAuth from "../../../Hoc/withAuth";

import EditModal from "../../../Components/Containers/EditModal";
import DeleteSchoolDataModal from "../../../Components/Containers/DeleteSchoolDataModal";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@emotion/react";
import PageHeader from "../../../Components/Items/PageHeader";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Masonry from "@mui/lab/Masonry";
import ViewOnlySlate from "../../../Components/Forms/ViewOnlySlate";

const MySchool = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetSchoolNews({ id }));
  }, [id, dispatch]);

  const schoolData = useSelector((state) => state.schoolData);
  const { data } = schoolData;

  // const permitted = userId === uuid;

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("lg"));

  const news = [];
  const sticky = [];
  const Notices = [];
  const Resource = [];

  for (let i = 0; i < data?.results?.length; i++) {
    if (data?.results && data?.results[i].tag === "news") {
      news.push(data?.results[i]);
    }
    if (data?.results && data?.results[i].tag === "sticky") {
      sticky.push(data?.results[i]);
    }
    if (data?.results && data?.results[i].tag === "notice") {
      Notices.push(data?.results[i]);
    }
    if (data?.results && data?.results[i].tag === "resource") {
      Resource.push(data?.results[i]);
    }
  }

  return (
    <>
      <Container>
        <PageHeader
          title="My School Notice Board"
          description="Manage your school"
        />

        <Grid
          container
          spacing={2}
          direction={matches ? "column-reverse" : "row"}>
          <Grid item xs={12}>
            <BasicMasonry data={data?.results} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withAuth(MySchool);

const MoreMenu = ({ resource }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openModal, setOpenModal] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        {/* <MenuItem>
          Delete
        </MenuItem> */}

        <EditModal
          resource={resource}
          open={openModal}
          setOpen={setOpenModal}
        />
        <DeleteSchoolDataModal id={resource.uuid} org_id={resource.org_id} />
      </Menu>
    </div>
  );
};

const ContentCard = ({ resource, permitted }) => {
  return (
    <Card
      sx={{
        border: 1,
        borderColor: resource.tag === "news" ? "error.main" : "secondary.main",
        background: "theme.palette.background.default",
      }}>
      <CardHeader
        action={permitted && <MoreMenu resource={resource} />}
        title={resource?.title}
        subheader={new Date(resource?.updatedAt).toDateString()}
      />
      <Divider />
      <CardContent>
        <ViewOnlySlate values={resource?.data} />
      </CardContent>
    </Card>
  );
};

const StickyNoteCard = ({ content, index, permitted }) => {
  const theme = useTheme();

  return (
    <Grid item xl={12} lg={12} md={6} sm={6} xs={6}>
      <Card
        sx={{
          backgroundColor:
            theme.palette.mode === "dark"
              ? index % 2 === 0
                ? "primary.light"
                : "secondary.light"
              : index % 2 === 0
              ? "#cfc"
              : "#ffc",

          transform: index % 2 === 0 ? "rotate(-2deg)" : "rotate(2deg)",
          overflow: "hidden",
          height: "15em",
          width: "15em",
          border: "none",
          borderRadius: "0",
          ":hover": {
            transition: "all 0.3s ease-in-out",
            mt: 5,
            zIndex: 5,
            transform: "scale(1.5)",
            position: "relative",
          },

          color: (theme) => theme.palette.common.black,
        }}>
        <CardHeader
          sx={{ pb: 0 }}
          title={
            <Typography
              align="start"
              variant="h6"
              color="text.dark"
              sx={{
                fontFamily: "cursive",
              }}>
              {content.title}
            </Typography>
          }
          action={permitted && <MoreMenu resource={content} />}
        />
        <CardContent sx={{ px: 1 }}>
          <Typography
            variant="h5"
            color="text.dark"
            sx={{
              fontFamily: "Reenie Beanie",
            }}>
            {content.data}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: "transparent",
  border: "none",
  color: theme.palette.text.secondary,
}));
function BasicMasonry({ data }) {
  console.log(data);
  return (
    <Box>
      <Masonry columns={3} spacing={2}>
        {data?.map((content, index) => {
          if (content.tag === "sticky") {
            return (
              <Item key={index}>
                <StickyNoteCard key={index} content={content} />
              </Item>
            );
          }

          return (
            <Item key={index}>
              <ContentCard key={index} resource={content} />
            </Item>
          );
        })}
      </Masonry>
    </Box>
  );
}
