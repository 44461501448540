import React from "react";
import EditBooking from "../Components/Forms/EditBooking";

import Profile from "../Screens/Auth/Profile";
import MyOrg from "../Screens/Pages/Orgs/MyOrg";
import OrgAssociations from "../Screens/Pages/Orgs/OrgAssociations";
import MySchool from "../Screens/Pages/School/MySchool";
import Bookings from "../Screens/Pages/Users/Bookings";
import PendingRequestsToAssociate from "../Screens/Pages/Users/PendingRequestsToAssociate";
import Notifications from "../Screens/Pages/Users/Notifications";
import Stats from "../Screens/Pages/Users/Stats";
import PrivateRoute from "./PrivateRoute";
import CausesList from "../Screens/Pages/Causes/CausesList";

const GlobalPrivateRoutes = () => {
  return (
    <>
      <PrivateRoute path="/CausesList" exact>
        <CausesList />
      </PrivateRoute>
      <PrivateRoute path="/bookings" exact>
        <Bookings />
      </PrivateRoute>
      <PrivateRoute path="/profile" exact>
        <Profile />
      </PrivateRoute>
      <PrivateRoute path="/mySchool/:id" exact>
        <MySchool />
      </PrivateRoute>
      <PrivateRoute path="/notifications" exact>
        <Notifications />
      </PrivateRoute>
      <PrivateRoute path="/stats/:id" exact>
        <Stats />
      </PrivateRoute>
      <PrivateRoute path="/myorg" exact>
        <MyOrg />
      </PrivateRoute>

      <PrivateRoute path="/orgs/:id/OrgAssociations" exact>
        <OrgAssociations />
      </PrivateRoute>
      <PrivateRoute path="/orgs/:id/associations/pending/type/:type">
        <PendingRequestsToAssociate />
      </PrivateRoute>
      <PrivateRoute path="/editBooking/:id/:eventId" exact>
        <EditBooking />
      </PrivateRoute>
    </>
  );
};

export default GlobalPrivateRoutes;
