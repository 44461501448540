import React from "react";
import ParentDashBoard from "../Screens/Pages/ParentDashBoard";
import ParentAssociations from "../Screens/Pages/Users/ParentAssociations";
import ParentStats from "../Screens/Pages/Users/ParentStats";
import ParentViewStudentStats from "../Screens/Pages/Users/ParentViewStudentStats";
import GlobalPrivateRoutes from "./GlobalPrivateRoutes";
import PrivateRoute from "./PrivateRoute";
import PublicRoutes from "./PublicRoutes";

const ParentRoutes = () => {
  return (
    <>
      <PrivateRoute path="/parentDashBoard">
        <ParentDashBoard />
      </PrivateRoute>
      <PrivateRoute path="/parentAssociations" exact>
        <ParentAssociations />
      </PrivateRoute>
      <PrivateRoute path="/ParentStats" exact>
        <ParentStats />
      </PrivateRoute>
      <PrivateRoute path="/ParentViewStudentStats" exact>
        <ParentViewStudentStats />
      </PrivateRoute>
      <GlobalPrivateRoutes />
      <PublicRoutes />
    </>
  );
};

export default ParentRoutes;
