// import { Html5Qrcode } from "html5-qrcode";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Container, Stack } from "@mui/system";
import React, { useEffect } from "react";

import {
  useHtml5QrCodeScanner,
  useAvailableDevices,
} from "react-html5-qrcode-reader";
// import useWindowSize from "../../../Hooks/useWindowSize";

const html5QrCodeScannerFile = process.env.PUBLIC_URL + "/html5-qrcode.min.js";

const CodeScanner = ({ type, setStartCode }) => {
  // const { width, height } = useWindowSize();

  const [deviceList, setDeviceList] = React.useState("");

  const { Html5QrcodeScanner } = useHtml5QrCodeScanner(html5QrCodeScannerFile);

  const { devices, error } = useAvailableDevices(html5QrCodeScannerFile);

  useEffect(() => {
    if (Html5QrcodeScanner && deviceList) {
      let html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        {
          fps: 10,
          qrbox: 250,
          aspectRatio: 1,
          disableFlip: true,
          videoConstraints: {
            deviceId: deviceList,
          },
        },
        /* verbose= */ false
      );
      html5QrcodeScanner.render(
        (data) => setStartCode(data),
        (err) => console.log("err ->", err)
      );
    }
  }, [Html5QrcodeScanner, setStartCode, deviceList]);

  return (
    <Container>
      {error && `Devices error: ${error}`}
      <Stack sx={{ mt: 3 }} spacing={2}>
        {devices && (
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Select Camera</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={deviceList}
              label="Select Camera"
              onChange={(event) => {
                setDeviceList(event.target.value);
              }}>
              {devices.map((v) => (
                <MenuItem key={v.id} value={v.id}>
                  {v.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}

        {deviceList && (
          <div id="reader" style={{ width: "100%", height: "100%" }}></div>
        )}
      </Stack>
    </Container>
  );
};

export default CodeScanner;
