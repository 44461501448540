import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { Slide } from "@mui/material";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TransitionDown(props) {
  return <Slide {...props} direction="down" />;
}

const AlertPopup = ({
  open: isOpen,
  message,
  severity,
  onClose,
  ...otherProps
}) => {
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = (event, reason) => {
    setOpen(false);
    onClose && onClose();
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={open}
        TransitionComponent={TransitionDown}
        autoHideDuration={6000}
        {...otherProps}
        onClose={handleClose}>
        <Alert
          sx={{ backgroundColor: "background.paper", width: "100%" }}
          variant="outlined"
          onClose={handleClose}
          severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default AlertPopup;

AlertPopup.prototype = {
  open: PropTypes.bool,
  message: PropTypes.string,
  severity: PropTypes.string,
};
AlertPopup.defaultProps = {
  open: false,
  message: "",
  severity: "info",
};
