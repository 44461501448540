import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOrgAssociationsList } from "../../../Redux/actions/OrgAssociationsActions";
import OrgRequestUserToAssociate from "../../Forms/Orgs/OrgRequestUserToAssociate";

import AlertPopup from "../../Items/AlertPopup";

const OrgAdminDashAssoc = ({ org }) => {
  const dispatch = useDispatch();

  let id = org.uuid;

  useEffect(() => {
    dispatch(getOrgAssociationsList(id));
  }, [dispatch, id]);

  const orgAssociations = useSelector((state) => state.orgAssociations);
  const { createSuccess } = orgAssociations;

  return (
    <>
      <OrgRequestUserToAssociate orgId={org.uuid} />
      <AlertPopup open={createSuccess} message="Association Created" />
    </>
  );
};

export default OrgAdminDashAssoc;
