import { Card, CardHeader, Container } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import PageHeader from "../../../Components/Items/PageHeader";
import { useDispatch } from "react-redux";
import { getOrgById } from "../../../Redux/actions/Orgs";

const PendingRequestsToAssociate = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrgById({ id }));
  }, [dispatch, id]);

  // const orgById = useSelector((state) => state.orgById);
  // const { data } = orgById;

  return (
    <Container>
      <PageHeader
        title="Pending Requests To Associate"
        description="Pending Requests To Associate"
      />

      <Card>
        <CardHeader title="Pending Requests To Associate" />
      </Card>
    </Container>
  );
};

export default PendingRequestsToAssociate;
