import React, { useContext, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import PageHeader from "../../../Components/Items/PageHeader";
import NotificationItem from "../../../Components/Navigation/NotificationItem";
import { Box, Button } from "@mui/material";
import { useState } from "react";
import AlertPopup from "../../../Components/Items/AlertPopup";
import { useMutation, useQuery, useQueryClient } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import { NotificationContext } from "../../../App";

const Notifications = ({ token }) => {
  const queryClient = useQueryClient();

  const { notifications, setNotifications } = useContext(NotificationContext);

  let data = useQuery(
    {
      queryKey: ["userNotifications"],
      queryFn: () => {
        return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/notifications`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      },
    },

    {
      onSuccess: (data) => {
        setNotifications(data.data.data.userNotifications);
      },
    }
  );

  useEffect(() => {
    setNotifications(data.data?.data?.userNotifications);
  }, [data, setNotifications]);

  const readNotification = useMutation(
    (id) => {
      return axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/notifications/${id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        data.refetch();
        queryClient.invalidateQueries({ queryKey: ["userNotifications"] });
      },
    }
  );

  let userNotifications = notifications;

  const [open, setOpen] = useState(false);

  return (
    <Container>
      <PageHeader
        title="Notifications"
        description="Manage your notifications"
      />

      <AlertPopup
        open={readNotification?.isSuccess}
        message="Notification Read"
        severity={"success"}
      />

      <Grid container spacing={2} justifyContent="center">
        {userNotifications
          ?.filter((userNotification) => !userNotification.read)
          .map((userNotification, index) => {
            return (
              <Grid
                item
                key={index}
                xl={12}
                lg={12}
                md={12}
                sm={12}
                xs={12}
                sx={{ flexGrow: 1, mb: 0.1 }}>
                <NotificationItem
                  userNotification={userNotification}
                  readNotification={readNotification}
                />
              </Grid>
            );
          })}
      </Grid>

      <Box component={"div"} sx={{ textAlign: "center", m: 5 }}>
        <Button variant="outlined" onClick={() => setOpen(!open)}>
          Archived Notifications
        </Button>
      </Box>

      <Grid container spacing={2} justifyContent="center">
        {open &&
          userNotifications
            ?.filter((userNotification) => userNotification.read)
            .map((userNotification, index) => {
              return (
                <Grid key={index} item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <NotificationItem
                    userNotification={userNotification}
                    readNotification={readNotification}
                  />
                </Grid>
              );
            })}
      </Grid>
    </Container>
  );
};

export default withAuth(Notifications);
