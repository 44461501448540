import axios from "axios";
import { url } from "./URL";

export const userRegister =
  ({ username, name, email, password, role, parentEmail }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "REQUEST" });

      const { data } = await axios.post(`${url}/auth/register`, {
        username,
        name,
        email,
        password,
        role,
        parentEmail,
      });

      dispatch({ type: "REGISTER_SUCCESS", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: "FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const RegisterAsStudent =
  ({ username, name, email, password, role, parentEmail }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "REQUEST" });

      const { data } = await axios.post(`${url}/auth/RegisterAsStudent`, {
        username,
        name,
        email,
        password,
        role,
        parentEmail,
      });
      dispatch({ type: "REGISTER_SUCCESS", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: "FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const RegisterAsParent =
  ({ username, name, email, password, role }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "REQUEST" });

      const { data } = await axios.post(`${url}/auth/RegisterAsParent`, {
        username,
        name,
        email,
        password,
        role,
      });

      dispatch({ type: "REGISTER_SUCCESS", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: "FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const userLogin =
  ({ email, password }) =>
  async (dispatch) => {
    try {
      dispatch({ type: "REQUEST" });

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post(
        `${url}/auth/login`,
        { email, password },
        config
      );

      // console.log(data);

      dispatch({ type: "LOGIN_SUCCESS", payload: data });

      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: "FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getMe = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${url}/auth/me`, config);
    localStorage.setItem("userInfo", JSON.stringify(data));
    dispatch({ type: "GET_ME_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "FAIL_GET_ME",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const UpdateMe = (values) => async (dispatch, getState) => {
  try {
    dispatch({ type: "UPDATE_ME_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.put(`${url}/auth/me`, values, config);

    dispatch({ type: "UPDATE_ME_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "FAIL_UPDATE_ME",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => async (dispatch) => {
  try {
    localStorage.clear();
    dispatch({ type: "LOGOUT" });
  } catch (error) {
    console.log(error);
  }
};

export const VerifyEmailRequest =
  (emailToken) => async (dispatch, getState) => {
    try {
      dispatch({ type: "REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${url}/auth/emailVerify/${emailToken}`,
        {},
        config
      );

      dispatch({ type: "VERIFY_EMAIL_REQUEST_SUCCESS", payload: data });
      localStorage.setItem("userInfo", JSON.stringify(data));
    } catch (error) {
      dispatch({
        type: "FAIL_EMAIL_VERIFY",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const ResendEmail = (email) => async (dispatch, getState) => {
  try {
    dispatch({ type: "REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
      },
    };

    const { data } = await axios.put(
      `${url}/auth/resendEmailVerification`,
      { email: email },
      config
    );

    dispatch({ type: "RESEND_EMAIL_SUCCESS", payload: data });

    dispatch(getMe());
    console.log("dispatched");
  } catch (error) {
    dispatch({
      type: "RESEND_EMAIL_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
