import {
  Alert,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AdminAllOrgs } from "../../Redux/actions/Admin";
import AvatarImage from "../../Components/ImageUploaders/AvatarImage";

const AdminOrgs = () => {
  const router = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(AdminAllOrgs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AdminData = useSelector((state) => state.AdminData);
  const { error, loading, orgs } = AdminData;

  const [search, setSearch] = React.useState("");

  // search orgs

  const filteredOrgs = (orgs) => {
    if (search) {
      return orgs.filter((orgs) => {
        return orgs.name.toLowerCase().includes(search.toLowerCase());
      });
    } else {
      return orgs;
    }
  };

  return (
    <>
      <Stack
        sx={{ height: 200 }}
        display="flex"
        justifyContent="center"
        alignItems="center">
        <Typography variant="h2" align="center">
          Organizations
        </Typography>
      </Stack>

      <Container maxWidth="xl" disableGutters>
        <Grid
          container
          spacing={2}
          display="flex"
          justifyContent="center"
          sx={{ mb: 4 }}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <TextField
              fullWidth
              label="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              {loading && <CircularProgress />}
            </Stack>

            {error && <Alert severity="error">{error}</Alert>}

            <List>
              {orgs &&
                filteredOrgs(orgs)
                  .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
                  .map((org) => {
                    return (
                      <div key={org.uuid}>
                        <ListItemButton
                          onClick={() =>
                            router.push(`/admin/orgs/${org.uuid}`)
                          }>
                          <ListItem
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                            alignItems="flex-start"
                            secondaryAction={
                              <Stack>
                                <Chip
                                  label={
                                    org.approved ? "approved" : "Not Approved"
                                  }
                                  color={org.approved ? "success" : "error"}
                                />
                                <Typography variant="caption">
                                  {new Date(org.createdAt).toDateString()}
                                </Typography>
                              </Stack>
                            }>
                            <ListItemAvatar>
                              <AvatarImage uuid={org?.uuid} />
                            </ListItemAvatar>
                            <ListItemText
                              sx={{ ml: 20 }}
                              primary={
                                <Typography variant="h6">
                                  {org?.name}
                                </Typography>
                              }
                            />
                          </ListItem>
                        </ListItemButton>
                        <Divider />
                      </div>
                    );
                  })}
            </List>
          </Grid>
          {/* <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
              <StickyContainer>
                <Typography align="center">Ad Space</Typography>
                <AddCards />
              </StickyContainer>
            </Grid> */}
        </Grid>
      </Container>
    </>
  );
};

export default AdminOrgs;
