import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Slide,
  Typography,
} from "@mui/material";
import React from "react";
import { Formik, Form, useFormikContext } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { updateSchoolData } from "../../Redux/actions/SchoolsActions";
import TextfieldWrapper from "../Forms/FormComponents/TextfieldWrapper";
import SelectWrapper from "../Forms/FormComponents/Select";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import draftToHtml from "draftjs-to-html";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditModal = ({ resource }) => {
  const dispatch = useDispatch();
  // const [error, setError] = useState(false);

  const [open, setOpen] = useState(false);

  //   const [data,setData] = useState(resource?.data);

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(!open);
        }}>
        <Typography color="warning">Edit</Typography>
      </MenuItem>

      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        TransitionComponent={Transition}>
        <DialogTitle>Edit Content</DialogTitle>
        <Formik
          initialValues={{
            title: resource?.title,
            data: resource?.data,
            tag: resource?.tag,
            org_id: resource?.org_id,
          }}
          validationSchema={Yup.object({
            title: Yup.string().required("Title is required*"),
            tag: Yup.string().required("Please select a tag*"),
            data: Yup.string().required("Resource data is required*"),
          })}
          onSubmit={(values) => {
            dispatch(updateSchoolData({ ...values, id: resource.uuid }));
            setOpen(!open);
          }}>
          {({ values, errors, touched, setValues }) => {
            return (
              <Form>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextfieldWrapper label="Title" name="title" />
                    </Grid>
                    <Grid item xs={6}>
                      <SelectWrapper
                        name="tag"
                        label="Tag"
                        options={{
                          notice: "Notices",
                          news: "News",
                          resource: "Resource",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <DraftEditor />
                    </Grid>
                    <Divider />
                  </Grid>
                </DialogContent>

                <DialogActions>
                  <Button onClick={() => setOpen(false)}>Cancel</Button>
                  <Button type="submit">Submit</Button>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  );
};

export default EditModal;

const DraftEditor = () => {
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createEmpty()
  );

  const { setFieldValue, values } = useFormikContext();

  const handleChange = (editorState) => {
    setEditorState(editorState);
    setFieldValue(
      "data",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    console.log(values.data);
  };

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        ContentState.createFromBlockArray(convertFromHTML(values.data))
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Editor
      toolbarClassName="toolbarClassName"
      wrapperClassName="wrapperClassName"
      editorClassName="editorClassName"
      editorState={editorState}
      onEditorStateChange={handleChange}
    />
  );
};
