import React from "react";
import AdminOrgs from "../Screens/Admin/AdminOrgs";
import AdminPanel from "../Screens/Admin/AdminPanel";
import AdminUsers from "../Screens/Admin/AdminUsers";
import AdminEditOrg from "../Screens/Admin/pages/AdminEditOrg";
import EditUser from "../Screens/Admin/pages/EditUser";
import GlobalPrivateRoutes from "./GlobalPrivateRoutes";
import OrgRoutes from "./OrgRoutes";
import PrivateRoute from "./PrivateRoute";
import PublicRoutes from "./PublicRoutes";
import AdminTags from "../Screens/Admin/AdminTags";

const AdminRoutes = () => {
  return (
    <>
      <PrivateRoute path="/admin" exact>
        <AdminPanel />
      </PrivateRoute>
      <PrivateRoute path="/admin/orgs" exact>
        <AdminOrgs />
      </PrivateRoute>
      <PrivateRoute path="/admin/orgs/:id" exact>
        <AdminEditOrg />
      </PrivateRoute>
      <PrivateRoute path="/admin/users" exact>
        <AdminUsers />
      </PrivateRoute>
      <PrivateRoute path="/admin/EditUser/:id" exact>
        <EditUser />
      </PrivateRoute>
      <PrivateRoute path="/admin/AdminTags" exact>
        <AdminTags />
      </PrivateRoute>
      <OrgRoutes />
      <GlobalPrivateRoutes />
      <PublicRoutes />
    </>
  );
};

export default AdminRoutes;
