import React from "react";
import { useQuery } from "react-query";
import { Avatar } from "@mui/material";
import withAuth from "../../Hoc/withAuth";

const AvatarImage = ({ token, uuid, size }) => {
  const { data, isLoading, isError } = useQuery(["avatar", uuid], async () => {
    const res = await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/images/${uuid}/type/avatar`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.json();
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (isError) {
    return (
      <Avatar
        alt="Avatar"
        image={`${process.env.REACT_APP_IMAGE_URL}/default.jpg`}
        sx={
          size === "small"
            ? { width: 45, height: 45 }
            : { width: 100, height: 100 }
        }
      />
    );
  }

  if (data?.file_name === "default.jpg") {
    return (
      <Avatar
        alt="Avatar"
        src={`${process.env.REACT_APP_IMAGE_URL}/default.jpg`}
        sx={
          size === "small"
            ? { width: 45, height: 45 }
            : { width: 100, height: 100 }
        }
      />
    );
  }

  return (
    <div>
      <Avatar
        alt="Avatar"
        src={`${process.env.REACT_APP_PUBLIC_URL}/images/file/${data?.file_name}`}
        sx={
          size === "small"
            ? { width: 45, height: 45 }
            : { width: 100, height: 100 }
        }
      />
    </div>
  );
};

export default withAuth(AvatarImage);
