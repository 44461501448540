import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Box,
  Button,
  CardContent,
  Chip,
  Typography,
  Avatar,
} from "@mui/material";
import withAuth from "../../Hoc/withAuth";
import { useSelector, useDispatch } from "react-redux";
import { AddBookingRequest } from "../../Redux/actions/Bookings";
import { Alert } from "@mui/material";
import moment from "moment";
import TimeSelectSlider from "./TimeSelectSlider";
import { getAssRequest } from "../../Redux/actions/UserActions";
import { getMyRelations } from "../../Redux/actions/Relations";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

const JoinEvent = ({ data, eventId, user, bookings }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const dispatch = useDispatch();

  const Relations = useSelector((state) => state.Relations);
  const { relations } = Relations;

  const ValidOccurrences = (data) => {
    let occurrences = [];

    data.forEach((element) => {
      if (
        moment(element.occurrence).add(1, "days").isAfter(moment(new Date()))
      ) {
        occurrences.push(element);
      }
    });

    occurrences.sort((a, b) => {
      return new Date(a.occurrence) - new Date(b.occurrence);
    });

    return occurrences;
  };

  const ParentRelationIsAuthorized = (relation) => {
    if (
      relation.parentConfirmed === true &&
      relation.studentConfirmed === true
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch(getMyRelations());
  }, [dispatch]);

  const addBooking = useSelector((state) => state.addBooking);
  const { error, success } = addBooking;

  useEffect(() => {
    dispatch(getAssRequest());
  }, [dispatch]);

  const [selectedOcc, setSelectedOcc] = useState("");

  const [timeSlots, setTimeSlots] = useState("");
  const [userData, setUserData] = useState("");

  let check = () => {
    if (timeSlots && selectedOcc) {
      return false;
    } else return true;
  };

  const handleChange = (event) => {
    setSelectedOcc(event.target.value);
  };

  const handleUserSelect = (event) => {
    setUserData(event.target.value);
  };

  const addUserSelect = (role) => {
    if (role === "parent") {
      return (
        <div>
          <FormControl fullWidth>
            <InputLabel id="UserSelect">Select User</InputLabel>
            <Select
              labelId="UserSelect"
              id="UserSelect"
              value={userData}
              label="Select User"
              onChange={handleUserSelect}>
              {relations &&
                relations.map((user) => {
                  return (
                    <MenuItem
                      disabled={!ParentRelationIsAuthorized(user)}
                      key={user?.User?.uuid}
                      value={user?.User}>
                      <Stack direction="row">
                        <Avatar
                          sx={{ width: 24, height: 24, mr: 2 }}
                          alt={user?.User?.name}
                          src={`${process.env.REACT_APP_IMAGE_URL}/${user?.User?.avatar}`}
                        />
                        {user?.User?.username}
                        <Typography color="error" sx={{ ml: 3 }}>
                          {!ParentRelationIsAuthorized(user)
                            ? "This User is not authorized to join this event yet"
                            : null}
                        </Typography>
                      </Stack>
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
      );
    }
  };

  const handelJoin = () => {
    dispatch(
      AddBookingRequest({
        eventId,
        occurrenceId: selectedOcc.uuid,
        user: user.role === "parent" ? userData?.uuid : user?.uuid,
        username:
          user.role === "parent" ? userData?.User?.username : user?.username,
        avatar: user.role === "parent" ? userData?.User?.avatar : user?.avatar,
        timeSlots: timeSlots,
        bookedTime: timeSlots.length,
      })
    );
  };

  if (!relations) {
    return <Typography>You are not Authorized to join Events</Typography>;
  }

  const disableOccIfBooked = (occurrence) => {
    if (bookings) {
      let booked = false;
      bookings.forEach((booking) => {
        if (booking.occurrenceId === occurrence.uuid) {
          booked = true;
        }
      });
      if (booked) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="success"
        fullWidth
        onClick={handleClickOpen}>
        VOLUNTEER
      </Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle>Join Event</DialogTitle>

        <CardContent>
          <Stack my={2} spacing={2} sx={{ flex: 1 }} direction="column">
            {addUserSelect(user?.role)}
            <FormControl fullWidth>
              <InputLabel id="Select Date">Select Date</InputLabel>

              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedOcc}
                label="Select Date"
                onChange={handleChange}>
                {data &&
                  ValidOccurrences(data).map((occ, index) => {
                    return (
                      <MenuItem
                        key={index}
                        value={occ}
                        disabled={disableOccIfBooked(occ)}>
                        {moment(occ.occurrence).format("dddd, MMMM Do YYYY")}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              {selectedOcc?.timeSlots && (
                <>
                  <TimeSelectSlider
                    setTimeSlots={setTimeSlots}
                    slots={selectedOcc?.timeSlots && selectedOcc?.timeSlots}
                  />
                </>
              )}
            </FormControl>
            {selectedOcc?.capacity && selectedOcc?.capacity && (
              <Chip
                sx={{
                  m: 1,
                }}
                color={selectedOcc?.capacity > 0 ? "secondary" : "error"}
                variant="outlined"
                onClick={() => {}}
                label={`${selectedOcc?.capacity} Slots available `}
              />
            )}
          </Stack>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              pl: 1,
              pb: 1,
            }}>
            {user.role === "student" || user?.role === "parent" ? (
              <>
                {success ? (
                  <>
                    <Alert severity="success">Event Joined Successfully</Alert>{" "}
                    <Button
                      onClick={() => {
                        dispatch({ type: "ADD_BOOKING_RESET" });
                        setTimeSlots("");
                        setSelectedOcc("");
                      }}>
                      Join Another?
                    </Button>
                  </>
                ) : (
                  <Button
                    disabled={check()}
                    fullWidth
                    variant="contained"
                    color="success"
                    onClick={() => handelJoin()}>
                    Join
                  </Button>
                )}
              </>
            ) : (
              <Alert severity="warning">
                You need to be authenticated by a parent to join the event.{" "}
                <Button onClick={() => console.log(`sending parent an email`)}>
                  Request Access from Parent?
                </Button>
              </Alert>
            )}

            {error && <Alert severity="error">{JSON.stringify(error)}</Alert>}
          </Box>
        </CardContent>

        <>
          <Button onClick={handleClose}>Cancel</Button>
        </>
      </Dialog>
    </React.Fragment>
  );
};

export default withAuth(JoinEvent);
