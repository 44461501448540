import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import logo from "../../images/logos/NewLogoLight.png";
import logo2 from "../../images/logos/NewLogoDark.png";

import { url } from "../../Redux/actions/URL";

// import Logo from "../../images/logos/logo.png";
import { useDispatch } from "react-redux";
import { useTheme } from "@emotion/react";

const PasswordReset = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState("");
  const [error, setError] = useState("");
  const [response, setResponse] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "RESET" });
  }, [dispatch]);

  const resetHandler = async () => {
    console.log({ email });

    try {
      setLoading(true);

      const request = await axios.post(`${url}/auth/forgotPassword`, { email });

      setResponse(request.data.message);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(error);
    }
  };

  const theme = useTheme();

  return (
    <Container maxWidth="xs">
      <Box my={4} display="flex" alignItems="center" justifyContent="center">
        {loading && <CircularProgress />}
      </Box>
      <Box mt={4} display="flex" justifyContent="center">
        <img
          style={{
            width: "300px",
            height: "310px",
          }}
          src={theme.palette.mode === "dark" ? logo2 : logo}
          alt="logo"
        />
      </Box>
      <Typography variant="h4" align="center">
        Reset Password
      </Typography>
      <TextField
        value={email}
        onChange={(event) => setEmail(event.target.value)}
        margin="normal"
        type="email"
        variant="outlined"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoFocus
      />

      <Button
        fullWidth
        variant="contained"
        color="primary"
        onClick={resetHandler}>
        Send request
      </Button>
      {response && <Alert severity="info">{JSON.stringify(response)}</Alert>}

      <Box my={2}>
        {error && (
          <Alert severity="error">{JSON.stringify(error.message)}</Alert>
        )}
      </Box>
      <Grid container>
        <Grid item xs>
          <Link to="/PasswordResetReq" variant="body2">
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link to="/register">{"Don't have an account? Sign Up"}</Link>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Typography variant="body2" align="center">
          {"Copyright © "}
          <Link to="https://chalkitupsa.co.za">{" Chalk it Up "}</Link>
          {new Date().getFullYear()}
        </Typography>
        <Typography variant="body2" align="center">
          {"(Alpha v0.1.2)"}
        </Typography>
      </Box>
    </Container>
  );
};

export default PasswordReset;
