import React from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import SchoolManageGradesAndClasses from "../../Screens/Pages/School/SchoolManageGradesAndClasses";

import withAuth from "../../Hoc/withAuth";
// import { useHistory } from "react-router-dom";

const SetUpSchool = ({ token, user }) => {
  let id = user.Org.uuid;

  return (
    <Card sx={{ mt: 3 }}>
      <CardHeader
        title="Set Up School"
        subheader="Set up your school by adding grades and classes"
      />

      <CardContent>
        <SchoolManageGradesAndClasses token={token} org_id={id} />
      </CardContent>
    </Card>
  );
};

export default withAuth(SetUpSchool);
