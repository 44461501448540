import React, { useEffect } from "react";
import {
  Grid,
  Typography,
  CardHeader,
  Avatar,
  Card,
  CardActionArea,
  Button,
  IconButton,
  CardContent,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import { getMySchoolRequest } from "../../../Redux/actions/AppData";
import { useHistory } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

import SchoolSelect from "../SchoolSelect";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import BannerImage from "../../ImageUploaders/BannerImage";
import SetGoals from "../../../Screens/Pages/Users/SelectHourGoals";
import BasicPie from "../../Charts/PieChart";
import { getTotalStats } from "../../../Redux/actions/UserStats";

const StudentSchoolForms = ({ token, user }) => {
  const router = useHistory();
  const dispatch = useDispatch();

  const getMySchool = useQuery({
    queryKey: "mySchool",
    queryFn: () => {
      return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/schools/mySchool`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  useEffect(() => {
    dispatch(getMySchoolRequest());
    dispatch(getTotalStats({ studentId: user?.uuid }));
  }, [dispatch, user]);

  const removeSchoolAssociation = useMutation(
    ({ id }) => {
      return axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/mySchool/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        getMySchool.refetch();
      },
    }
  );

  const addUserToSchool = useMutation(
    ({ body }) => {
      return axios.put(`${process.env.REACT_APP_PUBLIC_URL}/schools`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        getMySchool.refetch();
      },
    }
  );

  const school = getMySchool?.data?.data;

  const stats = useSelector((state) => state.stats);
  const { data } = stats;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={6} sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          My School
        </Typography>
        <Typography variant="body1" color="GrayText" gutterBottom>
          Select a school, grade and class.
        </Typography>

        {school && school.length > 0 ? (
          school.map((school, index) => {
            return (
              <React.Fragment key={index}>
                <Card>
                  <CardHeader
                    title={school?.Org?.name}
                    avatar={
                      <Avatar
                        src={`${process.env.REACT_APP_IMAGE_URL}/${school?.Org?.avatar}`}
                        aria-label={school?.Org?.name}
                      />
                    }
                    action={
                      <RemoveSchool
                        school={school?.Org}
                        removeSchoolAssociation={removeSchoolAssociation}
                      />
                    }
                  />
                  <CardActionArea
                    onClick={() => router.push(`/mySchool/${school.Org.uuid}`)}>
                    <BannerImage uuid={school?.Org?.uuid} type="banner" />
                  </CardActionArea>
                </Card>
              </React.Fragment>
            );
          })
        ) : (
          <SchoolSelect addUserToSchool={addUserToSchool} />
        )}
      </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>
          My Targets
        </Typography>
        <Typography variant="body1" color="GrayText" gutterBottom>
          Set your yearly targets.
        </Typography>
        {data?.stats ? (
          <Card>
            <CardContent>
              <Tooltip title="View Your Stats" placement="top">
                <CardActionArea
                  onClick={() => router.push(`/stats/${user.uuid}`)}>
                  <BasicPie
                    goals={Number(data?.stats?.hourGoals) || 0}
                    hours={Number(data?.stats?.totalHours) || 0}
                  />

                  {/* <BarChart
                    goals={data?.stats?.hourGoals || 0}
                    hours={data?.stats?.totalHours || 0}
                  /> */}
                </CardActionArea>
              </Tooltip>
            </CardContent>
          </Card>
        ) : (
          <SetGoals user={user} />
        )}
      </Grid>
    </Grid>
  );
};

export default withAuth(StudentSchoolForms);

function RemoveSchool({ removeSchoolAssociation, school }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    removeSchoolAssociation.mutate({ id: school.id });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <IconButton variant="outlined" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Remove School Association"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to remove this school?
          </DialogContentText>
          <Typography variant="h6" color="error">
            {school?.name}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button
            color="error"
            variant="contained"
            onClick={handleSave}
            autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
