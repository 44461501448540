import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useQuery, useMutation, useQueryClient } from "react-query";
import axios from "axios";

import withAuth from "../../../Hoc/withAuth";
import { IconList } from "../../../Components/IconList";
import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  CardActionArea,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AddEventTags = ({ eventId, token, eventTags }) => {
  const [open, setOpen] = React.useState(false);

  const getTags = useQuery({
    queryKey: ["tags"],
    queryFn: () =>
      axios(`${process.env.REACT_APP_PUBLIC_URL}/tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedTags([]);
    setOpen(false);
  };

  const [selectedTags, setSelectedTags] = React.useState([]);

  const SelectTags = (id) => {
    // create a new array with the selected tags
    setSelectedTags([...selectedTags, id]);
  };

  const deSelectTags = (id) => {
    // create a new array without the selected tags
    setSelectedTags(selectedTags.filter((tag) => tag !== id));
  };

  const queryClient = useQueryClient();

  const PostOrgTags = useMutation(
    (tags) => {
      return axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/tags/eventTags`,
        tags,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ["orgTags", eventId],
        });
      },
    }
  );

  const handleSaveTags = () => {
    PostOrgTags.mutate({
      eventId: eventId,
      tags: selectedTags,
    });

    setSelectedTags([]);
    setOpen(false);
  };

  const filterTags = (tags) => {
    return tags?.filter((tag) => {
      return !eventTags?.some((orgTag) => orgTag.tag.uuid === tag.uuid);
    });
  };

  let tags = getTags?.data?.data;

  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="secondary"
        fullWidth
        onClick={handleClickOpen}>
        Add Causes
      </Button>
      <Dialog
        maxWidth="lg"
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>
          {"Select the causes that your organization supports"}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {tags &&
              tags.length > 0 &&
              filterTags(tags).map((row) => {
                let TheIcon = IconList.find(
                  (icon) => icon.name === row.icon
                )?.Icon;
                return (
                  <Grid item xs={12} sm={6} key={row.uuid}>
                    <Card
                      sx={{
                        border: selectedTags.includes(row.uuid)
                          ? "2px solid #3f51b5"
                          : "2px solid #fff",
                      }}>
                      <CardActionArea
                        onClick={() => {
                          if (selectedTags.includes(row.uuid)) {
                            deSelectTags(row.uuid);
                          } else {
                            SelectTags(row.uuid);
                          }
                        }}>
                        <CardContent>
                          <Stack spacing={1}>
                            <TheIcon fontSize="large" color={row.color} />
                            <Typography variant="body1" fontWeight={500}>
                              {row.name}
                            </Typography>
                            <Typography variant="caption">
                              {row.description}
                            </Typography>
                          </Stack>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ my: 2 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleSaveTags}>
            Add Tags to Org
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default withAuth(AddEventTags);
