import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { joinEventRequest } from "../../../Redux/actions/EventOcc";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router";
import moment from "moment";
import { getSingleBooking } from "../../../Redux/actions/Bookings";
import CodeScanner from "./CodeScanner";

const EventJoinCode = () => {
  const { id } = useParams();

  const [startScan, setStartScan] = useState(false);

  const router = useHistory();

  const [startCode, setStartCode] = useState("");
  const [EnteredStartCode, setEnteredStartCode] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleBooking({ id }));
  }, [dispatch, id]);

  const singleBooking = useSelector((state) => state.singleBooking);
  const { data, loading } = singleBooking;

  const handleJoinEvent = () => {
    dispatch(joinEventRequest({ id, startCode: EnteredStartCode }));
    dispatch({ type: "GET_USER_BOOKING_RESET" });
    startScan && setStartScan(false);
  };

  useEffect(() => {
    if (startCode.length >= 6) {
      setStartScan(false);
      dispatch(joinEventRequest({ id, startCode: startCode.split("/")[0] }));

      console.log("Event Start Code Firing ", startCode.split("/")[0]);

      // setTimeout(() => {
      //   window.location.reload();
      // }, 1000);
    }
  }, [startCode, dispatch, id, router]);

  const joinEvent = useSelector((state) => state.joinEvent);
  const { eventStarted, data: StartData } = joinEvent;

  if (loading) {
    return <h1>Loading</h1>;
  }

  if (data?.endTime && data?.startTime) {
    return (
      <Container maxWidth="xl">
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  <Typography variant="h6" align="center">
                    Event Ended
                  </Typography>

                  <Typography variant="h6" color="GrayText" align="center">
                    Your total time spent in the event was{" "}
                    {moment(data?.endTime).diff(
                      moment(data?.startTime),
                      "minutes"
                    )}{" "}
                    minutes
                  </Typography>

                  <Typography>TODO : /// Make a nice end page</Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h5" align="center" gutterBottom>
            Join Event
          </Typography>

          {data && !data.startTime ? (
            <Card>
              <CardHeader
                title="Join event by scanning the barcode from the event host."
                subheader="Start Event"
              />
              <CardContent>
                <Stack spacing={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setStartScan(!startScan)}>
                    Scan Barcode
                  </Button>
                </Stack>
                {startScan && <CodeScanner setStartCode={setStartCode} />}

                <Divider />

                <Stack spacing={2}>
                  <Typography variant="h6" align="center">
                    Join event by entering the event code
                  </Typography>
                  <Typography variant="caption" color="GrayText" align="center">
                    Event Hosts will have this code
                  </Typography>
                  <Stack alignItems="center" justifyContent="center">
                    <TextField
                      size="small"
                      name="Event Code"
                      label="Start Event Code"
                      placeholder="123456"
                      value={EnteredStartCode}
                      onChange={(event) =>
                        setEnteredStartCode(event.target.value)
                      }
                      color="primary"
                    />
                    {eventStarted ? (
                      <Stack spacing={2}>
                        <Alert sx={{ mt: 2 }} severity="success">
                          {`You have started at ${moment(
                            StartData?.Edited?.startTime
                          ).format("MMMM Do YYYY, h:mm:ss a")}`}
                        </Alert>
                        <Button
                          variant="contained"
                          onClick={() =>
                            router.push(`/EventJoinCode/${id}/end`)
                          }>
                          END EVENT
                        </Button>
                      </Stack>
                    ) : (
                      <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="primary"
                        onClick={() => handleJoinEvent()}>
                        START
                      </Button>
                    )}
                  </Stack>
                  <Divider />
                  <Stack
                    spacing={2}
                    alignItems="center"
                    justifyContent="center">
                    <Typography variant="h6">
                      Join event by scanning the barcode from the event host.
                    </Typography>
                    <Typography variant="caption" color="GrayText">
                      This will only work properly when the app is online
                    </Typography>

                    <Button variant="outlined" disabled>
                      Barcode scanner
                    </Button>
                  </Stack>
                  <Divider />
                  <Button>Find Event Host</Button>
                </Stack>
              </CardContent>
            </Card>
          ) : (
            <Stack spacing={2}>
              <Alert sx={{ mt: 2 }} severity="success">
                {`You have started at ${moment(
                  StartData?.Edited?.startTime
                ).format("MMMM Do YYYY, h:mm:ss a")}`}
              </Alert>
              <Button
                variant="contained"
                onClick={() => router.push(`/EventJoinCode/${id}/end`)}>
                END EVENT
              </Button>
            </Stack>
          )}
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {/* <EventsCard data={data?.Event} /> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventJoinCode;
