import { Button, Container, Divider, Grid } from "@mui/material";
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import PageHeader from "../../../Components/Items/PageHeader";
import TextfieldWrapper from "../../../Components/Forms/FormComponents/TextfieldWrapper";
import SelectWrapper from "../../../Components/Forms/FormComponents/Select";
import PreviewModal from "../../../Components/Containers/PreviewModal";
import { Stack } from "@mui/system";
import AlertPopup from "../../../Components/Items/AlertPopup";
import MySlateEditor from "../../../Components/Forms/MySlateEditor";
import { useMutation } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import { useHistory } from "react-router-dom";

const SchoolRecourses = ({ token }) => {
  const { id } = useParams();
  const router = useHistory();
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const AddSchoolContent = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/add`,
        data,
        config
      ),
  });

  return (
    <Container>
      <PageHeader
        title="School Notice Board"
        description="Add Notices, News and Resources to the notice board"
      />

      <Formik
        initialValues={{
          org_id: id,
          title: "",
          data: [{ type: "paragraph", children: [{ text: "" }] }],
          tag: "",
        }}
        validationSchema={Yup.object({
          title: Yup.string().required("Title is required*"),
          tag: Yup.string().required("Please select a tag*"),
        })}
        onSubmit={(values) => {
          AddSchoolContent.mutate(values);
          setTimeout(() => {
            router.push(`/mySchool/${id}`);
          }, 1000);
        }}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextfieldWrapper label="Title" name="title" />
                </Grid>
                <Grid item xs={6}>
                  <SelectWrapper
                    name="tag"
                    label="Tag"
                    options={{
                      notice: "Notices",
                      news: "News",
                      resource: "Resource",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <MySlateEditor
                    value={values.data}
                    onChange={(value) => {
                      setFieldValue("data", value);
                    }}
                  />
                </Grid>
                <Divider />
                <Grid item xs={12}>
                  <Stack spacing={4}>
                    {values && <PreviewModal formData={values} />}

                    <Button type="submit" variant="contained" color="secondary">
                      Save
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <AlertPopup
        open={AddSchoolContent.isSuccess}
        message="Notice Board Updated"
        severity="success"
      />
      <AlertPopup
        open={AddSchoolContent.isError}
        message="Error updating NoticeBoard"
        severity="error"
      />
    </Container>
  );
};

export default withAuth(SchoolRecourses);
