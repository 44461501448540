import { Grid } from "@mui/material";
import React, { useState } from "react";

import dayjs from "dayjs";
import dayjsRecur from "dayjs-recur";
import { Typography } from "@mui/material";
import { useEffect } from "react";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";

dayjs.extend(dayjsRecur);

const WeeklyEventsForm = ({
  setOccurrences,
  daysOfTheWeek,
  timeSlots,
  capacity,
  eventId,
  startTime,
  endTime,
}) => {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());

  const generateWeeklyOccurrences = (startDate, endDate) => {
    let stuff = dayjs()
      .recur({
        start: dayjs(startDate).format("YYYY-MM-DD"),
        end: dayjs(endDate).format("YYYY-MM-DD"),
      })
      .every(daysOfTheWeek)
      .daysOfWeek();

    return stuff.all("L");
  };

  useEffect(() => {
    let occ = generateWeeklyOccurrences(startDate, endDate, daysOfTheWeek);

    let occs = occ.map((occur, index) => {
      return {
        id: index,
        daysOfTheWeek: daysOfTheWeek,
        occurrence: occur,
        timeSlots: timeSlots,
        capacity: capacity,
        eventId: eventId,
        startTime: startTime,
        endTime: endTime,
      };
    });

    setOccurrences(occs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, daysOfTheWeek]);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
        <Grid item xs={6}>
          <Stack>
            <Typography>Start Date</Typography>
            <Typography variant="caption" color="text.secondary">
              Select the start date of the Date Range
            </Typography>

            <DatePicker
              format="LL"
              variant="inline"
              onChange={(date) => setStartDate(date)}
              inputVariant="outlined"
              fullWidth
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack>
            <Typography>End Date</Typography>
            <Typography variant="caption" color="text.secondary">
              Select the end date of the Date Range
            </Typography>
            <DatePicker
              format="LL"
              variant="inline"
              onChange={(date) => setEndDate(date)}
              inputVariant="outlined"
              fullWidth
            />
          </Stack>
        </Grid>
      </LocalizationProvider>
    </>
  );
};

export default WeeklyEventsForm;
