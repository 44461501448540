import {
  Alert,
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import {
  AdminGetOrg,
  AdminApproveOrg,
  SuspendOrg,
} from "../../../Redux/actions/Admin";
import ModeEditTwoToneIcon from "@mui/icons-material/ModeEditTwoTone";
import { sendNotification } from "../../../Redux/actions/Notifications";

import AdminOrgForm from "../components/AdminOrgForm";
import UploadAvatarImage from "../../../Components/ImageUploaders/UploadAvatarImage";
import UploadBannerImage from "../../../Components/ImageUploaders/UploadBannerImage";
import withAuth from "../../../Hoc/withAuth";

const AdminEditOrg = ({ token }) => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const AdminData = useSelector((state) => state.AdminData);
  const { error, loading, org, orgAction } = AdminData;

  useEffect(() => {
    dispatch(AdminGetOrg({ id }));
  }, [id, dispatch, orgAction]);

  const router = useHistory();

  const handleApproveOrg = () => {
    dispatch(AdminApproveOrg({ id }));

    let notification = {
      receiverId: org?.userId,
      content: `Your organization ${org?.name} has been approved`,
      title: "Organization Approved",
      type: "Organization",
      severity: "success",
      url: `/myorg/${org?.uuid}`,
    };

    dispatch(sendNotification(notification));
  };
  const handlesSusOrg = () => {
    dispatch(SuspendOrg({ id }));

    let notification = {
      receiverId: org?.userId,
      content: `Your organization ${org?.name} has been suspended`,
      title: "Organization suspended",
      type: "Organization",
      severity: "error",
      url: `/myorg/${org?.uuid}`,
    };

    dispatch(sendNotification(notification));
  };

  console.log(org);

  if (error) {
    return (
      <Box display="flex" justifyContent="center" my={4}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <Container maxWidth="xl">
      <Typography variant="h4" align="center" sx={{ my: 4 }}>
        AdminEditOrg
      </Typography>

      {loading && (
        <Box my={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      )}

      <Stack sx={{ mb: 4 }} direction="row" justifyContent="space-between">
        {org?.approved ? (
          <Button color="warning" variant="outlined" onClick={handlesSusOrg}>
            Suspend
          </Button>
        ) : (
          <Button
            onClick={handleApproveOrg}
            color="success"
            variant="contained">
            Approve
          </Button>
        )}

        <Button color="error">Delete Org</Button>
      </Stack>

      <Grid container spacing={2}>
        <Grid item xl={8} md={8} sm={12} xs={12}>
          <Stack spacing={2}>
            <Stack sx={{ mb: 2 }}>
              {org?.uuid && (
                <UploadAvatarImage
                  uuid={org.uuid}
                  token={token}
                  type="avatar"
                />
              )}
            </Stack>
            {org?.uuid && <UploadBannerImage uuid={org.uuid} token={token} />}

            {org && <AdminOrgForm data={org} />}
          </Stack>
        </Grid>
        <Grid item xl={4} md={4} sm={12} xs={12}>
          <Typography variant="h6" align="center" sx={{ my: 4 }}>
            Events
          </Typography>

          <List>
            {org?.Events &&
              org?.Events?.map((event) => {
                return (
                  <div key={event.uuid}>
                    <ListItemButton
                      onClick={() => router.push(`/editEvent/${event.uuid}`)}>
                      <ListItem
                        alignItems="flex-start"
                        secondary={
                          <IconButton edge="end" aria-label="delete">
                            <ModeEditTwoToneIcon />
                          </IconButton>
                        }>
                        <ListItemAvatar>
                          <Avatar
                            alt="Remy Sharp"
                            src={`${process.env.REACT_APP_IMAGE_URL}/${event?.banner}`}
                          />
                        </ListItemAvatar>
                        <ListItemText primary={event?.name} />
                      </ListItem>
                    </ListItemButton>
                    <Divider />
                  </div>
                );
              })}
          </List>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(AdminEditOrg);
