import { Grid, Slider, Typography, Box } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import MuiInput from "@mui/material/Input";
// import { useFormikContext } from "formik";
import dayjs from "dayjs";
import DateSelectorWrapper from "../FormComponents/DateSelectorWrapper";
import { useFormikContext } from "formik";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import weekOfYear from "dayjs/plugin/weekOfYear";

const MonthlyOcc = ({ setOccurrences, capacity }) => {
  const { values, setFieldValue } = useFormikContext();

  const [value, setValue] = useState(1);

  const [occArray, setOccArray] = useState([]);

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    if (value < 0) {
      setValue(0);
    } else if (value > 12) {
      setValue(12);
    }
  };

  dayjs.extend(isSameOrAfter);
  dayjs.extend(weekOfYear);

  useEffect(() => {
    if (!values.startDate) {
      return;
    }

    setFieldValue("daysOfTheWeek", values.startDate.format("dddd"));

    const generateOccurrences = (startDate) => {
      let occurrences = [];
      const getDayOfTheWeek = dayjs(startDate).day();
      const weekOfTheMonth =
        dayjs(startDate).week() - dayjs(startDate).startOf("month").week() + 1;

      const numberOfMonths = value;

      let firstOcc = dayjs(startDate);

      for (let i = 0; i < numberOfMonths; i++) {
        let occ = firstOcc.add(i, "month");

        const week = dayjs(occ).week() - dayjs(occ).startOf("month").week() + 1;

        if (week !== weekOfTheMonth) {
          occ = occ.add(weekOfTheMonth - week, "week");
        }

        if (getDayOfTheWeek !== occ.day()) {
          occ = occ.add(getDayOfTheWeek - occ.day(), "day");
        }

        occurrences.push({
          occurrence: occ,
        });
      }

      return occurrences;
    };

    setOccArray(generateOccurrences(values.startDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, values.startDate]);

  useEffect(() => {
    setOccurrences(
      occArray.map((occur, index) => {
        return {
          startTime: dayjs(values.startTime),
          endTime: dayjs(values.endTime),
          occurrence: occur?.occurrence,
          daysOfTheWeek: occur?.occurrence.format("dddd"),
          capacity: values?.capacity,
          slots: values?.slots,
        };
      })
    );
  }, [
    occArray,
    setOccurrences,
    values.capacity,
    values.endTime,
    values.startTime,
    values.slots,
  ]);

  return (
    <>
      <Grid item xs={6}>
        <DateSelectorWrapper
          name="startDate"
          label="Select Day of the Month"
          minDate={values.startDate}
          maxDate={dayjs(values.startDate).add(12, "month")}
        />
      </Grid>

      <Grid item xs={12}>
        <Box>
          <Typography id="input-slider" gutterBottom>
            Number of months
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs>
              <Slider
                value={typeof value === "number" ? value : 0}
                onChange={handleSliderChange}
                aria-labelledby="input-slider"
                min={1}
                max={12}
              />
            </Grid>
            <Grid item>
              <Input
                value={value}
                size="small"
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputProps={{
                  step: 1,
                  min: 1,
                  max: 12,
                  type: "number",
                  "aria-labelledby": "input-slider",
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default MonthlyOcc;

const Input = styled(MuiInput)`
  width: 42px;
`;
