import { LinearProgress, Container, Grid, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EventsCard from "../../../Components/Containers/EventsCard";
import PageHeader from "../../../Components/Items/PageHeader";
import EventsSearchComp from "./EventsSearchComp";
import axios from "axios";
import { useInfiniteQuery } from "react-query";
import withAuth from "../../../Hoc/withAuth";

const fetchEvents = async ({
  pageParam = 1,
  rowsPerPage = 20,
  searchParams,
  token,
}) => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_PUBLIC_URL}/events/search?pageNumber=${pageParam}&limit=${rowsPerPage}${searchParams}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

const Events = ({ token }) => {
  const [values, setValues] = useState("");

  const [searchParams, setSearchParams] = useState("");

  console.log("searchParams", searchParams);

  const loadMoreRef = useRef();

  const [rowsPerPage] = React.useState(6);

  const [EventsData, setEventsData] = useState([]);

  useEffect(() => {
    let params = "";
    if (values?.field) {
      params += `&search=${encodeURIComponent(values.field)}`;
    }
    if (values?.tags?.length) {
      const tagsString = values.tags.join(",");
      params += `&searchTags=${encodeURIComponent(tagsString)}`;
    }
    setSearchParams(params);
  }, [values]);

  const { fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ["Events", { rowsPerPage, searchParams }],
    ({ pageParam = 0 }) =>
      fetchEvents({ pageParam, rowsPerPage, searchParams, token }),
    {
      getNextPageParam: (lastPage, allPages) => lastPage.nextPage ?? false,
      onSuccess: (data) => {
        setEventsData(data);
      },
    }
  );

  // Intersection Observer to trigger load more
  useEffect(() => {
    if (isLoading) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1 }
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => observer.disconnect();
  }, [isLoading, hasNextPage, fetchNextPage]);

  const handleClearSearch = () => {
    setValues("");
  };

  return (
    <>
      <Container maxWidth="lg">
        <PageHeader title="Events" />
        {isLoading && <LinearProgress />}
        <Grid container spacing={2} justifyContent="center">
          <Grid item xl={12} md={12} sm={12} xs={12}>
            <EventsSearchComp setValues={setValues} values={values} />
            {searchParams && (
              <Button onClick={handleClearSearch}>Clear Search</Button>
            )}
          </Grid>

          <Grid item xl={12} md={12} sm={12} xs={12}>
            {EventsData?.pages?.map((page, i) => (
              <Grid
                sx={{ mb: 0.5 }}
                container
                spacing={0.5}
                justifyContent="center"
                key={i}>
                {page?.rows.map((event, i) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                      xl={4}
                      key={event.uuid}>
                      <EventsCard token={token} key={event.uuid} data={event} />
                    </Grid>
                  );
                })}
              </Grid>
            ))}

            <div ref={loadMoreRef} style={{ height: "20px" }} />
            {isLoading && <div>Loading more...</div>}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default withAuth(Events);
