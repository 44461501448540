import React from "react";
import { useQuery } from "react-query";
import { CardMedia } from "@mui/material";
import withAuth from "../../Hoc/withAuth";

const BannerImage = ({ token, uuid, height }) => {
  const { data, isLoading } = useQuery(["image", uuid], async () => {
    const res = await fetch(
      `${process.env.REACT_APP_PUBLIC_URL}/images/${uuid}/type/banner`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return res.json();
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data?.file_name === "default.jpg") {
    return (
      <CardMedia
        component="img"
        height={height ? height : 350}
        image={`${process.env.REACT_APP_IMAGE_URL}/default.jpg`}
        alt="Event banner"
      />
    );
  }

  return (
    <div>
      <CardMedia
        component="img"
        height={height ? height : 350}
        image={`${process.env.REACT_APP_PUBLIC_URL}/images/file/${data?.file_name}`}
        alt="Event banner"
      />
    </div>
  );
};

export default withAuth(BannerImage);
