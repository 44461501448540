import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  DialogActions,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import withAuth from "../../../Hoc/withAuth";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../FormComponents/TextfieldWrapper";
import SelectWrapper from "../FormComponents/Select";
import * as yup from "yup";
import { requestUserToAssociate } from "../../../Redux/actions/OrgAssociationsActions";

const OrgRequestUserToAssociate = ({ orgId }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const orgAssociations = useSelector((state) => state.orgAssociations);

  const { error } = orgAssociations;

  return (
    <div>
      <Button onClick={handleClickOpen}>Associate New User</Button>
      <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
        <DialogTitle variant="h6" gutterBottom>
          Request a user to be associated with your organization
        </DialogTitle>

        <DialogContent>
          <Formik
            initialValues={{
              org_id: orgId,
              userEmail: "",
              type: "",
            }}
            validationSchema={yup.object({
              org_id: yup.string().required("Org Id is required"),
              userEmail: yup
                .string()
                .email("Email is not valid")
                .required("Email is required"),
              type: yup.string().required("Type is required"),
            })}
            onSubmit={(values) => {
              dispatch(requestUserToAssociate(values));
              handleClose();
            }}>
            {() => {
              return (
                <Form>
                  <Typography variant="body2" gutterBottom>
                    Ambassadors are users who have been approved by the
                    organization to represent the organization and its events.
                  </Typography>
                  <Typography variant="body2" gutterBottom>
                    The user Needs To confirm the request.
                  </Typography>
                  <Stack spacing={2}>
                    <TextfieldWrapper
                      label="Email"
                      name="userEmail"
                      type="email"
                    />
                    <SelectWrapper
                      label="Type"
                      name="type"
                      options={{
                        user: "user",
                        ambassador: "ambassador",
                        teacher: "teacher",
                        student: "student",
                      }}
                    />
                  </Stack>
                  <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button variant="contained" color="secondary" type="submit">
                      Send Request
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
          {error && <Alert severity="error">{error}</Alert>}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default withAuth(OrgRequestUserToAssociate);
