import React, { useEffect } from "react";
import DateRangeTwoToneIcon from "@mui/icons-material/DateRangeTwoTone";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import { useDispatch } from "react-redux";
import { getMyRelations } from "../../../Redux/actions/Relations";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DrawerListItem from "../Components/DrawerListItem";
import GlobalDrawerItems from "./GlobalDrawerItems";

const ParentDrawer = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyRelations());
  }, [dispatch]);

  return (
    <div>
      <DrawerListItem
        name="Dashboard"
        path="parentDashboard"
        Icon={DashboardIcon}
      />
      <GlobalDrawerItems />
      <DrawerListItem
        name="Bookings"
        path="bookings"
        Icon={DateRangeTwoToneIcon}
      />
      <DrawerListItem
        name="Stats"
        path="ParentViewStudentStats"
        Icon={QueryStatsIcon}
      />
    </div>
  );
};

export default ParentDrawer;
