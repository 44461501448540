import React from "react";
import EditEvent from "../Screens/Pages/Events/EditEvent";
import EditOccurrence from "../Screens/Pages/Events/EditOccurrence";
import EventAdminView from "../Screens/Pages/Events/EventAdminView";
import EventJoin from "../Screens/Pages/Events/EventJoin";
import MyEvents from "../Screens/Pages/Events/MyEvents";
import OccurrencePage from "../Screens/Pages/Events/OccurrencePage";
import OrgStats from "../Screens/Pages/Orgs/OrgStats";
import GlobalPrivateRoutes from "./GlobalPrivateRoutes";
import PrivateRoute from "./PrivateRoute";
import PublicRoutes from "./PublicRoutes";
import SchoolAdmin from "../Screens/Pages/School/SchoolAdmin";
import SchoolAssociations from "../Screens/Pages/School/SchoolAssociations";
import SchoolRecourses from "../Screens/Pages/School/SchoolRecourses";
import SchoolStudentAdmin from "../Screens/Pages/School/SchoolStudentAdmin";
import SchoolAdminDashBoard from "../Screens/Pages/SchoolAdminDashBoard";
import SchoolUserDashBoard from "../Screens/Pages/SchoolUserDashBoard";
import SchoolUserAssociation from "../Screens/Pages/Users/SchoolUserAssociation";
import SchoolViewStudent from "../Screens/Pages/School/SchoolViewStudent";
import OrgAdminDashboard from "../Screens/Pages/OrgAdminDashboard";
import OrgUserAssociations from "../Screens/Pages/Users/OrgUserAssociations";
import SchoolReports from "../Screens/Pages/School/SchoolReports";
// import AddBulkStudents from "../Screens/Pages/School/AddBulkStudents";
import SchoolManageGradesAndClasses from "../Screens/Pages/School/SchoolManageGradesAndClasses";
import BulkAddedStudentsList from "../Screens/Pages/School/BulkAddedStudentsList";
import SchoolStudentCreation from "../Screens/Pages/School/SchoolStudentCreation";

const OrgRoutes = () => {
  return (
    <>
      <PrivateRoute path="/OrgAdminDashboard" exact>
        <OrgAdminDashboard />
      </PrivateRoute>
      <PrivateRoute path="/OrgUserAssociations" exact>
        <OrgUserAssociations />
      </PrivateRoute>
      <PrivateRoute path="/myEvents/:id" exact>
        <MyEvents />
      </PrivateRoute>
      <PrivateRoute path="/editEvent/:id" exact>
        <EditEvent />
      </PrivateRoute>
      <PrivateRoute path="/EventAdminView/:id" exact>
        <EventAdminView />
      </PrivateRoute>
      <PrivateRoute path="/OccurrencePage/:id" exact>
        <OccurrencePage />
      </PrivateRoute>
      <PrivateRoute path="/EditOccurrence/:id" exact>
        <EditOccurrence />
      </PrivateRoute>
      <PrivateRoute path="/EventJoin/:id" exact>
        <EventJoin />
      </PrivateRoute>
      <PrivateRoute path="/orgStats" exact>
        <OrgStats />
      </PrivateRoute>
      <PrivateRoute path="/schoolUserDashboard" exact>
        <SchoolUserDashBoard />
      </PrivateRoute>
      <PrivateRoute path="/SchoolAdminDashBoard" exact>
        <SchoolAdminDashBoard />
      </PrivateRoute>
      <PrivateRoute path="/SchoolAdmin" exact>
        <SchoolAdmin />
      </PrivateRoute>
      <PrivateRoute path="/orgUserAssociation" exact>
        <SchoolUserAssociation />
      </PrivateRoute>
      <PrivateRoute path="/SchoolAdmin/recourses/:id" exact>
        <SchoolRecourses />
      </PrivateRoute>
      <PrivateRoute path="/SchoolAdmin/SchoolStudentCreation/:id" exact>
        <SchoolStudentCreation />
      </PrivateRoute>
      <PrivateRoute path="/SchoolAdmin/students/:id" exact>
        <SchoolStudentAdmin />
      </PrivateRoute>
      {/* <PrivateRoute path="/SchoolAdmin/students/:id/addBulkStudents" exact>
        <AddBulkStudents />
      </PrivateRoute> */}
      <PrivateRoute
        path="/SchoolAdmin/students/:id/BulkAddedStudentsList"
        exact>
        <BulkAddedStudentsList />
      </PrivateRoute>
      <PrivateRoute path="/SchoolAdmin/students/:id/GradesAndClasses" exact>
        <SchoolManageGradesAndClasses />
      </PrivateRoute>
      <PrivateRoute path="/SchoolAdmin/student/:id" exact>
        <SchoolViewStudent />
      </PrivateRoute>
      <PrivateRoute path="/SchoolAdmin/SchoolReports/:id" exact>
        <SchoolReports />
      </PrivateRoute>
      <PrivateRoute path="/orgs/:id/associations" exact>
        <SchoolAssociations />
      </PrivateRoute>

      <GlobalPrivateRoutes />
      <PublicRoutes />
    </>
  );
};

export default OrgRoutes;
