import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Skeleton,
  Typography,
  CardActions,
} from "@mui/material";
import React from "react";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ShareIcon from "@mui/icons-material/Share";
import { useHistory } from "react-router-dom";
import TagListComp from "./TagListComp";
import BannerImage from "../ImageUploaders/BannerImage";
import AvatarImage from "../ImageUploaders/AvatarImage";
import withAuth from "../../Hoc/withAuth";

const EventsCard = (props) => {
  const { data, token } = props;
  const history = useHistory();

  return (
    <>
      {data && (
        <Card>
          <CardActionArea onClick={() => history.push(`/events/${data?.uuid}`)}>
            {data?.banner ? (
              <BannerImage uuid={data?.uuid} token={token} />
            ) : (
              <Skeleton />
            )}
            <CardHeader
              sx={{ mb: 0, pb: 0 }}
              avatar={
                <AvatarImage
                  uuid={data?.Org?.uuid}
                  size="small"
                  token={token}
                />
              }
              title={
                <Typography color="textPrimary">{data?.Org?.name}</Typography>
              }
              subheader={data?.Org?.orgType}
            />

            <CardContent>
              <Typography noWrap variant="h6">
                {data?.name}
              </Typography>
              <Typography
                gutterBottom
                variant="subtitle1"
                noWrap
                color="textPrimary">
                {data?.description}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions sx={{ height: "5vh" }}>
            {data.eventTags && data.eventTags.length > 0 && (
              <TagListComp orgTags={data.eventTags} />
            )}
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default withAuth(EventsCard);
