import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
// import Calender from "../../../Components/Calender";
import { getEventAdminRequest } from "../../../Redux/actions/Events";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box } from "@mui/system";
import {
  DataGridPremium,
  useGridSelector,
  useGridApiContext,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
} from "@mui/x-data-grid-premium";

const dayInMonthComparator = (v1, v2) => moment(v1) - moment(v2);

function CustomDetailPanelToggle(props) {
  const { id, value: isExpanded } = props;
  const apiRef = useGridApiContext();

  // To avoid calling ´getDetailPanelContent` all the time, the following selector
  // gives an object with the detail panel content for each row id.
  const contentCache = useGridSelector(
    apiRef,
    gridDetailPanelExpandedRowsContentCacheSelector
  );

  // If the value is not a valid React element, it means that the row has no detail panel.
  const hasDetail = React.isValidElement(contentCache[id]);

  return (
    <IconButton
      size="small"
      tabIndex={-1}
      disabled={!hasDetail}
      color={hasDetail ? "secondary" : "default"}
      aria-label={isExpanded ? "Close" : "Open"}>
      <ExpandMoreIcon
        sx={{
          transform: `rotateZ(${isExpanded ? 180 : 0}deg)`,
          transition: (theme) =>
            theme.transitions.create("transform", {
              duration: theme.transitions.duration.shortest,
            }),
        }}
      />
    </IconButton>
  );
}

const EventAdminView = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const eventAdmin = useSelector((state) => state.eventAdmin);
  const { error, loading, data } = eventAdmin;

  useEffect(() => {
    dispatch(getEventAdminRequest({ id }));
  }, [dispatch, id]);

  const columns = [
    {
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: (params) => (
        <CustomDetailPanelToggle id={params.id} value={params.value} />
      ),
    },
    {
      field: "Bookings",
      headerName: "bookings",
      width: 100,
      renderCell: (value) => {
        if (value.row.bookings[0] !== null) {
          return <Chip label={value.row.bookings.length} color="primary" />;
        }
      },
    },
    {
      field: "occurrence",
      headerName: "Occurrence",
      valueGetter: (params) => params.row.occurrence,
      type: "date",
      sortComparator: dayInMonthComparator,
      width: 200,
      renderCell: (params) => {
        // color if the event is past
        return (
          <Typography
            variant="body2"
            sx={{
              color: moment(params.value).isBefore(moment()) ? "red" : "black",
            }}>
            {moment(params.value).format("DD-MMMM-YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "capacity",
      headerName: "Capacity",
      width: 100,
    },
    {
      field: "totalTime",
      headerName: "Total Time",
      width: 100,
    },
    {
      field: "startTime",
      headerName: "Start Time",
      width: 100,
      valueGetter: (value) => moment(value.row.startTime).format("HH:mm"),
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 100,
      valueGetter: (value) => moment(value.row.startTime).format("HH:mm"),
    },
    {
      field: "bookingCloses",
      headerName: "Booking Closes",
      width: 200,
      valueGetter: (value) =>
        moment(value.row.bookingCloses).format("DD/MM/YYYY HH:mm"),
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 120,
      valueGetter: (value) => moment(value.row.createdAt).format("DD/MM/YYYY"),
    },
    {
      field: "updatedAt",
      headerName: "Updated At",
      width: 120,
      valueGetter: (value) => moment(value.row.updatedAt).format("DD/MM/YYYY"),
    },
    {
      field: "Edit Occurrence",
      headerName: "Edit Occurrence",
      width: 120,
      renderCell: (params) => {
        return (
          <Tooltip title="Coming Soon">
            <Button disabled variant="contained">
              Edit
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Container maxWidth="xl">
        <Grid container>
          {error && <Alert severity="error">{error}</Alert>}
        </Grid>
        {data && data?.occurrences && data?.occurrences.length > 0 && (
          <DataGridPremium
            autoHeight
            rows={data.occurrences}
            getRowId={(row) => row.uuid}
            initialState={{
              ...data.initialState,
              sorting: {
                ...data.initialState?.sorting,
                sortModel: [
                  {
                    field: "occurrence",
                    sort: "asc",
                  },
                ],
              },
            }}
            getDetailPanelContent={({ row }) => {
              if (row.bookings && row.bookings[0] !== null) {
                return (
                  <Container>
                    <PanelContent bookings={row?.bookings} />
                  </Container>
                );
              } else
                <>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h6">No Bookings</Typography>
                  </Box>
                </>;
            }}
            getDetailPanelHeight={({ row }) => "auto"}
            columns={columns}
          />
        )}
      </Container>
    </>
  );
};

export default EventAdminView;

const PanelContent = ({ bookings }) => {
  return (
    <>
      {bookings?.map((booking, index) => {
        return (
          <Card sx={{ my: 1 }} key={index}>
            <CardContent>
              <Stack mb={2} direction="row" justifyContent="space-between">
                <Avatar
                  size="large"
                  alt={`${booking?.username} Profile Picture`}
                  src={
                    booking?.User?.avatar
                      ? `${process.env.REACT_APP_IMAGE_URL}/${booking?.User?.avatar}`
                      : null
                  }
                />
                <Typography variant="h6">{booking?.username}</Typography>
                <Chip
                  label={booking?.status}
                  variant="contained"
                  color={
                    booking?.status === "active"
                      ? "primary"
                      : booking?.status === "completed"
                      ? "success"
                      : "error"
                  }
                />
              </Stack>

              <Typography variant="body1" color="text.secondary">
                {moment(booking?.eventDate).format("dddd, MMMM Do YYYY")}
              </Typography>
              <Divider sx={{ my: 1 }} />
              <Typography color="text.secondary" variant="body1">
                Time Slots Booked
              </Typography>
              <Grid container spacing={2}>
                {booking?.timeSlots &&
                  booking?.timeSlots.map((time, index) => {
                    return (
                      <Grid item key={index} sx={{ mt: 2 }}>
                        <Chip label={time.slot} />
                      </Grid>
                    );
                  })}
              </Grid>
            </CardContent>
            <>
              <Box
                p={1}
                display="flex"
                align="center"
                justifyContent="space-between">
                <Chip
                  label={
                    <Typography variant="caption" color="text.secondary">
                      Created at :{" "}
                      {moment(booking?.createdAt).format("dddd, MMMM Do YYYY")}
                    </Typography>
                  }
                  color="default"
                  variant="outlined"
                />
              </Box>
            </>
          </Card>
        );
      })}
    </>
  );
};
