import React from "react";
import { Grid, Card, CardContent, Stack } from "@mui/material";
import AvatarImageUploader from "../../Components/Forms/AvatarImageUploader";
import SchoolAdminProfileForm from "../../Components/Forms/School/SchoolAdminProfileForm";
import withAuth from "../../Hoc/withAuth";

const CompleteProfile = ({ user }) => {
  return (
    <Grid container justifyContent="center" alignItems="flex-start" spacing={4}>
      <Grid item xs={12} md={12} sm={12} lg={12} xl={12}>
        <Card>
          <CardContent>
            <Stack
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <AvatarImageUploader
                type="profile"
                image={user?.avatar}
                url="/users/avatar"
              />
            </Stack>
            <SchoolAdminProfileForm data={user} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default withAuth(CompleteProfile);
