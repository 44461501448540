import axios from "axios";
import { url } from "./URL";

export const setHoursRequest =
  ({ hourGoals }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "REQUEST_SET_HOURS_STUDENT" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${url}/stats/setHours`,
        { hourGoals },
        config
      );

      dispatch({ type: "SUCCESS_SET_HOURS_STUDENT", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_SET_HOURS_STUDENT",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getTotalStats =
  ({ studentId }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "REQUEST_STATS_STUDENT" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(`${url}/stats/${studentId}`, config);

      dispatch({ type: "SUCCESS_STATS_STUDENT", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_STATS_STUDENT",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getAllStats = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "REQUEST_ALL_STATS_STUDENT" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${url}/stats/${id}`, config);

    dispatch({ type: "GET_ALL_STATS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_ALL_STATS_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
