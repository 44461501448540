export const StatsReducer = (
  state = { loading: false, statsData: [], hoursSetSuccess: false },
  action
) => {
  switch (action.type) {
    case "REQUEST_STATS_STUDENT":
      return { ...state, loading: true };
    case "SUCCESS_STATS_STUDENT":
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      };
    case "FAIL_STATS_STUDENT":
      return { ...state, loading: false, error: action.payload };

    case "GET_ALL_STATS_REQUEST":
      return { ...state, loading: true };
    case "GET_ALL_STATS_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        statsData: [
          ...state.statsData.filter(
            (stat) => stat.userId !== action.payload.stats.userId
          ),
          action.payload.stats,
        ],
      };
    case "GET_ALL_STATS_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "REQUEST_SET_HOURS_STUDENT":
      return { ...state, loading: true };
    case "SUCCESS_SET_HOURS_STUDENT":
      return {
        ...state,
        loading: false,
        success: true,
        hoursSetSuccess: true,
        updatedHours: action.payload,
      };
    case "FAIL_SET_HOURS_STUDENT":
      return { ...state, loading: false, error: action.payload };

    case "RESET_STATS_STUDENT":
      return { loading: false };
    case "GET_ALL_STATS_RESET":
      return { loading: false };

    default:
      return state;
  }
};
