import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Card, CardHeader, Divider, IconButton, Slide } from "@mui/material";

import MoreVertIcon from "@mui/icons-material/MoreVert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PreviewModal({ formData }) {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="contained" onClick={() => setOpen(!open)}>
        Preview
      </Button>

      <Dialog
        maxWidth="md"
        fullWidth
        sx={{ minHeight: "60vh" }}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <DialogTitle>Add Resource</DialogTitle>
        <DialogContent>
          <Card>
            <CardHeader
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title={formData?.title.length > 0 ? formData?.title : ""}
              subheader={new Date().toISOString()}
            />
            <Divider light />
            {/* <CardContent>
              {HTMLReactParser(formData?.data ? formData?.data : "")}
            </CardContent> */}
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
