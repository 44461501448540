import axios from "axios";
import { url } from "./URL";

export const AddBookingRequest =
  ({ eventId, occurrenceId, user, timeSlots, bookedTime, username, avatar }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "ADD_BOOKING_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${url}/events/${eventId}/bookings`,
        { occurrenceId, user, timeSlots, bookedTime, username, avatar },
        config
      );

      dispatch({ type: "ADD_BOOKING_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "ADD_BOOKING_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getUserBookingsRequest =
  (id, params) => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_USER_BOOKING_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${url}/bookings/${id}${params ? params : ""}`,
        config
      );

      dispatch({ type: "GET_USER_BOOKING_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_USER_BOOKING_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSingleBooking =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_SINGLE_BOOKING_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/bookings/${id}/user`,
        config
      );

      dispatch({ type: "GET_SINGLE_BOOKING_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_SINGLE_BOOKING_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getParentStudentsBookings = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_PARENT_STUDENTS_BOOKING_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${url}/bookings/parentStudentBookings`,
      config
    );

    dispatch({ type: "GET_PARENT_STUDENTS_BOOKING_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_PARENT_STUDENTS_BOOKING_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const cancelBookingRequest = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "CANCEL_BOOKING_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(`${url}/bookings/${id}`, { id }, config);

    dispatch({ type: "CANCEL_BOOKING_SUCCESS", payload: data.data });
  } catch (error) {
    dispatch({
      type: "CANCEL_BOOKING_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getActiveBookings = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_ACTIVE_BOOKING_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${url}/bookings/active/${id}`,
      { id },
      config
    );

    dispatch({ type: "GET_ACTIVE_BOOKING_SUCCESS", payload: data.data });
  } catch (error) {
    dispatch({
      type: "GET_ACTIVE_BOOKING_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
