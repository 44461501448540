import AccountCircle from "@mui/icons-material/AccountCircle";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import React, { useEffect } from "react";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";

import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getMyRelations } from "../../../Redux/actions/Relations";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { getMyRequests } from "../../../Redux/actions/OrgAssociationsActions";

const SchoolUserDrawer = () => {
  const router = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyRelations());
    dispatch(getMyRequests());
  }, [dispatch]);

  return (
    <div>
      <ListItem
        button
        onClick={(event) => {
          router.push("/schoolUserDashboard");
        }}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary={"Dashboard"} />
      </ListItem>
      <ListItem
        button
        onClick={(event) => {
          router.push("/profile");
        }}>
        <ListItemIcon>
          <AccountCircle />
        </ListItemIcon>
        <ListItemText primary={"My Profile"} />
      </ListItem>

      <ListItem
        button
        onClick={(event) => {
          router.push("/orgUserAssociation");
        }}>
        <ListItemIcon>
          <AddBusinessIcon />
        </ListItemIcon>
        <ListItemText primary={"Association"} />
      </ListItem>
    </div>
  );
};

export default SchoolUserDrawer;
