import React from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { Alert, Button, Grid } from "@mui/material";
import TextfieldWrapper from "../../../Components/Forms/FormComponents/TextfieldWrapper";
import MultiSelectWrapper from "../../../Components/Forms/FormComponents/MultiSelectWrapper";
import AlertPopup from "../../../Components/Items/AlertPopup";
import withAuth from "../../../Hoc/withAuth";
import axios from "axios";
import { useMutation } from "react-query";
import SelectFieldWrapper from "../../../Components/Forms/FormComponents/SelectFieldWrapper";

const AdminOrgForm = ({ data, token }) => {
  const editOrg = useSelector((state) => state.editOrg);
  const { loading, success, error } = editOrg;

  console.log("This data", data);

  const updateOrg = useMutation((values) => {
    return axios.put(
      `${process.env.REACT_APP_PUBLIC_URL}/admin/orgs/${data.uuid}`,
      values,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  });

  return (
    <>
      <Formik
        initialValues={{
          uuid: data.uuid,
          name: data.name || "",
          description: data.description || "",
          address: data.address || "",
          aboutUs: data.aboutUs || [
            { type: "paragraph", children: [{ text: "" }] },
          ],
          phone: data.telephone || "",
          location: data.location || {},
          email: data.email || "",
          website: data.website || "",
          tags: data.tags || [],
          orgType: data.orgType || "",
          registrationNumber: data.registrationNumber || "",
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateOrg.mutate(values);
          console.log("Submiited", values);
        }}>
        {({ values }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextfieldWrapper name="name" label="Name" />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper
                    name="description"
                    label="Description"
                    helperText="A short description of your organization..."
                  />
                </Grid>
                <Grid item xs={12}>
                  {/* <DraftEditor
                    setData={setDraftData}
                    initData={data.aboutUs || ""}
                  /> */}
                  {/* <MultiLineFormWrapper
                    name="aboutUs"
                    label="About Us"
                    helperText="Tell the community all about your organization..."
                  /> */}
                </Grid>

                <Grid item xs={6}>
                  <TextfieldWrapper name="email" label="Email" />
                </Grid>
                <Grid item xs={6}>
                  <TextfieldWrapper name="phone" label="Telephone" />
                </Grid>
                <Grid item xs={12}>
                  <TextfieldWrapper name="website" label="Website" />
                </Grid>
                <Grid item xs={4}>
                  <TextfieldWrapper
                    name="registrationNumber"
                    label="Registration Number"
                  />
                </Grid>

                <Grid item xs={4}>
                  <SelectFieldWrapper
                    name="orgType"
                    label="Organization Type"
                    options={[
                      { value: "charity", label: "charity" },
                      { value: "school", label: "school" },
                    ]}
                  />
                </Grid>

                <Grid item xs={8}>
                  <MultiSelectWrapper name="tags" />
                </Grid>

                <Grid item xs={12} sx={{ mt: 4 }}>
                  {loading ? (
                    <Button
                      fullWidth
                      disabled
                      variant="contained"
                      color="primary"
                      type="submit">
                      Saving....
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit">
                      Save Edit
                    </Button>
                  )}
                </Grid>
                {error && (
                  <Grid item xs={12}>
                    <Alert severity="error">{error}</Alert>
                  </Grid>
                )}
                {success && (
                  <>
                    <AlertPopup
                      message="Organization edited successfully"
                      open={success}
                      severity="success"
                    />
                  </>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default withAuth(AdminOrgForm);
