import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextfieldWrapper from "./FormComponents/TextfieldWrapper";
import DOBPicker from "./FormComponents/DobPicker";
import { Stack, Typography } from "@mui/material";
import UploadAvatarImage from "../ImageUploaders/UploadAvatarImage";
import AlertPopup from "../Items/AlertPopup";

const UserProfile = ({ data, updateMe }) => {
  return (
    <Container>
      <Grid item xs={12}>
        <Stack
          sx={{
            width: "100%",
          }}
          direction="row"
          color="GrayText"
          alignContent="center"
          justifyContent="flex-start">
          <UploadAvatarImage uuid={data?.uuid} size="large" />
        </Stack>
        <Typography variant="caption">
          Upload a profile picture to help others recognize you.
        </Typography>
      </Grid>

      <Formik
        initialValues={{
          name: data.name || "",
          surname: data.surname || "",
          email: data.email || "",
          username: data.username || "",
          number: data.number || "",
          dob: data.dob || "",
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
          surname: Yup.string().required("Surname is required"),
          email: Yup.string()
            .email("Email is invalid")
            .required("Email is required"),
          username: Yup.string().required("Username is required"),
          number: Yup.string()
            .transform((value) => value.replace(/\D/g, "")) // Remove non-digit characters before validation
            .matches(
              /^0\d{9}$/,
              "Must be a valid South African cellphone number"
            )
            .required("Cellphone number is required"),
          dob: Yup.string().required("Date of birth is required"),
        })}
        enableReinitialize={true}
        onSubmit={(values) => {
          updateMe.mutate(values);
        }}>
        {() => {
          return (
            <Form>
              <Grid sx={{ mt: 2 }} container spacing={2}>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper label="Username" name="username" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextfieldWrapper label="Name" name="name" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextfieldWrapper label="Surname" name="surname" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextfieldWrapper label="Email" name="email" />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextfieldWrapper label="Number" name="number" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DOBPicker name="dob" />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>

      <AlertPopup
        severity="success"
        message="Profile updated successfully"
        open={updateMe.isSuccess}
      />
      <AlertPopup
        severity="error"
        message="Failed to update profile"
        open={updateMe.isError}
      />
    </Container>
  );
};

export default UserProfile;
