import {
  Alert,
  Button,
  CardContent,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import PageHeader from "../../Components/Items/PageHeader";
import { useSelector } from "react-redux";
import SchoolUserProcess from "../../Components/LandingProcess/SchoolUserProcess";

import { useHistory } from "react-router-dom";
import { getOrgAssociationsList } from "../../Redux/actions/OrgAssociationsActions";
import { useDispatch } from "react-redux";

const SchoolUserDashBoard = () => {
  const router = useHistory();

  const dispatch = useDispatch();

  const orgAssociations = useSelector((state) => state.orgAssociations);

  const { associations } = orgAssociations;

  useEffect(() => {
    dispatch(getOrgAssociationsList());
  }, [dispatch]);

  return (
    <Container>
      <PageHeader
        title="School User DashBoard"
        description="School User DashBoard"
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography variant="h6">Associations</Typography>

          {associations && associations.length > 0 ? (
            associations.map((request, index) => {
              return (
                <Stack key={request.uuid} spacing={1}>
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography align="center" variant="button">
                        {request.Org.email}
                      </Typography>

                      <Alert severity={request.approved ? "success" : "error"}>
                        {request.approved ? "Approved" : "Pending"}
                      </Alert>

                      {request.approved && (
                        <Button
                          onClick={() =>
                            router.push(
                              `/SchoolAdmin/students/${request.Org.uuid}`
                            )
                          }>
                          Manage Students
                        </Button>
                      )}
                    </Stack>
                  </CardContent>
                </Stack>
              );
            })
          ) : (
            <SchoolUserProcess />
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default SchoolUserDashBoard;
