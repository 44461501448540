import { Card, CardActionArea, Typography } from "@mui/material";

import React from "react";
import { useHistory } from "react-router";

const AdminCard = ({ title, link, Icon, admin }) => {
  const router = useHistory();

  return (
    <>
      <Card sx={admin && { border: "solid 1px grey" }}>
        <CardActionArea
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            py: 5,
          }}
          onClick={() => {
            router.push(`${link}`);
          }}>
          <Icon sx={{ fontSize: 80, mb: 1 }} />
          <Typography color="textPrimary">{title}</Typography>
        </CardActionArea>
      </Card>
    </>
  );
};

export default AdminCard;
