const initialState = {
  GradeReportData: [],
  loading: false,
  error: null,
  success: null,
};

const SchoolReportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SCHOOL_REPORTS_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_SCHOOL_REPORTS_SUCCESS":
      return {
        ...state,
        loading: false,
        GradeReportData: action.payload,
      };
    case "GET_SCHOOL_REPORTS_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "CREATE_CERTIFICATES_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "CREATE_CERTIFICATES_SUCCESS":
      return {
        ...state,
        loading: false,
        success: true,
        CreatedRequest: action.payload,
      };
    case "CREATE_CERTIFICATES_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case "GET_CERTIFICATES_REQUEST":
      return {
        ...state,
        loading: true,
      };
    case "GET_CERTIFICATES_SUCCESS":
      return {
        ...state,
        loading: false,
        CertificateData: action.payload,
      };
    case "GET_CERTIFICATES_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default SchoolReportsReducer;
