import axios from "axios";
import { url } from "./URL";

export const getAssRequest = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_ASS_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(`${url}/users/association`, config);
    dispatch({ type: "GET_ASS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_ASS_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const updateStudentRequest =
  ({ formData }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "REQUEST_EDIT_STUDENT" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${url}/users/editStudent`,
        formData,
        config
      );

      dispatch({ type: "SUCCESS_EDIT_STUDENT", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_EDIT_STUDENT",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
export const parentAddStudent =
  ({ formData }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "REQUEST_ADD_STUDENT" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.post(
        `${url}/users/addStudent`,
        formData,
        config
      );

      dispatch({ type: "SUCCESS_ADD_STUDENT", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_ADD_STUDENT",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const parentAssSchoolRequest =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "REQUEST_ASS_SCHOOL" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.get(
        `${url}/schools/parentSchool/${id}`,
        config
      );

      dispatch({ type: "SUCCESS_ASS_SCHOOL", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_ASS_SCHOOL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
