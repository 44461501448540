import {
  Alert,
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import SchoolUserProcess from "../../../Components/LandingProcess/SchoolUserProcess";
import { useDispatch, useSelector } from "react-redux";
import { getMyRequests } from "../../../Redux/actions/OrgAssociationsActions";
import { useHistory } from "react-router-dom";

const SchoolUserAssociation = () => {
  const dispatch = useDispatch();

  const router = useHistory();

  useEffect(() => {
    dispatch(getMyRequests());
  }, [dispatch]);

  const orgAssociations = useSelector((state) => state.orgAssociations);

  const { userRequestToAssociate } = orgAssociations;

  return (
    <Container>
      <SchoolUserProcess />
      <Grid container spacing={2}>
        {userRequestToAssociate &&
          userRequestToAssociate.length > 0 &&
          userRequestToAssociate.map((orgAssociation) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={orgAssociation.uuid}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        src={`${process.env.REACT_APP_IMAGE_URL}/${orgAssociation.Org.avatar}`}
                        aria-label={orgAssociation.Org.name}
                      />
                    }
                    title={orgAssociation.Org.name}
                    subheader={orgAssociation.Org.email}
                  />
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography align="center" variant="button">
                        {orgAssociation.userType}
                      </Typography>

                      <Alert
                        severity={
                          orgAssociation.approved ? "success" : "error"
                        }>
                        {orgAssociation.approved ? "Approved" : "Pending"}
                      </Alert>
                      {orgAssociation.approved && (
                        <Button
                          onClick={() =>
                            router.push(
                              `/SchoolAdmin/students/${orgAssociation.Org.uuid}`
                            )
                          }>
                          Manage Students
                        </Button>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};

export default SchoolUserAssociation;
