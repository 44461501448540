import React from "react";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../FormComponents/TextfieldWrapper";
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Stack,
  Alert,
} from "@mui/material";
import * as Yup from "yup";
// import MultiSelectWrapper from "../FormComponents/MultiSelectWrapper";
import { ExpandMore } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ContactsDialog from "../FormComponents/ContactsDialog";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

import MapAndSearchComponent from "../FormComponents/MapAndSearchComponent";
import AddTagsToOrg from "../Tags/AddTagsToOrg";
import ListOrgTags from "../Tags/ListOrgTags";
import { useQuery } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import MySlateEditor from "../MySlateEditor";

const EditSchoolForm = ({ data, UpdateOrg, token }) => {
  const getTags = useQuery({
    queryKey: ["orgTags", data.uuid],
    queryFn: () =>
      axios(`${process.env.REACT_APP_PUBLIC_URL}/tags/orgTags/${data.uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
  });

  return (
    <>
      <Formik
        initialValues={{
          name: data.name || "",
          description: data.description || "",
          aboutUs: [{ type: "paragraph", children: [{ text: "" }] }],
          location: {
            lat: data?.location?.lat || "",
            lng: data?.location?.lng || "",
          },
          email: data.email || "",
          telephone: data.telephone || "",
          website: data.website || "https://",
          registrationNumber: data.registrationNumber || "",
          orgType: "school",
          tags: data.tags || "",
          address: data?.address || "",
          contacts:
            data?.contacts && data?.contacts.length > 0 ? data.contacts : [],
        }}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
          description: Yup.string().required("Description is required"),

          email: Yup.string().email().required("Email is required"),

          tags: Yup.array().of(Yup.string().required("Tags is required")),
        })}
        enableReinitialize={true}
        onSubmit={(values) => {
          UpdateOrg.mutate({ id: data.uuid, values });
        }}>
        {({ values, setFieldValue, errors }) => {
          return (
            <Form>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography>Name</Typography>
                  <Typography variant="caption" color="GrayText">
                    Name of your School
                  </Typography>
                  <TextfieldWrapper name="name" />
                </Grid>
                <Grid item xs={12}>
                  <Typography>Description</Typography>
                  <Typography variant="caption" color="GrayText">
                    A short description of your School...
                  </Typography>

                  <TextfieldWrapper name="description" />
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">About Us</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Your &quot;About Us&quot; section is more than just
                        words; it&apos;s your introduction to potential charity
                        partners and a beacon for students seeking to make a
                        difference.
                      </Typography>
                      <Accordion sx={{ border: "none" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMore />}
                          aria-label="Expand"
                          aria-controls="-content"
                          id="-header">
                          <Typography variant="caption" color="text.secondary">
                            Not sure what to write? Click here for some tips!
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="h6" gutterBottom>
                            Why Share Your Story?
                          </Typography>
                          <Typography variant="body2" gutterBottom>
                            In the heart of every school is a unique story, a
                            tapestry woven from your ethos, achievements, and
                            community spirit. Our platform connects schools with
                            charities to foster meaningful community service
                            opportunities, enabling students to fulfill their
                            service hours with purpose and passion.
                          </Typography>

                          <Typography variant="h6" gutterBottom>
                            What to Include?
                          </Typography>

                          <ul>
                            <li>
                              <Typography variant="body2">
                                <strong>Your Mission:</strong> Share the core
                                values and objectives that drive your
                                institution. What is your school&apos;s vision
                                for community service and student development?
                              </Typography>
                            </li>
                            <li>
                              <Typography variant="body2">
                                <strong>Community Impact:</strong>
                                Highlight past initiatives or projects that
                                showcase your commitment to community service.
                                How have your students contributed to making a
                                positive impact?
                              </Typography>
                            </li>
                            <li>
                              <Typography variant="body2">
                                <strong>Unique Offerings:</strong>
                                Every school has something special to offer. Do
                                you have specific programs, clubs, or resources
                                dedicated to community service?
                              </Typography>
                            </li>
                            <li>
                              <Typography variant="body2">
                                <strong>Collaboration Goals:</strong>
                                Describe what you&apos;re looking for in a
                                charity partner and the types of projects or
                                events you&apos;re interested in hosting.
                              </Typography>
                            </li>
                          </ul>

                          <Typography variant="h6" gutterBottom>
                            Inspire Collaboration
                          </Typography>

                          <Typography variant="body2">
                            Your story sets the stage for future collaborations.
                            By providing a compelling narrative, you not only
                            showcase your school&apos;s dedication to social
                            responsibility but also attract charities that align
                            with your values and goals. This synergy creates
                            more meaningful and impactful service opportunities
                            for your students, fostering a culture of empathy,
                            engagement, and excellence.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <MySlateEditor
                    value={values.aboutUs}
                    onChange={(value) => {
                      setFieldValue("aboutUs", value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Card>
                    <CardContent>
                      <Typography variant="h6">Location</Typography>
                      <Typography variant="body2" color="text.secondary">
                        Start typing your address in the field below and select
                        the correct one from the dropdown suggestions. Our
                        Google-powered location lookup ensures an accurate and
                        seamless process, making it easier to pinpoint your
                        exact location. Ideal for ensuring precise event
                        coordination and connectivity.
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12}>
                  <MapAndSearchComponent />
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>

                <Grid item xs={12} md={6}>
                  <Typography>Main Email</Typography>
                  <Typography variant="caption" color="GrayText">
                    Email of your School
                  </Typography>
                  <TextfieldWrapper name="email" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>Main Telephone</Typography>
                  <Typography variant="caption" color="GrayText">
                    Telephone of your School
                  </Typography>

                  <TextfieldWrapper name="telephone" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>Website</Typography>
                  <Typography variant="caption" color="GrayText">
                    Website of your School
                  </Typography>

                  <TextfieldWrapper name="website" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography>Registration Number</Typography>
                  <Typography variant="caption" color="GrayText">
                    Registration Number of your School
                  </Typography>
                  <TextfieldWrapper name="registrationNumber" />
                </Grid>

                <Grid item xs={12}>
                  <Stack>
                    <Typography>Contacts</Typography>
                    <Typography variant="caption" color="GrayText">
                      Add a list of Contacts personnel for your School.
                    </Typography>
                  </Stack>
                  <ContactsDialog />
                  <ContactsTable
                    contacts={values.contacts}
                    setFieldValue={setFieldValue}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography>Causes</Typography>
                  <Typography variant="caption" color="GrayText">
                    Select some causes that best describe your School
                  </Typography>

                  <AddTagsToOrg
                    orgId={data.uuid}
                    OrgTags={getTags?.data?.data}
                  />
                </Grid>
                <Grid item xs={12}>
                  {getTags?.data?.data && getTags?.data?.data.length > 0 && (
                    <ListOrgTags
                      orgId={data.uuid}
                      OrgTags={getTags?.data?.data}
                    />
                  )}
                </Grid>
                {/* 
                <Grid item xs={12} md={6}>
                  <Typography>Tags</Typography>
                  <Typography variant="caption" color="GrayText">
                    Select some tags that best describe your School
                  </Typography>
                  <MultiSelectWrapper name="tags" />
                </Grid> */}

                <Grid item xs={12} sx={{ mt: 4 }}>
                  {UpdateOrg.isLoading ? (
                    <Button
                      fullWidth
                      disabled
                      variant="contained"
                      color="primary"
                      type="submit">
                      Saving....
                    </Button>
                  ) : (
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      type="submit">
                      Save
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12}>
                  {errors &&
                    Object.entries(errors).map(([key, value]) => {
                      return value ? (
                        <Alert key={key} severity="error">
                          {value}
                        </Alert>
                      ) : null;
                    })}
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default withAuth(EditSchoolForm);

const ContactsTable = ({ contacts, setFieldValue }) => {
  const removeContact = (index) => {
    const newContacts = contacts.filter((_, i) => i !== index);
    setFieldValue("contacts", newContacts);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Telephone</TableCell>
            <TableCell>Remove</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts?.map((row) => (
            <TableRow
              key={row?.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row?.name}
              </TableCell>
              <TableCell component="th" scope="row">
                {row?.email}
              </TableCell>
              <TableCell component="th" scope="row">
                {row?.telephone}
              </TableCell>
              <TableCell component="th" scope="row">
                <IconButton
                  aria-label="delete"
                  onClick={() => removeContact(contacts.indexOf(row))}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
