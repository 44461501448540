export const commonActionsReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "REQUEST_COMMON":
      return { loading: true, success: null };
    case "ADD_IMAGE_SUCCESS":
      return { ...state, loading: false, data: action.payload, success: true };
    case "FAIL_COMMON":
      return {
        ...state,
        loading: false,
        data: {},
        error: action.payload,
        success: false,
      };
    case "RESET_COMMON":
      return { data: {} };
    default:
      return state;
  }
};
