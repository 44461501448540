import React from "react";
import StudentDashBoard from "../Screens/Pages/StudentDashBoard";

import PrivateRoute from "./PrivateRoute";
import PublicRoutes from "./PublicRoutes";
import GlobalPrivateRoutes from "./GlobalPrivateRoutes";
import StudentAssoc from "../Screens/Pages/Users/StudentAssoc";

const StudentRoutes = () => {
  return (
    <>
      <PrivateRoute path="/StudentDashBoard" exact>
        <StudentDashBoard />
      </PrivateRoute>

      <PrivateRoute path="/associations" exact>
        <StudentAssoc />
      </PrivateRoute>
      <PublicRoutes />
      <GlobalPrivateRoutes />
    </>
  );
};

export default StudentRoutes;
