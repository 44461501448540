import {
  Alert,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getOccurrenceRequest } from "../../../Redux/actions/EventOcc";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/lab";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import OccBooking from "../../../Components/Containers/OccBooking";

const EditOccurrence = () => {
  const { id } = useParams();

  const [capacity, setCapacity] = useState("");
  const [startTime, setStartTime] = useState("");
  const [bookingCloses, setBookingCloses] = useState("");

  const [endTime, setEndTime] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOccurrenceRequest({ id }));
  }, [dispatch, id]);

  const occData = useSelector((state) => state.occData);
  const { data, loading, error, success } = occData;

  //   let endTime = data?.timeSlots && data?.timeSlots.length - 1;

  //   const isToday =
  //     moment(data?.occurrence).format("MMMM Do YYYY") ===
  //     moment().format("MMMM Do YYYY")
  //       ? true
  //       : false;

  useEffect(() => {
    if (success) {
      setCapacity(data?.capacity);
      setEndTime(moment(`${data?.Event.endTime}`, "YYYY-MM-DD HH:mm"));
      setStartTime(moment(`${data?.Event.startTime}`, "YYYY-MM-DD HH:mm"));
    }
  }, [data, success]);

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
          {error && <Alert severity="error"> {JSON.stringify(error)}</Alert>}
          {loading && <CircularProgress />}
          <Card>
            <CardHeader
              title="Event Name"
              //   subheader={`Start Time : ${
              //     data?.timeSlots && data?.timeSlots[0].slot
              //   }`}
            />

            <CardContent>
              <Grid container direction="row" justifyContent="center">
                <Grid item>
                  {/* <Typography align="center" variant="h6">
                    {`${moment(data?.occurrence).format("MMMM Do YYYY")}`}
                  </Typography>
                  {data?.timeSlots && (
                    <Typography align="center" variant="h6">
                      From : {data?.timeSlots[0].slot} To :{" "}
                      {data?.timeSlots[endTime].slot} + 1 Hour
                    </Typography>
                  )} */}

                  {/* {data?.timeSlots &&
                    data?.timeSlots.map((slot) => {
                      return (
                        <Chip
                          variant="outlined"
                          sx={{ m: 1 }}
                          label={slot.slot}
                          key={slot.slot}
                        />
                      );
                    })} */}
                </Grid>
              </Grid>
              <LocalizationProvider dateAdapter={DateAdapter} local="en-ZA">
                <Stack spacing={2}>
                  {/* TODO : ADD MIN AND MAX TIMES */}

                  <TimePicker
                    fullWidth
                    renderInput={(params) => <TextField {...params} />}
                    value={startTime}
                    minutesStep={15}
                    label="Start Time"
                    // maxTime={new Date(0, 0, 0, 18, 45)}
                    onChange={(newValue) => {
                      setStartTime(newValue);
                    }}
                  />

                  <TimePicker
                    ampm={true}
                    renderInput={(params) => <TextField {...params} />}
                    value={endTime}
                    minutesStep={15}
                    label="End Time"
                    onChange={(newValue) => {
                      setEndTime(newValue);
                    }}
                  />
                  <Typography>Time Slots</Typography>
                  <Stack direction="row">
                    {/* {slots.map((slot) => (
      <Chip
        sx={{ mr: 1 }}
        variant="outlined"
        key={slot.slot}
        label={slot.slot}
      />
    ))} */}
                  </Stack>
                  <DatePicker
                    label="bookingCloses"
                    views={["day"]}
                    value={bookingCloses}
                    onChange={(newValue) => {
                      setBookingCloses(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <TextField
                    label="Capacity"
                    id="Capacity"
                    type="number"
                    name="Capacity"
                    value={capacity}
                    onChange={(event) => setCapacity(event.target.value)}
                  />
                </Stack>
              </LocalizationProvider>

              <Stack spacing={2}>
                {data &&
                  data.Bookings.map((booking) => <OccBooking data={booking} />)}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditOccurrence;
