import * as React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../../../Components/Forms/FormComponents/TextfieldWrapper";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { EditSchoolStudent } from "../../../Redux/actions/SchoolStudentsActions";
import * as Yup from "yup";

export default function EditStudent({ schoolGradesList, student }) {
  let grade = schoolGradesList?.find((grade) => grade?.uuid === student?.grade);

  let cls = grade?.SchoolClasses?.find((cls) => cls?.uuid === student?.class);

  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Edit Student
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Edit Student Update </DialogTitle>

        <Formik
          initialValues={{
            name: student?.User?.name,
            email: student?.User?.email,
            parentEmail: student?.parentEmail,
            grade: grade,
            class: cls,
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string().required("Email is required"),
            grade: Yup.object().required("Grade is required"),
            class: Yup.object().required("Class is required"),
          })}
          onSubmit={(values) => {
            let data = {
              ...values,
              grade: values.grade.uuid,
              class: values.class.uuid,
            };

            dispatch(EditSchoolStudent(student.studentId, data));
          }}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <DialogContent>
                  <Stack spacing={2}>
                    <TextfieldWrapper disabled name="name" label="Name" />
                    <TextfieldWrapper
                      disabled
                      name="email"
                      label="Email"
                      type="email"
                    />

                    <FormControl error={errors.grade ? true : false}>
                      <InputLabel id="GRADE">Grade</InputLabel>
                      <Select
                        labelId="GRADE"
                        id="grade"
                        name="grade"
                        value={values.grade}
                        onChange={(event) =>
                          setFieldValue("grade", event.target.value)
                        }
                        label="Grade">
                        <MenuItem value={""}>Please Select</MenuItem>
                        {schoolGradesList &&
                          schoolGradesList?.map((grade) => {
                            return (
                              <MenuItem key={grade.uuid} value={grade}>
                                {grade.grade}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>Grade is Required</FormHelperText>
                    </FormControl>

                    <FormControl error={errors.class ? true : false}>
                      <InputLabel id="Class">Select Class</InputLabel>
                      <Select
                        labelId="Class"
                        id="Class"
                        name="class"
                        value={values.class}
                        onChange={(event) =>
                          setFieldValue("class", event.target.value)
                        }
                        label="Select Class">
                        <MenuItem value={{}}>Please Select</MenuItem>
                        {values?.grade &&
                          values?.grade?.SchoolClasses?.map((C) => {
                            return (
                              <MenuItem key={C.uuid} value={C}>
                                {C.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {errors.class && (
                        <FormHelperText>Class is Required</FormHelperText>
                      )}
                    </FormControl>

                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button
                        id="editStudent"
                        type="submit"
                        variant="contained">
                        save
                      </Button>
                    </DialogActions>
                  </Stack>
                </DialogContent>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
}
