import axios from "axios";
import { url } from "./URL";

export const GetSchoolReportsByGrade =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_SCHOOL_REPORTS_REQUEST" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
        },
      };
      const { data } = await axios.get(
        `${url}/SchoolReports/${id}`,
        { id },
        config
      );

      dispatch({ type: "GET_SCHOOL_REPORTS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_SCHOOL_REPORTS_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const CreateCertificates = (values) => async (dispatch, getState) => {
  try {
    dispatch({ type: "CREATE_CERTIFICATES_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
      },
    };
    const { data } = await axios.post(
      `${url}/schools/certificates`,
      values,
      config
    );

    dispatch({ type: "CREATE_CERTIFICATES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "CREATE_CERTIFICATES_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getCertificates = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_CERTIFICATES_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
      },
    };
    const { data } = await axios.get(
      `${url}/schools/certificates/${id}`,
      config
    );

    dispatch({ type: "GET_CERTIFICATES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_CERTIFICATES_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
