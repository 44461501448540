import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";
import { Typography } from "@mui/material";

export default function TimeSelectSlider({ slots, setTimeSlots }) {
  const marks = [];

  slots &&
    slots.forEach((element, index) => {
      marks.push({ label: element.slot, value: index });
    });

  const [value1, setValue1] = React.useState(
    [marks[0].value, marks.length] || []
  );

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  useEffect(() => {
    setTimeSlots(slots.slice(value1[0], value1[1]));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value1]);

  return (
    <Box px={2}>
      {marks && (
        <>
          <Typography> Select Time Slots</Typography>
          <Slider
            name="slots"
            value={value1}
            onChange={handleChange1}
            min={marks[0].value}
            max={marks.length}
            marks={marks}
          />
        </>
      )}
    </Box>
  );
}
