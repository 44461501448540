import {
  Avatar,
  Card,
  CardContent,
  Chip,
  Stack,
  Typography,
  Grid,
  Box
} from "@mui/material";


import React from "react";

const OccBooking = ({ data }) => {
  let { avatar, username, status, timeSlots } = data;

  console.log(data);

  return (
    <>
      <Card>
        <CardContent>
          <Stack mb={2} direction="row" justifyContent="space-between">
            <Avatar
              size="large"
              alt={`${username} Profile Picture`}
              src={`${process.env.REACT_APP_IMAGE_URL}/${avatar}`}
            />
            <Chip
              label={status}
              variant="outlined"
              color={status === "active" ? "primary" : "error"}
            />
          </Stack>
          <Typography gutterBottom variant="h5" component="div">
            {username}
          </Typography>
          <Typography gutterBottom>TIme Slots</Typography>
          <Grid container spacing={1}>
            {timeSlots &&
              timeSlots.map((time) => {
                return (
                  <Grid item>
                    <Chip label={time.slot} />
                  </Grid>
                );
              })}
          </Grid>
        </CardContent>
        <>
          <Box
            p={1}
            display="flex"
            align="center"
            justifyContent="space-between"></Box>
        </>
      </Card>
    </>
  );
};

export default OccBooking;
