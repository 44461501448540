import React from "react";
import {
  Grid,
  Stack,
  Card,
  Container,
  CardHeader,
  Divider,
  Box,
  Tab,
  Tabs,
  Typography,
  Button,
  useMediaQuery,
  Alert,
  LinearProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import PageHeader from "../../Components/Items/PageHeader";
import StudentAssoc from "../Pages/Users/StudentAssoc";
import StudentSchoolForms from "../../Components/Forms/Student/StudentSchoolForms";
import Person2TwoToneIcon from "@mui/icons-material/Person2TwoTone";
import Groups3TwoToneIcon from "@mui/icons-material/Groups3TwoTone";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { useTheme } from "@emotion/react";
import UserProfile from "../../Components/Forms/UserProfile";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import { useHistory } from "react-router-dom";
import withAuth from "../../Hoc/withAuth";

const Profile = ({ token }) => {
  const router = useHistory();

  const getMe = useQuery(
    "me",
    () => {
      return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      enabled: !!token,
      onError: () => {
        router.push("/login");
      },
    }
  );

  const updateMe = useMutation(
    "updateMe",
    (data) => {
      return axios.put(`${process.env.REACT_APP_PUBLIC_URL}/auth/me`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        getMe.refetch();
      },
    }
  );

  let profile = getMe.data?.data?.user;

  if (getMe.isLoading) {
    return <LinearProgress />;
  }

  const returnUserForm = () => {
    return profile && profile.uuid && profile.role === "parent" ? (
      <>
        <UserProfile data={profile} updateMe={updateMe} />
      </>
    ) : profile && profile.uuid && profile.role === "student" ? (
      <>
        <ProfileSettings updateMe={updateMe} token={token} user={profile} />
      </>
    ) : profile && profile.uuid && profile.role === "org-admin" ? (
      <UserProfile data={profile} updateMe={updateMe} />
    ) : profile?.role === "school-admin" ? (
      <UserProfile data={profile} updateMe={updateMe} />
    ) : profile?.role === "school-user" ? (
      <UserProfile data={profile} updateMe={updateMe} />
    ) : (
      <Alert severity="warning">
        No User Profile Found. Please contact support if you believe this is an
        error
      </Alert>
    );
  };

  return (
    <Container maxWidth="xl">
      <PageHeader
        title="Profile"
        description="Manage your profile and settings"
        info={profile?.role}
      />

      {returnUserForm()}
    </Container>
  );
};

const ProfileSettings = ({ user, token, updateMe }) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <Card>
        <CardHeader title="Profile Settings" />
        <Divider />

        <Grid container>
          <Grid item xs={12} md={12} lg={3}>
            <Tabs
              orientation={isSmall ? "horizontal" : "vertical"}
              variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{
                borderRight: 1,
                borderColor: "divider",
                height: isSmall ? "auto" : "60vh",
              }}>
              <Tab
                component="div"
                sx={{
                  minHeight: "auto",
                  minWidth: "auto",
                  textTransform: "none",
                }}
                label={
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Person2TwoToneIcon fontSize="large" />
                    <Stack>
                      <Typography align="left" variant="body1">
                        User Profile
                      </Typography>
                      <Typography align="left" variant="caption">
                        Manage your profile and settings
                      </Typography>
                    </Stack>
                  </Stack>
                }
                {...a11yProps(0)}
              />

              <Tab
                sx={{
                  minHeight: "auto",
                  minWidth: "auto",

                  textTransform: "none",
                }}
                label={
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <Groups3TwoToneIcon fontSize="large" />
                    <Stack>
                      <Typography align="left" variant="body1">
                        Linked Users
                      </Typography>
                      <Typography align="left" variant="caption">
                        Manage your profile and settings
                      </Typography>
                    </Stack>
                  </Stack>
                }
                {...a11yProps(1)}
              />
              <Tab
                sx={{
                  minHeight: "auto",
                  minWidth: "auto",
                  textTransform: "none",
                }}
                label={
                  <Stack
                    spacing={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center">
                    <LocationCityIcon fontSize="large" />
                    <Stack>
                      <Typography align="left" variant="body1">
                        My School
                      </Typography>
                      <Typography align="left" variant="caption">
                        Manage your profile and settings
                      </Typography>
                    </Stack>
                  </Stack>
                }
                {...a11yProps(2)}
              />
            </Tabs>
          </Grid>
          <Grid item xs={12} md={12} lg={9}>
            <TabPanel value={value} index={0}>
              <UserProfile data={user} updateMe={updateMe} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <StudentAssoc />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <StudentSchoolForms />
            </TabPanel>
          </Grid>
        </Grid>
        <Divider />
        <Stack sx={{ p: 2 }} direction="row" justifyContent="space-between">
          <Button>Back </Button>
          <Button>Next</Button>
        </Stack>
      </Card>
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

export default withAuth(Profile);
