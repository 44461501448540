import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../../Components/Items/PageHeader";

import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useParams } from "react-router-dom";
import {
  EditSchoolStudent,
  getSchoolGrades,
  GetSchoolStudents,
} from "../../../Redux/actions/SchoolStudentsActions";

import AlertPopup from "../../../Components/Items/AlertPopup";
import EditStudent from "./EditStudent";
import { useHistory } from "react-router-dom";
import SchoolManageGradesAndClasses from "./SchoolManageGradesAndClasses";
import { SCHOOL_STUDENT_RESET } from "../../../Redux/Reducers/SchoolStudentReducer";
import SchoolViewStudent from "./SchoolViewStudent";

const SchoolStudentAdmin = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const router = useHistory();

  useEffect(() => {
    dispatch(GetSchoolStudents(id));
    dispatch(getSchoolGrades(id));
    dispatch({ type: SCHOOL_STUDENT_RESET });
  }, [dispatch, id]);

  const schoolStudents = useSelector((state) => state.schoolStudents);

  const {
    schoolStudentsList,
    schoolGradesList,
    schoolStudentSuccess,
    schoolClassCreated,
    addedGradeSuccess,
  } = schoolStudents;

  let NoGradeStudents = schoolStudentsList?.filter(
    (student) => student.grade === null || student.class === null
  );

  const unApprovedStudents = schoolStudentsList?.filter(
    (student) => student.approved !== true
  );

  // const approvedStudents = schoolStudentsList?.filter(
  //   (student) => student.approved === true
  // );

  const [filteredStudents, setFilteredStudents] = useState("");

  const SearchStudent = (e) => {
    const search = e.target.value;
    const filteredStudents = schoolStudentsList?.filter((student) =>
      student.User.username.toLowerCase().includes(search.toLowerCase())
    );
    if (search) {
      setFilteredStudents(filteredStudents);
    } else {
      setFilteredStudents("");
    }
  };

  return (
    <Container>
      <AlertPopup open={schoolStudentSuccess} message="Student Added" />
      <AlertPopup open={schoolClassCreated} message="Class Added" />
      <AlertPopup open={addedGradeSuccess} message="Grade Added" />
      <PageHeader
        title="Manage School"
        description="Manage Students"
        info="Manage Students Teachers and Classes"
      />

      <Stack direction="row" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            router.push(`/SchoolAdmin/SchoolStudentCreation/${id}`);
          }}>
          Add Students
        </Button>
      </Stack>

      <Stack sx={{ my: 2 }}>
        <TextField
          id="standard-basic"
          label="Search Student"
          fullWidth
          margin="normal"
          disabled
          helperText="Coming Soon"
          onChange={SearchStudent}
        />

        {filteredStudents && filteredStudents.length > 0 && (
          <>
            <Grid container spacing={2} sx={{ my: 4 }}>
              {filteredStudents?.map((student) => (
                <Grid
                  key={student.studentId}
                  item
                  xl={4}
                  lg={4}
                  md={4}
                  sm={12}
                  xs={12}>
                  <Card>
                    <CardHeader
                      avatar={
                        <Avatar
                          src={`${process.env.REACT_APP_IMAGE_URL}/${student?.User?.avatar}`}
                          aria-label={student?.User?.name}
                        />
                      }
                      title={student?.User?.name}
                      subheader={student?.User?.name}
                    />
                    <CardActions>
                      <SchoolViewStudent schoolStudent={student} />
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {unApprovedStudents && unApprovedStudents?.length > 0 && (
          <UnApprovedStudentsAccordion
            unApprovedStudents={unApprovedStudents}
          />
        )}
        {NoGradeStudents && NoGradeStudents?.length > 0 && (
          <StudentsWithNoClassOrGrade
            org_id={id}
            schoolGradesList={schoolGradesList}
            NoGradeStudents={NoGradeStudents}
          />
        )}
      </Stack>

      <SchoolManageGradesAndClasses org_id={id} />
    </Container>
  );
};

export default SchoolStudentAdmin;

export const UnApprovedStudentsAccordion = ({ unApprovedStudents }) => {
  const dispatch = useDispatch();

  const handleSchoolStudentApprove = (student) => {
    dispatch(
      EditSchoolStudent(student.studentId, {
        approved: true,
      })
    );
  };

  return (
    <Accordion sx={{ mb: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <CardHeader
          sx={{ m: 0, p: 0, borderRadius: 0 }}
          avatar={
            <Avatar
              sx={{ bgcolor: "primary.main" }}
              aria-label="Unauthorized Students Count">
              {unApprovedStudents?.length}
            </Avatar>
          }
          title="Unauthorized Students"
          subheader="Students that have not been approved by the school"
        />
      </AccordionSummary>

      <AccordionDetails>
        <List>
          {unApprovedStudents && unApprovedStudents?.length > 0 ? (
            unApprovedStudents.map((student) => (
              <ListItem
                dense
                sx={{ my: 1, boxShadow: 2, width: "100%" }}
                key={student.studentId}
                secondaryAction={
                  <Button onClick={() => handleSchoolStudentApprove(student)}>
                    Approve
                  </Button>
                }>
                <ListItemAvatar>
                  <Avatar
                    src={`${process.env.REACT_APP_IMAGE_URL}/${student?.User?.avatar}`}
                    title={student?.User?.name}
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={student?.User?.name}
                  secondary={student?.User?.email}
                />
              </ListItem>
            ))
          ) : (
            <Typography align="center" variant="button">
              No Unauthorized Students
            </Typography>
          )}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};

const StudentsWithNoClassOrGrade = ({
  NoGradeStudents,
  org_id,
  schoolGradesList,
}) => {
  return (
    <Accordion sx={{ my: 2 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header">
        <Stack direction="row" spacing={2}>
          <Typography>Students with No Class/Grade</Typography>
          {NoGradeStudents?.length > 0 && (
            <Chip
              variant="outlined"
              color="info"
              label={NoGradeStudents?.length}
            />
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          {NoGradeStudents && NoGradeStudents?.length > 0 ? (
            NoGradeStudents.map((student) => (
              <Grid item xs={12} sm={6} md={4} key={student.studentId}>
                <Card variant="outlined">
                  <CardHeader
                    avatar={
                      <Avatar
                        src={`${process.env.REACT_APP_IMAGE_URL}/${student?.User?.avatar}`}
                        aria-label={student?.User?.name}
                      />
                    }
                    title={student?.User?.name}
                    subheader={student?.User?.name}
                  />
                  <CardContent>
                    <Stack>
                      <Alert severity="error">Pending</Alert>
                      <EditStudent
                        student={student}
                        org_id={org_id}
                        schoolGradesList={schoolGradesList}
                      />
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography align="center" variant="button">
              No UnAuthorized Students
            </Typography>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
