import {
  Alert,
  Button,
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import AlertPopup from "../../../Components/Items/AlertPopup";
import DeleteRelation from "../../../Components/Relations/DeleteRelation";
import withAuth from "../../../Hoc/withAuth";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../Components/Forms/FormComponents/TextfieldWrapper";

const StudentAssoc = ({ token, user }) => {
  const { data, refetch } = useQuery({
    queryKey: "getMyRelations",
    queryFn: () => {
      return axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/relations/ParentStudentRelation`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    enabled: !!token,
  });

  const CreateNewRelation = useMutation(
    "CreateNewRelation",
    (values) => {
      return axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/relations/ParentStudentRelation`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const EditRelation = useMutation(
    "EditRelation",
    (values) => {
      return axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/relations/ParentStudentRelation/${values.uuid}`,
        values,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const DeleteRela = useMutation(
    "DeleteRelation",
    (id) => {
      return axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}/relations/ParentStudentAuthorize/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );

  const handleAuthParent = (id) => {
    EditRelation.mutate({ uuid: id, parentConfirmed: true });
  };

  const buttonsForStudent = (relation) => {
    if (relation.parentId) {
      return (
        <>
          <>
            {!relation?.parentConfirmed && (
              <Button onClick={() => handleAuthParent(relation?.uuid)}>
                Confirm Parent
              </Button>
            )}
            {!relation.studentAuthorized ? (
              <Alert severity="warning">
                You are not authorized to join events
              </Alert>
            ) : (
              <Alert severity="success">You are Authorized</Alert>
            )}
          </>
        </>
      );
    } else {
      return (
        <>
          <Alert severity="warning">
            Your request has been sent, please wait for the parent to confirm
            your request. And Authorize you to join events.
          </Alert>
        </>
      );
    }
  };

  return (
    <Grid container spacing={2}>
      <AlertPopup
        severity="success"
        open={DeleteRela.isSuccess}
        message="Relation Deleted Successfully"
      />
      <AlertPopup
        severity="success"
        open={EditRelation.isSuccess}
        message="Relation Updated Successfully"
      />
      <AlertPopup
        severity="success"
        open={CreateNewRelation.isSuccess}
        message="Relation Created Successfully"
      />
      <AlertPopup
        severity="error"
        open={DeleteRela.isError}
        message="Error Deleting Relation"
      />
      <AlertPopup
        severity="error"
        open={EditRelation.isError}
        message="Error Updating Relation"
      />
      <AlertPopup
        severity="error"
        open={CreateNewRelation.isError}
        message="Error Creating Relation"
      />

      {data?.data &&
        data?.data?.map((relation, index) => {
          return (
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12} key={index}>
              <Card>
                <CardContent>
                  <Stack spacing={2}>
                    <Typography variant="body1">
                      {relation?.parentEmail}
                    </Typography>
                    {buttonsForStudent(relation)}
                    <DeleteRelation
                      id={relation?.uuid}
                      DeleteRela={DeleteRela}
                    />
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          );
        })}

      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <AddParent
          studentEmail={user.email}
          CreateNewRelation={CreateNewRelation}
        />
      </Grid>
    </Grid>
  );
};
export default withAuth(StudentAssoc);

function AddParent({ studentEmail, CreateNewRelation }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button fullWidth variant="contained" onClick={handleClickOpen}>
        Add Parent
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Add Parent</DialogTitle>

        <Formik
          initialValues={{
            email: "",
          }}
          onSubmit={(values) => {
            let formData = {
              parentEmail: values.email,
              studentEmail: studentEmail,
            };

            CreateNewRelation.mutate(formData);
            handleClose();
          }}>
          {() => {
            return (
              <Form>
                <DialogContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextfieldWrapper label="Email" name="email" />
                    </Grid>
                    <Grid item xs={12}>
                      <Stack
                        sx={{ mt: 3 }}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="space-between">
                        <Button onClick={handleClose}>Cancel</Button>
                        <Button type="submit" variant="contained">
                          Add Parent
                        </Button>
                      </Stack>
                    </Grid>
                  </Grid>
                </DialogContent>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
