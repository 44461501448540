export const GetOrgStatsReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "REQUEST_ORG_STATS":
      return { ...state, loading: true };
    case "SUCCESS_ORG_STATS":
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      };
    case "FAIL_ORG_STATS":
      return { ...state, loading: false, error: action.payload };
    case "RESET_ORG_STATS":
      return { loading: false };
    default:
      return state;
  }
};
