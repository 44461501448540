import {
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";

import { useSelector, useDispatch } from "react-redux";

import withAuth from "../../../Hoc/withAuth";
import { getOrgStatsRequest } from "../../../Redux/actions/OrgStats";
import moment from "moment";

const OrgStats = ({ user }) => {
  const dispatch = useDispatch();

  const [orgStatsData, setOrgStatsData] = useState([]);
  console.log(orgStatsData);

  const [selectEvent, setSelectEvent] = useState("");
  const [Event, setEvent] = useState("");

  useEffect(() => {
    dispatch(getOrgStatsRequest());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const orgStats = useSelector((state) => state.orgStats);
  const { data } = orgStats;

  useEffect(() => {
    setOrgStatsData(data);
  }, [data]);

  const filterEvents = useCallback(() => {
    let selected = data.Events.filter((e) => e.uuid === selectEvent);

    setEvent(...selected);
  }, [data?.Events, selectEvent]);

  useEffect(() => {
    if (selectEvent) {
      filterEvents();
    }
  }, [filterEvents, selectEvent]);

  console.log(data);

  let Total = 0;
  let BookedTime = 0;

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xl={6}>
          <FormControl sx={{ minWidth: 300 }}>
            <InputLabel id="demo-simple-select-label">Select Events</InputLabel>

            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectEvent}
              label="Select Events"
              onChange={(event) => setSelectEvent(event.target.value)}>
              <MenuItem value={0}>select</MenuItem>
              {data?.Events.map((event) => (
                <MenuItem key={event.uuid} value={event.uuid}>
                  {event.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography>
              This Will be Total Booked Time Vs Total Attended Time ?
            </Typography>
          </Paper>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {Event && (
            <>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>User Name</TableCell>

                      <TableCell align="right">eventDate</TableCell>
                      <TableCell align="right">user start time</TableCell>
                      <TableCell align="right">user end time</TableCell>
                      <TableCell align="right">user Booked time</TableCell>
                      <TableCell align="right">user total Attendance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Event &&
                      Event.Bookings.map((row, index) => {
                        Total = Total + row.totalAttendance;
                        BookedTime = BookedTime + row.bookedTime;
                        return (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}>
                            <TableCell component="th" scope="row">
                              {row.username}
                            </TableCell>

                            <TableCell align="right">
                              {moment(row.eventDate).format(" MMM, DD")}
                            </TableCell>
                            <TableCell align="right">
                              {moment(row.startTime).format("LT")}
                            </TableCell>
                            <TableCell align="right">
                              {moment(row.endTime).format("LT")}
                            </TableCell>
                            <TableCell align="right">
                              {row.bookedTime} Hours
                            </TableCell>
                            <TableCell align="right">
                              {row.totalAttendance} Hours
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>

                      <TableCell align="right">
                        BookedTime = {BookedTime}{" "}
                      </TableCell>
                      <TableCell align="right">TOTAL = {Total} </TableCell>
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(OrgStats);
