import React from "react";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import PageHeader from "../../../Components/Items/PageHeader";
import { useQuery } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import { IconList } from "../../../Components/IconList";

const CausesList = ({ token }) => {
  const getTags = useQuery({
    queryKey: ["tags"],
    queryFn: () =>
      axios(`${process.env.REACT_APP_PUBLIC_URL}/tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
  });

  return (
    <Container>
      <PageHeader
        title="Causes"
        description="Find organizations that support your cause"
      />

      <Grid container spacing={2}>
        {getTags.data?.data.map((row) => {
          let TheIcon = IconList.find((icon) => icon.name === row.icon)?.Icon;
          return (
            <Grid item xs={12} sm={6}>
              <Card>
                <CardContent>
                  <Stack spacing={1}>
                    <TheIcon fontSize="large" color={row.color} />
                    <Typography variant="body1" fontWeight={500}>
                      {row.name}
                    </Typography>
                    <Typography variant="caption">{row.description}</Typography>
                  </Stack>
                  <Button sx={{ mt: 2 }} color="primary" variant="outlined">
                    View
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
        <Grid item xs={12} sm={6}></Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(CausesList);
