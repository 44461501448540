import { Alert, Container, Grid, LinearProgress, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import OrgCard from "../../../Components/Containers/OrgCard";
import PageHeader from "../../../Components/Items/PageHeader";
import OrgSearch from "./OrgSearch";
import axios from "axios";
import { Stack } from "@mui/system";
import { useInfiniteQuery } from "react-query";
import withAuth from "../../../Hoc/withAuth";

const fetchOrgs = async ({
  pageParam = 0,
  rowsPerPage = 20,
  values,
  token,
}) => {
  const searchParam = values ? `&search=${values}` : "";
  const { data } = await axios.get(
    `${process.env.REACT_APP_PUBLIC_URL}/orgs/search?pageNumber=${pageParam}&limit=${rowsPerPage}${searchParam}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

const Orgs = ({ token }) => {
  const [values] = useState("");

  const loadMoreRef = useRef();

  const [rowsPerPage] = React.useState(6);

  const { data, fetchNextPage, hasNextPage, isLoading, error } =
    useInfiniteQuery(
      ["orgsSearch", { rowsPerPage, values }],
      ({ pageParam = 0 }) =>
        fetchOrgs({ pageParam, rowsPerPage, values, token }),
      {
        getNextPageParam: (lastPage, allPages) => lastPage.nextPage ?? false,
      }
    );

  // Intersection Observer to trigger load more
  useEffect(() => {
    if (isLoading) return;
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      },
      { threshold: 1 }
    );
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
    return () => observer.disconnect();
  }, [isLoading, hasNextPage, fetchNextPage]);

  // const orgs = useSelector((state) => state.orgs);
  // const { error, loading, data } = orgs;

  if (isLoading) {
    <LinearProgress />;
  }

  return (
    <>
      <Container>
        <Box sx={{ overflowX: "hidden" }}>
          <PageHeader
            title="Orgs"
            description="Find organizations that support your cause"
          />
          {error && <Alert severity="error">{error}</Alert>}

          <Grid container justifyContent="center">
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <Stack>
                <OrgSearch />
              </Stack>
            </Grid>
          </Grid>

          {data?.pages?.map((page, i) => (
            <Grid
              sx={{ mb: 0.5 }}
              container
              spacing={0.5}
              justifyContent="center"
              key={i}>
              {page?.rows.map((org, i) => {
                return (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={6}
                    lg={4}
                    xl={4}
                    key={org.uuid}>
                    <OrgCard data={org} token={token} />
                  </Grid>
                );
              })}
            </Grid>
          ))}

          <div ref={loadMoreRef} style={{ height: "20px" }} />
          {isLoading && <LinearProgress />}
        </Box>
      </Container>
    </>
  );
};

export default withAuth(Orgs);
