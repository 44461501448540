const initialState = {
  schoolStudent: {},
  schoolStudentsList: [],
  schoolStudentLoading: false,
  schoolStudentSuccess: false,
  schoolStudentError: null,
  schoolStudentReset: false,
  schoolGradesList: [],
};

export const SCHOOL_STUDENT_RESET = "SCHOOL_STUDENT_RESET";

const SchoolStudentReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_SCHOOL_STUDENTS":
      return {
        ...state,
        schoolStudentLoading: true,
        schoolStudentSuccess: false,
        schoolStudentError: null,
        schoolStudentReset: false,
      };
    case "GET_SCHOOL_STUDENTS_SUCCESS":
      return {
        ...state,
        schoolStudentLoading: false,

        schoolStudentError: null,
        schoolStudentReset: false,
        schoolStudentsList: action.payload,
      };
    case "GET_SCHOOL_STUDENTS_FAIL":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolStudentSuccess: false,
        schoolStudentError: action.payload,
        schoolStudentReset: false,
      };

    case "EDIT_SCHOOL_STUDENT":
      return { ...state, schoolStudentLoading: true };
    case "EDIT_SCHOOL_STUDENT_SUCCESS":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolStudentSuccess: true,
        schoolStudentsList: state.schoolStudentsList.map((student) => {
          if (student.studentId === action.payload.studentId) {
            return action.payload;
          }
          return student;
        }),
      };
    case "EDIT_SCHOOL_STUDENT_FAIL":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolStudentError: action.payload,
      };
    case "EDIT_SCHOOL_STUDENT_RESET":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolStudentSuccess: null,
        schoolStudentError: null,
      };

    case "GET_SCHOOL_GRADES":
      return {
        ...state,
        schoolStudentLoading: true,
        schoolStudentSuccess: false,
        schoolStudentError: null,
        schoolStudentReset: false,
      };
    case "GET_SCHOOL_GRADES_SUCCESS":
      return {
        ...state,
        schoolStudentLoading: false,

        schoolStudentError: null,
        schoolStudentReset: false,
        schoolGradesList: action.payload,
      };
    case "GET_SCHOOL_GRADES_FAIL":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolStudentSuccess: false,
        schoolStudentError: action.payload,
        schoolStudentReset: false,
      };

    case "ADD_SCHOOL_GRADE":
      return { ...state, schoolStudentLoading: true, addedGradeSuccess: false };
    case "ADD_SCHOOL_GRADE_SUCCESS":
      return {
        ...state,
        schoolStudentLoading: false,
        addedGradeSuccess: true,
        schoolStudentError: null,
        schoolStudentReset: false,
        schoolGradesList: [
          ...state.schoolGradesList,
          { SchoolClasses: [], ...action.payload },
        ],
      };
    case "ADD_SCHOOL_GRADE_FAIL":
      return {
        ...state,
        schoolStudentLoading: false,
        addedGradeSuccess: false,
        schoolStudentError: action.payload,
      };

    case "EDIT_SCHOOL_GRADE":
      return { ...state, schoolStudentLoading: true, addedGradeSuccess: false };
    case "EDIT_SCHOOL_GRADE_SUCCESS":
      return {
        ...state,
        schoolStudentLoading: false,
        addedGradeSuccess: true,
        schoolStudentError: null,
        schoolStudentReset: false,
        schoolGradesList: [
          ...state.schoolGradesList.map((grade) => {
            if (grade.uuid === action.payload.uuid) {
              return action.payload;
            }
            return grade;
          }),
        ],
      };
    case "EDIT_SCHOOL_GRADE_FAIL":
      return {
        ...state,
        schoolStudentLoading: false,
        addedGradeSuccess: false,
        schoolStudentError: action.payload,
      };

    case "ADD_SCHOOL_GRADE_RESET":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolStudentSuccess: null,
        schoolStudentError: null,
      };

    case "CREATE_CLASSES":
      return {
        ...state,
        schoolStudentLoading: true,
        schoolClassCreated: false,
      };
    case "CREATE_CLASSES_SUCCESS":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolClassCreated: true,
        schoolStudentError: null,
        schoolStudentReset: false,
        schoolGradesList: [
          ...state.schoolGradesList.map((grade) => {
            if (grade.uuid === action.payload.grade) {
              return {
                ...grade,
                SchoolClasses: [...grade.SchoolClasses, action.payload],
              };
            }

            return grade;
          }),
        ],
      };
    case "CREATE_CLASSES_FAIL":
      return {
        ...state,
        schoolClassCreated: false,
        schoolStudentLoading: false,
        schoolStudentError: action.payload,
      };

    case "EDIT_CLASSES":
      return {
        ...state,
        schoolStudentLoading: true,
        schoolClassCreated: false,
      };
    case "EDIT_CLASSES_SUCCESS":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolClassCreated: true,
        schoolStudentError: null,
        schoolStudentReset: false,
        schoolGradesList: [
          ...state.schoolGradesList.map((grade) => {
            if (grade.uuid === action.payload.grade) {
              return {
                ...grade,
                SchoolClasses: [
                  ...grade.SchoolClasses.map((schoolClass) => {
                    if (schoolClass.uuid === action.payload.uuid) {
                      return action.payload;
                    }
                    return schoolClass;
                  }),
                ],
              };
            }
            return grade;
          }),
        ],
      };
    case "EDIT_CLASSES_FAIL":
      return {
        ...state,
        schoolClassCreated: false,
        schoolStudentLoading: false,
        schoolStudentError: action.payload,
      };

    case "SCHOOL_ADD_STUDENT":
      return {
        ...state,
        schoolStudentLoading: true,
        schoolStudentSuccess: false,
      };
    case "SCHOOL_ADD_STUDENT_SUCCESS":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolStudentSuccess: true,
        schoolStudentError: null,
        schoolStudentReset: false,
        schoolStudentsList: [...state.schoolStudentsList, action.payload.data],
      };
    case "SCHOOL_ADD_STUDENT_FAIL":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolStudentSuccess: false,
        schoolStudentError: action.payload,
      };

    case "GET_SINGLE_STUDENT":
      return {
        ...state,
        schoolStudentLoading: true,
        schoolStudentSuccess: false,
        schoolStudentError: null,
        schoolStudentReset: false,
      };
    case "GET_SINGLE_STUDENT_SUCCESS":
      return {
        ...state,
        schoolStudentLoading: false,

        schoolStudentError: null,
        schoolStudentReset: false,
        schoolStudent: action.payload,
      };
    case "GET_SINGLE_STUDENT_FAIL":
      return {
        ...state,
        schoolStudentLoading: false,
        schoolStudentSuccess: false,
        schoolStudentError: action.payload,
        schoolStudentReset: false,
      };

    case SCHOOL_STUDENT_RESET:
      return {
        ...state,
        addedGradeSuccess: false,
        schoolStudentLoading: false,
        schoolClassCreated: false,
        schoolStudentSuccess: null,
        schoolStudentError: null,
        schoolStudentReset: true,
      };

    default:
      return state;
  }
};

export default SchoolStudentReducer;
