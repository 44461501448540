import * as React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import DialogTitle from "@mui/material/DialogTitle";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../../../Components/Forms/FormComponents/TextfieldWrapper";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { schoolAddStudent } from "../../../Redux/actions/SchoolStudentsActions";
import * as Yup from "yup";

export default function AddStudent({ org_id, schoolGradesList }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add single Student
      </Button>
      <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Add Student</DialogTitle>

        <Formik
          initialValues={{
            name: "",
            email: "",
            parentEmail: "",
            grade: "",
            class: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Name is required"),
            email: Yup.string().required("Email is required"),
            parentEmail: Yup.string().required("Parent Email is required"),
            grade: Yup.object().required("Grade is required"),
            class: Yup.object().required("Class is required"),
          })}
          onSubmit={(values) => {
            let data = {
              ...values,
              org_id,
              grade: values.grade.uuid,
              class: values.class.uuid,
            };

            dispatch(schoolAddStudent(org_id, data));
          }}>
          {({ values, setFieldValue, errors }) => {
            return (
              <Form>
                <DialogContent>
                  <Stack spacing={2}>
                    <TextfieldWrapper name="name" label="Name" />
                    <TextfieldWrapper name="email" label="Email" type="email" />
                    <TextfieldWrapper
                      name="parentEmail"
                      label="Parent Email Address"
                      type="email"
                    />
                    {schoolGradesList && org_id && (
                      <FormControl error={errors.grade ? true : false}>
                        <InputLabel id="GRADE">Grade</InputLabel>
                        <Select
                          labelId="GRADE"
                          id="grade"
                          name="grade"
                          value={values.grade}
                          onChange={(event) =>
                            setFieldValue("grade", event.target.value)
                          }
                          label="Grade">
                          <MenuItem value={""}>Please Select</MenuItem>
                          {schoolGradesList &&
                            schoolGradesList.map((grade) => {
                              return (
                                <MenuItem key={grade.uuid} value={grade}>
                                  {grade.grade}
                                </MenuItem>
                              );
                            })}
                        </Select>
                        <FormHelperText>Grade is Required</FormHelperText>
                      </FormControl>
                    )}

                    <FormControl error={errors.class ? true : false}>
                      <InputLabel id="Class">Select Class</InputLabel>
                      <Select
                        labelId="Class"
                        id="Class"
                        name="class"
                        value={values.class}
                        onChange={(event) =>
                          setFieldValue("class", event.target.value)
                        }
                        label="Select Class">
                        <MenuItem value={{}}>Please Select</MenuItem>
                        {values?.grade &&
                          values?.grade?.SchoolClasses.map((C) => {
                            return (
                              <MenuItem key={C.uuid} value={C}>
                                {C.name}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      {errors.class && (
                        <FormHelperText>Class is Required</FormHelperText>
                      )}
                    </FormControl>

                    <DialogActions>
                      <Button onClick={handleClose}>Cancel</Button>
                      <Button type="submit" variant="contained">
                        Submit
                      </Button>
                    </DialogActions>
                  </Stack>
                </DialogContent>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </div>
  );
}
