import {
  Alert,
  Avatar,
  Button,
  Card,
  CardActionArea,
  CardHeader,
  CardMedia,
  Container,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
  CardContent,
  LinearProgress,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageHeader from "../../Components/Items/PageHeader";
import { useHistory } from "react-router-dom";
// import { getOrgAssociationsList } from "../../Redux/actions/OrgAssociationsActions";
// import {
//   ApproveAssociation,
//   RemoveApproval,
// } from "./School/SchoolAssociations";
// import { GetSchoolStudents } from "../../Redux/actions/SchoolStudentsActions";
import { UnApprovedStudentsAccordion } from "./School/SchoolStudentAdmin";
import EventNoteTwoToneIcon from "@mui/icons-material/EventNoteTwoTone";
import SchoolTwoToneIcon from "@mui/icons-material/SchoolTwoTone";
// import GroupAddIcon from "@mui/icons-material/GroupAdd";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import withAuth from "../../Hoc/withAuth";
import { getMyOrg } from "../../Redux/actions/Orgs";
import SchoolProfileSetup from "./School/SchoolProfileSetup";
import { useQuery } from "react-query";
import axios from "axios";

import BannerImage from "../../Components/ImageUploaders/BannerImage";
import AvatarImage from "../../Components/ImageUploaders/AvatarImage";

const SchoolAdminDashBoard = ({ token }) => {
  const router = useHistory();

  const getMe = useQuery(
    "me",
    () => {
      return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      enabled: !!token,
      onError: () => {
        router.push("/login");
      },
    }
  );

  const user = getMe?.data?.data?.user;
  const data = user?.Org;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyOrg());
  }, [dispatch]);

  const myOrganization = useSelector((state) => state.myOrg);

  let events = myOrganization?.data?.Events;

  const schoolStudents = useSelector((state) => state.schoolStudents);

  const { schoolStudentsList } = schoolStudents;

  const unApprovedStudents = schoolStudentsList?.filter(
    (student) => student.approved !== true
  );

  if (getMe.isLoading) {
    return <LinearProgress />;
  }

  if (user?.userData?.profileComplete === false) {
    return <SchoolProfileSetup user={user} token={token} />;
  }

  return (
    <Container maxWidth="xl">
      <PageHeader
        title="School Administrator DashBoard"
        description="Manage your school and its events"
      />

      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          {data?.approved === false && (
            <Alert severity="warning">
              Your school is not approved yet. Please Send an email to{" "}
              <a href="mailto:hello@chalkitup.co.za">hello@chalkitup.co.za</a>{" "}
              to approve your school.
            </Alert>
          )}

          {data?.uuid ? (
            <Tooltip
              title="Click to view school details"
              placement="right"
              arrow>
              <Card>
                <CardActionArea onClick={() => router.push("/SchoolAdmin")}>
                  <CardHeader
                    title={data?.name}
                    avatar={
                      <AvatarImage
                        size="small"
                        uuid={data?.uuid}
                        token={token}
                      />
                    }
                  />
                  <BannerImage uuid={data?.uuid} token={token} type="banner" />
                </CardActionArea>
              </Card>
            </Tooltip>
          ) : (
            <Card
              sx={{
                border: "2px dashed #ccc",
              }}>
              <CardActionArea onClick={() => router.push("/SchoolAdmin")}>
                <CardHeader title="Create A School" />
                <CardMedia
                  component="img"
                  height="350"
                  image={`${process.env.REACT_APP_IMAGE_URL}`}
                  alt={data?.name}
                />
              </CardActionArea>
            </Card>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack mb={2}>
            <Stack justifyContent="center">
              <EventNoteTwoToneIcon
                sx={{
                  fontSize: 40,
                }}
              />
              <Typography variant="h6" gutterBottom>
                Events
              </Typography>
            </Stack>

            <Typography variant="body1" color="GrayText" gutterBottom>
              View Up Coming Events
            </Typography>

            {events && events.length > 0 && (
              <Grid container spacing={0.5}>
                {events?.map((Events) => {
                  return (
                    <Grid item xs={6} md={4} key={Events?.uuid}>
                      <Card>
                        <CardActionArea
                          onClick={() => router.push(`/myEvents/${data.uuid}`)}>
                          <CardContent>
                            <Typography sx={{ my: 1 }} variant="body1" noWrap>
                              {Events?.name}
                            </Typography>
                          </CardContent>
                          <Card>
                            <BannerImage
                              height={200}
                              uuid={Events?.uuid}
                              token={token}
                              type="banner"
                            />
                          </Card>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            <Button onClick={() => router.push(`/myEvents/${data.uuid}`)}>
              Manage Events
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack>
            <Stack justifyContent="center">
              <SchoolTwoToneIcon
                sx={{
                  fontSize: 40,
                }}
              />
              <Typography variant="h6" gutterBottom>
                Student Management
              </Typography>
            </Stack>

            <Typography variant="body1" color="GrayText" gutterBottom>
              View and Manage Students
            </Typography>

            {unApprovedStudents && unApprovedStudents?.length > 0 && (
              <UnApprovedStudentsAccordion
                unApprovedStudents={unApprovedStudents}
              />
            )}
            <StudentManagement id={data?.uuid} data={data} />
          </Stack>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <Stack>
            <Stack justifyContent="center">
              <GroupAddIcon
                sx={{
                  fontSize: 40,
                }}
              />
              <Typography variant="h6" gutterBottom>
                School Associations
              </Typography>
            </Stack>

            <Typography variant="body1" color="GrayText" gutterBottom>
              View and Manage Associations (Teachers / Ambassadors)
            </Typography>
            <AssociationList id={data?.uuid} />
            <Button
              onClick={() => router.push(`/orgs/${data.uuid}/associations`)}>
              View All Associations
            </Button>
          </Stack>
        </Grid> */}
      </Grid>
    </Container>
  );
};
export default withAuth(SchoolAdminDashBoard);

const StudentManagement = ({ id, data }) => {
  const router = useHistory();
  return (
    <CardActionArea
      sx={{ mb: 2 }}
      onClick={() => router.push(`/SchoolAdmin/students/${id}`)}>
      <Paper elevation={3}>
        <CardHeader
          avatar={
            <Avatar
              src={`${process.env.REACT_APP_IMAGE_URL}${data?.avatar}`}
              aria-label={data?.name}
            />
          }
          action={<KeyboardArrowRightIcon color="disabled" />}
          title="Manage School Students"
        />
      </Paper>
    </CardActionArea>
  );
};
