import axios from "axios";

export const EditSchoolStudent =
  (id, formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: "EDIT_SCHOOL_STUDENT" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/students/${id}`,
        formData,
        config
      );

      dispatch({ type: "EDIT_SCHOOL_STUDENT_SUCCESS", payload: data.data });
    } catch (error) {
      dispatch({
        type: "EDIT_SCHOOL_STUDENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const GetSchoolStudents = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_SCHOOL_STUDENTS" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/schools/students/${id}`,
      config
    );

    dispatch({ type: "GET_SCHOOL_STUDENTS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_SCHOOL_STUDENTS_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getSchoolGrades = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_SCHOOL_GRADES" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/schools/grades/${id}`,
      config
    );

    dispatch({ type: "GET_SCHOOL_GRADES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_SCHOOL_GRADES_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const addSchoolGrade = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ADD_SCHOOL_GRADE" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}/schools/grades`,
      formData,
      config
    );

    dispatch({ type: "ADD_SCHOOL_GRADE_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "ADD_SCHOOL_GRADE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
export const editSchoolGrade = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "EDIT_SCHOOL_GRADE" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_PUBLIC_URL}/schools/grades/${formData.uuid}`,
      formData,
      config
    );

    dispatch({ type: "EDIT_SCHOOL_GRADE_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "EDIT_SCHOOL_GRADE_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const createClasses = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "CREATE_CLASSES" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}/schools/classes`,
      formData,
      config
    );

    dispatch({ type: "CREATE_CLASSES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "CREATE_CLASSES_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editClasses = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: "EDIT_CLASSES" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_PUBLIC_URL}/schools/classes/${formData.uuid}`,
      formData,
      config
    );

    dispatch({ type: "EDIT_CLASSES_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "EDIT_CLASSES_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const schoolAddStudent =
  (id, formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: "SCHOOL_ADD_STUDENT" });
      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/students/${id}`,
        formData,
        config
      );

      dispatch({ type: "SCHOOL_ADD_STUDENT_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "SCHOOL_ADD_STUDENT_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getSingleStudent = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_SINGLE_STUDENT" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/schools/student/${id}`,
      config
    );

    dispatch({ type: "GET_SINGLE_STUDENT_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_SINGLE_STUDENT_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
