import {
  Alert,
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  Stack,
} from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ApproveDialog from "../../../Components/Forms/ApproveAssociationToOrgDialog";
import OrgRequestUserToAssociate from "../../../Components/Forms/Orgs/OrgRequestUserToAssociate";
import PageHeader from "../../../Components/Items/PageHeader";
import { getOrgAssociationsList } from "../../../Redux/actions/OrgAssociationsActions";

const OrgAssociations = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  useEffect(() => {
    dispatch(getOrgAssociationsList(id));
  }, [dispatch, id]);

  const orgAssociations = useSelector((state) => state.orgAssociations);
  const { orgAssociationList, loading } = orgAssociations;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <PageHeader
        title="Organization Associations"
        description="Organization Associations"
        info="Organization Associations"
      />
      <OrgRequestUserToAssociate orgId={id} />
      <Grid container>
        {orgAssociationList &&
          orgAssociationList.map((orgAssociation, index) => (
            <Grid item xs={4} key={index}>
              <Card>
                <CardHeader
                  title={orgAssociation?.User?.username}
                  subheader={orgAssociation?.User?.email}
                  avatar={
                    <Avatar
                      src={`${process.env.REACT_APP_IMAGE_URL}/${orgAssociation?.User?.avatar}`}
                      aria-label={orgAssociation?.User?.username}
                    />
                  }
                />
                <CardContent>
                  <Stack spacing={2}>
                    <Chip label={orgAssociation?.userType} />

                    {orgAssociation.orgApproved ? (
                      <>
                        <Alert severity="info">
                          User is approved by the organization
                        </Alert>
                      </>
                    ) : (
                      <>
                        <Alert severity="warning">
                          Ambassadors are users who have been approved by the
                          organization to represent the organization and its
                          events.
                        </Alert>
                        <ApproveDialog
                          approveType="orgApprove"
                          ass={orgAssociation}
                        />
                      </>
                    )}
                    {orgAssociation.userApproved ? (
                      <>
                        <Alert severity="info">
                          User is approve the organization
                        </Alert>
                      </>
                    ) : (
                      <>
                        <Alert severity="warning">
                          User Needs to confirm their association with the
                          organization
                        </Alert>
                      </>
                    )}
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

export default OrgAssociations;
