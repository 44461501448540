import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import PageHeader from "../../../Components/Items/PageHeader";
import { useSelector, useDispatch } from "react-redux";
import {
  deleteOrgAssociation,
  getOrgAssociationsList,
  updateOrgAssociation,
} from "../../../Redux/actions/OrgAssociationsActions";
import { useParams } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";

import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";

const SchoolAssociations = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrgAssociationsList(id));
  }, [dispatch, id]);

  const orgAssociations = useSelector((state) => state.orgAssociations);

  const { orgAssociationList } = orgAssociations;

  return (
    <Container>
      <PageHeader
        title="School Associations"
        description="School Associations"
        info="School Associations"
      />
      <Grid container spacing={2}>
        {orgAssociationList &&
          orgAssociationList.map((orgAssociation) => {
            return (
              <Grid item xs={12} sm={6} md={4} key={orgAssociation.uuid}>
                <Card>
                  <CardHeader
                    avatar={
                      <Avatar
                        src={`${process.env.REACT_APP_IMAGE_URL}/${orgAssociation.User.avatar}`}
                        aria-label={orgAssociation.User.name}
                      />
                    }
                    title={orgAssociation.User.name}
                    subheader={orgAssociation.User.email}
                    action={<MoreMenu association={orgAssociation} />}
                  />
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography align="center" variant="button">
                        {orgAssociation.userType}
                      </Typography>
                      {orgAssociation.approved ? (
                        <RemoveApproval association={orgAssociation} />
                      ) : (
                        <ApproveAssociation association={orgAssociation} />
                      )}

                      {/* {buttonsForParent(relation)} */}
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </Grid>
    </Container>
  );
};

export default SchoolAssociations;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ApproveAssociation = ({ association }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const [userType, setUserType] = React.useState(association.userType);

  const handleSubmit = () => {
    let values = {
      userType,
      approved: true,
      user_id: association.user_id,
    };

    dispatch(updateOrgAssociation(association.org_id, values));

    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth variant="outlined" onClick={handleClickOpen}>
        Approve
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Approve User and select User type"}</DialogTitle>
        <DialogContent>
          <FormControl margin="normal" fullWidth>
            <InputLabel id="demo-simple-select-label">
              Select User Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={userType}
              label="Select User Type"
              onChange={(event) => setUserType(event.target.value)}>
              <MenuItem value="ambassador">Ambassador</MenuItem>
              <MenuItem value="teacher">Teacher</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button variant="contained" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const RemoveApproval = ({ association }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    let values = {
      userType: association.userType,
      approved: false,
      user_id: association.user_id,
    };
    dispatch(updateOrgAssociation(association.org_id, values));

    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        fullWidth
        variant="outlined"
        color="warning"
        onClick={handleClickOpen}>
        Remove Approval
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{"Remove User Approval"}</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button variant="contained" color="error" onClick={handleSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const DeleteAssociation = ({ association }) => {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    dispatch(deleteOrgAssociation(association.uuid));

    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button fullWidth color="error" onClick={handleClickOpen}>
        DELETE ASSOCIATION
      </Button>
      <Dialog
        sx={{ border: "4px solid red" }}
        maxWidth="md"
        fullWidth
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{`Are you sure you want to delete Association with ${association.User.name}`}</DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button variant="contained" color="error" onClick={handleSubmit}>
            DELETE
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const MoreMenu = ({ association }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}>
        <MenuItem>
          <DeleteAssociation association={association} />
        </MenuItem>
      </Menu>
    </div>
  );
};
