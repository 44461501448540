import { Alert, AlertTitle, Button, Container } from "@mui/material";

import React, { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ResendEmail, VerifyEmailRequest } from "../../Redux/actions/Auth";
import PageHeader from "../../Components/Items/PageHeader";
import withAuth from "../../Hoc/withAuth";
import { useHistory } from "react-router-dom";

const VerifyEmail = ({ token }) => {
  const router = useHistory();

  if (!token) {
    router.push("/login");
  }

  const { emailToken } = useParams();

  const [unable, setDisable] = useState(null);

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);
  console.log(userInfo?.user?.userData);

  const emailVerified = userInfo?.user?.userData?.emailVerified;

  const emailSend = userInfo?.emailSend;

  const resend = (e) => {
    setDisable(true);
    e.preventDefault();
    dispatch(ResendEmail(userInfo?.user.email));
    setDisable(false);
  };

  useEffect(() => {
    if (emailToken !== "NotVerified") {
      dispatch(VerifyEmailRequest(emailToken));
    }
  }, [emailToken, dispatch]);

  return (
    <Container>
      <PageHeader
        title="Verify Email"
        description={emailVerified ? "Email Verified" : "Email not verified"}
      />
      {emailVerified ? (
        <Alert severity="success">Email verified</Alert>
      ) : (
        <>
          <Alert severity="error">
            <AlertTitle>Email not verified </AlertTitle>
            Please Check your mail for verification link
          </Alert>

          <Button onClick={(e) => resend(e)} disabled={unable ? true : false}>
            Resend Verify Email
          </Button>

          {emailSend && (
            <Alert severity="success">
              Email sent, open your email and verify your account
            </Alert>
          )}
        </>
      )}
    </Container>
  );
};

export default withAuth(VerifyEmail);
