export const eventOccReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "GET_OCC_REQUEST":
      return { ...state, loading: true };
    case "GET_CODE_REQUEST":
      return { ...state, loading: true };
    case "GET_OCC_SUCCESS":
      return { ...state, loading: false, success: true, data: action.payload };
    case "GET_CODE_SUCCESS":
      return { ...state, loading: false, success: true, code: action.payload };
    case "GET_OCC_FAIL":
      return { ...state, loading: false, error: action.payload };
    case "GET_CODE_FAIL":
      return { ...state, loading: false, error: action.payload };

    case "GET_OCC_RESET":
      return {};
    default:
      return state;
  }
};

const initialState = {
  loading: false,
  eventStarted: false,
  success: false,
  error: null,
  data: null,
  code: null,
};

export const joinEventReducer = (state = initialState, action) => {
  switch (action.type) {
    case "JOIN_REQUEST":
      return { ...state, loading: true, eventStarted: false };
    case "JOIN_SUCCESS":
      return {
        loading: false,
        success: true,
        eventStarted: true,
        data: action.payload,
      };
    case "JOIN_FAIL":
      return { loading: false, error: action.payload, eventStarted: false };
    case "JOIN_RESET":
      return {};
    default:
      return state;
  }
};

export const endEventReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "END_REQUEST":
      return { ...state, loading: true };
    case "END_SUCCESS":
      return { loading: false, success: true, data: action.payload };
    case "END_FAIL":
      return { loading: false, error: action.payload };
    case "END_RESET":
      return {};
    default:
      return state;
  }
};
