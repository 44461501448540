import { Container } from "@mui/material";
import React from "react";
import PageHeader from "../../../Components/Items/PageHeader";

const OrgUserAssociations = () => {
  return (
    <Container>
      <PageHeader
        title="Manage Associations"
        description="Manage your organization associations"
      />
    </Container>
  );
};

export default OrgUserAssociations;
