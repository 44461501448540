import { Alert, CircularProgress, Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { joinEventRequest } from "../../../Redux/actions/EventOcc";

const EventJoin = () => {
  const { id } = useParams();

  const dispatch = useDispatch();

  const joinEvent = useSelector((state) => state.joinEvent);
  const { success, error, loading } = joinEvent;

  useEffect(() => {
    dispatch(joinEventRequest({ id }));
  }, [id, dispatch]);

  return (
    <Container sx={{ mt: 4 }} maxWidth="md">
      <Grid container>
        <Grid item>
          {loading && <CircularProgress />}
          {success && (
            <Alert severity="success">
              You have been signed in to this event
            </Alert>
          )}
          {error && <Alert severity="error">{JSON.stringify(error)}</Alert>}
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventJoin;
