import * as React from "react";

import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LinearProgress from "@mui/material/LinearProgress";
import Container from "@mui/material/Container";
import {
  Stack,
  Button,
  Typography,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";
import UserProfile from "../../../Components/Forms/UserProfile";
import StudentAssoc from "./StudentAssoc";
import StudentSchoolForms from "../../../Components/Forms/Student/StudentSchoolForms";

let steps = [
  "Complete Profile",
  "Set Linked Users",
  "Set Up School Connection",
];

const StudentSetupProcess = ({ token, user }) => {
  const queryClient = useQueryClient();

  const updateMe = useMutation(
    "updateMe",
    (data) => {
      return axios.put(`${process.env.REACT_APP_PUBLIC_URL}/auth/me`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: "me",
        });
      },
    }
  );

  let step = user?.userData?.currentStep;

  const userData = user?.userData;

  const handleNext = () => {
    userData.currentStep = step + 1;
    updateMe.mutate({ userData });
  };

  const complete = () => {
    userData.currentStep = step + 1;
    userData.profileComplete = true;

    updateMe.mutate({ userData });

    // reload page
    window.location.reload(true);
  };

  const handleBack = () => {
    userData.currentStep = step - 1;
    updateMe.mutate({ userData });
  };

  const ReturnCurrentStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            <UserProfile data={user} updateMe={updateMe} />
          </div>
        );
      case 1:
        return (
          <div>
            <StudentAssoc />
          </div>
        );
      case 2:
        return (
          <div>
            <StudentSchoolForms user={user} token={token} />
          </div>
        );

      default:
        return (
          <Card sx={{ mt: 3 }}>
            <CardHeader title="Profile Setup Complete" />
            <CardContent>
              <Typography>
                You have successfully completed your profile setup.
              </Typography>
              <Button
                sx={{ mt: 3 }}
                variant="contained"
                color="success"
                onClick={() => {
                  complete();
                }}>
                Complete
              </Button>
            </CardContent>
          </Card>
        );
    }
  };

  return (
    <Container sx={{ width: "100%", mt: 2 }}>
      <Stepper activeStep={Number(step)} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {updateMe.isLoading && <LinearProgress />}
      <Card sx={{ my: 2 }}>
        <CardContent>{ReturnCurrentStep()}</CardContent>
      </Card>
      <Stack direction="row" justifyContent="space-between">
        <Button
          disabled={step === 0}
          variant="contained"
          color="warning"
          onClick={() => {
            handleBack();
          }}>
          Back
        </Button>

        {step === steps.length ? (
          <>
            {" "}
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                complete();
              }}>
              Complete
            </Button>
          </>
        ) : (
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              handleNext();
            }}>
            Next
          </Button>
        )}
      </Stack>
    </Container>
  );
};

export default StudentSetupProcess;
