import React, { useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getOrgById } from "../../../Redux/actions/Orgs";

import { useParams } from "react-router";
import {
  Container,
  Grid,
  Box,
  CardContent,
  Chip,
  Divider,
  Typography,
  // Rating,
  CircularProgress,
  Alert,
  Card,
} from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
// import FavoriteIcon from "@mui/icons-material/Favorite";
// import ShareIcon from "@mui/icons-material/Share";
// import UserRequestAssociation from "../../../Components/Forms/Orgs/UserRequestAssociation";
import ContentItem from "../../../Components/Containers/ContentListItem";
import PageHeader from "../../../Components/Items/PageHeader";

import EventsCard from "../../../Components/Containers/EventsCard";
import BannerImage from "../../../Components/ImageUploaders/BannerImage";

const OrgHome = () => {
  let { id } = useParams();

  const dispatch = useDispatch();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(getOrgById({ id }));
  }, [dispatch, id]);

  const orgById = useSelector((state) => state.orgById);
  const { data, loading, error } = orgById;

  console.log("data", data);

  return (
    <Container maxWidth="xl" sx={{ mt: 3 }}>
      <PageHeader title={data?.orgType} />
      {data?.approved !== true && (
        <Alert severity="warning" sx={{ mb: 2 }}>
          This organisation is not yet approved. Please check back later.
        </Alert>
      )}

      {loading && <CircularProgress />}
      {error && <Alert severity="error">{error}</Alert>}
      <Grid container spacing={2}>
        <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
          <Card
            sx={{
              maxWidth: "100",
            }}>
            <BannerImage uuid={data?.uuid} />
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Typography variant="h4">{data?.name}</Typography>

          <Typography gutterBottom variant="subtitle1" color="textPrimary">
            {data?.description}
          </Typography>
          <Divider />

          <ContentItem title="Contact Number" value={data?.telephone} />
          <ContentItem title="Email Address" value={data?.email} />
          <ContentItem
            title="Registration Number"
            value={data?.registrationNumber}
          />
          <ContentItem title="Website" value={data?.website} />
        </Grid>
      </Grid>

      <CardContent>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example">
              <Tab label="Events" {...a11yProps(0)} />
              <Tab label="About" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Grid container>
              {data?.Events &&
                data?.Events?.map((event, index) => {
                  return (
                    <Grid
                      key={index}
                      item
                      xl={4}
                      lg={4}
                      md={12}
                      sm={12}
                      xs={12}>
                      <EventsCard key={event.uuid} data={event} />
                    </Grid>
                  );
                })}
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
              variant="h6"
              color="initial">
              About
            </Typography>

            {data?.location?.address && (
              <iframe
                width="600"
                height="450"
                frameBorder="0"
                title={data?.location?.address}
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
  &q=${data?.location?.address}`}></iframe>
            )}
          </CustomTabPanel>
        </Box>
      </CardContent>

      <>
        <Box display="flex" justifyContent="space-between">
          <div>
            {data.tags &&
              data.tags.map((tag) => (
                <Chip sx={{ m: 1 }} variant="outlined" key={tag} label={tag} />
              ))}
          </div>
        </Box>
      </>
    </Container>
  );
};

export default OrgHome;

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ pt: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
