import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useHistory, useLocation } from "react-router";
import withAuth from "../../Hoc/withAuth";

import { List, Stack, useMediaQuery } from "@mui/material";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";

import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useDispatch } from "react-redux";

import MuiDrawer from "@mui/material/Drawer";
import { useTheme } from "@mui/material";
import logo from "../../images/logos/NewLogoLight.png";
import logo2 from "../../images/logos/NewLogoDark.png";

import SchoolAdminDrawer from "./Drawers/SchoolAdminDrawer";

import SchoolUserDrawer from "./Drawers/SchoolUserDrawer";
import StudentDrawer from "./Drawers/StudentDrawer";
import ParentDrawer from "./Drawers/ParentDrawer";
import OrgAdminDrawer from "./Drawers/OrgAdminDrawer";
import AdminDrawer from "./Drawers/AdminDrawer";
import BottomNav from "./BottomNav";

import AppBar from "@mui/material/AppBar";

import ListItemIcon from "@mui/material/ListItemIcon";
import ListItem from "@mui/material/ListItem";
import LogOutDialog from "../Dialogs/LogOutDialog";
import ConnectedOrgItems from "./Drawers/ConnectedOrgItems";
import { getMe } from "../../Redux/actions/Auth";

const NavBarWDrawer = ({ setTheme, currentTheme, user, children, token }) => {
  const router = useHistory();
  const dispatch = useDispatch();

  const location = useLocation();

  const [open, setOpen] = useState(true);

  const theme = useTheme();

  const isSmall = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // close the drawer when isSmall
    if (isSmall) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isSmall]);

  useEffect(() => {
    if (isSmall) {
      setOpen(false);
    }
  }, [location.pathname, isSmall]);

  useEffect(() => {
    if (token) {
      dispatch(getMe());
    }
  }, [dispatch, token]);

  return (
    <>
      {!open && (
        <Box sx={{ flexGrow: 1, mb: 5 }}>
          <AppBar
            // position="fixed"
            sx={{
              border: "none",
              backgroundColor: "background.default",
            }}>
            <Toolbar>
              <IconButton
                color="default"
                aria-label="open drawer"
                onClick={() => {
                  setOpen(!open);
                }}
                edge="start"
                sx={{
                  mr: 2,
                }}>
                {open ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
              <IconButton
                onClick={() => router.push("/")}
                size="large"
                edge="start"
                color="inherit"
                sx={{ p: 0, mr: 1 }}
                aria-label="open drawer">
                <img
                  src={theme.palette.mode === "dark" ? logo2 : logo}
                  height="35"
                  alt="Logo"
                />
              </IconButton>
              <Typography
                color="text.primary"
                variant="h6"
                noWrap
                component="div">
                Chalk It Up
              </Typography>
            </Toolbar>
          </AppBar>
        </Box>
      )}

      <Box sx={{ display: "flex", maxWidth: "100wv" }}>
        {user && user.role && (
          <Drawer variant="permanent" open={open}>
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  setOpen(!open);
                }}
                edge="start"
                sx={{
                  marginRight: 3,
                }}>
                {open ? <MenuOpenIcon /> : <MenuIcon />}
              </IconButton>
              <IconButton
                onClick={() => router.push("/")}
                size="large"
                edge="start"
                color="inherit"
                sx={{ p: 0, mr: 1 }}
                aria-label="open drawer">
                <img
                  src={theme.palette.mode === "dark" ? logo2 : logo}
                  height="35"
                  alt="Logo"
                />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                Chalk It Up
              </Typography>
            </Toolbar>

            <List>
              {user && <ConnectedOrgItems />}

              {user && user.role === "school-admin" && <SchoolAdminDrawer />}

              {user && user.role === "school-user" && <SchoolUserDrawer />}
              {user && user.role === "student" && <StudentDrawer />}
              {user && user.role === "parent" && <ParentDrawer />}

              {user && user.role === "org-admin" && <OrgAdminDrawer />}
              {user && user.role === "administrator" && <AdminDrawer />}
            </List>

            <Stack sx={{ my: "auto" }}></Stack>
            <ListItem
              button
              onClick={() => {
                setTheme(!currentTheme);
              }}>
              <ListItemIcon>
                {!currentTheme ? <LightModeIcon /> : <DarkModeIcon />}
              </ListItemIcon>
              Theme
            </ListItem>
            <LogOutDialog />
          </Drawer>
        )}

        {user && user.role && isSmall && <BottomNav user={user} />}

        <Container sx={{ flexGrow: 1, mb: 10 }}>{children}</Container>
      </Box>
    </>
  );
};

export default withAuth(NavBarWDrawer);

const drawerWidth = 300;

const openedMixin = (theme) => ({
  width: drawerWidth,
  border: "none",
  backgroundColor: theme.palette.background.default,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(0)} + 1px)`,
  [theme.breakpoints.up("md")]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,

  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
