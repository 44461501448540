import React from "react";
import withAuth from "../../../Hoc/withAuth";
import { IconList } from "../../IconList";
import {
  Card,
  CardContent,
  Grid,
  Stack,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQueryClient } from "react-query";
import axios from "axios";

const ListEventTags = ({ OrgTags, token }) => {
  const queryClient = useQueryClient();

  const Delete = useMutation(({ eventId, tagId }) => {
    return axios.delete(
      `${process.env.REACT_APP_PUBLIC_URL}/tags/eventTags/${eventId}/tag/${tagId}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  });

  const handleDelete = ({ eventId, tagId }) => {
    Delete.mutate(
      { eventId, tagId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["orgTags", eventId],
          });
        },
      }
    );
  };

  return (
    <Grid container spacing={2}>
      {OrgTags.map((row) => {
        let TheIcon = IconList.find(
          (icon) => icon.name === row?.tag.icon
        )?.Icon;
        return (
          <Grid item xs={12} sm={6} key={row?.tag.uuid}>
            <Card>
              <>
                <CardContent>
                  <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between">
                      <TheIcon fontSize="large" color={row?.tag.color} />
                      {Delete.isLoading ? (
                        <CircularProgress />
                      ) : (
                        <IconButton
                          color="error"
                          aria-label="delete"
                          onClick={() => {
                            handleDelete({
                              eventId: row?.eventId,
                              tagId: row?.tagId,
                            });
                          }}>
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </Stack>

                    <Typography variant="body1" fontWeight={500}>
                      {row?.tag.name}
                    </Typography>
                    <Typography variant="caption">
                      {row?.tag.description}
                    </Typography>
                  </Stack>
                </CardContent>
              </>
            </Card>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default withAuth(ListEventTags);
