import { Divider, Stack, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

const PageHeader = ({ title, description, info }) => {
  return (
    <Stack mt={4}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>

      <Divider sx={{ my: 2 }} />
    </Stack>
  );
};

export default PageHeader;

PageHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  info: PropTypes.string,
};
