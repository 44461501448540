import React, { useEffect, useCallback } from "react";
import {
  Container,
  Divider,
  Grid,
  CardContent,
  Typography,
  Card,
  CardHeader,
  Button,
  Stack,
  AvatarGroup,
  Avatar,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";
import JoinEvent from "../../../Components/Forms/JoinEvent";
import { getUserBookingsRequest } from "../../../Redux/actions/Bookings";
import { getMyRelations } from "../../../Redux/actions/Relations";
import PageHeader from "../../../Components/Items/PageHeader";
import { useQuery } from "react-query";
import axios from "axios";
import ViewOnlySlate from "../../../Components/Forms/ViewOnlySlate";
import DateRangeIcon from "@mui/icons-material/DateRange";
import dayjs from "dayjs";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { PieChart } from "@mui/x-charts/PieChart";
import { useDrawingArea } from "@mui/x-charts/hooks";
import { styled } from "@mui/material/styles";
import TagListComp from "../../../Components/Containers/TagListComp";
import BannerImage from "../../../Components/ImageUploaders/BannerImage";

const EventHome = () => {
  let { id } = useParams();
  let history = useHistory();
  const dispatch = useDispatch();

  const goBack = () => {
    history.goBack();
  };

  const userInfo = useSelector((state) => state.userInfo);

  const { token } = userInfo;

  const getMe = useQuery(
    "me",
    () => {
      return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/auth/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
    {
      enabled: !!token,
    }
  );

  const getEvent = useQuery({
    queryKey: ["event", id],
    queryFn: () => {
      return axios.get(`${process.env.REACT_APP_PUBLIC_URL}/events/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    },
  });

  let user = getMe?.data?.data?.user;

  let data = getEvent?.data?.data;

  const Relations = useSelector((state) => state.Relations);
  const { relations } = Relations;

  const GetUserBookings = useCallback(() => {
    if (user?.role === "parent") {
      relations?.forEach((relation) => {
        dispatch(getUserBookingsRequest(relation?.User?.uuid));
      });
    } else {
      dispatch(getUserBookingsRequest(user?.uuid));
    }
  }, [relations, user, dispatch]);

  const userBookings = useSelector((state) => state.userBookings);
  const { bookings } = userBookings;

  useEffect(() => {
    GetUserBookings();
  }, [GetUserBookings]);

  const Bookings = () => {
    let Booked = [];
    bookings?.forEach((booking) => {
      if (booking.eventId === data?.uuid) {
        Booked.push(booking);
      }
    });
    return Booked;
  };

  useEffect(() => {
    if (user?.role === "student" || user?.role === "parent") {
      dispatch(getMyRelations());
    }
  }, [dispatch, user]);

  const isStudentAuth = () => {
    let auth = false;
    relations.forEach((relation) => {
      if (relation.studentAuthorized === true) {
        auth = true;
      }
    });
    return auth;
  };

  let nextOccurrence = data?.Occurrences[0];

  return (
    <>
      <Container sx={{ mt: 3 }} maxWidth="xl">
        <Button onClick={goBack}>Go Back</Button>
        <PageHeader
          title="Event"
          description={data?.name}
          info={data?.description}
        />

        <Card>
          <CardHeader title={data?.name} subheader={data?.description} />
          {data?.eventTags && data?.eventTags?.length > 0 && (
            <TagListComp orgTags={data?.eventTags} />
          )}

          {data?.banner && <BannerImage uuid={data?.uuid} token={token} />}

          <CardContent>
            {(user?.role === "parent" ||
              (user?.role === "student" && isStudentAuth())) && (
              <JoinEvent
                bookings={Bookings()}
                data={data?.Occurrences}
                eventId={data?.uuid}
              />
            )}
          </CardContent>
          <Divider />
          <CardContent>
            <Stack spacing={2}>
              <Typography variant="h6">Next Date & Time</Typography>
              <Stack direction="row" spacing={2}>
                <AccessTimeIcon />
                <Stack direction="row">
                  {dayjs(nextOccurrence?.startTime).format("hh:mm A")} -{" "}
                  {dayjs(nextOccurrence?.endTime).format("hh:mm A")}
                </Stack>
              </Stack>
              <Stack direction="row" spacing={2}>
                <DateRangeIcon />
                <Stack>
                  {dayjs(nextOccurrence?.occurrence).format(
                    "dddd, MMMM D, YYYY"
                  )}
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
          <Divider />

          <CardContent>
            <Grid container>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Stack>
                  <Typography variant="h6">Spots Taken</Typography>

                  <PieChartWithCenterLabel />
                </Stack>
              </Grid>
              <Grid
                item
                md={6}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Stack>
                  <Typography variant="h6">Attendees</Typography>
                  <AvatarGroup total={24}>
                    <Avatar
                      alt="Remy Sharp"
                      src="https://picsum.photos/50/50"
                    />
                    <Avatar
                      alt="Travis Howard"
                      src={"https://picsum.photos/50/50"}
                    />
                    <Avatar
                      alt="Agnes Walker"
                      src="https://picsum.photos/50/50"
                    />
                    <Avatar
                      alt="Trevor Henderson"
                      src="https://picsum.photos/50/50"
                    />
                  </AvatarGroup>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />

          <CardContent>
            {data?.additionalInformation && (
              <>
                <Typography variant="h6">Additional Information</Typography>
                <ViewOnlySlate values={data?.additionalInformation} />
              </>
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
};

export default EventHome;

const data = [
  { value: 5, label: "A" },
  { value: 10, label: "B" },
];

const size = {
  width: 200,
  height: 100,
};

const StyledText = styled("text")(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: "middle",
  dominantBaseline: "central",
  fontSize: 10,
}));

function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

function PieChartWithCenterLabel() {
  return (
    <PieChart
      slotProps={{
        legend: { hidden: true },
      }}
      series={[{ data, innerRadius: 40 }]}
      {...size}>
      <PieCenterLabel>Spots Taken</PieCenterLabel>
    </PieChart>
  );
}
