export const SearchReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "SEARCH_REQUEST":
      return { ...state, loading: true };
    case "SEARCH_SUCCESS":
      return { loading: false, success: true, data: action.payload };
    case "SEARCH_FAIL":
      return { loading: false, error: action.payload };
    case "SEARCH_RESET":
      return {};
    default:
      return state;
  }
};
