import React, { useRef } from "react";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
// Import your CSS module here if you're using CSS modules
import { useQuery } from "react-query";
import "./TagRow.css";
import axios from "axios";
import withAuth from "../../Hoc/withAuth";
import { Button, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconList } from "../IconList";

const Tags = ({ token, selected, setSelected }) => {
  // Example data for the chips

  const getTags = useQuery({
    queryKey: ["tags"],
    queryFn: () =>
      axios(`${process.env.REACT_APP_PUBLIC_URL}/tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
  });

  const tags = getTags.data?.data;

  return (
    <Stack direction="row" spacing={0.5}>
      {tags
        ?.sort((a, b) => {
          return a.name < b.name ? -1 : 1;
        })
        ?.map((data, index) => {
          let TheIcon = IconList.find((icon) => icon.name === data.icon)?.Icon;
          return (
            <Chip
              key={index}
              onClick={() => {
                if (selected?.includes(data.name)) {
                  setSelected(selected?.filter((item) => item !== data?.name));
                } else {
                  setSelected([...selected, data.name]);
                }
              }}
              variant={selected?.includes(data?.name) ? "filled" : "outlined"}
              label={
                <Typography color="text.primary" variant="body2">
                  {data.name}
                </Typography>
              }
              sx={{ px: 1 }}
              color={selected?.includes(data.name) ? data.color : "default"}
              icon={<TheIcon />}
            />
          );
        })}
    </Stack>
  );
};

const TagRow = ({ token, selected, setSelected }) => {
  const scrollContainer = useRef(null);

  const scroll = (scrollOffset) => {
    scrollContainer.current.scrollLeft += scrollOffset;
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Button
        size="small"
        color="inherit"
        className="scrollButton left"
        onClick={() => scroll(-200)} // Adjust the scroll value as needed
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </Button>
      <div className="scrollContainer" ref={scrollContainer}>
        <Tags selected={selected} setSelected={setSelected} token={token} />
      </div>
      <Button
        size="small"
        color="inherit"
        className="scrollButton right"
        onClick={() => scroll(200)} // Adjust the scroll value as needed
      >
        <ArrowForwardIosIcon fontSize="small" />
      </Button>
    </Stack>
  );
};

export default withAuth(TagRow);
