import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";

import { useDispatch } from "react-redux";
import { approveAssociation } from "../../Redux/actions/OrgAssociationsActions";

export default function ApproveDialog({ ass, approveType, msg }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button onClick={handleClickOpen}>{msg ? msg : "Approve User"}</Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Approve</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {msg ? msg : "Are you sure you want to approve this user?"}
          </DialogContentText>

          <Formik
            initialValues={{
              org_id: ass.org_id,
              user_id: ass.user_id,
              approveType: approveType,
            }}
            onSubmit={(values) => {
              dispatch(approveAssociation({ values }));
              handleClose();
            }}>
            {() => {
              return (
                <Form>
                  <Stack sx={{ mt: 4 }} spacing={2}>
                    <Button type="submit" variant="contained">
                      Approve
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
