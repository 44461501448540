import React, { useRef } from "react";
import { Chip, IconButton, Stack, Typography } from "@mui/material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconList } from "../IconList";

const TagListComp = ({ orgTags }) => {
  const scrollContainer = useRef(null);

  const scroll = (scrollOffset) => {
    scrollContainer.current.scrollLeft += scrollOffset;
  };

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      {orgTags && orgTags.length > 2 && (
        <IconButton
          size="small"
          color="inherit"
          className="scrollButton left"
          onClick={() => scroll(-200)} // Adjust the scroll value as needed
        >
          <ArrowBackIosNewIcon fontSize="small" />
        </IconButton>
      )}

      <div className="scrollContainer" ref={scrollContainer}>
        <Stack direction="row" spacing={0.5}>
          {orgTags.length > 0 ? (
            orgTags?.map((data, index) => {
              let TheIcon = IconList.find(
                (icon) => icon.name === data.tag.icon
              )?.Icon;
              return (
                <Chip
                  size="small"
                  key={index}
                  variant="outlined"
                  label={
                    <Typography color="text.primary" variant="body2">
                      {data.tag.name}
                    </Typography>
                  }
                  sx={{ px: 1 }}
                  color={data.tag.color}
                  icon={<TheIcon />}
                />
              );
            })
          ) : (
            <Chip size="small" />
          )}
        </Stack>
      </div>
      {orgTags && orgTags.length > 2 && (
        <IconButton
          size="small"
          color="inherit"
          className="scrollButton right"
          onClick={() => scroll(200)} // Adjust the scroll value as needed
        >
          <ArrowForwardIosIcon fontSize="small" />
        </IconButton>
      )}
    </Stack>
  );
};

export default TagListComp;
