import React from "react";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import {
  Container,
  Typography,
  Divider,
  Grid,
  Button,
  Card,
  CardContent,
  Chip,
} from "@mui/material";
import withAuth from "../../Hoc/withAuth";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../../Components/Forms/FormComponents/TextfieldWrapper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SelectFieldWrapper from "../../Components/Forms/FormComponents/SelectFieldWrapper";
import { IconList } from "../../Components/IconList";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";

const AdminTags = ({ token }) => {
  const getTags = useQuery({
    queryKey: ["tags"],
    queryFn: () =>
      axios(`${process.env.REACT_APP_PUBLIC_URL}/tags`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
  });

  const createTags = useMutation(
    (tag) =>
      axios.post(`${process.env.REACT_APP_PUBLIC_URL}/tags`, tag, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    {
      onSuccess: () => {
        getTags.refetch();
      },
    }
  );

  const deleteTags = useMutation(
    (id) =>
      axios.delete(`${process.env.REACT_APP_PUBLIC_URL}/tags/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    {
      onSuccess: () => {
        getTags.refetch();
      },
    }
  );

  return (
    <Container>
      <Typography>Admin Tags</Typography>
      <Divider />

      <Grid container sx={{ mt: 3 }} spacing={2}>
        <Grid item xs={12}>
          <Formik
            initialValues={{ name: "", description: "", color: "", icon: "" }}
            onSubmit={(values) => createTags.mutate(values)}>
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextfieldWrapper name="name" label="Name" />
                        </Grid>
                        <Grid item xs={12}>
                          <TextfieldWrapper
                            name="description"
                            label="Description"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <SelectFieldWrapper
                            name="color"
                            label="Color"
                            options={[
                              {
                                label: "Green",
                                value: "success",
                              },
                              {
                                label: "Blue",
                                value: "primary",
                              },
                              {
                                label: "Red",
                                value: "error",
                              },
                              {
                                label: "Yellow",
                                value: "warning",
                              },
                              {
                                label: "Grey",
                                value: "default",
                              },
                            ]}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel id="select">Icons</InputLabel>
                            <Select
                              labelId="select"
                              id="select_id"
                              value={values.icon}
                              onChange={(event) => {
                                setFieldValue("icon", event.target.value);
                              }}
                              label="Icons">
                              {IconList?.map((item, index) => {
                                return (
                                  <MenuItem key={index} value={item.name}>
                                    <Stack direction="row" spacing={2}>
                                      <item.Icon />
                                      <Typography>{item.name}</Typography>
                                    </Stack>
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <Button variant="contained" type="submit">
                            Create Tag
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Form>
              );
            }}
          </Formik>
        </Grid>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="right">Icon</TableCell>

                  <TableCell align="right">Delete</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {getTags.data?.data.map((row) => {
                  let TheIcon = IconList.find(
                    (icon) => icon.name === row.icon
                  )?.Icon;

                  return (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}>
                      <TableCell component="th" scope="row">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.description}</TableCell>
                      <TableCell align="right">
                        {
                          <Chip
                            variant="outlined"
                            label={row.name}
                            sx={{ px: 1 }}
                            color={row.color}
                            icon={<TheIcon />}
                          />
                        }
                      </TableCell>

                      <TableCell align="right">
                        <Button
                          color="error"
                          onClick={() => {
                            deleteTags.mutate(row.uuid);
                          }}>
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default withAuth(AdminTags);
