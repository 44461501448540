export const tags = [
  "Animals",
  "Art & culture",
  "Babies",
  "Computers",
  "Children",
  "Community",
  "Education",
  "Environment",
  "Health & wellness",
  "Hunger",
  "People with disabilities",
  "Sport & recreation",
  "Seniors",
];
