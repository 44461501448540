import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";

import { useParams } from "react-router";
// import Logo from "../../images/logos/logo.png";
import { useDispatch } from "react-redux";

import axios from "axios";
import { url } from "../../Redux/actions/URL";
import { Stack } from "@mui/material";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const [response, setResponse] = useState();

  let { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "RESET" });
  }, [dispatch]);

  const resetHandler = async () => {
    console.log({ password });

    try {
      setLoading(true);

      const request = await axios.put(`${url}/auth/resetPassword/${id}`, {
        password,
      });

      setResponse(request);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setError(error.message);
    }
  };

  return (
    <Container maxWidth="xs">
      <Box my={4} display="flex" alignItems="center" justifyContent="center">
        {loading && <CircularProgress />}
      </Box>
      <Typography variant="h4" align="center">
        Reset Password
      </Typography>
      <TextField
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        margin="normal"
        type="password"
        variant="outlined"
        required
        fullWidth
        id="password"
        label="Enter new password"
        name="password"
        autoFocus
      />
      <TextField
        value={confirmPassword}
        onChange={(event) => setConfirmPassword(event.target.value)}
        margin="normal"
        type="password"
        variant="outlined"
        required
        fullWidth
        id="confirm password"
        label="Confirm new password"
        name="confirm password"
      />

      <Button
        fullWidth
        disabled={password !== confirmPassword ? true : false}
        variant="contained"
        color="primary"
        onClick={resetHandler}>
        Send request
      </Button>
      <Box mt={2}>
        {error && <Alert severity="error">{JSON.stringify(error)}</Alert>}
      </Box>
      <Box mt={2}>
        {response && (
          <Alert severity="success">
            <Stack spacing={2}>
              Password has been reset, please login with your new password
              <Link to="/login">Login</Link>
            </Stack>
          </Alert>
        )}
      </Box>
      <Grid container>
        <Grid item xs>
          <Link to="/PasswordResetReq" variant="body2">
            Forgot password?
          </Link>
        </Grid>
        <Grid item>
          <Link to="/register">{"Don't have an account? Sign Up"}</Link>
        </Grid>
      </Grid>
      <Box mt={8}>
        <Typography variant="body2" align="center">
          {"Copyright © "}
          <Link to="https://chalkitupsa.co.za">{" Chalk it Up "}</Link>
          {new Date().getFullYear()}
        </Typography>
        <Typography variant="body2" align="center">
          {"(Alpha v0.1.2)"}
        </Typography>
      </Box>
    </Container>
  );
};

export default PasswordReset;
