export const eventsReducer = (
  state = {
    data: {
      rows: [],
    },
  },
  action
) => {
  switch (action.type) {
    case "REQUEST_EVENTS":
      return { loading: true, ...state };
    case "GET_EVENTS_SUCCESS":
      // create set of events for infinite scroll
      function combineUniqueEvents(arr1, arr2) {
        const combinedArray = arr1;
        arr2.forEach((obj) => {
          if (
            !combinedArray?.some(
              (item) => JSON.stringify(item) === JSON.stringify(obj)
            )
          ) {
            combinedArray?.push(obj);
          }
        });
        return combinedArray;
      }

      return {
        loading: false,
        data: {
          ...state.data,
          rows: combineUniqueEvents(state.data.rows, action.payload.rows),
          count: action.payload.count,
        },
      };

    case "GET_EVENTS__SEARCH_SUCCESS":
      return {
        loading: false,
        data: {
          ...state.data,
          rows: action.payload.rows,
          count: action.payload.count,
        },
      };

    case "FAIL_EVENTS":
      return { loading: false, data: {}, error: action.payload };
    case "RESET_EVENTS":
      return {
        data: {
          rows: [],
        },
      };
    default:
      return state;
  }
};

export const eventByIdReducer = (state = { data: {} }, action) => {
  switch (action.type) {
    case "REQUEST_EVENT":
      return { loading: true, ...state };
    case "GET_EVENT_SUCCESS":
      return { loading: false, data: action.payload, OccurrenceCreated: false };

    case "FAIL_EVENT":
      return { loading: false, data: {}, error: action.payload };

    case "CREATE_SINGLE_OCCURRENCE_REQUEST":
      return { loading: true, ...state };
    case "CREATE_SINGLE_OCCURRENCE_SUCCESS":
      return {
        loading: false,
        OccurrenceCreated: true,
        data: {
          ...state.data,
          Occurrences: [...state.data.Occurrences, action.payload],
        },
      };
    case "CREATE_SINGLE_OCCURRENCE_FAIL":
      return { loading: false, error: action.payload };

    case "EDIT_EVENT_OCCURRENCE_REQUEST":
      return { loading: true, ...state };
    case "EDIT_EVENT_OCCURRENCE_SUCCESS":
      return {
        loading: false,
        data: {
          ...state.data,
          Occurrences: state.data.Occurrences.map((occ) =>
            occ.uuid === action.payload.uuid ? action.payload : occ
          ),
        },
      };
    case "EDIT_EVENT_OCCURRENCE_FAIL":
      return { loading: false, error: action.payload };

    case "RESET_EVENT":
      return {
        data: {
          OccurrenceCreated: false,
        },
      };
    default:
      return state;
  }
};

export const addEventReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "ADD_EVENT_REQUEST":
      return { loading: true, ...state };
    case "ADD_EVENT_SUCCESS":
      return { loading: false, ...action.payload };
    case "ADD_EVENT_FAIL":
      return { loading: false, error: action.payload };
    case "ADD_RESET_EVENT":
      return {};
    default:
      return state;
  }
};

export const editEventReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "EDIT_EVENT_REQUEST":
      return { loading: true, ...state };
    case "EDIT_EVENT_SUCCESS":
      return { loading: false, success: true, ...action.payload };
    case "EDIT_EVENT_FAIL":
      return { loading: false, error: action.payload };
    case "EDIT_RESET_EVENT":
      return {};
    default:
      return state;
  }
};

export const deleteEventReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "DELETE_EVENT_REQUEST":
      return { loading: true, ...state };
    case "DELETE_EVENT_SUCCESS":
      return { loading: false, success: true, ...action.payload };
    case "DELETE_EVENT_FAIL":
      return { loading: false, error: action.payload };
    case "DELETE_RESET_EVENT":
      return {};
    default:
      return state;
  }
};

export const getEventAdminReducer = (state = { loading: false }, action) => {
  switch (action.type) {
    case "GET_ADMIN_EVENT_REQUEST":
      return { loading: true, ...state };
    case "GET_ADMIN_EVENT_SUCCESS":
      return { loading: false, success: true, data: action.payload };
    case "GET_ADMIN_EVENT_FAIL":
      return { loading: false, error: action.payload };
    case "GET_ADMIN_EVENT_EVENT":
      return {};
    default:
      return state;
  }
};
