import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";

import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  useTheme,
} from "@mui/material";
import SchoolIcon from "@mui/icons-material/School";
import logo from "../../images/logos/NewLogoLight.png";
import logo2 from "../../images/logos/NewLogoDark.png";

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  RegisterAsParent,
  RegisterAsStudent,
  userRegister,
} from "../../Redux/actions/Auth";
import { Formik, Form } from "formik";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";
import * as Yup from "yup";
import FoundationIcon from "@mui/icons-material/Foundation";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";
import Diversity1Icon from "@mui/icons-material/Diversity1";

const validationSchema = Yup.object({
  username: Yup.string().required("Username is required"),
  email: Yup.string().email("Email is invalid").required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  role: Yup.string().required("Role is required"),
  parentEmail: Yup.string().when("role", {
    is: (role) => role === "student", // Ensure this is a function returning a boolean
    then: Yup.string()
      .email("Email is invalid")
      .required("Parent email is required for students."),
    otherwise: Yup.string().notRequired(), // Explicitly mark as not required for non-student roles
  }),
});

const Register = ({ location, history }) => {
  const router = useHistory();

  const theme = useTheme();
  const dispatch = useDispatch();

  let radialGradient = `radial-gradient(#0A90EF, #02B3F8)`;

  const userInfo = useSelector((state) => state.userInfo);
  const { error, loading, token, user } = userInfo;

  useEffect(() => {
    if (token) {
      if (user.role === "student") {
        router.push("/StudentDashBoard");
      } else if (user.role === "parent") {
        router.push("/parentDashboard");
      } else if (user.role === "school-admin") {
        router.push("/SchoolAdminDashBoard");
      } else if (user.role === "school-user") {
        router.push("/schoolUserDashboard");
      } else if (user.role === "org-admin") {
        router.push("/myorg");
      } else if (user.role === "org-user") {
        router.push("/orgUserDashboard");
      }
    }
  }, [token, router, user]);

  const RegisterHandler = (values) => {
    if (values.role === "student") {
      return dispatch(
        RegisterAsStudent({
          username: values.username,
          name: values.username,
          email: values.email,
          password: values.password,
          role: values.role,
          parentEmail: values.parentEmail,
        })
      );
    }
    if (values.role === "parent") {
      return dispatch(
        RegisterAsParent({
          username: values.username,
          name: values.username,
          email: values.email,
          password: values.password,
          role: values.role,
        })
      );
    }

    return dispatch(
      userRegister({
        username: values.username,
        name: values.username,
        email: values.email,
        password: values.password,
        role: values.role,
        parentEmail: values.parentEmail,
      })
    );
  };

  const userTypes = [
    {
      value: "student",
      label: "Student",
      disabled: false,
    },
    {
      value: "parent",
      label: "Parent",
      disabled: false,
    },
    {
      value: "org-admin",
      label: "Charity",
      disabled: false,
    },
    {
      value: "school-admin",
      label: "School Administrator",
      disabled: false,
    },
    {
      value: "school-user",
      label: "School Teacher",
      disabled: true,
    },
    {
      value: "org-user",
      label: "Charity User",
      disabled: true,
    },
  ];

  return (
    <Container maxWidth="xs">
      <Formik
        initialValues={{
          username: "",
          email: "",
          password: "",
          confirmPassword: "",
          role: "",
          parentEmail: "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          RegisterHandler(values);
        }}>
        {({
          errors,
          touched,
          handleChange,
          setFieldValue,
          handleBlur,
          handleSubmit,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Stack
              display="flex"
              flexDirection="column"
              alignItems="center"
              spacing={2}>
              <Box mt={4} display="flex" justifyContent="center">
                <img
                  style={{
                    width: "300px",
                    height: "310px",
                  }}
                  src={theme.palette.mode === "dark" ? logo2 : logo}
                  alt="logo"
                />
              </Box>
              <Typography mb={2} variant="h5" align="center">
                Chalk It Up
              </Typography>

              <div>
                <Typography variant="body1">Register</Typography>
              </div>

              <Grid
                sx={{ mt: 4 }}
                container
                display="flex"
                justifyContent="center">
                <Typography sx={{ mb: 2 }} variant="body1" align="center">
                  Select your role
                </Typography>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    {userTypes.map((option, index) => {
                      return (
                        <Grid key={index} item xs={6} md={4}>
                          <CardActionArea
                            disabled={option.disabled}
                            onClick={() => setFieldValue("role", option.value)}>
                            <Card
                              sx={{
                                color: option.disabled ? "#ccc" : "",
                                border:
                                  values.role === option.value
                                    ? `2px solid #02B3F8`
                                    : ``,
                                background:
                                  values.role === option.value
                                    ? radialGradient
                                    : "",
                                boxShadow:
                                  values.role === option.value
                                    ? theme.shadows[2]
                                    : "",
                              }}>
                              <CardContent>
                                <Stack
                                  sx={{ height: 60 }}
                                  display="flex"
                                  justifyContent="space-between"
                                  alignItems="center">
                                  {option.value === "student" && (
                                    <SchoolIcon
                                      sx={{
                                        color:
                                          values.role === "student" && "#fff",
                                      }}
                                    />
                                  )}
                                  {option.value === "school-admin" && (
                                    <LocationCityIcon
                                      sx={{
                                        color:
                                          values.role === "school-admin" &&
                                          "#fff",
                                      }}
                                    />
                                  )}
                                  {option.value === "school-user" && (
                                    <CastForEducationIcon
                                      sx={{
                                        color:
                                          values.role === "school-user" &&
                                          "#fff",
                                      }}
                                    />
                                  )}
                                  {option.value === "org-admin" && (
                                    <FoundationIcon
                                      sx={{
                                        color:
                                          values.role === "org-admin" && "#fff",
                                      }}
                                    />
                                  )}
                                  {option.value === "org-user" && (
                                    <Diversity1Icon
                                      sx={{
                                        color:
                                          values.role === "org-user" && "#fff",
                                      }}
                                    />
                                  )}
                                  {option.value === "parent" && (
                                    <FamilyRestroomIcon
                                      sx={{
                                        color:
                                          values.role === "parent" && "#fff",
                                      }}
                                    />
                                  )}
                                  <Typography
                                    color={
                                      option.value === values.role && "#fff"
                                    }
                                    variant="body2"
                                    align="center">
                                    {option.label}
                                  </Typography>
                                </Stack>
                              </CardContent>
                            </Card>
                          </CardActionArea>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </Grid>

              {values.role && (
                <>
                  {values.role === "org-admin" && (
                    <Typography>
                      Organizations will have to be verified by the admin.
                    </Typography>
                  )}
                  {values.role === "student" && (
                    <TextField
                      value={values.parentEmail}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={errors.parentEmail && touched.parentEmail}
                      helperText={
                        errors.parentEmail &&
                        touched.parentEmail &&
                        errors.parentEmail
                      }
                      type="email"
                      variant="outlined"
                      fullWidth
                      id="Parent email"
                      label="Parent email"
                      name="parentEmail"
                    />
                  )}
                  <TextField
                    value={values.username}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.username && touched.username}
                    helperText={
                      errors.username && touched.username && errors.username
                    }
                    name="username"
                    type="text"
                    variant="outlined"
                    required
                    fullWidth
                    label="Username"
                  />
                  <TextField
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.email && touched.email}
                    helperText={errors.email && touched.email && errors.email}
                    type="email"
                    variant="outlined"
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoFocus
                  />
                  <TextField
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.password && touched.password}
                    helperText={
                      errors.password && touched.password && errors.password
                    }
                    variant="outlined"
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                  />
                  <TextField
                    value={values.confirmPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={errors.confirmPassword && touched.confirmPassword}
                    helperText={
                      errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword
                    }
                    variant="outlined"
                    fullWidth
                    name="confirmPassword"
                    label="Confirm Password"
                    type="password"
                    id="confirm_password"
                  />
                  <Stack direction="row" sx={{ my: 2, width: "100%" }}>
                    {loading ? (
                      <Button
                        fullWidth
                        disabled
                        variant="contained"
                        color="primary">
                        ...Loading
                      </Button>
                    ) : (
                      <Button
                        fullWidth
                        type="submit"
                        variant="contained"
                        color="primary">
                        Sign Up
                      </Button>
                    )}
                  </Stack>
                </>
              )}
              <Grid container>
                <Grid item xs>
                  <Link to="/PasswordResetReq" variant="body2">
                    Forgot password?
                  </Link>
                </Grid>
                <Grid item>
                  <Link to="/login">{"Have an account? Sign In"}</Link>
                </Grid>
              </Grid>
            </Stack>
          </Form>
        )}
      </Formik>

      <Box mt={2}>
        {error && <Alert severity="error">{JSON.stringify(error)}</Alert>}
      </Box>

      <Box mt={8}>
        <Typography variant="body2" align="center">
          {"Copyright © "}
          <Link to="https://chalkitupsa.co.za">{" Chalk it Up "}</Link>
          {new Date().getFullYear()}
        </Typography>
        <Typography variant="body2" align="center">
          {"(Alpha v0.1.2)"}
        </Typography>
      </Box>
    </Container>
  );
};

export default Register;
