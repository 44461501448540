import Container from "@mui/material/Container";
import React, { useState } from "react";

import Slider from "@mui/material/Slider";
import { Stack, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import { LoadingButton } from "@mui/lab";
import { setHoursRequest } from "../../../Redux/actions/UserStats";

const SetGoals = ({ user }) => {
  // const [hourGoals, setHourGoals] = useState(0);

  return (
    <Container maxWidth="md">
      <SelectHourGoals user={user} />
    </Container>
  );
};

export default SetGoals;

function valueText(value) {
  return `${value}H`;
}

const marks = [
  { value: 0, label: "0" },
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 30, label: "30" },
  { value: 40, label: "40" },
  { value: 50, label: "50" },
  { value: 60, label: "60" },
  { value: 70, label: "70" },
  { value: 80, label: "80" },
  { value: 90, label: "90" },
  { value: 100, label: "100" },
];

const SelectHourGoals = ({ user }) => {
  const dispatch = useDispatch();

  const stats = useSelector((state) => state.stats);
  const { loading } = stats;

  const [hourGoals, setHourGoals] = useState(0);

  const handleChange = (event, newValue) => {
    setHourGoals(newValue);
  };

  const handelUpdateHours = () => {
    dispatch(setHoursRequest({ hourGoals }));
  };

  return (
    <div>
      <Typography variant="h5">Set your hourly goals for the year</Typography>
      <Stack sx={{ mb: 4 }}>
        <Slider
          id="input"
          value={hourGoals}
          onChange={handleChange}
          getAriaValueText={valueText}
          marks={marks}
          min={0}
          step={10}
          max={100}
        />
      </Stack>
      <LoadingButton
        loading={loading}
        onClick={() => handelUpdateHours()}
        disabled={hourGoals > 0 ? false : true}
        variant="outlined">
        Save
      </LoadingButton>
    </div>
  );
};
