import axios from "axios";
import { url } from "./URL";

export const GetSchoolNews =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_SCHOOL_NEWS" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
        },
      };

      const { data } = await axios.get(
        `${url}/schools?filter[org_id]=${id}`,
        { id },
        config
      );

      dispatch({ type: "GET_SCHOOL_NEWS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_SCHOOL_NEWS_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const AddSchoolDataRequest =
  (formData) => async (dispatch, getState) => {
    try {
      dispatch({ type: "ADD_SCHOOL_NEWS" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
        },
      };

      const { data } = await axios.post(`${url}/schools/add`, formData, config);

      dispatch({ type: "ADD_SCHOOL_NEWS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_SCHOOL_NEWS_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  
  export const updateSchoolData = (formData) => async (dispatch, getState) => {
    try{

      dispatch({ type : 'UPDATE_SCHOOL_DATA_REQUEST' })

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
        },
      };
      // console.log(formData);
      const { data } = await axios.put(`${url}/schools/${formData.id}`, formData, config);

      dispatch({ type : 'UPDATE_SCHOOL_DATA_SUCCESS', payload : data })

      dispatch(GetSchoolNews({id : formData.org_id}));

    }catch(error){
      dispatch({
        type: "UPDATE_SCHOOL_DATA_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  }

  export const deleteSchoolData= (id,org_id) => async (dispatch,getState) => {
    console.log(org_id)

    try{

      dispatch({ type : 'DELETE_SCHOOL_DATA_REQUEST' })

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`, // the token is a variable which holds the token
        },
      };
      // console.log(formData);
      const { data } = await axios.delete(`${url}/schools/${id}`, config);

      dispatch({ type : 'DELETE_SCHOOL_DATA_SUCCESS', payload : data });

      dispatch(GetSchoolNews({id : org_id}));

    }catch(error){
      dispatch({
        type: "DELETE_SCHOOL_DATA_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }

  }
