import axios from "axios";
import {
  ADD_BULK_STUDENTS,
  ADD_BULK_STUDENTS_FAILURE,
  ADD_BULK_STUDENTS_SUCCESS,
  GET_BULK_ADDED_STUDENTS_FAILURE,
  GET_BULK_ADDED_STUDENTS_SUCCESS,
} from "../Reducers/SchoolBulkManageStudentsReducer";

export const addBulkStudents = (students, id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_BULK_STUDENTS,
    });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}/schools/studentsBulk/${id}`,
      students,
      config
    );

    dispatch({
      type: ADD_BULK_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: ADD_BULK_STUDENTS_FAILURE,
      payload: error,
    });
  }
};

export const getBulkAddedStudents = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_BULK_STUDENTS,
    });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/schools/studentsBulk/${id}`,
      config
    );

    dispatch({
      type: GET_BULK_ADDED_STUDENTS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_BULK_ADDED_STUDENTS_FAILURE,
      payload: error,
    });
  }
};
