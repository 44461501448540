const initialState = {
  userNotifications: [],
  readNotification: [],
  loading: false,
  error: null,
  deleted: null,
  notificationSent: false,
};

export const getNotificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_NOTIFICATIONS":
      return { loading: true, ...state };
    case "GET_NOTIFICATIONS_SUCCESS":
      return {
        loading: false,
        success: true,
        userNotifications: [...action.payload.userNotifications],
      };
    case "GET_NOTIFICATIONS_FAIL":
      return { loading: false, error: action.payload };
    case "GET_NOTIFICATIONS_RESET":
      return {};

    case "RESET_SEEN_NOTIFICATION_REQUEST":
      return { loading: true, ...state };
    case "RESET_SEEN_NOTIFICATION_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
      };

    case "READ_NOTIFICATION_REQUEST":
      // console.log(state.userNotifications);
      return { ...state, loading: true, deleted: null };
    case "READ_NOTIFICATION_SUCCESS":
      // console.log(action.payload);
      return {
        ...state,
        loading: false,
        error: false,
        userNotifications: state.userNotifications.map((notification) => {
          return notification.uuid === action.payload.uuid ? action.payload : notification
        }),
        deleted: true,
      };
    case "READ_NOTIFICATION_FAIL":
      return {
        ...state,
        loading: false,
        error: action.payload,
        deleted: false,
      };

    case "RESET_SEEN_NOTIFICATION_FAIL":
      return { loading: false, error: action.payload };

    case "SEND_NOTIFICATION_REQUEST":
      return { loading: true, ...state };
    case "SEND_NOTIFICATION_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        notificationSent: true,
      };
    case "SEND_NOTIFICATION_FAIL":
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
