import axios from "axios";
import { url } from "./URL";

export const getOccurrenceRequest =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_OCC_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(`${url}/occurrences/${id}`, config);

      dispatch({ type: "GET_OCC_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_OCC_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const generateStartEndCodeRequest =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_CODE_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${url}/occurrences/generateStartEndCode/${id}`,
        config
      );

      dispatch({ type: "GET_CODE_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "GET_CODE_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const joinEventRequest =
  ({ id, startCode }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "JOIN_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${url}/occurrences/startEvent/${id}`,
        { startCode },
        config
      );

      dispatch({ type: "JOIN_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "JOIN_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const EndEventRequest =
  ({ id, endCode }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "END_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${url}/occurrences/endEvent/${id}`,
        { endCode },
        config
      );

      dispatch({ type: "END_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "END_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
