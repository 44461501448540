import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EndEventRequest } from "../../../Redux/actions/EventOcc";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useHistory } from "react-router";

// import Confetti from "react-confetti";
import moment from "moment";
import { getSingleBooking } from "../../../Redux/actions/Bookings";
import CodeScanner from "./CodeScanner";
// import useWindowSize from "../../../Hooks/useWindowSize";

const EventEndCode = () => {
  const { id } = useParams();
  // const { width, height } = useWindowSize();

  const [startScan, setStartScan] = useState(false);

  const router = useHistory();

  const [endCode, setEndCode] = useState("");
  const [EnteredEndCode, setEnteredEndCode] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleBooking({ id }));
  }, [dispatch, id]);

  const singleBooking = useSelector((state) => state.singleBooking);
  const { data, loading } = singleBooking;

  const handleEndEvent = () => {
    dispatch(EndEventRequest({ id, endCode: EnteredEndCode }));
    dispatch({ type: "GET_USER_BOOKING_RESET" });
  };

  useEffect(() => {
    if (endCode.length === 6) {
      setStartScan(false);
      console.log(endCode.split("/")[0]);
      dispatch(EndEventRequest({ id, endCode: endCode.split("/")[0] }));
    }
  }, [dispatch, id, router, endCode]);

  const endEvent = useSelector((state) => state.endEvent);
  const { success: endEventSuccess, data: endData } = endEvent;

  if (loading) {
    return <h1>Loading</h1>;
  }

  if (data?.endTime && data?.startTime) {
    return (
      <Container maxWidth="xl">
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card>
              <CardContent>
                <Stack spacing={2}>
                  <Typography variant="h6" align="center">
                    Event Ended
                  </Typography>

                  <Typography variant="h6" color="GrayText" align="center">
                    Your total time spent in the event was{" "}
                    {moment(data?.endTime).diff(
                      moment(data?.startTime),
                      "minutes"
                    )}{" "}
                    minutes
                  </Typography>

                  <Typography>TODO : /// Make a nice end page</Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="xl">
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h5" align="center" gutterBottom>
            End Event
          </Typography>

          <CardHeader
            title=" End Event by scanning the barcode from the event host."
            subheader="End Event"
          />
          <CardContent>
            <Stack spacing={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setStartScan(!startScan)}>
                Scan Barcode
              </Button>
              {startScan && <CodeScanner setStartCode={setEndCode} />}
            </Stack>
          </CardContent>

          <Card>
            <CardContent>
              <Stack spacing={2}>
                <Typography variant="h6" align="center">
                  End event by entering the event code
                </Typography>
                <Typography variant="caption" color="GrayText" align="center">
                  Event Hosts will have this code
                </Typography>
                <Stack alignItems="center" justifyContent="center">
                  <TextField
                    size="small"
                    name="Event Code"
                    label="End Event Code"
                    placeholder="123456"
                    value={EnteredEndCode}
                    onChange={(event) => setEnteredEndCode(event.target.value)}
                    color="primary"
                  />

                  {endEventSuccess ? (
                    <>
                      <Alert
                        sx={{ mt: 2 }}
                        severity="success">{`You have ended at ${endData?.endTime}`}</Alert>
                      {/* <Confetti width={width} height={height} /> */}
                    </>
                  ) : (
                    <Button
                      sx={{ mt: 2 }}
                      variant="contained"
                      color="primary"
                      onClick={() => handleEndEvent()}>
                      End
                    </Button>
                  )}
                </Stack>

                <Divider />
                <Button>Find Event Host</Button>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          {/* <EventsCard data={data?.Event} /> */}
        </Grid>
      </Grid>
    </Container>
  );
};

export default EventEndCode;
