import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import BookingsCard from "../../../Components/Containers/BookingCard";
import { getUserBookingsRequest } from "../../../Redux/actions/Bookings";
import withAuth from "../../../Hoc/withAuth";
import { Container, Grid, Stack, Typography, Button } from "@mui/material";
import { useHistory } from "react-router";
import moment from "moment";
import { getAssRequest } from "../../../Redux/actions/UserActions";
import { getMyRelations } from "../../../Redux/actions/Relations";
import PageHeader from "../../../Components/Items/PageHeader";
import AlertPopup from "../../../Components/Items/AlertPopup";

// const sortList = (list, sortBy) => {
//   if (sortBy === "eventDate") {
//     return list.sort((a, b) => {
//       return new Date(a.eventDate) - new Date(b.eventDate);
//     });
//   } else if (sortBy === "status") {
//     return list.sort((a, b) => {
//       if (a.status < b.status) {
//         return -1;
//       } else if (a.status > b.status) {
//         return 1;
//       } else {
//         return 0;
//       }
//     });
//   } else if (sortBy === "createdAt") {
//     return list.sort((a, b) => {
//       return new Date(a.createdAt) - new Date(b.createdAt);
//     });
//   }
// };

const Bookings = ({ user }) => {
  const dispatch = useDispatch();
  const router = useHistory();

  useEffect(() => {
    dispatch(getAssRequest());
    dispatch(getMyRelations());
  }, [dispatch]);

  const Relations = useSelector((state) => state.Relations);
  const { relations } = Relations;

  const GetUserBookings = useCallback(() => {
    if (user.role === "parent") {
      relations?.forEach((relation) => {
        dispatch(getUserBookingsRequest(relation?.User?.uuid));
      });
    } else {
      dispatch(getUserBookingsRequest(user.uuid));
    }
  }, [relations, user, dispatch]);

  const userBookings = useSelector((state) => state.userBookings);
  const { bookings, bookingCancel } = userBookings;

  useEffect(() => {
    GetUserBookings();
  }, [GetUserBookings]);

  const findEvent = (bookings) => {
    return bookings.filter((booking) => {
      return (
        moment(booking.eventDate).format("MMMM Do YYYY") ===
        moment().format("MMMM Do YYYY")
      );
    });
  };

  let todayEvents = findEvent(bookings);

  return (
    <Container sx={{ mt: 2 }}>
      <PageHeader
        title="Bookings"
        description={`You have ${todayEvents.length} bookings today`}
      />

      <AlertPopup
        open={bookingCancel}
        message={"Booking cancelled successfully"}
        severity="success"
      />

      {todayEvents && todayEvents.length > 0 ? (
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography
            align="center"
            variant="h5"
            gutterBottom
            sx={{ fontWeight: "bold", mb: 2 }}>
            Today's events
          </Typography>

          <Stack sx={{ border: 1, p: 2 }} spacing={2}>
            {todayEvents.map((booking, index) => {
              return (
                <div key={index}>
                  {booking.status !== "completed" ? (
                    <>
                      <BookingsCard
                        booking={booking}
                        uuid={booking.uuid}
                        avatar={booking.avatar}
                        name={booking.name}
                        timeSlots={booking.timeSlots}
                        eventDate={booking.eventDate}
                        status={booking.status}
                        createdAt={booking.createdAt}
                        eventId={booking.eventId}
                        router={router}
                      />

                      {user.role === "student" && (
                        <>
                          <Button
                            sx={{ mt: 2 }}
                            fullWidth
                            variant={
                              booking.status === "inProgress"
                                ? "contained"
                                : "outlined"
                            }
                            onClick={() =>
                              router.push(
                                `/EventJoinCode/${booking.uuid}/${
                                  booking.status === "inProgress"
                                    ? "end"
                                    : "start"
                                }`
                              )
                            }>
                            {booking.status === "inProgress" ? "end" : "start"}
                          </Button>
                        </>
                      )}
                    </>
                  ) : (
                    <div key={index}></div>
                  )}
                </div>
              );
            })}
          </Stack>
        </Grid>
      ) : (
        <Typography
          align="center"
          variant="h5"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 2 }}>
          No events today
        </Typography>
      )}

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {bookings &&
          bookings
            .sort((a, b) => moment(b.eventDate) - moment(a.eventDate))
            .map((booking, index) => {
              return (
                <Grid key={index} item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <BookingsCard
                    booking={booking}
                    uuid={booking.uuid}
                    timeSlots={booking.timeSlots}
                    avatar={booking.avatar}
                    name={booking.name}
                    eventDate={booking.eventDate}
                    status={booking.status}
                    createdAt={booking.createdAt}
                    eventId={booking.eventId}
                    router={router}
                  />
                </Grid>
              );
            })}
      </Grid>
    </Container>
  );
};

export default withAuth(Bookings);
