import React, { Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Link,
  Slide,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Divider,
  LinearProgress,
  IconButton,
  Alert,
} from "@mui/material";
import { SCHOOL_STUDENT_RESET } from "../../../Redux/Reducers/SchoolStudentReducer";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCardTwoToneIcon from "@mui/icons-material/AddCardTwoTone";
import { Stack } from "@mui/system";
import styled from "@emotion/styled";
import { useMutation, useQuery } from "react-query";
import axios from "axios";
import withAuth from "../../../Hoc/withAuth";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ControlCameraTwoToneIcon from "@mui/icons-material/ControlCameraTwoTone";

const SchoolViewStudent = React.lazy(() => import("./SchoolViewStudent"));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SchoolManageGradesAndClasses = ({ token, org_id }) => {
  let id1 = org_id;

  let id2 = useParams().id;

  let id = id1 || id2;

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["grades", id],
    queryFn: () =>
      axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/grades/${id}`,
        config
      ),
  });

  useEffect(() => {
    if (data) {
      const sortedData = data?.data.sort((a, b) => {
        return (
          a.order - b.order || new Date(a.createdAt) - new Date(b.createdAt)
        );
      });

      setGrades(sortedData);
    }
  }, [data]);

  const [Grades, setGrades] = useState([]);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    const newCards = Array.from(Grades);
    const [removed] = newCards.splice(source.index, 1);
    newCards.splice(destination.index, 0, removed);

    // Update local state first
    setGrades(newCards);

    // Now, update the order in the backend
    // Assuming the order should reflect the array index + 1 (or another starting value)
    newCards.forEach((grade, index) => {
      // Only update if the order has changed
      if (grade.order !== index + 1) {
        const updatedGrade = { ...grade, order: index + 1 };
        // Here, use your mutation function to update each grade
        // Assuming you have access to a function to update each grade's order
        EditGradeName.mutate({
          ...grade,
          order: updatedGrade.order,
        });
      }
    });
  };
  const createGrade = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/grades`,
        data,
        config
      ),

    onSuccess: () => {
      refetch();
    },
  });

  const EditGradeName = useMutation({
    mutationFn: (data) =>
      axios.patch(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/grades/${data?.uuid}`,
        data,
        config
      ),

    onSuccess: () => {
      refetch();
    },
  });

  const deleteGrade = useMutation({
    mutationFn: (data) =>
      axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/grades/${data.uuid}`,
        config
      ),
    onSuccess: () => {
      refetch();
    },
  });

  const CreateClasses = useMutation({
    mutationFn: (data) =>
      axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/classes`,
        data,
        config
      ),

    onMutate: (newData) => {
      console.log(newData);
    },

    onSuccess: () => {
      refetch();
    },
  });

  const deleteClass = useMutation({
    mutationFn: (data) =>
      axios.delete(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/classes/${data.uuid}`,
        config
      ),
    onSuccess: () => {
      refetch();
    },
  });

  const EditClassName = useMutation({
    mutationFn: (data) =>
      axios.patch(
        `${process.env.REACT_APP_PUBLIC_URL}/schools/classes/${data.uuid}`,
        data,
        config
      ),

    onSuccess: () => {
      refetch();
    },
  });

  if (isLoading) {
    return <LinearProgress />;
  }

  return (
    <>
      <GradesDialog Grades={Grades} createGrade={createGrade} org_id={id} />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {Grades &&
                Grades.length > 0 &&
                Grades.map((item, index) => (
                  <Draggable
                    key={item.uuid}
                    draggableId={item.uuid}
                    index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef} // Correct usage of innerRef
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <Card sx={{ width: "100%" }}>
                          <CardHeader
                            title={
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between">
                                {item.grade}
                                <ControlCameraTwoToneIcon />
                                <EditGrade
                                  EditGradeName={EditGradeName}
                                  item={item}
                                />
                              </Stack>
                            }
                            action={
                              <Stack
                                direction="row"
                                justifyContent="flex-end"
                                sx={{ m: 0, p: 0, width: "100%" }}>
                                <IconButton
                                  onClick={() => {
                                    deleteGrade.mutate({ uuid: item?.uuid });
                                  }}>
                                  <DeleteIcon />
                                </IconButton>
                              </Stack>
                            }
                          />
                          <Divider />
                          <CardContent>
                            <ClassDialog
                              CreateClasses={CreateClasses}
                              org_id={id}
                              grade={item?.uuid}
                              gradeName={item?.grade}
                            />
                            {item?.SchoolClasses &&
                            item?.SchoolClasses?.length > 0 ? (
                              item?.SchoolClasses.map((item2, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <Accordion
                                      sx={{
                                        border: "none",
                                      }}
                                      key={index}>
                                      <AccordionSummary
                                        sx={{
                                          // color if accordion is expanded
                                          "&.Mui-expanded": {
                                            backgroundColor:
                                              "background.default",
                                            color: "info.main",
                                            borderBottom: 1,
                                            borderRadius: 2,
                                          },

                                          flexDirection: "row-reverse",
                                        }}
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls={`panel${index}b-content`}
                                        id="panel1b-header">
                                        <Stack
                                          sx={{ width: "100%" }}
                                          direction="row"
                                          justifyContent="space-between"
                                          alignItems="center">
                                          <Typography>{item2.name}</Typography>
                                          <Typography>
                                            Students :
                                            {item2.SchoolStudents &&
                                              item2.SchoolStudents.length}
                                          </Typography>
                                          <IconButton
                                            onClick={() => {
                                              deleteClass.mutate({
                                                uuid: item2?.uuid,
                                              });
                                            }}>
                                            <DeleteIcon />
                                          </IconButton>
                                        </Stack>
                                      </AccordionSummary>

                                      <AccordionDetails>
                                        <Stack
                                          direction="row"
                                          sx={{ mb: 2, width: "100%" }}
                                          justifyContent="flex-start">
                                          <ClassEdit
                                            EditClassName={EditClassName}
                                            classDetails={item2}
                                          />
                                        </Stack>
                                        <TableContainer>
                                          <Table
                                            sx={{
                                              minWidth: 650,
                                            }}
                                            aria-label="a dense table">
                                            <TableHead
                                              sx={{
                                                backgroundColor:
                                                  "background.paper",
                                              }}>
                                              <TableRow>
                                                <TableCell>Avatar</TableCell>
                                                <TableCell>
                                                  Student Name
                                                </TableCell>
                                                <TableCell>
                                                  Student Email
                                                </TableCell>
                                                <TableCell>
                                                  Parent(s) Email
                                                </TableCell>
                                                <TableCell>
                                                  View Student
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody>
                                              {item2?.SchoolStudents?.map(
                                                (student) => {
                                                  return (
                                                    <StyledTableRow
                                                      key={student?.User?.uuid}>
                                                      <StyledTableCell
                                                        component="th"
                                                        scope="row">
                                                        <Avatar
                                                          alt={`Student ${student.User.name} avatar`}
                                                          src={`${process.env.REACT_APP_IMAGE_URL}/${student.User.avatar}`}
                                                        />
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        component="th"
                                                        scope="row">
                                                        {student.User.name}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        component="th"
                                                        scope="row">
                                                        <Link
                                                          href={`mailto:${student.User.email}`}>
                                                          {student.User.email}
                                                        </Link>
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        component="th"
                                                        scope="row">
                                                        {student?.User?.relations.map(
                                                          (parent) => {
                                                            return (
                                                              <Stack
                                                                key={
                                                                  parent?.uuid
                                                                }>
                                                                <Link
                                                                  href={`mailto:${parent.parentEmail}`}>
                                                                  {
                                                                    parent.parentEmail
                                                                  }
                                                                </Link>
                                                              </Stack>
                                                            );
                                                          }
                                                        )}
                                                      </StyledTableCell>
                                                      <StyledTableCell
                                                        component="th"
                                                        scope="row">
                                                        <Suspense
                                                          fallback={
                                                            <div>
                                                              Loading...
                                                            </div>
                                                          }>
                                                          <SchoolViewStudent
                                                            schoolStudent={
                                                              student
                                                            }
                                                          />
                                                        </Suspense>
                                                      </StyledTableCell>
                                                    </StyledTableRow>
                                                  );
                                                }
                                              )}
                                            </TableBody>
                                          </Table>
                                        </TableContainer>
                                      </AccordionDetails>
                                    </Accordion>
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              <Alert severity="warning">
                                No Classes Found for {item.grade}
                              </Alert>
                            )}
                          </CardContent>
                        </Card>
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default withAuth(SchoolManageGradesAndClasses);

function ClassDialog({ org_id, grade, gradeName, CreateClasses }) {
  const [open2, setOpen2] = React.useState(false);

  const dispatch = useDispatch();

  const [className, setClassName] = React.useState("");

  const handelSubmit = () => {
    let formData = {
      org_id,
      grade,
      name: className,
    };

    CreateClasses.mutate(formData);
    setClassName("");
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen2(true);
  };

  const handleClose = () => {
    dispatch({ type: SCHOOL_STUDENT_RESET });
    setOpen2(false);
  };

  return (
    <div>
      <Button
        sx={{ mb: 2 }}
        startIcon={<AddCardTwoToneIcon />}
        onClick={handleClickOpen}>
        Add Class {gradeName}
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open2}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Create New Class
          </Typography>
          <Typography variant="h6">{gradeName}</Typography>
          <TextField
            id="standard-basic"
            label="Class Name"
            fullWidth
            margin="normal"
            value={className}
            onChange={(e) => setClassName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handelSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function GradesDialog({ org_id, createGrade, Grades }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const [grade, setGrade] = React.useState("");

  const handelSubmit = () => {
    let formData = {
      org_id,
      grade,
      order: Grades?.length + 1,
    };

    // dispatch(addSchoolGrade(formData));
    createGrade.mutate(formData);
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ type: SCHOOL_STUDENT_RESET });

    setOpen(false);
  };

  return (
    <div>
      <Button
        sx={{ my: 2 }}
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}>
        Add A new Grade to Your School
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <DialogContent>
          <Typography variant="h6">Create New Grade</Typography>
          <TextField
            id="standard-basic"
            label="Grade Name"
            fullWidth
            margin="normal"
            value={grade}
            onChange={(e) => setGrade(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handelSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function EditGrade({ item, EditGradeName }) {
  const [open, setOpen] = React.useState(false);

  const dispatch = useDispatch();

  const [grade, setGrade] = React.useState(item.grade);

  const handelSubmit = () => {
    let formData = {
      uuid: item?.uuid,
      grade,
    };

    // dispatch(editSchoolGrade(formData));
    EditGradeName.mutate(formData);
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    dispatch({ type: SCHOOL_STUDENT_RESET });
    setOpen(false);
  };

  return (
    <div>
      <Button sx={{ m: 2 }} color="secondary" onClick={handleClickOpen}>
        Edit name
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <DialogContent>
          <Typography variant="h6">Edit Grade</Typography>
          <TextField
            id="standard-basic"
            label="Grade Name"
            fullWidth
            margin="normal"
            value={grade}
            onChange={(e) => setGrade(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handelSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

function ClassEdit({ classDetails, EditClassName }) {
  const [open3, setOpen3] = React.useState(false);

  const dispatch = useDispatch();

  const [className, setClassName] = React.useState(classDetails.name);

  const handelSubmit = () => {
    let formData = {
      ...classDetails,
      name: className,
    };
    EditClassName.mutate(formData);
    handleClose();
  };

  const handleClickOpen = () => {
    setOpen3(true);
  };

  const handleClose = () => {
    dispatch({ type: SCHOOL_STUDENT_RESET });
    setOpen3(false);
  };

  return (
    <div>
      <Button sx={{ my: 0, py: 0 }} onClick={handleClickOpen}>
        Edit Class Name
      </Button>
      <Dialog
        maxWidth="md"
        fullWidth
        open={open3}
        onClose={handleClose}
        TransitionComponent={Transition}>
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Edit Class Name
          </Typography>
          <Typography variant="h6">{classDetails.name}</Typography>
          <TextField
            id="standard-basic"
            label="Class Name"
            fullWidth
            margin="normal"
            value={className}
            onChange={(e) => setClassName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant="contained" onClick={handelSubmit}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
