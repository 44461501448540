import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Grid,
  Typography,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getEventByOrgIdRequest,
  getMyOrg,
  getOrgById,
} from "../../../Redux/actions/Orgs";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory, useParams } from "react-router";
import withAuth from "../../../Hoc/withAuth";
import moment from "moment";
import PageHeader from "../../../Components/Items/PageHeader";
import EventNoteTwoToneIcon from "@mui/icons-material/EventNoteTwoTone";
import { Stack } from "@mui/system";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Form, Formik } from "formik";
import TextfieldWrapper from "../../../Components/Forms/FormComponents/TextfieldWrapper";
import { useMutation } from "react-query";
import axios from "axios";
import * as Yup from "yup";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import BannerImage from "../../../Components/ImageUploaders/BannerImage";

const MyEvents = ({ token }) => {
  const { id } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getEventByOrgIdRequest(id));
    dispatch({ type: "ADD_RESET_EVENT" });
    dispatch(getMyOrg());
    dispatch({ type: "EDIT_ORG_RESET" });
  }, [dispatch, id]);

  const [passEvents, setPassEvents] = useState(false);

  const orgEvents = useSelector((state) => state.orgEvents);
  const { error, data } = orgEvents;

  useEffect(() => {
    dispatch(getOrgById({ id }));
  }, [dispatch, id]);

  const orgById = useSelector((state) => state.orgById);
  const { data: orgData } = orgById;

  const eventsWithOccurrences = data?.events?.filter(
    (event) => event?.Occurrences.length > 0
  );

  const EventWithNextOccurrence = (events) => {
    // return event with the next occurrence

    return events?.map((event) => {
      const nextOccurrence = event?.Occurrences.sort(
        (a, b) => new Date(a.occurrence) - new Date(b.occurrence)
      )[0];

      return nextOccurrence;
    });
  };

  const sortEvents = (events) => {
    return events.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  };

  return (
    <Container>
      <PageHeader title="Events Manager" />

      <Grid container spacing={2} justifyContent="center">
        <Container>
          <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
              my: 3,
            }}>
            <EventNoteTwoToneIcon align="center" sx={{ fontSize: 60, mb: 3 }} />

            {orgData.approved ? (
              <StartNewEvent id={id} token={token} />
            ) : (
              // <Button
              //   fullWidth
              //   variant="contained"
              //   onClick={() => router.push(`/orgs/${data && data.org}/event`)}>
              //   Create New Event
              // </Button>
              <Alert severity="warning">
                Your organization is not approved yet. Please contact your
                administrator to approve your organization.
              </Alert>
            )}
          </Stack>

          {error && (
            <Alert severity="error">
              Oops Something went wrong. Please reload.
            </Alert>
          )}

          {EventWithNextOccurrence(eventsWithOccurrences) &&
            EventWithNextOccurrence(eventsWithOccurrences).length > 0 && (
              <OccurrenceTable
                occurrences={EventWithNextOccurrence(
                  eventsWithOccurrences
                ).slice(0, 3)}
              />
            )}

          <Button
            sx={{ my: 2 }}
            onClick={() => setPassEvents(!passEvents)}
            variant={passEvents ? "contained" : "outlined"}>
            {passEvents ? "Upcoming Events" : "Past Events"}
          </Button>

          <Grid container spacing={2}>
            <>
              {data?.events &&
                sortEvents(data?.events).map((event) => {
                  return <EventCard key={event.uuid} event={event} />;
                })}
            </>
          </Grid>
        </Container>
      </Grid>
    </Container>
  );
};

export default withAuth(MyEvents);

const OccurrenceTable = ({ occurrences }) => {
  const columns = [
    {
      field: "View Event",
      headerName: "View Event",
      width: 100,
      renderCell: (params) => {
        console.log("Row", params.row);
        return (
          <Link to={`/editEvent/${params.row.eventId}`}>
            <Button variant="contained" color="info">
              View
            </Button>
          </Link>
        );
      },
    },
    {
      field: "occurrence",
      headerName: "Occurrence",
      width: 200,
    },

    {
      field: "View Occurrence",
      headerName: "View Occurrence",
      width: 200,
      renderCell: (params) => (
        <Link to={`/OccurrencePage/${params.row.id}`}>
          <Button variant="contained" color="secondary">
            View Occurrence
          </Button>
        </Link>
      ),
    },

    {
      field: "startTime",
      headerName: "Start Time",
      width: 200,
    },
    {
      field: "endTime",
      headerName: "End Time",
      width: 200,
    },
    {
      field: "totalTime",
      headerName: "Total Time",
      width: 200,
    },
    {
      field: "totalBookings",
      headerName: "Total Bookings",
      width: 200,
    },
  ];

  let rows = occurrences.map((occurrence) => {
    return {
      id: occurrence.uuid,
      occurrence: moment(occurrence.occurrence).format("MMMM Do YYYY"),
      startTime: moment(occurrence.startTime).format("hh:mm A"),
      endTime: moment(occurrence.endTime).format("hh:mm A"),
      eventId: occurrence.eventId,
      totalTime: moment(occurrence.endTime).diff(
        moment(occurrence.startTime),
        "minutes"
      ),
      totalBookings: occurrence.Bookings.length,
    };
  });

  return (
    <Stack
      sx={{
        width: "100%",
        overflowX: "auto",
        maxHeight: "60vh",
        height: "40vh",
      }}>
      {rows && rows.length > 0 && (
        <DataGridPremium rows={rows} columns={columns} />
      )}
    </Stack>
  );
};

const EventCard = ({ event }) => {
  return (
    <Grid key={event.uuid} item xl={12} lg={12} md={12} sm={12} xs={12}>
      <Card
        sx={{
          border: 2,
          borderColor: "primary.main",
          borderRadius: 2,
        }}>
        <CardHeader
          subheader={moment(event.createdAt).format("MMMM Do YYYY h:mm a")}
        />
        <BannerImage uuid={event?.uuid} />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {event?.name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}>
            <Link
              style={{ textDecoration: "none" }}
              to={`/events/${event.uuid}`}>
              <Stack>
                <Button variant="contained" color="info">
                  View
                </Button>
                <Typography variant="caption" color="text.secondary">
                  As it will be displayed to all users
                </Typography>
              </Stack>
            </Link>
            <Link
              style={{ textDecoration: "none" }}
              to={`/editEvent/${event.uuid}`}>
              <Stack>
                <Button variant="contained" color="warning">
                  Edit Event
                </Button>
                <Typography variant="caption" color="text.secondary">
                  Customize details
                </Typography>
              </Stack>
            </Link>
          </Box>
          <Typography variant="body2" color="text.secondary">
            {event?.description}
          </Typography>

          {event?.Occurrences && event?.Occurrences.length > 0 ? (
            <OccurrenceTable occurrences={event?.Occurrences} />
          ) : (
            <Alert color="error">No Occurrences</Alert>
          )}
        </CardContent>
      </Card>
    </Grid>
  );
};

function StartNewEvent({ id, token }) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const createEvent = useMutation({
    mutationFn: (values) =>
      axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}/orgs/${id}/events`,
        values,
        config
      ),

    onSuccess: (data) => {
      router.push(`/editEvent/${data?.data?.data?.uuid}`);
    },
  });

  const router = useHistory();

  return (
    <React.Fragment>
      <Button
        fullWidth
        color="primary"
        variant="contained"
        onClick={handleClickOpen}>
        Create New Event
      </Button>
      <Dialog fullWidth={true} maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>Create New Event</DialogTitle>
        <Formik
          initialValues={{
            name: "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required("Required"),
          })}
          onSubmit={(values) => {
            createEvent.mutate(values);
          }}>
          {() => {
            return (
              <Form>
                <DialogContent>
                  <Stack>
                    <Typography>Event Name</Typography>
                    <Typography variant="caption" color="text.secondary">
                      The name of the event
                    </Typography>
                    <TextfieldWrapper autoFocus name="name" />
                  </Stack>

                  {createEvent.isError && (
                    <Alert severity="error">
                      {createEvent.error?.response?.data?.message}
                    </Alert>
                  )}
                </DialogContent>

                <DialogActions>
                  <Button onClick={handleClose} color="secondary">
                    Cancel
                  </Button>

                  {createEvent.isLoading ? (
                    <Button color="primary" disabled variant="contained">
                      Loading
                    </Button>
                  ) : (
                    <Button type="submit" color="primary" variant="contained">
                      Create
                    </Button>
                  )}
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
