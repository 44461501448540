import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Typography, Stack } from "@mui/material";

import { useField, useFormikContext } from "formik";
import dayjs from "dayjs";

const DOBPicker = ({ name, ...otherProps }) => {
  const [field, mata] = useField(name);
  const { setFieldValue } = useFormikContext();

  const configTextfield = {
    ...field,
    ...otherProps,
    fullWidth: true,
    variant: "outlined",
  };

  if (mata && mata.touched && mata.error) {
    configTextfield.error = true;
    configTextfield.helperText = mata.error;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Stack>
        <DatePicker
          {...configTextfield}
          name={name}
          label="Date of Birth"
          format="DD/MM/YYYY"
          openTo="year"
          minDate={dayjs(new Date(1900, 0, 1))}
          maxDate={dayjs(new Date())}
          value={field.value ? dayjs(field.value) : null}
          onChange={(date) => setFieldValue(name, dayjs(date))}
          helperText={mata.touched && mata.error}
        />
        {mata.error && (
          <Typography variant="caption" color="error">
            {mata.error}
          </Typography>
        )}
      </Stack>
    </LocalizationProvider>
  );
};

export default DOBPicker;
