import {
  Alert,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DateAdapter from "@mui/lab/AdapterMoment";

import { useSelector, useDispatch } from "react-redux";

import { getTotalStats } from "../../../Redux/actions/UserStats";
import BookingsStatsTable from "../../../Components/Containers/BookingsStatsTable";
import withAuth from "../../../Hoc/withAuth";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { getAssRequest } from "../../../Redux/actions/UserActions";

const ParentStats = ({ user }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAssRequest());
  }, [dispatch]);

  const associations = useSelector((state) => state.associations);
  const { user: ass } = associations;

  const [studentId, setStudentId] = useState(0);
  const [userStats, setUserStats] = useState(0);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    if (studentId !== 0) {
      dispatch(getTotalStats({ studentId }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentId]);

  const stats = useSelector((state) => state.stats);
  const { data } = stats;

  useEffect(() => {
    setUserStats(data);
  }, [data]);

  let userSelected;

  if (studentId) {
    userSelected = ass.filter((as) => as.userId !== studentId);
  }

  console.log(userSelected);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xl={12}>
          <Card>
            <CardContent>
              <Stack direction="row" spacing={2} justifyContent="space-between">
                <FormControl sx={{ minWidth: 300 }}>
                  <InputLabel id="demo-simple-select-label">
                    studentId
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={studentId}
                    label="studentId"
                    onChange={(event) => setStudentId(event.target.value)}>
                    <MenuItem value={0}>select</MenuItem>
                    {ass &&
                      ass.map((user) => (
                        <MenuItem key={user.User.uuid} value={user.User.uuid}>
                          {user.User.username}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <LocalizationProvider dateAdapter={DateAdapter}>
                  <DatePicker
                    label="Start Date"
                    views={["day"]}
                    value={startDate}
                    onChange={(newValue) => {
                      setStartDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />

                  <DatePicker
                    label="End Date"
                    views={["day"]}
                    value={endDate}
                    onChange={(newValue) => {
                      setEndDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </Stack>
            </CardContent>
          </Card>
        </Grid>

        <Grid>
          <Card>{JSON.stringify(userStats)}</Card>
        </Grid>

        {studentId && userStats ? (
          <>
            <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
              <BookingsStatsTable data={userStats?.bookings} />
            </Grid>
          </>
        ) : (
          <Alert severity="info">No Stats found for this user </Alert>
        )}
      </Grid>
    </Container>
  );
};

export default withAuth(ParentStats);
