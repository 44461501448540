import axios from "axios";

export const getOrgs = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "REQUEST_ORGS" });

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/orgs`
    );

    dispatch({ type: "GET_ORGS_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "FAIL_ORGS",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const OrgsSearch =
  ({ rowsPerPage, page, values }) =>
  async (dispatch) => {
    dispatch({ type: "REQUEST_ORGS" });
    try {
      let S = values ? "&search=" + values.search : "";
      let P = rowsPerPage ? rowsPerPage : 20;
      let PA = page ? page : 0;

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/orgs/search?pageNumber=${PA}&limit=${P}${S}`
      );
      values
        ? dispatch({ type: "GET_ORGS__SEARCH_SUCCESS", payload: data })
        : dispatch({ type: "GET_ORGS_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_ORGS",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getOrgById =
  ({ id }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "REQUEST_ORG" });

      const { data } = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}/orgs/${id}`
      );

      dispatch({ type: "GET_ORG_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "FAIL_ORG",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const getMyOrg = () => async (dispatch, getState) => {
  try {
    dispatch({ type: "REQUEST_MY_ORG" });

    const { userInfo } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/orgs/myorg`,
      config
    );

    dispatch({ type: "GET_MY_ORG", payload: data });

    dispatch(getOrgOccurrences(data.uuid));
  } catch (error) {
    dispatch({
      type: "FAIL_MY_ORG",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getEventByOrgIdRequest = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_EVENTS_ORG_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/events/myEvents/${id}`,
      config
    );

    dispatch({ type: "GET_EVENTS_ORG_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "GET_EVENTS_ORG_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const editOrgRequest =
  ({ id, values }) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: "EDIT_ORG_REQUEST" });

      const { userInfo } = getState();
      const config = {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const { data } = await axios.put(
        `${process.env.REACT_APP_PUBLIC_URL}/orgs/${id}`,
        values,
        config
      );

      dispatch({ type: "EDIT_ORG_SUCCESS", payload: data });
    } catch (error) {
      dispatch({
        type: "EDIT_ORG_FAIL",
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const addOrgRequest = (values) => async (dispatch, getState) => {
  try {
    dispatch({ type: "ADD_ORG_REQUEST" });

    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };
    const { data } = await axios.post(
      `${process.env.REACT_APP_PUBLIC_URL}/orgs`,
      values,
      config
    );

    dispatch({ type: "ADD_ORG_SUCCESS", payload: data });
  } catch (error) {
    dispatch({
      type: "ADD_ORG_FAIL",
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const getOrgOccurrences = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: "GET_ORG_OCCURRENCES_REQUEST" });
    const { userInfo } = getState();
    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_PUBLIC_URL}/orgs/${id}/occurrences`,
      config
    );

    dispatch({ type: "GET_ORG_OCCURRENCES_SUCCESS", payload: data.data });
  } catch (error) {
    console.log(error);
    dispatch({ type: "GET_ORG_OCCURRENCES_FAIL", payload: error });
  }
};
