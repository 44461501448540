import React from "react";
import withAuth from "../../../Hoc/withAuth";
import {
  ListItem,
  ListItemText,
  ListItemButton,
  ListItemIcon,
  Typography,
  Stack,
} from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useTheme } from "@emotion/react";
import AvatarImage from "../../ImageUploaders/AvatarImage";

const ConnectedOrgItems = ({ user }) => {
  const theme = useTheme();

  let currentTheme = theme.palette.mode;

  const lightStyle = {
    // borderRadius: "0 20px 20px 0",
    // backgroundColor: "transparent",
    // backgroundImage: "linear-gradient(45deg,  #83fdfefe,transparent)",
    // boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.05)",\
    position: "relative",
    border: "1px solid transparent",
    backgroundClip: "padding-box",
    borderRadius: "0 20px 20px 0",

    "&:after": {
      position: "absolute",
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      background: `linear-gradient(to left,  #83fdfefe,#8cffbe35)`,
      backgroundColor: "#ffffff",
      content: '""',
      zIndex: -1,
      borderRadius: "0 20px 20px 0",
    },
  };

  const darkStyle = {
    backgroundImage: "linear-gradient(to left,  #70b9fd2a,#8cffbe35)",
    backgroundColor: "transparent",
  };

  const location = useLocation();

  const router = useHistory();
  let OrgIdList = [];

  if (user.SchoolStudent && user.SchoolStudent.approved === true) {
    OrgIdList.push(user.SchoolStudent.Org);
  }
  if (user?.Org) {
    OrgIdList.push(user?.Org);
  }

  if (user?.parentSchool) {
    OrgIdList.push(user?.parentSchool?.Org);
  }

  let currentPath = location.pathname;

  return (
    <>
      <ListItem
        button
        sx={{
          m: 0,
          p: 0,
          borderRadius: "0 20px 20px 0",
          ...(currentPath === "/profile" &&
            (currentTheme === "light" ? lightStyle : darkStyle)),
        }}>
        <ListItemButton
          onClick={(event) => {
            router.push("/profile");
          }}
          sx={{
            borderRadius: "0 20px 20px 0",
            mx: 1,
            px: 0,
            backgroundColor: "transparent",
          }}>
          <ListItemIcon>
            <AvatarImage size="small" uuid={user?.uuid} />
          </ListItemIcon>
          <ListItemText
            primary={
              <Stack>
                <Typography align="left" variant="button" color="textPrimary">
                  {user.name}
                </Typography>
                <Typography align="left" variant="body2">
                  {user.role}
                </Typography>
              </Stack>
            }
          />
        </ListItemButton>
      </ListItem>
      {OrgIdList?.map((org) => {
        let isSelected = currentPath.includes(org?.uuid) ? true : false;

        return (
          <ListItem
            key={org?.uuid}
            sx={{
              m: 0,
              p: 0,
              borderRadius: "0 20px 20px 0",
              ...(isSelected &&
                (currentTheme === "light" ? lightStyle : darkStyle)),
            }}>
            <ListItemButton
              sx={{
                borderRadius: "0 20px 20px 0",
                mx: 1,
                px: 0,
                backgroundColor: "transparent",
              }}
              onClick={() => {
                if (org && org?.orgType === "school") {
                  router.push(`/mySchool/${org?.uuid}`);
                } else {
                  router.push(`/org/${org?.uuid}`);
                }
              }}>
              <ListItemIcon sx={{ mx: 0, px: 0 }}>
                <AvatarImage size="small" uuid={org?.uuid} />
              </ListItemIcon>
              <ListItemText
                sx={{ my: 0, py: 0 }}
                primary={org?.name}
                secondary="Notice board"
              />
            </ListItemButton>
          </ListItem>
        );
      })}
    </>
  );
};

export default withAuth(ConnectedOrgItems);
