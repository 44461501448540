import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
  Grid,
  CardHeader,
  Stack,
  Box,
  Tooltip,
  CardActionArea,
  CardActions,
} from "@mui/material";

import moment from "moment";
import React from "react";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { cancelBookingRequest } from "../../Redux/actions/Bookings";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const BookingsCard = (props) => {
  const { name, eventDate, status, createdAt, timeSlots, booking } = props;

  const router = useHistory();

  return (
    <Card sx={{ m: 0 }}>
      <CardHeader
        title={
          <Typography variant="h6">{`${booking?.Event?.name}`}</Typography>
        }
        subheader={`${booking?.User?.username}`}
        action={
          <Chip
            label={status}
            variant="outlined"
            color={
              status === "active"
                ? "primary"
                : status === "completed"
                ? "success"
                : status === "completed"
                ? "info"
                : "warning"
            }
          />
        }
        avatar={
          <Tooltip title="Click to view event details">
            <CardActionArea
              onClick={() => {
                router.push(`/events/${booking?.Event?.uuid}`);
              }}>
              <Avatar
                sx={{ width: 56, height: 56 }}
                variant="square"
                alt={`${name} Profile Picture`}
                src={`${process.env.REACT_APP_IMAGE_URL}/${booking?.Event?.banner}`}
              />
            </CardActionArea>
          </Tooltip>
        }
      />

      <CardContent>
        {booking.startTime && (
          <Stack justifyContent="center">
            <Chip
              variant="outlined"
              label={` Started At : ${moment(booking?.startTime).format(
                "dddd, MMMM Do YYYY, h:mm:ss a"
              )}`}
            />
          </Stack>
        )}

        <Typography variant="body1" color="text.secondary">
          {moment(eventDate).format("dddd, MMMM Do YYYY")}
        </Typography>
        <Grid container spacing={2}>
          {timeSlots &&
            timeSlots.map((time, index) => {
              return (
                <Grid item key={index} sx={{ mt: 2 }}>
                  <Chip label={time.slot} />
                </Grid>
              );
            })}
        </Grid>
      </CardContent>
      <>
        <Box p={1} display="flex" align="center" justifyContent="space-between">
          <Chip
            label={
              <Typography variant="caption" color="text.secondary">
                Created at : {moment(createdAt).format("dddd, MMMM Do YYYY")}
              </Typography>
            }
            color="default"
            variant="outlined"
          />
        </Box>

        <CardActions>
          {status === "active" && <CancelBooking booking={booking} />}
        </CardActions>
      </>
    </Card>
  );
};

export default BookingsCard;

const CancelBooking = ({ booking }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const dispatch = useDispatch();

  const handelCancel = () => {
    dispatch(cancelBookingRequest(booking.uuid));
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button color="error" onClick={handleClickOpen}>
        Cancel Booking
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description">
        <DialogTitle id="cancel-dialog-title">
          Are you sure you want to cancel this booking?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Close
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handelCancel}
            autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
