import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { useHistory } from "react-router-dom";
import TagListComp from "./TagListComp";
import BannerImage from "../ImageUploaders/BannerImage";

const OrgCard = ({ data, token }) => {
  const history = useHistory();

  return (
    <Card sx={{ maxWidth: "100%", overflow: "hidden" }}>
      <CardActionArea onClick={() => history.push(`/org/${data?.uuid}`)}>
        <BannerImage uuid={data?.uuid} token={token} />
      </CardActionArea>
      <CardContent>
        {/* <CardHeader
          title={data?.name}
          avatar={
          
          }
        /> */}
        <Stack direction="row" alignItems="center">
          <Typography
            variant="body1"
            fontWeight="500"
            gutterBottom
            noWrap
            color="textPrimary">
            {data?.name}
          </Typography>
        </Stack>

        <Typography gutterBottom variant="subtitle1" noWrap color="textPrimary">
          {data?.description}
        </Typography>
        {data?.Events && (
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle1" color="textPrimary">
              Events available: {data?.Events.length}
            </Typography>
          </Box>
        )}

        <TagListComp orgTags={data.OrgTags} />
      </CardContent>
    </Card>
  );
};

export default OrgCard;
