import styled from "@emotion/styled";
import { Button, Stack, Divider } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useMutation } from "react-query";
import AlertPopup from "../Items/AlertPopup";
import AvatarImage from "./AvatarImage";
import withAuth from "../../Hoc/withAuth";

const Input = styled("input")({
  display: "none",
});

const UploadAvatarImage = ({ uuid, token, type = "avatar" }) => {
  const [avatar, setAvatar] = useState("");
  const [name, setName] = useState("");

  const UploadImage = useMutation((image) =>
    axios.put(`${process.env.REACT_APP_PUBLIC_URL}/images/org`, image, {
      headers: { Authorization: `Bearer ${token}` },
    })
  );

  const handleImageInput = (event) => {
    const file = event.target.files[0];
    setName(file.name);

    if (file) {
      setAvatar(URL.createObjectURL(file));
    }
  };

  return (
    <>
      {avatar ? (
        <ImageCropper
          name={name}
          type={type}
          uuid={uuid}
          UploadImage={UploadImage}
          cancel={() => setAvatar("")}
          src={avatar}
        />
      ) : (
        <>
          <Stack direction="row" alignItems="center">
            <AvatarImage uuid={uuid} token={token} type={type} />

            <label htmlFor="contained-avatar-file">
              <Input
                accept="image/*"
                id="contained-avatar-file"
                type="file"
                onChange={handleImageInput}
              />
              <Button fullWidth component="span">
                Change Avatar Image
              </Button>
            </label>
          </Stack>

          <AlertPopup
            open={UploadImage.isError}
            severity="error"
            message={
              UploadImage.error?.response?.data?.message
                ? UploadImage.error?.response?.data?.message
                : "An error occurred"
            }
          />
          <AlertPopup
            open={UploadImage.isSuccess}
            severity="success"
            message="Image uploaded successfully"
          />
        </>
      )}
    </>
  );
};

export default withAuth(UploadAvatarImage);

const ImageCropper = ({ src, cancel, UploadImage, uuid, type, name }) => {
  const cropperRef = useRef(null);
  const [loadingUpload, setLoading] = useState(false);

  const isMountedRef = useRef(true);

  useEffect(() => {
    // Component did mount logic can go here

    // Cleanup function to set isMountedRef to false when the component unmounts
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const onSave = () => {
    if (!isMountedRef.current) return; // Guard against running if component has unmounted

    setLoading(true); // Start loading
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    cropper?.getCroppedCanvas().toBlob((blob) => {
      const formData = new FormData();
      formData.append("file", blob, name);
      formData.append("link_id", uuid);
      formData.append("type", type);
      UploadImage.mutate(formData, {
        onSuccess: () => {
          if (!isMountedRef.current) return; // Check mounted status before updating state
          cancel();
        },
        onError: () => {
          if (!isMountedRef.current) return; // Check mounted status before updating state
          console.error("Upload failed");
        },
        onSettled: () => {
          if (!isMountedRef.current) return; // Always check mounted status before updating state
          setLoading(false);
        },
      });
    }, "image/jpeg");
  };

  const customStyles = {
    cropper: {
      borderRadius: "50%",
      overflow: "hidden",
    },
    // Additional styles for the circular mask or other elements can go here
  };

  return (
    <Stack spacing={2}>
      <Cropper
        src={src}
        style={{ height: 300, width: "100%", ...customStyles }}
        aspectRatio={1}
        guides={true}
        background={false}
        responsive={true}
        viewMode={1}
        autoCropArea={1}
        ref={cropperRef}
      />
      <Stack
        direction="row"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}>
        <Button onClick={cancel} variant="contained">
          Cancel
        </Button>
        <LoadingButton
          onClick={onSave}
          loading={loadingUpload}
          loadingPosition="end"
          endIcon={<SaveIcon />}
          variant="contained"
          color="secondary">
          Save
        </LoadingButton>
      </Stack>
    </Stack>
  );
};
