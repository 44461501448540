import * as React from "react";

import Slide from "@mui/material/Slide";
import { useFormikContext } from "formik";
import {
  Grid,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  DialogTitle,
  TextField,
} from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ContactsDialog() {
  const [open, setOpen] = React.useState(false);

  const { values, setFieldValue } = useFormikContext();

  const [contact, setContact] = React.useState({
    name: "",
    email: "",
    telephone: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setContact({
      name: "",
      email: "",
      telephone: "",
    });

    setOpen(false);
  };

  const handleSave = () => {
    setFieldValue("contacts", [...values.contacts, contact]);
    setContact({
      name: "",
      email: "",
      telephone: "",
    });
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Button
        sx={{ my: 1 }}
        variant="contained"
        color="secondary"
        onClick={handleClickOpen}>
        Add Contact
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>Add Contacts to your organisation.</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                type="text"
                name="name"
                placeholder="Name"
                value={contact.name}
                onChange={(e) => {
                  setContact({ ...contact, name: e.target.value });
                }}
              />
            </Grid>

            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                type="text"
                name="email"
                placeholder="Email"
                value={contact.email}
                onChange={(e) => {
                  setContact({ ...contact, email: e.target.value });
                }}
              />
            </Grid>

            <Grid item lg={12} xl={12} md={12} sm={12} xs={12}>
              <TextField
                fullWidth
                name="telephone"
                type="number"
                placeholder="Telephone"
                value={contact.telephone}
                onChange={(e) => {
                  setContact({ ...contact, telephone: e.target.value });
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <DialogActions>
                <Button
                  variant="contained"
                  color="warning"
                  onClick={handleClose}>
                  Discard
                </Button>
                <Button
                  onClick={handleSave}
                  variant="contained"
                  color="secondary">
                  Save
                </Button>
              </DialogActions>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
