import React, { useContext, useEffect, useState } from "react";
import EventNoteTwoToneIcon from "@mui/icons-material/EventNoteTwoTone";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import NotificationsIcon from "@mui/icons-material/Notifications";
import DrawerListItem from "../Components/DrawerListItem";
import StyleIcon from "@mui/icons-material/Style";

import withAuth from "../../../Hoc/withAuth";
import Badge from "@mui/material/Badge";
import { NotificationContext } from "../../../App";

const GlobalDrawerItems = ({ token }) => {
  const [countUnseenNotif, setCountUnseenNotif] = useState(0);

  const { notifications } = useContext(NotificationContext);

  useEffect(() => {
    let count = notifications?.filter((notif) => notif.read === false).length;
    setCountUnseenNotif(count);
  }, [notifications]);

  return (
    <>
      <DrawerListItem
        secondary={
          countUnseenNotif > 0 ? (
            <Badge badgeContent={countUnseenNotif} color="error">
              <NotificationsIcon />
            </Badge>
          ) : null
        }
        name="Notifications"
        path="notifications"
        Icon={NotificationsIcon}
      />
      <DrawerListItem
        outlined={true}
        name="Events"
        path="Events"
        Icon={EventNoteTwoToneIcon}
      />
      <DrawerListItem
        outlined={true}
        name="Organisations"
        path="orgs"
        Icon={LocationCityIcon}
      />
      <DrawerListItem name="Causes" path="CausesList" Icon={StyleIcon} />
    </>
  );
};

export default withAuth(GlobalDrawerItems);
