import React from "react";
import { useLocation } from "react-router-dom";
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListItem,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useTheme } from "@emotion/react";

const DrawerListItem = ({ path, name, Icon, secondary, outlined }) => {
  const location = useLocation();
  const router = useHistory();

  const theme = useTheme();

  let currentTheme = theme.palette.mode;

  const lightStyle = {
    // borderRadius: "0 20px 20px 0",
    // backgroundColor: "transparent",
    // backgroundImage: "linear-gradient(45deg,  #83fdfefe,transparent)",
    // boxShadow: "2px 2px 2px 2px rgba(0,0,0,0.05)",\
    position: "relative",
    border: "1px solid transparent",
    backgroundClip: "padding-box",
    borderRadius: "0 20px 20px 0",

    "&:after": {
      position: "absolute",
      top: -1,
      left: -1,
      right: -1,
      bottom: -1,
      background: `linear-gradient(to left,  #83fdfefe,#8cffbe35)`,
      backgroundColor: "#ffffff",
      content: '""',
      zIndex: -1,
      borderRadius: "0 20px 20px 0",
      "@mui-transition": "background 2s ease-in-out",
    },
  };

  const darkStyle = {
    backgroundImage: "linear-gradient(to left,  #70b9fd2a,#8cffbe35)",
    backgroundColor: "transparent",
  };

  let currentLocation = location?.pathname?.split("/")[1];

  let isSelected = currentLocation === path ? true : false;

  return (
    <ListItem
      secondaryAction={secondary}
      sx={{
        m: 0,
        p: 0,
        borderRadius: "0 20px 20px 0",
        ...(outlined && {
          border: "1px dashed #70b9fd2a",
        }),

        ...(isSelected && (currentTheme === "light" ? lightStyle : darkStyle)),
      }}>
      <ListItemButton
        sx={{ borderRadius: "0 20px 20px 0" }}
        selected={isSelected}
        onClick={() => {
          router.push(`/${path}`);
        }}>
        <ListItemIcon>
          <Icon color="primary" />
        </ListItemIcon>
        <ListItemText primary={name} />
      </ListItemButton>
    </ListItem>
  );
};

export default DrawerListItem;
