import React, { useState, useEffect, useCallback } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { useTheme } from "@emotion/react";
import { useFormikContext } from "formik";

const libraries = ["places"];

const MapAndSearchComponent = () => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === "dark";
  const [zoom, setZoom] = useState(15); // Start with a default zoom level

  useEffect(() => {
    setTimeout(() => {
      setZoom(15);
    }, 300);
  }, []);

  const { setFieldValue, values } = useFormikContext();
  const [center, setCenter] = useState({ lat: -34.397, lng: 150.644 });
  const [address, setAddress] = useState(values.address || "");

  const containerStyle = {
    width: "100%",
    height: "400px",
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  };

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || "",
    libraries,
  });

  useEffect(() => {
    // Guard against executing this logic before the API is loaded
    if (isLoaded && values.address) {
      geocodeByAddress(values.address)
        .then((results) => getLatLng(results[0]))
        .then((latLng) => {
          setCenter(latLng);
          setAddress(values.address); // Optionally update the input field
        })
        .catch((error) => console.error("Error", error));
    }
    setZoom(15); // Zoom in closer for selected addresses
  }, [values.address, isLoaded]);

  const handleChange = (address) => {
    setAddress(address);
  };

  useEffect(() => {
    setFieldValue("location", center);
    // eslint-disable-next-line
  }, [center]);

  const handleSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      setCenter(latLng);
      setAddress(results[0].formatted_address);

      setFieldValue("address", results[0].formatted_address);
      setZoom(15); // Zoom in closer for selected addresses
    } catch (error) {
      console.error("Error", error);
    }
  };

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(center);
      map.setCenter(bounds);
    },
    [center]
  );

  const onUnmount = useCallback(function callback() {
    setCenter({ lat: -34.397, lng: 150.644 }); // Reset or maintain center as needed
  }, []);

  if (loadError) {
    return <div>Map cannot be loaded right now, sorry.</div>;
  }

  return (
    <div>
      {isLoaded && (
        <>
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}>
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading,
            }) => (
              <Box>
                <TextField
                  sx={{ mb: 2 }}
                  {...getInputProps({
                    placeholder: "Search Places ...",
                    className: "location-search-input",
                  })}
                  fullWidth
                  variant="outlined"
                />
                {loading && <Typography>Loading...</Typography>}
                {suggestions.length > 0 && (
                  <Paper
                    elevation={2}
                    sx={{ mt: 1, maxHeight: 300, overflow: "auto" }}>
                    {suggestions.map((suggestion, index) => (
                      <Box
                        {...getSuggestionItemProps(suggestion)}
                        key={index}
                        sx={{
                          padding: 1,
                          mb: 1,
                          backgroundColor: suggestion.active
                            ? theme.palette.action.selected
                            : theme.palette.background.paper,
                          cursor: "pointer",
                          "&:hover": {
                            backgroundColor: theme.palette.background.default,
                          },
                        }}>
                        <Typography variant="body2">
                          {suggestion.description}
                        </Typography>
                      </Box>
                    ))}
                  </Paper>
                )}
              </Box>
            )}
          </PlacesAutocomplete>
          <GoogleMap
            options={{
              styles: isDarkMode ? darkModeStyles : [],
            }}
            mapContainerStyle={containerStyle}
            center={center}
            zoom={zoom}
            onLoad={onLoad}
            onUnmount={onUnmount}>
            <Marker position={center} />
          </GoogleMap>
        </>
      )}
    </div>
  );
};

export default MapAndSearchComponent;

const darkModeStyles = [
  { elementType: "geometry", stylers: [{ color: "#252528" }] },
  { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
  { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [{ color: "#263c3f" }],
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [{ color: "#19191C" }],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [{ color: "#38414e" }],
  },
  {
    featureType: "road",
    elementType: "geometry.stroke",
    stylers: [{ color: "#212a37" }],
  },
  {
    featureType: "road",
    elementType: "labels.text.fill",
    stylers: [{ color: "#9ca5b3" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [{ color: "#746855" }],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [{ color: "#1f2835" }],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [{ color: "#f3d19c" }],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [{ color: "#2f3948" }],
  },
  {
    featureType: "transit.station",
    elementType: "labels.text.fill",
    stylers: [{ color: "#d59563" }],
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [{ color: "#17263c" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [{ color: "#515c6d" }],
  },
  {
    featureType: "water",
    elementType: "labels.text.stroke",
    stylers: [{ color: "#17263c" }],
  },
];
